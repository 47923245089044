import React from 'react'
import { Link } from 'react-router-dom'
// import team1 from '../../../Images_1/Teams/OurTeamImages/TechnicalTeam/Syed-Zaheer-Hasan.jpg';
// import team2 from '../../../Images_1/Teams/OurTeamImages/TechnicalTeam/PLS-Rao.jpg'
// import team3 from '../../../Images_1/Teams/OurTeamImages/TechnicalTeam/PH-Rao.jpg'
// import team4 from '../../../Images_1/Teams/OurTeamImages/TechnicalTeam/SantoshDhubia.jpg'
// import team5 from '../../../Images_1/Teams/OurTeamImages/TechnicalTeam/K-Ramachandran.jpg'
// import team6 from '../../../Images_1/Teams/OurTeamImages/TechnicalTeam/AkashDavda.jpg'
// import team7 from '../../../Images_1/Teams/OurTeamImages/TechnicalTeam/KaushikPatel.jpg'
// import team8 from '../../../Images_1/Teams/OurTeamImages/TechnicalTeam/M-Abdul-Rasheed.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import { useNavigate } from "react-router-dom";
import teamData from "../json/Team.json";

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './OurTeam.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import BreadCrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/team.jpg'
import { BASE_URL, BASE_URL_Link } from '../../../constants';
import OnScrollAnimation from '../../OnScrollAnimation';
function TechnicalTeams() {
    const navigate = useNavigate()
    return (
        <div>
            <div>
                <BreadCrumbs parent="Home" child="Technical Team" parentPath="/" imgPath={BreadCrumbImage} />
            </div>

            <section className="section-lgb tm-main">
                <div className="container-team pbmit-col-stretched-yes pbmit-col-right">
                    <div className="row main-div-team">
                        {/* <div className='heading-container' style={{ color: "#18181B" }}>
                            <h4 className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }} >Our Team</h4>
                            <h2 id="title1" className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }}>Technical Team</h2>
                            <div className=' div-for-hr-animation'>
                                <hr id='hr1' className='animate-hr-tag-about-us' />
                                <hr id='hr2' className='animate-hr-tag-about-us' />
                            </div>
                        </div> */}
                        <OnScrollAnimation title1="tt1" title2="tt2" hr1="tt3" hr2="tt4" heading="Technical Team" subheading="" />
                      
                      
                        <div className='team-block'>
                            <h4 className='medium-heading-fonts'>New Technology Management</h4>
                            <div className='team-grid-div-2'>


                                {
                                    teamData["technical"][2].NTM && teamData["technical"][2].NTM.map((data, index) => {

                                        return (
                                            <div className="pbmit-service-style-4 team-block-size">
                                                <div className="pbminfotech-post-item team-block-alignment">
                                                    <div className="pbmit-featured-wrapper img-border team-img-alignment">
                                                    {data.type && data.type=="HOD"?
                                                    <Link  onClick={() =>
                                                            window.open("teamdetails/" + data.id + "/" + "technical" + "/" + "NTM" + "/2")
                                                            // navigate("/teamdetails/"+data.id+"/"+"technical"+"/"+"NTM"+"/2", { state:data })
                                                        } >
                                                       
                                                        <img 
                                                            src={process.env.PUBLIC_URL + data.imageSrc}

                                                            className="img-fluid img-radius img-team-dept-style" alt="" />
                                                       </Link>:
                                                        <img 
                                                        src={process.env.PUBLIC_URL + data.imageSrc}

                                                        className="img-fluid img-radius img-team-dept-style" alt="" />
                                                  
                                                       } {/* <img src={team6} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                                    </div>
                                                    <div className='team-content-div'>
                                                        <div className='team-about-div'>
                                                            <h3 className='team-name-class twenty-sixteen-fonts'>{data.name}</h3>
                                                            <h3 className='team-name-class small-text'>{data.jobPost}</h3>
                                                            { data.type && data.type=="HOD"?
                                                           <> <h3 className='team-name-class small-text'>{data.emailid}</h3><h3 className='team-name-class small-text'>{data.contactno}</h3>
                                                           </> :
                                                            <></>}
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })}


                            </div>
                        </div>

                        <div className='team-block'>
                            <h4 className='medium-heading-fonts'>Renewables Management</h4>
                            <div className='team-grid-div-2'>



                                {
                                    teamData["technical"][3].RM && teamData["technical"][3].RM.map((data, index) => {

                                        return (
                                            <div className="pbmit-service-style-4 team-block-size">
                                                <div className="pbminfotech-post-item team-block-alignment">
                                                    <div className="pbmit-featured-wrapper img-border team-img-alignment">
                                                    {data.type && data.type=="HOD"?
                                                    <Link  onClick={() =>
                                                            window.open("teamdetails/" + data.id + "/" + "technical" + "/" + "RM" + "/3")
                                                        } >
                                                        <img src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                        {/* <img src={team7} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                                    </Link>: <img src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                       }
                                                    </div>
                                                    <div className='team-content-div'>
                                                        <div className='team-about-div'>
                                                            <h3 className='team-name-class twenty-sixteen-fonts'>{data.name}</h3>
                                                            <h3 className='team-name-class small-text'>{data.jobPost}</h3>
                                                            { data.type && data.type=="HOD"?
                                                           <> <h3 className='team-name-class small-text'>{data.emailid}</h3><h3 className='team-name-class small-text'>{data.contactno}</h3>
                                                           </> :
                                                            <></>}
                                                            {/* <h4 className='team-desig-class small-text'>{data.jobPost}</h4> */}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                            </div>
                        </div>
                        <div className='team-block'>
                            <h4 className='medium-heading-fonts'>Petroleum Management</h4>
                            <div className='team-grid-div-2'>
                                {
                                    teamData["technical"][1].PM && teamData["technical"][1].PM.map((data, index) => {

                                        return (
                                            <div className="pbmit-service-style-4 team-block-size">
                                                <div className="pbminfotech-post-item team-block-alignment">
                                                    <div className="pbmit-featured-wrapper img-border team-img-alignment">
                                                    {data.type && data.type=="HOD"?
                                                    <Link onClick={() =>
                                                        
                                                            window.open("teamdetails/" + data.id + "/" + "technical" + "/" + "PM" + "/1")
                                                        }>
                                                        <img  src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                        </Link>:  <img  src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                    }
                                                        {/* <img src={team3} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                                    </div>
                                                    <div className='team-content-div'>
                                                        <div className='team-about-div'>
                                                            <h3 className='team-name-class twenty-sixteen-fonts'>{data.name}</h3>
                                                            <h3 className='team-name-class small-text'>{data.jobPost}</h3>
                                                            { data.type && data.type=="HOD"?
                                                           <> <h3 className='team-name-class small-text'>{data.emailid }</h3><h3 className='team-name-class small-text'>{ data.contactno}</h3>
                                                           </> :
                                                            <></>}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}





                            </div>
                        </div>
                      
                      
                        <div className='team-block'>
                            <h4 className='medium-heading-fonts'>Petroleum Research Management</h4>
                            <div className='team-grid-div-2'>


                                {
                                    teamData["technical"][0].PRM && teamData["technical"][0].PRM.map((data, index) => {

                                        return (
                                            <div className="pbmit-service-style-4 team-block-size">
                                                <div className="pbminfotech-post-item team-block-alignment">
                                                    <div className="pbmit-featured-wrapper img-border team-img-alignment">
                                                  
                                                    {data.type && data.type=="HOD"?
                                                   <Link onClick={() => window.open( "teamdetails/" + data.id + "/" + "technical" + "/" + "PRM" + "/0")}>
                                                        <img 
                                                            src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                     </Link> :<img 
                                                            src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                    }
                                                            {/* <img src={team1} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                                    </div>
                                                    <div className='team-content-div'>
                                                        <div className='team-about-div'>
                                                            <h3 className='team-name-class twenty-sixteen-fonts'>{data.name}</h3>
                                                            <h3 className='team-name-class small-text'>{data.jobPost}</h3>
                                                            { data.type && data.type=="HOD"?
                                                           <> <h3 className='team-name-class small-text'>{data.emailid }</h3><h3 className='team-name-class small-text'>{ data.contactno}</h3>
                                                           </> :
                                                            <></>}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })
                                }




                                {/* <div className="pbmit-service-style-4 team-block-size">
                                    <div className="pbminfotech-post-item team-block-alignment">
                                        <div className="pbmit-featured-wrapper img-border team-img-alignment">
                                        <img onClick={() => navigate("/teamdetails", { state: teamData["technical"][1] })} src={process.env.PUBLIC_URL + teamData["technical"][1].imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                        </div>
                                        <div className='team-content-div'>
                                            <div className='team-about-div'>
                                                <h3 className='team-name-class twenty-sixteen-fonts'>Dr. P. L. S. Rao</h3>
                                                <h4 className='team-desig-class small-text'>Dy. General Manager</h4>
                                            </div>
                                          
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                      

                       

                        <div className='team-block'>
                            <h4 className='medium-heading-fonts'>Environment Management</h4>
                            <div className='team-grid-div-2'>



                                {teamData["technical"][4].EM && teamData["technical"][4].EM.map((data, index) => {

                                    return (

                                        <div className="pbmit-service-style-4 team-block-size">
                                            <div className="pbminfotech-post-item team-block-alignment">
                                                <div className="pbmit-featured-wrapper img-border team-img-alignment">
                                                {data.type && data.type=="HOD"?
                                                <Link onClick={() =>
                                                        window.open("teamdetails/" + data.id + "/" + "technical" + "/" + "EM" + "/4")


                                                    }>
                                                       
                                                    <img  src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                   </Link>: <img  src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                }
                                                    {/* <img src={team8} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                                </div>
                                                <div className='team-content-div'>
                                                    <div className='team-about-div'>
                                                        <h3 className='team-name-class twenty-sixteen-fonts'>{data.name}</h3>
                                                        <h3 className='team-name-class small-text'>{data.jobPost}</h3>
                                                            { data.type && data.type=="HOD"?
                                                           <> <h3 className='team-name-class small-text'>{data.emailid }</h3><h3 className='team-name-class small-text'>{ data.contactno}</h3>
                                                           </> :
                                                            <></>}
                                                        {/* <h4 className='team-desig-class small-text'>Dy. General Manager and 'Scientist E1'</h4> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>

                    </div>
                </div>

            </section >
        </div >
    )
}

export default TechnicalTeams
