import React from 'react'
import { Link } from 'react-router-dom'


import image3 from   '../../Images_1/Departments/DepartmentList/ntdl1.png'
import image2n1 from '../../Images_1/Departments/DepartmentList/rmds1.png'
import image3n2 from '../../Images_1/Departments/DepartmentList/pmds2.png'
import image4n1 from '../../Images_1/Departments/DepartmentList/emdl1.png'
import image5n3 from '../../Images_1/Departments/DepartmentList/prmdl1.png'

import "./DepartmentDemo.css"
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import BreadCrumbImage from '../../Images_1/CommonSection/Braedcrumbs/DM.jpg'
const DepartmentDemo = () => {
    return (
        <div>
            <BreadCrumbs parent="Home" child="Departments" parentPath="/" imgPath={BreadCrumbImage} />
            <section className='department-details-section'>
                <div className='department-details-div-container'>
                    <div className='department-details-div dep-div-1'>
                        <div className='flex-center-div'>
                            <div className='department-details-div-text-div'>
                                <h4 className='department-details-heading'>New Technology Management</h4>
                                <p className='department-details-text normal-text'>
                                    The New Technology Management Department is dedicated to leading-edge and emerging technologies, presently concentrating on the swiftly evolving domains of renewable energy,
                                    e-mobility and testing. Through research, skilling and advisory services, the department strives to propel developments in these arenas, positioning itself as a leader in technological advancement and fostering a greener, more sustainable future.
                                </p>
                                <Link to='new-technology-management/5/0'>
                                    <div className='seemore-text-department'>
                                        <button className='see-more-button-department'>
                                            Read More
                                        </button>
                                    </div>
                                </Link>
                            </div>
                            <div className='department-details-div-image-div-1-1'>
                                {/* <div className='department-details-div-image-div-1'>
                                    <img className='dep-detail-image dep-image-1' src={image1} />
                                    <img className='dep-detail-image dep-image-2' src={image2} />
                                </div> */}

                                <div className='department-details-div-image-div-2'>
                                    <img className='dep-image-3' src={image3} />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='dep-div-2'>
                        <div className='department-details-div dep-div-2-bg'>
                            <div className='flex-center-div dep-div-2'>
                                {/* <div className='dep-div-2-bg dep-div-2'> */}
                                <div className='department-details-div-text-div'>
                                    <h4 className='department-details-heading heading-fonts'>Renewables Management</h4>
                                    <p className='department-details-text normal-text'>
                                        *Renewables Management (RM) Department, where innovation meets sustainability!
                                        As a leading Renewable Project Management Consultancy firm in India, we specialize in providing comprehensive and cutting-edge services to propel renewable energy initiatives to new heights in various states of India. Our team of experts is dedicated to guiding the clients through every stage of the project,
                                        ensuring a seamless transition towards a cleaner and greener future.
                                    </p>
                                    <Link to='renewable-management/5/0'>
                                        <div className='seemore-text-department'>
                                            <button className='see-more-button-department'>
                                                Read More
                                            </button>
                                        </div>
                                    </Link>
                                </div>
                                <div className='department-details-div-image-div-2'>
                                    {/* <div className='shape-div-3'></div> */}
                                    {/* <img className='dep-detail-image dep-image-4' src={image2n1} /> */}
                                    <img className='dep-image-3' src={image2n1} />
                                    {/* <div className='shape-div-4'></div> */}
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                    <div className='department-details-div dep-div-1'>
                        <div className='flex-center-div'>
                            <div className='department-details-div-text-div'>
                                <h4 className='department-details-heading'>Petroleum Management</h4>
                                <p className='department-details-text normal-text'>
                                    GERMI’s Petroleum Management Department excels in hydrocarbon exploration and production,
                                    offering advanced data interpretation and analysis, in collaboration with CMPDI and ONGC. Our innovative software improves seismic imaging, supporting international oil and gas leaders with block evaluation, reservoir characterization,
                                    and reserves estimation. Explore our expertise for precise, expert-led geological insights.
                                </p>
                                <Link to='petroleum-management/5/0'>
                                    <div className='seemore-text-department'>
                                        <button className='see-more-button-department'>
                                            Read More
                                        </button>
                                    </div>
                                </Link>
                            </div>
                            <div className='department-details-div-image-div-1-1'>
                                {/* <div className='department-details-div-image-div-1'>
                                    <img className='dep-detail-image dep-image-1' src={image3n1} />
                                    <img className='dep-detail-image dep-image-2' src={image3n2} />
                                </div> */}
                                {/* <div className='shape-div'></div> */}
                                <div className='department-details-div-image-div-2'>
                                    <img className='dep-image-3' src={image3n2} />
                                </div>
                                {/* <div className='shape-div-2'></div> */}
                            </div>
                        </div>
                    </div>

                    <div className='dep-div-2'>
                        <div className='department-details-div dep-div-2-bg'>
                            <div className='flex-center-div dep-div-2'>
                                <div className='department-details-div-text-div'>
                                    <h4 className='department-details-heading'>Environment Management </h4>
                                    <p className='department-details-text normal-text'>
                                        The Environment Management Department conducts research on national and global environmental issues, emphasizing pollution management, energy efficiency, renewable energy, and waste-to-energy optimization. It assists industries
                                        in implementing innovative technologies and holistic interventions. Research addresses emerging environmental challenges, particularly in Gujarat and nationwide, aligned with India's commitments to multilateral environmental agreements,
                                        prioritizing India's environmental and energy efficiency needs.
                                    </p>
                                    <Link to='environment-management/5/0'>
                                        <div className='seemore-text-department'>
                                            <button className='see-more-button-department'>
                                                Read More
                                            </button>
                                        </div>
                                    </Link>
                                </div>
                                <div className='department-details-div-image-div-1-2'>
                                    <img className='dep-image-3' src={image4n1} />
                                    {/* <img className='dep-detail-image dep-image-5' src={image4n2} /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='department-details-div dep-div-1'>
                        <div className='flex-center-div'>
                            <div className='department-details-div-text-div'>
                                <h4 className='department-details-heading'>Petroleum Research Management </h4>
                                <p className='department-details-text normal-text'>
                                The Petroleum Research Management Group excels in hydrocarbon exploration tools, branching into diverse research areas like synthetic combustion fuels, qualitative water resource management, CO2 sequestration and laboratory support to industries. 
                                Noteworthy achievements include numerous publications, research proposals, and patents, notably in seaweed cellulose-based technology. Future plans involve expanding facilities for industrial needs, advancing green hydrogen blending with natural gas, 
                                biogas-compressed biogas development and collaborating with institutes / industries for enhanced R & D.
                                </p>
                                <Link to='petroleum-research-management/5/0'>
                                    <div className='seemore-text-department'>
                                        <button className='see-more-button-department'>
                                            Read More
                                        </button>
                                    </div>
                                </Link>
                            </div>
                            <div className='department-details-div-image-div-1-1'>
                                {/* <div className='department-details-div-image-div-1'>
                                    <img className='dep-detail-image dep-image-1' src={image5n1} />
                                    <img className='dep-detail-image dep-image-2' src={image5n2} />
                                </div> */}
                                {/* <div className='shape-div'></div> */}
                                <div className='department-details-div-image-div-2'>
                                    <img className='dep-image-3' src={image5n3} />
                                </div>
                                {/* <div className='shape-div-2'></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default DepartmentDemo;
