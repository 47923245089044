import * as React from 'react';
import Table from '@mui/material/Table';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
export default function EducationTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Degree</TableCell>
            <TableCell align="right">Completed in Year</TableCell>
            <TableCell align="right">Board / University</TableCell>
            <TableCell align="right">Subjects / Specialization</TableCell>
            <TableCell align="right">Percentage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              Diploma
            </TableCell>

            <TableCell align="right">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker views={['year', 'month']}
                    label="Year" />
                </DemoContainer>
              </LocalizationProvider>
            </TableCell>
            <TableCell align="right">
              <TextField required id="outlined-basic" variant="outlined" />
            </TableCell>
            <TableCell align="right">
              <TextField required id="outlined-basic" variant="outlined" />
            </TableCell>
            <TableCell align="right">
              <TextField
                id="outlined-number"
                // label="Gross Salary (Monthly)"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </TableCell>



          </TableRow>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              Bachelor's
            </TableCell>

            <TableCell align="right">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker views={['year', 'month']}
                    label="Year" />
                </DemoContainer>
              </LocalizationProvider>
            </TableCell>
            <TableCell align="right">
              <TextField required id="outlined-basic" variant="outlined" />
            </TableCell>
            <TableCell align="right">
              <TextField required id="outlined-basic" variant="outlined" />
            </TableCell>
            <TableCell align="right">
            <TextField
                id="outlined-number"
                // label="Gross Salary (Monthly)"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </TableCell>



          </TableRow>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              Master's
            </TableCell>

            <TableCell align="right">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker views={['year', 'month']}
                    label="Year" />
                </DemoContainer>
              </LocalizationProvider>
            </TableCell>
            <TableCell align="right">
              <TextField required id="outlined-basic" variant="outlined" />
            </TableCell>
            <TableCell align="right">
              <TextField required id="outlined-basic" variant="outlined" />
            </TableCell>
            <TableCell align="right">
            <TextField
                id="outlined-number"
                // label="Gross Salary (Monthly)"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </TableCell>



          </TableRow>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              Doctoral
            </TableCell>

            <TableCell align="right">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker views={['year', 'month']}
                    label="Year" />
                </DemoContainer>
              </LocalizationProvider>
            </TableCell>
            <TableCell align="right">
              <TextField required id="outlined-basic" variant="outlined" />
            </TableCell>
            <TableCell align="right">
              <TextField required id="outlined-basic" variant="outlined" />
            </TableCell>
            <TableCell align="right">
            <TextField
                id="outlined-number"
                // label="Gross Salary (Monthly)"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </TableCell>



          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}