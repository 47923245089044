// import newsImage from '../../../../Images_1/Notifications/News/NewsHomeImages/newsImage.jpg';
// import newsImage2 from '../../../../Images_1/Notifications/News/NewsDetailImages/newsImage-mou-2.png';
// import newsImage3 from '../../../../Images_1/Notifications/News/NewsDetailImages/newsImage-mou-3.png';
const NoticeData = {
    News: [
        {
            type: "News",
            // image1: newsImage,
            // image2: newsImage2,
            // image3: newsImage3,
            title: "MOU signed between Gujarat Energy Research and Management Institute (GERMI) and National Institute of Solar Energy (NISE) For Association in the field of Renewable Energy.",
            desc1: "Gujarat Energy Research and Management Institute (GERMI) registered under the Societies Registration Act, 1860 and the Bombay Public Trust Act, 1950 and National Institute of Solar Energy (NISE), Gwal Pahari, Faridabad – Gurugram road, Gurugram, Haryana is an autonomous institution of Ministry of New and Renewable Energy (MNRE), is the apex National R&D institution in the field of Solar Energy.",
            desc2:"Dr. Anirbid Sircar, Director General, GERMI and Dr. A. K. Tripathy, Director General, NISE signed and exchanged the MoU at NISE, Delhi on 8th April 2019.",
            desc3:"GERMI and NISE both are committed to skill development in India and have agreed to acknowledge each other’s strengths & expertise and enter into a long term and sustainable professional collaboration to evolve measures to facilitate meaningful and productive cooperation for achieving common objectives in mutually beneficial areas in the field of renewable energy technologies, electrical vehicle and energy storage systems for skill development programs and research. NISE and GERMI will explore, undertake and support each other in Renewable Energy and especially Solar Energy initiatives including but not limited to Projects, Research and Development, Solar Energy Laboratory Development, Training and Education, Capacity Building and skilling in Renewable Energy Sector, Developing Testing facility for Solar related and other equipment, undertake joint Consultancy Projects in Renewable Energy particularly Solar Energy, jointly propose and undertake Government funded Projects and so on.",
            date: "4th Mar 2024",
            author:""
        },
        {
            type: "News",
            // image1: newsImage,
            // image2: newsImage2,
            // image3: newsImage3,
            title: "MOU signed between Gujarat Energy Research and Management Institute (GERMI) and National Institute of Solar Energy (NISE) For Association in the field of Renewable Energy.",
            desc1: "Gujarat Energy Research and Management Institute (GERMI) registered under the Societies Registration Act, 1860 and the Bombay Public Trust Act, 1950 and National Institute of Solar Energy (NISE), Gwal Pahari, Faridabad – Gurugram road, Gurugram, Haryana is an autonomous institution of Ministry of New and Renewable Energy (MNRE), is the apex National R&D institution in the field of Solar Energy.",
            desc2:"Dr. Anirbid Sircar, Director General, GERMI and Dr. A. K. Tripathy, Director General, NISE signed and exchanged the MoU at NISE, Delhi on 8th April 2019.",
            desc3:"GERMI and NISE both are committed to skill development in India and have agreed to acknowledge each other’s strengths & expertise and enter into a long term and sustainable professional collaboration to evolve measures to facilitate meaningful and productive cooperation for achieving common objectives in mutually beneficial areas in the field of renewable energy technologies, electrical vehicle and energy storage systems for skill development programs and research. NISE and GERMI will explore, undertake and support each other in Renewable Energy and especially Solar Energy initiatives including but not limited to Projects, Research and Development, Solar Energy Laboratory Development, Training and Education, Capacity Building and skilling in Renewable Energy Sector, Developing Testing facility for Solar related and other equipment, undertake joint Consultancy Projects in Renewable Energy particularly Solar Energy, jointly propose and undertake Government funded Projects and so on.",
            date: "4th Mar 2024",
            author:""
        }
    ],
    Announcement: [
        {
            type: "Announcement",
            // image1: newsImage,
            // image2: newsImage2,
            // image3: newsImage3,
            title: "MOU signed between Gujarat Energy Research and Management Institute (GERMI) and National Institute of Solar Energy (NISE) For Association in the field of Renewable Energy.",
            desc1: "Gujarat Energy Research and Management Institute (GERMI) registered under the Societies Registration Act, 1860 and the Bombay Public Trust Act, 1950 and National Institute of Solar Energy (NISE), Gwal Pahari, Faridabad – Gurugram road, Gurugram, Haryana is an autonomous institution of Ministry of New and Renewable Energy (MNRE), is the apex National R&D institution in the field of Solar Energy.",
            desc2:"Dr. Anirbid Sircar, Director General, GERMI and Dr. A. K. Tripathy, Director General, NISE signed and exchanged the MoU at NISE, Delhi on 8th April 2019.",
            desc3:"GERMI and NISE both are committed to skill development in India and have agreed to acknowledge each other’s strengths & expertise and enter into a long term and sustainable professional collaboration to evolve measures to facilitate meaningful and productive cooperation for achieving common objectives in mutually beneficial areas in the field of renewable energy technologies, electrical vehicle and energy storage systems for skill development programs and research. NISE and GERMI will explore, undertake and support each other in Renewable Energy and especially Solar Energy initiatives including but not limited to Projects, Research and Development, Solar Energy Laboratory Development, Training and Education, Capacity Building and skilling in Renewable Energy Sector, Developing Testing facility for Solar related and other equipment, undertake joint Consultancy Projects in Renewable Energy particularly Solar Energy, jointly propose and undertake Government funded Projects and so on.",
            date: "4th Mar 2024",
            author:""
        },
        {
            type: "Announcement",
            // image1: newsImage,
            // image2: newsImage2,
            // image3: newsImage3,
            title: "MOU signed between Gujarat Energy Research and Management Institute (GERMI) and National Institute of Solar Energy (NISE) For Association in the field of Renewable Energy.",
            desc1: "Gujarat Energy Research and Management Institute (GERMI) registered under the Societies Registration Act, 1860 and the Bombay Public Trust Act, 1950 and National Institute of Solar Energy (NISE), Gwal Pahari, Faridabad – Gurugram road, Gurugram, Haryana is an autonomous institution of Ministry of New and Renewable Energy (MNRE), is the apex National R&D institution in the field of Solar Energy.",
            desc2:"Dr. Anirbid Sircar, Director General, GERMI and Dr. A. K. Tripathy, Director General, NISE signed and exchanged the MoU at NISE, Delhi on 8th April 2019.",
            desc3:"GERMI and NISE both are committed to skill development in India and have agreed to acknowledge each other’s strengths & expertise and enter into a long term and sustainable professional collaboration to evolve measures to facilitate meaningful and productive cooperation for achieving common objectives in mutually beneficial areas in the field of renewable energy technologies, electrical vehicle and energy storage systems for skill development programs and research. NISE and GERMI will explore, undertake and support each other in Renewable Energy and especially Solar Energy initiatives including but not limited to Projects, Research and Development, Solar Energy Laboratory Development, Training and Education, Capacity Building and skilling in Renewable Energy Sector, Developing Testing facility for Solar related and other equipment, undertake joint Consultancy Projects in Renewable Energy particularly Solar Energy, jointly propose and undertake Government funded Projects and so on.",
            date: "4th Mar 2024",
            author:"GERMI"
        }
    ],
    Tender: [
        {
            type: "Tender",
            // image1: newsImage,
            // image2: newsImage2,
            // image3: newsImage3,
            title: "MOU signed between Gujarat Energy Research and Management Institute (GERMI) and National Institute of Solar Energy (NISE) For Association in the field of Renewable Energy.",
            desc1: "Gujarat Energy Research and Management Institute (GERMI) registered under the Societies Registration Act, 1860 and the Bombay Public Trust Act, 1950 and National Institute of Solar Energy (NISE), Gwal Pahari, Faridabad – Gurugram road, Gurugram, Haryana is an autonomous institution of Ministry of New and Renewable Energy (MNRE), is the apex National R&D institution in the field of Solar Energy.",
            desc2:"Dr. Anirbid Sircar, Director General, GERMI and Dr. A. K. Tripathy, Director General, NISE signed and exchanged the MoU at NISE, Delhi on 8th April 2019.",
            desc3:"GERMI and NISE both are committed to skill development in India and have agreed to acknowledge each other’s strengths & expertise and enter into a long term and sustainable professional collaboration to evolve measures to facilitate meaningful and productive cooperation for achieving common objectives in mutually beneficial areas in the field of renewable energy technologies, electrical vehicle and energy storage systems for skill development programs and research. NISE and GERMI will explore, undertake and support each other in Renewable Energy and especially Solar Energy initiatives including but not limited to Projects, Research and Development, Solar Energy Laboratory Development, Training and Education, Capacity Building and skilling in Renewable Energy Sector, Developing Testing facility for Solar related and other equipment, undertake joint Consultancy Projects in Renewable Energy particularly Solar Energy, jointly propose and undertake Government funded Projects and so on.",
            date: "4th Mar 2024",
            author:""
        },
        {
            type: "Tender",
            // image1: newsImage,
            // image2: newsImage2,
            // image3: newsImage3,
            title: "MOU signed between Gujarat Energy Research and Management Institute (GERMI) and National Institute of Solar Energy (NISE) For Association in the field of Renewable Energy.",
            desc1: "Gujarat Energy Research and Management Institute (GERMI) registered under the Societies Registration Act, 1860 and the Bombay Public Trust Act, 1950 and National Institute of Solar Energy (NISE), Gwal Pahari, Faridabad – Gurugram road, Gurugram, Haryana is an autonomous institution of Ministry of New and Renewable Energy (MNRE), is the apex National R&D institution in the field of Solar Energy.",
            desc2:"Dr. Anirbid Sircar, Director General, GERMI and Dr. A. K. Tripathy, Director General, NISE signed and exchanged the MoU at NISE, Delhi on 8th April 2019.",
            desc3:"GERMI and NISE both are committed to skill development in India and have agreed to acknowledge each other’s strengths & expertise and enter into a long term and sustainable professional collaboration to evolve measures to facilitate meaningful and productive cooperation for achieving common objectives in mutually beneficial areas in the field of renewable energy technologies, electrical vehicle and energy storage systems for skill development programs and research. NISE and GERMI will explore, undertake and support each other in Renewable Energy and especially Solar Energy initiatives including but not limited to Projects, Research and Development, Solar Energy Laboratory Development, Training and Education, Capacity Building and skilling in Renewable Energy Sector, Developing Testing facility for Solar related and other equipment, undertake joint Consultancy Projects in Renewable Energy particularly Solar Energy, jointly propose and undertake Government funded Projects and so on.",
            date: "4th Mar 2024",
            author:""
        }
    ]
}

export default NoticeData;