import React, { useState, useEffect, useRef } from 'react'
import '../../css/bootstrap.min.css'
import '../../css/fontawesome.css'
import '../../css/flaticon.css'
import '../../css/pbminfotech-base-icons.css'
import '../../css/swiper.min.css'
import '../../css/magnific-popup.css'
import '../../css/shortcode.css'
import '../../css/demo-3.css'
import '../../css/base.css'
import '../../css/style.css'
import '../../css/responsive.css'
import HomeSlider from './HomeSlider/HomeSlider'
import HomeAboutUs from './HomeAboutUs/HomeAboutUs'
import SuccessNumbers from './Individual/SuccessNumbers'
import HomeNotices from './HomeNotices/HomeNotices.js'
import NewSuccessStories from './SuccessStories/NewSuccessStories/NewSuccessStories.js'
import Choose from './Choose/Choose.js'
import Clients from './Clients/Clients.js'
import './Home.css'
import HomeCounter from './HomeCounter/HomeCounter'
import HomeServicesNew from './HomeServicesNew/Home2ServicesNew.js'

import { PropagateLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux'
import { Updates } from './store/UpdateDataSlice.js'
import { Helmet } from 'react-helmet'
import ScrollButton from './ScrollButton/ScrollButton.js'
// import InstagramEmbed from 'react-instagram-embed';

import OnScrollAnimation from '../OnScrollAnimation.js'
import SocialMedia from './SocialMedia/SocialMedia.js'
import Home3ServicesNew from './HomeServicesNew/Home3ServicesNew.js'


const Popup = ({ onClose }) => {
    const popupRef = useRef(null);

  useEffect(() => {
    // Handle clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);
    return (
      <div className='popupoverlay'>
        <div className='div-card-container' ref={popupRef}>

        <button onClick={onClose} className='close-button'>
          &times;
        </button>

          {/* Image Section */}
          <div className='Section1'>
            <img
              src="http://localhost:3000/static/media/counter-bg2.94ce531e98a4c13c1551.jpg" // Replace with your image URL
              alt="Popup"
             className='popupimage'
            />
          </div>
  
          {/* Text Section */}
          <div className='Section2'>
            <h4>Welcome!This is your 5-second popup with an image and a description.</h4>
            <p>This is your 5-second popup with an image and a description.This is your 5-second popup with an image and a description.This is your 5-second popup with an image and a description.This is your 5-second popup with an image and a description.</p>
            <button onClick={console.log("")}>View Detail</button>
          </div>
        </div>
      </div>
    );
};



function Homepage() {

    const [showPopup, setShowPopup] = useState(false);

    // useEffect(() => {
    //   // Set a timer to show the popup after 5 seconds
    //   const timer = setTimeout(() => {
    //     setShowPopup(true);
    //   }, 5000);
  
    //   // Cleanup timer when component unmounts
    //   return () => clearTimeout(timer);
    // }, []);
  
    const closePopup = () => {
      setShowPopup(false);
    };
    const instagramPostUrl = 'https://www.instagram.com/p/CGXASWKjlMa/'; // Replace with your Instagram post URL

    // Function to check if scrollbar is visible
    // function isScrollbarVisible() {
    //     return document.documentElement.scrollHeight > window.innerHeight;
    // }

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Updates());
    }, []);
    const updates = useSelector((state) => state.updatesdata);
    const updatesData = updates?.data?.data;

    // // Function to check if an element is in the viewport
    // function isInViewport(element) {
    //     const rect = element.getBoundingClientRect();
    //     return (
    //         rect.top >= 0 &&
    //         rect.left >= 0 &&
    //         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    //         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    //     );
    // }

    // // Function to start animation when HR elements are in viewport
    // function startAnimationOnScroll() {
    //     const hr1 = document.getElementById('hr1');
    //     const hr2 = document.getElementById('hr2');
    //     const title1 = document.getElementById('title1');
    //     const title2 = document.getElementById('title2');
    //     if (title1 && isInViewport(title1)) {
    //         title1.classList.add('title-animation-about-us');
    //     }
    //     if (title2 && isInViewport(title2)) {
    //         title2.classList.add('title-animation-about-us');
    //     }
    //     if (hr1 && isInViewport(hr1)) {
    //         hr1.classList.add('animate-hr-tag-about-us');
    //     }
    //     if (hr2 && isInViewport(hr2)) {
    //         hr2.classList.add('animate-hr-tag-about-us');
    //     }
    // }

    // // Check scrollbar visibility and start animation on scroll
    // function handleScroll() {
    //     if (isScrollbarVisible()) {
    //         startAnimationOnScroll();
    //         window.removeEventListener('scroll', handleScroll); // Remove the scroll listener once animation starts
    //     }
    // }

    // // Check scrollbar visibility on page load and window resize
    // window.addEventListener('DOMContentLoaded', handleScroll);
    // window.addEventListener('resize', handleScroll);
    // window.addEventListener('scroll', startAnimationOnScroll);
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 4000)
    // }, [])

    return (
        <>
         {showPopup && <Popup onClose={closePopup} />}
            <Helmet>
                <title>GERMI</title>
                <title hidden>Gujarat Energy Resource Management Institute</title>
            </Helmet>
            {/* <PropagateLoader
                color={"#7cba27"}
                loading={loading}
                size={30}
                className='loader-center'
                aria-label="Loading Spinner"
                data-testid="loader"
                style={loading ? {
                    display: "flex"
                } : { display: "none" }}
            />*/}
            <div >
                <div className="page-wrapper pbmit-bg-color-white">
                    <div className="page-content">

                        {/* Home Slider Start */}

                        <HomeSlider data={updatesData} />

                        {/* Home Slider End */}

                        {/* About Us Start */}
                        <section>
                            <HomeAboutUs />
                        </section>
                        {/* About Us End */}

                        {/* Success Number Start */}
                        {/* <section>
                        <SuccessNumbers />
                    </section> */}

                        <section>
                            <HomeCounter />
                        </section>
                        {/* Success Number End */}


                        {/* Success Stories End */}

                        {/* <section >
                            <HomeServicesNew />
                        </section> */}
                        <section >
                            <Home3ServicesNew />
                        </section>

                        {/* Success Stories Start */}
                        <section className='ns-section'>
                            <NewSuccessStories />
                        </section>
                        {/* Services End */}

                        {/* Notice Card Start */}
                        <section>
                            <HomeNotices />
                        </section>
                        {/* Notice Card End */}

                        {/* Choose Start */}
                        <section>
                            <Choose />
                        </section>
                        {/* Choose End */}

                        {/* Social Media Start */}
                        {/* <SocialMedia /> */}
                        {/*Social Media End */}    

                        {/* Clients Start */}
                        {/* <section className='clients-bg-div'>
                            <div className="container">
                                <div className=" text-center">
                                    <div className='pt-5'>
                                    <OnScrollAnimation title1="oc1" title2="oc2" hr1="oc3" hr2="oc4" heading="Our Clients" subheading="" />
                                    </div>

                                </div>
                                <Clients />
                            </div>
                        </section> */}
                        {/* Clients End */}


                    </div>
                </div>


            </div>
        </>
    )
}

export default Homepage;
