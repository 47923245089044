import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const AnnouncementById = createAsyncThunk('AnnouncementById', async(id) => {

  
  const response = await publicRequest.post('/getannouncementbyid?id=',JSON.stringify(id));
  return response.data.data;
});

const getAnnouncementByIdSlice = createSlice({
  name: 'AnnouncementById',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(AnnouncementById.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default getAnnouncementByIdSlice.reducer;