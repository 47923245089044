import React, { useState } from 'react'
import './Clients.css'
import Divider from '@mui/material/Divider'
import img2 from '../../../Images_1/Home/ClientsImages/Central/cmpdi.png'
import img1 from '../../../Images_1/Home/ClientsImages/Central/bharat.png'
import img3 from '../../../Images_1/Home/ClientsImages/Central/coal.png'
import img4 from '../../../Images_1/Home/ClientsImages/Central/fertilizers.png'
import img5 from '../../../Images_1/Home/ClientsImages/Central/gnfc.png'
import img6 from '../../../Images_1/Home/ClientsImages/Central/gnrl.png'
import img7 from '../../../Images_1/Home/ClientsImages/Central/gp.png'
import img8 from '../../../Images_1/Home/ClientsImages/Central/gspc.png'
import img9 from '../../../Images_1/Home/ClientsImages/Central/gujarat_power.png'
import img10 from '../../../Images_1/Home/ClientsImages/Central/ntpc.png'
import img11 from '../../../Images_1/Home/ClientsImages/Govt/ced.png'
import img12 from '../../../Images_1/Home/ClientsImages/Govt/climate.png'
import img13 from '../../../Images_1/Home/ClientsImages/Govt/geda.png'
import img14 from '../../../Images_1/Home/ClientsImages/Govt/gujarat_govt.png'
import img15 from '../../../Images_1/Home/ClientsImages/Govt/guvnl.png'
import img16 from '../../../Images_1/Home/ClientsImages/Govt/moes.png'
import img17 from '../../../Images_1/Home/ClientsImages/Govt/nise.png'
import img18 from '../../../Images_1/Home/ClientsImages/Govt/renewable.png'
import img19 from '../../../Images_1/Home/ClientsImages/Govt/sandt.png'
import img20 from '../../../Images_1/Home/ClientsImages/Govt/scgj.png'
import img21 from '../../../Images_1/Home/ClientsImages/International/csis.png'
import img22 from '../../../Images_1/Home/ClientsImages/International/giz.png'
import img23 from '../../../Images_1/Home/ClientsImages/International/isa.png'
import img24 from '../../../Images_1/Home/ClientsImages/International/renac.png'
import img25 from '../../../Images_1/Home/ClientsImages/International/shakti.png'
import img26 from '../../../Images_1/Home/ClientsImages/International/world_bank.png'
import img27 from '../../../Images_1/Home/ClientsImages/International/usaid.png'
import img28 from '../../../Images_1/Home/ClientsImages/International/undp.png'
import img29 from '../../../Images_1/Home/ClientsImages/Private/antelopus.png'
import img30 from '../../../Images_1/Home/ClientsImages/Private/bodal.png'
import img31 from '../../../Images_1/Home/ClientsImages/Private/cairn.png'
import img32 from '../../../Images_1/Home/ClientsImages/Private/essar.png'
import img33 from '../../../Images_1/Home/ClientsImages/Private/iesa.png'
import img34 from '../../../Images_1/Home/ClientsImages/Private/iffco.jpg'
import img35 from '../../../Images_1/Home/ClientsImages/Private/iwmi.png'
import img36 from '../../../Images_1/Home/ClientsImages/Private/muniseva.png'
import img37 from '../../../Images_1/Home/ClientsImages/Private/saturn.png'
import img38 from '../../../Images_1/Home/ClientsImages/Private/selan.png'
import img39 from '../../../Images_1/Home/ClientsImages/Private/torrent.png'
import img40 from '../../../Images_1/Home/ClientsImages/Private/vedanta.png'
import img41 from '../../../Images_1/Home/ClientsImages/Private/oilex.png'

function Clients() {
    const [isAnimation, changeAnimation] = useState("running");
    const [isAnimation2, changeAnimation2] = useState("running");
    const [isAnimation3, changeAnimation3] = useState("running");
    const [isAnimation4, changeAnimation4] = useState("running");
    const customStyle = { animationPlayState: isAnimation }
    const customStyle2 = { animationPlayState: isAnimation2 }
    const customStyle3 = { animationPlayState: isAnimation3 }
    const customStyle4 = { animationPlayState: isAnimation4 }
    function anEvent() { changeAnimation("paused"); }
    function anEvent2() { changeAnimation("running"); }
    function twoAnEvent() { changeAnimation2("paused"); }
    function twoAnEvent2() { changeAnimation2("running"); }
    function threeAnEvent() { changeAnimation3("paused"); }
    function threeAnEvent2() { changeAnimation3("running"); }
    function fourAnEvent() { changeAnimation4("paused"); }
    function fourAnEvent2() { changeAnimation4("running"); }
    return (
        <>
            <div className='logotitle'><h3 className='clients-subtitle medium-heading-fonts'><Divider>&nbsp; &nbsp; Central and State PSU’s &nbsp; &nbsp;</Divider></h3>
                <div className="logos__marquee">
                    <div onMouseEnter={anEvent}
                        onMouseLeave={anEvent2}
                        style={customStyle} className="marquee__logos" id="id1">
                        <img src={img1} loading="lazy" alt="Zendesk" className="aLogo" />
                        <img src={img2} loading="lazy" alt="Rakuten" className="aLogo" />
                        <img src={img3} loading="lazy" alt="" className="aLogo" />
                        <img src={img4} loading="lazy" alt="Dell" className="aLogo" />
                        <img src={img5} loading="lazy" alt="Upwork" className="aLogo" />
                        <img src={img6} loading="lazy" alt="Lattice" className="aLogo" />
                        <img src={img7} loading="lazy" alt="PWC" className="aLogo" />
                        <img src={img8} loading="lazy" alt="Getaround" className="aLogo" />
                        <img src={img9} loading="lazy" alt="Hellosign" className="aLogo" />
                    </div>
                    <div onMouseEnter={anEvent}
                        onMouseLeave={anEvent2}
                        style={customStyle} className="marquee__logos" aria-hidden="true" id="id2">
                        <img src={img1} loading="lazy" alt="Zendesk" className="aLogo" />
                        <img src={img2} loading="lazy" alt="Rakuten" className="aLogo" />
                        <img src={img3} loading="lazy" alt="" className="aLogo" />
                        <img src={img4} loading="lazy" alt="Dell" className="aLogo" />
                        <img src={img5} loading="lazy" alt="Upwork" className="aLogo" />
                        <img src={img6} loading="lazy" alt="Lattice" className="aLogo" />
                        <img src={img7} loading="lazy" alt="PWC" className="aLogo" />
                        <img src={img8} loading="lazy" alt="Getaround" className="aLogo" />
                        <img src={img9} loading="lazy" alt="Hellosign" className="aLogo" />
                    </div>
                </div>
            </div>
            
            <div className='logotitle '><h3 className='clients-subtitle medium-heading-fonts'><Divider>&nbsp; &nbsp; Government Institutes &nbsp; &nbsp;</Divider></h3>
                <div className="logos__marquee  ">
                    <div
                        onMouseEnter={twoAnEvent}
                        onMouseLeave={twoAnEvent2}
                        style={customStyle2}
                        className="marquee__logoss">
                        <img src={img10} loading="lazy" alt="Petal" className="aLogo" />
                        <img src={img11} loading="lazy" alt="Vice" className="aLogo" />
                        <img src={img12} loading="lazy" alt="Zendesk" className="aLogo" />
                        <img src={img13} loading="lazy" alt="Rakuten" className="aLogo" />
                        <img src={img14} loading="lazy" alt="" className="aLogo" />
                        <img src={img15} loading="lazy" alt="Dell" className="aLogo" />
                        <img src={img16} loading="lazy" alt="Upwork" className="aLogo" />
                        <img src={img17} loading="lazy" alt="Lattice" className="aLogo" />
                        <img src={img18} loading="lazy" alt="PWC" className="aLogo" />
                        <img src={img19} loading="lazy" alt="Getaround" className="aLogo" />
                        <img src={img20} loading="lazy" alt="Hellosign" className="aLogo" />
                    </div>
                    <div
                        onMouseEnter={twoAnEvent}
                        onMouseLeave={twoAnEvent2}
                        style={customStyle2}
                        className="marquee__logoss" aria-hidden="true" >
                        <img src={img10} loading="lazy" alt="Petal" className="aLogo" />
                        <img src={img11} loading="lazy" alt="Vice" className="aLogo" />
                        <img src={img12} loading="lazy" alt="Zendesk" className="aLogo" />
                        <img src={img13} loading="lazy" alt="Rakuten" className="aLogo" />
                        <img src={img14} loading="lazy" alt="" className="aLogo" />
                        <img src={img15} loading="lazy" alt="Dell" className="aLogo" />
                        <img src={img16} loading="lazy" alt="Upwork" className="aLogo" />
                        <img src={img17} loading="lazy" alt="Lattice" className="aLogo" />
                        <img src={img18} loading="lazy" alt="PWC" className="aLogo" />
                        <img src={img19} loading="lazy" alt="Getaround" className="aLogo" />
                        <img src={img20} loading="lazy" alt="Hellosign" className="aLogo" />
                    </div>
                </div>
            </div>
            <div className='logotitle'><h3 className='clients-subtitle medium-heading-fonts'><Divider>&nbsp; &nbsp; International Institutes &nbsp; &nbsp;</Divider></h3>
                <div className="logos__marquee">
                    <div
                        onMouseEnter={threeAnEvent}
                        onMouseLeave={threeAnEvent2}
                        style={customStyle3}
                        className="marquee__logos"
                        aria-hidden="true">
                        <img src={img21} loading="lazy" alt="Petal" className="aLogo" />
                        <img src={img22} loading="lazy" alt="Vice" className="aLogo" />
                        <img src={img23} loading="eager" alt="Zendesk" className="aLogo" />
                        <img src={img24} loading="eager" alt="Rakuten" className="aLogo" />
                        <img src={img25} loading="eager" alt="" className="aLogo" />
                        <img src={img26} loading="eager" alt="Dell" className="aLogo" />
                        <img src={img27} loading="eager" alt="Upwork" className="aLogo" />
                        <img src={img28} loading="eager" alt="Lattice" className="aLogo" />
                    </div>
                    <div
                        onMouseEnter={threeAnEvent}
                        onMouseLeave={threeAnEvent2}
                        style={customStyle3}
                        className="marquee__logos">
                        <img src={img21} loading="lazy" alt="Petal" className="aLogo" />
                        <img src={img22} loading="lazy" alt="Vice" className="aLogo" />
                        <img src={img23} loading="eager" alt="Zendesk" className="aLogo" />
                        <img src={img24} loading="eager" alt="Rakuten" className="aLogo" />
                        <img src={img25} loading="eager" alt="" className="aLogo" />
                        <img src={img26} loading="eager" alt="Dell" className="aLogo" />
                        <img src={img27} loading="eager" alt="Upwork" className="aLogo" />
                        <img src={img28} loading="eager" alt="Lattice" className="aLogo" />
                    </div>
                </div>
            </div>
            <div className='logotitle'><h3 className='clients-subtitle medium-heading-fonts'><Divider>&nbsp; &nbsp; Corporates and Others &nbsp; &nbsp;</Divider></h3>
                <div className="logos__marquee bottom-spacing">
                    <div
                        onMouseEnter={fourAnEvent}
                        onMouseLeave={fourAnEvent2}
                        style={customStyle4}
                        className="marquee__logoss">
                        <img src={img29} loading="eager" alt="PWC" className="aLogo" />
                        <img src={img30} loading="eager" alt="Getaround" className="aLogo" />
                        <img src={img31} loading="eager" alt="Hellosign" className="aLogo" />
                        <img src={img32} loading="eager" alt="Petal" className="aLogo" />
                        <img src={img33} loading="eager" alt="Vice" className="aLogo" />
                        <img src={img34} loading="eager" alt="Zendesk" className="aLogo" />
                        <img src={img35} loading="eager" alt="Rakuten" className="aLogo" />
                        <img src={img36} loading="eager" alt="" className="aLogo" />
                        <img src={img37} loading="eager" alt="Dell" className="aLogo" />
                        <img src={img38} loading="eager" alt="Upwork" className="aLogo" />
                        <img src={img39} loading="eager" alt="Lattice" className="aLogo" />
                        <img src={img40} loading="eager" alt="PWC" className="aLogo" />
                        <img src={img41} loading="eager" alt="Getaround" className="aLogo" />
                    </div>

                    <div
                        onMouseEnter={fourAnEvent}
                        onMouseLeave={fourAnEvent2}
                        style={customStyle4}
                        className="marquee__logoss"
                        aria-hidden="true"
                    >
                        <img src={img29} loading="eager" alt="PWC" className="aLogo" />
                        <img src={img30} loading="eager" alt="Getaround" className="aLogo" />
                        <img src={img31} loading="eager" alt="Hellosign" className="aLogo" />
                        <img src={img32} loading="eager" alt="Petal" className="aLogo" />
                        <img src={img33} loading="eager" alt="Vice" className="aLogo" />
                        <img src={img34} loading="eager" alt="Zendesk" className="aLogo" />
                        <img src={img35} loading="eager" alt="Rakuten" className="aLogo" />
                        <img src={img36} loading="eager" alt="" className="aLogo" />
                        <img src={img37} loading="eager" alt="Dell" className="aLogo" />
                        <img src={img38} loading="eager" alt="Upwork" className="aLogo" />
                        <img src={img39} loading="eager" alt="Lattice" className="aLogo" />
                        <img src={img40} loading="eager" alt="PWC" className="aLogo" />
                        <img src={img41} loading="eager" alt="Getaround" className="aLogo" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Clients;