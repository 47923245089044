import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const Announcements = createAsyncThunk('announcementdata', async() => {
  const response = await publicRequest.get('/announcementlist');
  return response.data.data;
});

const AnnouncementDataSlice = createSlice({
  name: 'announcementdata',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(Announcements.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default AnnouncementDataSlice.reducer;