import React, { useEffect, useState } from 'react'
import './HomeNotices.css'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import OnScrollAnimation from '../../OnScrollAnimation';
import {useTheme} from '../../../ThemeContext';
import { BASE_URL_Image } from '../../../constants';
import { getAnnouncements } from '../../noticeCards/store/DptAnnouncementDataSlice';
import { getNews } from '../../noticeCards/store/DptNewsDataSlice';
import { getTender } from '../../noticeCards/store/DptTenderDataSlice';
function HomeNoticesDepartment(props) {
    const {department}=props;
    const {isNightMode} = useTheme();
    const [selectedDiv, setSelectedDiv] = useState('announcementdiv');
    const theStyle = {
        // border: "1px solid #7cba27",
        // backgroundColor: "#7cba27",
        // color: "#ffffff",
        // border-bottom: 2px solid 
        borderBottom: isNightMode?  "2px solid rgb(24 24 27 / 1)" : "2px solid white",
        // transitionDuration: "5000ms",
        // transitionProperty: "transform",
        // transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
        // transformOrigin: "right"
        // transition: "0.5s"
    }
    const navigate = useNavigate()

    const handleNavigate = (data) => () => {
        // console.log(data);
        // navigate("/announcementdetails");
        navigate("/announcementdetails/" + data.id, { state: { id: data.id, name: data.title } });
    };
    const handleNavigate2 = () => {
        if (selectedDiv == "announcementdiv") {
            navigate("/announcementlist");
        }
        else if (selectedDiv == "newsdiv") {
            navigate("/newslist");
        }
        else {
            navigate("/tenderlist");
        }

    };
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAnnouncements({id:department}));
        dispatch(getNews({id:department}));
        dispatch(getTender({id:department}));
    }, []);
    const announcement = useSelector((state) => state.dptannouncementdata);
    const newsdata = useSelector((state) => state.newsdata);
    const tenderdata = useSelector((state) => state.tenderdata);
    // console.log("announcement")
    // console.log(announcement.data)
    // console.log("news")
    // console.log(newsdata.data)
    // console.log("tender")
    // console.log(tenderdata)
    return (
        <div>
            <div className='Notices notice-section-spacing homenotice-bg-div'>
                {/* <div className='heading-container'>
                    <h4 id="title2" className='title-animation-about-us'>Notifications</h4>
                    <div id="title1" className='title-animation-about-us title-animation-about-us-notice-text'>Explore our newest announcements, stay informed with the latest news, and seize exciting tender opportunities now available.</div>
                    <div className=' div-for-hr-animation'>
                        <hr id='hr1' className='animate-hr-tag-about-us' />
                        <hr id='hr2' className='animate-hr-tag-about-us' />
                    </div>
                </div> */}
                <OnScrollAnimation title1="hm1" title2="hm2" hr1="hm3" hr2="hm4" heading="Notifications" subheading="Explore our newest announcements, stay informed with the latest news, and seize exciting tender opportunities now available." />

                <section className=" pb-150">
                    <div className="container relative z-10">
                        {/* <div className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 flex max-md:flex-col -z-10">
                            <div className="max-1xl:w-[335px] max-1xl:h-[335px]  1xl:w-[442px] 1xl:h-[442px]  rounded-full bg-primary-200/20 blur-[145px]"></div>
                            <div className="max-1xl:w-[335px] max-1xl:h-[335px]  1xl:w-[442px] 1xl:h-[442px]  rounded-full bg-primary-200/25 -ml-[170px] max-md:ml-0 blur-[145px]"></div>
                            <div className="max-1xl:w-[335px] max-1xl:h-[335px]  1xl:w-[442px] 1xl:h-[442px]  rounded-full bg-primary-200/20 -ml-[170px] max-md:ml-0 blur-[145px]"></div>
                        </div> */}

                        <div data-aos="fade-up" data-aos-offset="200" data-aos-duration="1000" data-aos-once="true">
                            <div className="notice-button-flex">
                                <button style={selectedDiv == 'announcementdiv' ? theStyle : {}} className="notice-button twenty-sixteen-fonts left-button"
                                    onClick={() => {
                                        setSelectedDiv('announcementdiv')
                                    }}
                                >
                                    Announcement

                                </button>
                                <button style={selectedDiv == 'newsdiv' ? theStyle : {}} className="notice-button twenty-sixteen-fonts"
                                    onClick={() => {
                                        setSelectedDiv('newsdiv')
                                    }}
                                >
                                    News
                                </button>
                                <button style={selectedDiv == 'tenderdiv' ? theStyle : {}} className="notice-button twenty-sixteen-fonts"
                                    onClick={() => {
                                        setSelectedDiv('tenderdiv')
                                    }}
                                >
                                    Tender
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='main-div'>
                    <div className='newsdiv' style={selectedDiv == 'newsdiv' ? { display: 'block' } : { display: 'none' }}>
                        <Swiper
                            className="noticeSwiper"
                            modules={[Pagination, Autoplay]}
                            spaceBetween={10} slidesPerView={1} loop={true}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            pagination={{ clickable: true }}>
                            {
                                // notices && notices.filter((data) => data.tag.toLowerCase() == "news").map((data) =>
                                newsdata.data && newsdata.data.map((data) =>
                                    <SwiperSlide>
                                        <div className='card-centre-container'>
                                            <div className='card-space-container shadow-nav'>
                                                <div className='card-container'>
                                                    <div className='notice-image-container'>
                                                        <img className='image-shadow-nav image-01' src={BASE_URL_Image + data.file} alt='' />
                                                    </div>
                                                    <div className='text-container'>
                                                        <div className='flex-between'>
                                                            <span className='badgebtn xxs-small-text'>{"News"}</span>
                                                            <div className='notice-flex'>
                                                                <span className='notice-date normal-text'>{data.startdate}</span>
                                                            </div>
                                                        </div>
                                                        <h3 className='notice-card-title twentytwo-eighteen-fonts'>{data.title}</h3>
                                                        <span className='notice-card-desc normal-text'>{data.shortdes}</span>
                                                        <div className='see-more-text-card'>
                                                            <button
                                                                onClick={()=>{data.filepdf!=""?
                                                                window.open(BASE_URL_Image + data.filepdf)
                                                                :
                                                                window.open(data.reglink)}

                                                                } 
                                                                className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                            {/* <SwiperSlide>
                                <div className='card-centre-container'>
                                    <div className='card-space-container shadow-nav'>
                                        <div className='card-container'>
                                            <div className='notice-image-container'>
                                                <img className='image-shadow-nav image-01' src={newsImage} alt='' />
                                            </div>
                                            <div className='text-container'>
                                                <div className='flex-between'>
                                                    <span className='badgebtn'>News</span>
                                                    <div className='notice-flex'>
                                                        <span className='notice-date'>Mar 4 2024</span>
                                                        <span className='span-text'>to</span>
                                                        <span className='notice-date'>Mar 1 2024</span>
                                                    </div>
                                                </div>
                                                <h3 className='notice-card-title'>MoU signed between Gujarat Energy Research and Management Institute (GERMI) and National Institute of Solar Energy (NISE) For Association in the field of Renewable Energy.</h3>
                                                <div className='notice-flex flex-gap top-spacing'>
                                                    <span className='card-cost'><span>Cost:  </span>$10000</span>
                                                    <span className='card-duration'><span>Duration: </span>20 Days</span>
                                                </div>
                                                <span className='notice-card-desc'>Dr. Anirbid Sircar, Director General, GERMI and Dr. A. K. Tripathy, Director General, NISE signed and exchanged the MoU at NISE, Delhi on 8th April 2019.</span>
                                                <div className='see-more-text-card'>
                                                    <button onClick={handleNavigate([1, "News"])} className='see-more-button-card'><h6 className=' text-color-card'>Read More</h6></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card-centre-container'>
                                    <div className='card-space-container shadow-nav'>
                                        <div className='card-container'>
                                            <div className='notice-image-container'>
                                                <img className='image-shadow-nav image-01' src={newsImage2} alt='' />
                                            </div>
                                            <div className='text-container'>
                                                <div className='flex-between'>
                                                    <span className='badgebtn'>News</span>
                                                    <div className='notice-flex'>
                                                        <span className='notice-date'>Jun 3 2017</span>
                                                        <span className='span-text'>to</span>
                                                        <span className='notice-date'>Mar 1 2024</span>
                                                    </div>
                                                </div>
                                                <h3 className='notice-card-title'>The Energy Revolution is truly underway in India </h3>
                                                <div className='notice-flex flex-gap top-spacing'>
                                                    <span className='card-cost'><span>Cost:  </span>$10000</span>
                                                    <span className='card-duration'><span>Duration: </span>20 Days</span>
                                                </div>
                                                <span className='notice-card-desc'>India is well in the midst of an energy revolution. The country witnessed yet another record low in solar tariffs at INR 2.44/kWh (USD 0.037 per unit).</span>
                                                <div className='see-more-text-card'>
                                                    <button onClick={handleNavigate([2, 'News'])} className='see-more-button-card'><h6 className=' text-color-card'>Read More</h6></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> */}
                        </Swiper>
                    </div>

                    <div className='announcementdiv' style={selectedDiv == 'announcementdiv' ? { display: 'block' } : { display: 'none' }}>
                        <Swiper
                            className="noticeSwiper"
                            modules={[Pagination, Autoplay]}
                            spaceBetween={10} slidesPerView={1} loop={true}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            pagination={{ clickable: true }}>

                            {
                                announcement.data && announcement.data.map((data) => <SwiperSlide>
                                    {/* {console.log("h1" + process.env.PUBLIC_URL + data.file)} */}
                                    <div className='card-centre-container'>
                                        <div className='card-space-container shadow-nav'>
                                            <div className='card-container'>
                                                <div className='notice-image-container'>
                                                    <img className='image-shadow-nav image-01' src={BASE_URL_Image + data.file} alt='' />
                                                </div>
                                                <div className='text-container'>
                                                    <div className='flex-between'>
                                                        <span className='badgebtn xxs-small-text'>{"Announcement"}</span>
                                                        <div className='notice-flex'>
                                                            <span className='notice-date normal-text'>{data.startdate}</span>
                                                            <span className='notice-date normal-text'>to</span>
                                                            <span className='notice-date normal-text'>{data.enddate}</span>
                                                        </div>
                                                    </div>
                                                    <h3 className='notice-card-title twentytwo-eighteen-fonts'>{data.title}</h3>
                                                    <div className='notice-flex flex-gap top-spacing'>
                                                        <span className='notice-date normal-text'><span>Cost: </span>{data.cost}</span>
                                                        <span className='notice-date normal-text'><span>Duration: </span>{data.duration}</span>
                                                    </div>
                                                    <span className='notice-card-desc normal-text'>{data.shortdes}</span>
                                                    <div className='see-more-text-card'>
                                                        <button
                                                            onClick={handleNavigate(data)}
                                                            className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                     {data.isTraining=="true"?
                                                                <button
                                                                onClick={()=>{window.open("/NTMTrainingForm/"+data.id+"/"+data.title)}} 
                                                                className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Apply for Training</h6></button>
                                                                :<></>}
                                                    </div>

                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>)
                            }
                            {/* {
                                notices && notices.filter((data) => data.tag.toLowerCase() == "announcement").map((data) => <SwiperSlide>
                                    <div className='card-centre-container'>
                                        <div className='card-space-container shadow-nav'>
                                            <div className='card-container'>
                                                <div className='notice-image-container'>
                                                    <img className='image-shadow-nav image-01' src={process.env.PUBLIC_URL + data.imageSrc} alt='' />
                                                </div>
                                                <div className='text-container'>
                                                    <div className='flex-between'>
                                                        <span className='badgebtn'>{data.tag}</span>
                                                        <div className='notice-flex'>
                                                            <span className='notice-date'>{data.startDate}</span>
                                                            <span className='span-text'>to</span>
                                                            <span className='notice-date'>{data.endDate}</span>
                                                        </div>
                                                    </div>
                                                    <h3 className='notice-card-title'>{data.title}</h3>
                                                    <div className='notice-flex flex-gap top-spacing'>
                                                        <span className='card-cost'><span>Cost:  </span>{data.cost}</span>
                                                        <span className='card-duration'><span>Duration: </span>{data.duration}</span>
                                                    </div>
                                                    <span className='notice-card-desc'>{data.description}</span>
                                                    <div className='see-more-text-card'>
                                                        <button onClick={handleNavigate([1, 'Announcement'])} className='see-more-button-card'><h6 className=' text-color-card'>Read More</h6></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>)
                            } */}

                            {/* <SwiperSlide>
                                <div className='card-centre-container'>
                                    <div className='card-space-container shadow-nav'>
                                        <div className='card-container'>
                                            <div className='notice-image-container'>
                                                <img className='image-shadow-nav image-01' src={announcementImage} alt='' />
                                            </div>
                                            <div className='text-container'>
                                                <div className='flex-between'>
                                                    <span className='badgebtn'>Announcement</span>
                                                    <div className='notice-flex'>
                                                        <span className='notice-date'>Mar 12 2024</span>
                                                        <span className='span-text'>to</span>
                                                        <span className='notice-date'>Mar 22 2024</span>
                                                    </div>
                                                </div>
                                                <h3 className='notice-card-title'>5-day Training Program on "Green Hydrogen PtX - Electrolyser and Fuel Cell Demonstration".</h3>
                                                <div className='notice-flex flex-gap top-spacing'>
                                                    <span className='card-cost'><span>Cost:  </span>Free</span>
                                                    <span className='card-duration'><span>Duration: </span>5 Days</span>
                                                </div>
                                                <span className='notice-card-desc'>GERMI is organizing two batches of training program on "Green Hydrogen PtX - Electrolyser and Fuel Cell Demonstration". The details of both the training programs are given below. </span>
                                                <div className='see-more-text-card'>
                                                    <button onClick={handleNavigate([1, 'Announcement'])} className='see-more-button-card'><h6 className=' text-color-card'>Read More</h6></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card-centre-container'>
                                    <div className='card-space-container shadow-nav'>
                                        <div className='card-container'>
                                            <div className='notice-image-container'>
                                                <img className='image-shadow-nav image-01' src={announcementImage2} alt='' />
                                            </div>
                                            <div className='text-container'>
                                                <div className='flex-between'>
                                                    <span className='badgebtn'>Announcement</span>
                                                    <div className='notice-flex'>
                                                        <span className='notice-date'>Mar 19 2024</span>
                                                        <span className='span-text'>to</span>
                                                        <span className='notice-date'>Mar 23 2024</span>
                                                    </div>
                                                </div>
                                                <h3 className='notice-card-title'>5 Days Training Program on "Green Hydrogen PtX - Electrolyser and Fuel Cell Installation"</h3>
                                                <div className='notice-flex flex-gap top-spacing'>
                                                    <span className='card-cost'><span>Cost:  </span>Free</span>
                                                    <span className='card-duration'><span>Duration: </span>5 Days</span>
                                                </div>
                                                <span className='notice-card-desc'>The training will be conducted in a 'participative mode' wherein each session builds upon the previous.Topics covered during the training duration are as follows</span>
                                                <div className='see-more-text-card'>
                                                    <button onClick={handleNavigate([2, 'Announcement'])} className='see-more-button-card'><h6 className=' text-color-card'>Read More</h6></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> */}
                        </Swiper>
                    </div>

                    <div className='tenderdiv' style={selectedDiv == 'tenderdiv' ? { display: 'block' } : { display: 'none' }}>
                        <Swiper
                            className="noticeSwiper"
                            modules={[Pagination, Autoplay]}
                            spaceBetween={10} slidesPerView={1} loop={true}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            pagination={{ clickable: true }}>

                            {
                                tenderdata.data && tenderdata.data.map((data) => <SwiperSlide>
                                    <div className='card-centre-container'>
                                        <div className='card-space-container shadow-nav'>
                                            <div className='card-container'>
                                                <div className='notice-image-container'>
                                                    <img className='image-shadow-nav image-01' src={BASE_URL_Image + data.file} alt='' />
                                                </div>
                                                <div className='text-container'>
                                                    <div className='flex-between'>
                                                        <span className='badgebtn xxs-small-text'>{"Tender"}</span>
                                                        {data.startDate === data.endDate ? <div className='notice-flex'>
                                                            <span className='notice-date normal-text'>{data.startDate}</span>
                                                            {/* <span className='span-text'>to</span>
                                                        <span className='notice-date'>Mar 1 2024</span> */}
                                                        </div> : <div className='notice-flex'>
                                                            <span className='notice-date normal-text'>{data.startDate}</span>
                                                            <span className='span-text'>to</span>
                                                            <span className='notice-date normal-text'>{data.endDate}</span>
                                                        </div>}
                                                    </div>
                                                    <h3 className='notice-card-title twentytwo-eighteen-fonts'>{data.title}</h3>
                                                    {/* <div className='notice-flex flex-gap top-spacing'>
                                                    <span className='card-cost'><span>Cost:  </span>$10000</span>
                                                    <span className='card-duration'><span>Duration: </span>20 Days</span>
                                                </div> */}
                                                    <span className='notice-card-desc normal-text'></span>
                                                    {/* <span className='notice-card-desc normal-text'>{data.description}</span> */}
                                                    <div className='see-more-text-card'>
                                                            <button
                                                                onClick={()=>{data.filepdf!=""?
                                                                window.open(BASE_URL_Image + data.filepdf)
                                                                :
                                                                window.open(data.reglink)}

                                                                } 
                                                                className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>)
                            }
                            {/* 
                            <SwiperSlide>
                                <div className='card-centre-container'>
                                    <div className='card-space-container shadow-nav'>
                                        <div className='card-container'>
                                            <div className='notice-image-container'>
                                                <img className='image-shadow-nav image-01' src={tenderImage} alt='' />
                                            </div>
                                            <div className='text-container'>
                                                <div className='flex-between'>
                                                    <span className='badgebtn'>Tender</span>
                                                    <div className='notice-flex'>
                                                        <span className='notice-date'>Nov 21 2023</span>
                                                        <span className='span-text'>to</span>
                                                        <span className='notice-date'>Mar 1 2024</span>
                                                    </div>
                                                </div>
                                                <h3 className='notice-card-title'>BSPGCL EPC Tender for the project</h3>
                                                <div className='notice-flex flex-gap top-spacing'>
                                                    <span className='card-cost'><span>Cost:  </span>$10000</span>
                                                    <span className='card-duration'><span>Duration: </span>20 Days</span>
                                                </div>
                                                <span className='notice-card-desc'>Tender is to be invited for EPC and O&M (for 10 years) from a
                                                    single Contractor for 185 MW (AC) plant capacity along with
                                                    45.4 MW for 4 hours (Min. 254 MWh) BESS.</span>
                                                <div className='see-more-text-card'>
                                                    <button onClick={handleNavigate([1, 'Tender'])} className='see-more-button-card'><h6 className=' text-color-card'>Read More</h6></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card-centre-container'>
                                    <div className='card-space-container shadow-nav'>
                                        <div className='card-container'>
                                            <div className='notice-image-container'>
                                                <img className='image-shadow-nav image-01' src={tenderImage2} alt='' />
                                            </div>
                                            <div className='text-container'>
                                                <div className='flex-between'>
                                                    <span className='badgebtn'>Tender</span>
                                                    <div className='notice-flex'>
                                                        <span className='notice-date'>Jan 19 2024</span>
                                                        <span className='span-text'>to</span>
                                                        <span className='notice-date'>Feb 8 2024</span>
                                                    </div>
                                                </div>
                                                <h3 className='notice-card-title'>Request for Proposal for Empanelment of
                                                    Electrical Consultant/Expert under Solar
                                                    Project Group of GERMI for Solar
                                                    Consultancy Projects
                                                </h3>
                                                <div className='notice-flex flex-gap top-spacing'>
                                                    <span className='card-cost'><span>Cost:  </span>$10000</span>
                                                    <span className='card-duration'><span>Duration: </span>20 Days</span>
                                                </div>
                                                <span className='notice-card-desc'>Sealed bids are invited through this Request for Proposal No: GERMI/RE4/SERVICES/2022-23/02
                                                    dated 19.01.2023 from all organizations/individuals working as consultants with considerable Electrical
                                                    engineering background in designing of Electrical System/Infrastructure. </span>
                                                <div className='see-more-text-card'>
                                                    <button onClick={handleNavigate([2, 'Tender'])} className='see-more-button-card'><h6 className=' text-color-card'>Read More</h6></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            {/* </SwiperSlide> */}
                        </Swiper>
                    </div>

                    <div className='view-all-button'>
                        <button className='view-all-btn static-small-text' onClick={handleNavigate2}>View All</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeNoticesDepartment;
