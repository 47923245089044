import React, { createContext, useState, useEffect, useContext } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isNightMode, setIsNightMode] = useState(true);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setIsNightMode(savedTheme === 'night');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', isNightMode ? 'night' : 'day');
  }, [isNightMode]);

  const toggleTheme = () => {
    setIsNightMode(!isNightMode);
  };

  return (
    <ThemeContext.Provider value={{ isNightMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);