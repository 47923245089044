// src/App.js
import React from 'react';
import ExpandableCard from './ExpandableCard';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';

import BreadCrumbImage from '../../Images_1/CommonSection/Braedcrumbs/gallery.jpg';
function ExpandableCardMain() {
  return (
    <div className='exp-card-main'>
    <BreadCrumbs parent="Home" child="Screen Reader Access" parentPath='/' imgPath={BreadCrumbImage} />

    <div className="AppExp">
      <h1 className='tmarginandb'>Information related to the various screen readers</h1>
      <p className='aboutus-heading-fonts'>Gujarat Energy Research and Management Institute(GERMI) website complies with World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) 2.0 level AA. This will enable people with visual impairments access the website using assistive technologies, such as screen readers. The information of the website is accessible with different screen readers.</p>
      {/* <ExpandableCard
        title="Screen Access For All (SAFA)"
        type="Free"
        content="http://www.nabdelhi.org/NAB_SAFA.htm"
      /> */}
      <ExpandableCard
        title="Non Visual Desktop Access (NVDA)"
        type="Free"
        content="http://www.nvda-project.org/"
      />
      <ExpandableCard
        title="System Access To Go"
        type="Free"
        content="http://www.satogo.com/"
      />
      <ExpandableCard
        title="Thunder"
        type="Free"
        content="http://www.screenreader.net/index.php?pageid=2"
      />
      <ExpandableCard
        title="WebAnywhere"
        type="Free"
        content="http://webanywhere.cs.washington.edu/wa.php"
      />
      <ExpandableCard
        title="Hal"
        type="Commercial"
        content="http://www.yourdolphin.co.uk/productdetail.asp?id=5"
      />
      <ExpandableCard
        title="JAWS"
        type="Commercial"
        content="http://www.freedomscientific.com/jaws-hq.asp"
      />
      <ExpandableCard
        title="Supernova"
        type="Commercial"
        content="http://www.yourdolphin.co.uk/productdetail.asp?id=1"
      />
      <ExpandableCard
        title="Window-Eyes"
        type="Commercial"
        content="http://www.gwmicro.com/Window-Eyes/"
      />
    </div>
    </div>
  );
}

export default ExpandableCardMain;
