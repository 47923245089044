import React from 'react'
import { Link } from 'react-router-dom'

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from "react-router-dom";
// import team4 from '../../images/team4.png'
// import team5 from '../../images/team5.png'
// import team6 from '../../images/team6.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './OurDepartmentTeam.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function ComingSoonTeam() {
    const navigate = useNavigate()
    // var teamData = props.teamData;
    return (
        <div>
            <div>
                {/* <div className="pbmit-title-bar-wrapper">
                <div className="title-adjustments">
                    <div className="pbmit-title-bar-content">
                        <div className="pbmit-title-bar-content-inner">
                            <div className="pbmit-tbar ">
                            <div className="pbmit-breadcrumb title-adjustments">
                                <div className="pbmit-breadcrumb-inner  title-adjustments1">
                                    <span><Link title="" to="/" className="home"><span>GERMI</span></Link></span>
                                    <span className="sep"><i className="pbmit-base-icon-angle-right"></i></span>
                                    <span className="post-root post post-post current-item">Our Team</span>
                                    
                                </div>
                            </div>
                                <div className="pbmit-tbar-inner container title-adjustments2">
                                    <h1 className="pbmit-tbar-title ">Our Team</h1>
                                </div>
                           
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}

            </div>
            <section className="section-lgb sectionbg">
                <div className="container pbmit-col-stretched-yes pbmit-col-right">

                    <div className="row main-div" >
                        <div className='heading-container'>
                            {/* <h4 className='title-animation-about-us'>Our Team</h4> */}
                            <h2 id="title1" className='title-animation-about-us'>Our Team</h2>
                            <div className=' div-for-hr-animation'>
                                <hr id='hr1' className='animate-hr-tag-about-us' />
                                <hr id='hr2' className='animate-hr-tag-about-us' />
                            </div>
                        </div>

                        <div style={{display: "flex", justifyContent:"center"}}>
                            <h3>
                                Coming Soon
                            </h3>

                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default ComingSoonTeam
