import React, { useState } from 'react'
import ScrollToTop from '../ScrollToTop'
import "./ContactUsDepartment.css"
import OnScrollAnimation from '../OnScrollAnimation'
import { addcontactus } from './store/contactusSlice'
import { useDispatch } from 'react-redux'

const ContactUsDepartment = (props) => {
    
        const {department}=props;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        let errors = {};
        if (!formData.name) {
            errors.name = "Name is required";
        }
        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email address is invalid";
        }
        if (!formData.message) {
            errors.message = "Message is required";
        }
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            console.log(JSON.stringify(formData));

            var jsonparams = {
                "name": formData.name,
                "email": formData.email,
                "message": formData.message,
                "department": department==1?"New Technology Department":department==2?"Renewables Department":department==3?"Petroleum Department":department==4?"Petroleum Research Department":"Environment Department"
            }
            dispatch(addcontactus(jsonparams)).then((data) => {
                console.log(data);
                window.close();
            });
            setFormData({ name: '', email: '', message: '' });
            setErrors({});
        } else {
            setErrors(validationErrors);
        }
    };
    return (
        <>
            <ScrollToTop />
            <div className='send-mail-main-container department-contact-us-div'>
                <div className='department-contact-bg-black'>
                    <div className="container pbmit-col-stretched-yes pbmit-col-right">
                        <div className="row main-div">
                            <div className=" text-center">
                                {/* <div className='heading-container'>
                                    <h4 className='title-animation-about-us heading-container-white-fonts'>Contact Us</h4>
                                    <h2 id="title1" className='title-animation-about-us heading-container-white-fonts'>Keep In Touch</h2>
                                    <div className=' div-for-hr-animation'>
                                        <hr id='hr1' className='animate-hr-tag-about-us' />
                                        <hr id='hr2' className='animate-hr-tag-about-us' />
                                    </div>
                                </div> */}
                                <OnScrollAnimation isWhite="true" title1="cuntm1" title2="cuntm2" hr1="cuntm3" hr2="cuntm4" heading="Contact Us" subheading="" />
                            </div>

                            <div className='send-mail-parent-container'>

                                <div className="Send-mail-container">
                                    <div className="Send-mail-content">
                                        <form>
                                            <div className="w-full">
                                                <div className='input-text-container'>

                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Name"
                                                        className='name-input hover-div'
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.name && <p className="error">{errors.name}</p>}
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email"
                                                        className='email-input hover-div'
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.email && <p className="error">{errors.email}</p>}
                                                </div>
                                                <div className='input-text-container'>
                                                    <textarea
                                                        className='textarea-input hover-div'
                                                        rows={8}
                                                        style={{ width: "100%" }}
                                                        name="message"
                                                        placeholder='Message'
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.message && <p className="error">{errors.message}</p>}

                                                <div className="col-md-12 col-lg-12 text-white mt-1" id="contact-status"></div>

                                                <div className='cud-button'>
                                                    <div className='see-more-text-card '>
                                                        <button
                                                            onClick={handleSubmit}
                                                            className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Submit</h6></button>
                                                    </div>
                                                </div>

                                             

                                                {/* <div className="Send-mail-Form-container">
                                                    <div className='contact-us-text-dept seemore-text-dept'>
                                                        <button 
                                                            className='contact-us-button see-more-button-dept' 
                                                            onClick={handleSubmit}
                                                        >
                                                            <h6 className='text-color-dept text-contact-us-dept static-small-text'>Submit</h6>
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </div>
                                            {/* <div className=" row">

                                                <div className='input-text-container'>
                                                    <input type="text" placeholder="Name" className='name-input hover-div' />
                                                    <input type="email" placeholder="Email" className='email-input hover-div' />
                                                </div>
                                                <textarea className='textarea-input hover-div' rows={8} placeholder='Message' />
                                                <div className='cud-button'>
                                                 <div className='see-more-text-card '>
                                                    <button
                                                        // onClick={handleNavigate(data)} 
                                                        className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                 </div>
                                                </div>                                          
                                            </div> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUsDepartment
