import React, { useState } from 'react'
import TheCounter from '../TheCounter';
import './Choose.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import ScrollTrigger from 'react-scroll-trigger'
// import IndiaMap from './IndiaMap';
import IndiaMap from '../../../Images_1/Home/ChooseUsImages/map.png'
import redicon from '../../../Images_1/Home/ChooseUsImages/Red.png'
import ppicon from '../../../Images_1/Home/ChooseUsImages/Purple.png'
import greenicon from '../../../Images_1/Home/ChooseUsImages/Green.png'
import Orange from '../../../Images_1/Home/ChooseUsImages/Orange.png'
import Blue from '../../../Images_1/Home/ChooseUsImages/Dark Blue.png'
import AboutImage1 from '../../../Images_1/Home/ChooseUsImages/gujarat green perfect.png'
import AboutImage2 from '../../../Images_1/Home/ChooseUsImages/india-map-country-svgrepo-com.svg'
import AboutImage3 from '../../../Images_1/Home/ChooseUsImages/client-svgrepo-com.png'
import AboutImage4 from '../../../Images_1/Home/ChooseUsImages/checklist-svgrepo-com.svg'
function Choose() {
    const [counterState, setCounterState] = useState(false)
    return (
        <div>
            <section className="progress-six_bg choose-bg">
                <div className='choose-bg-dark-div'>
                    <div className='radiant-effect-div'>
                        <div className='upper-div'>
                            <div className="map-container pbmit-col-stretched-yes pbmit-col-right">
                                <div className="row choose-flex-div">
                                    <div id='choose-us-desc' className="col-md-6 choose-flex-child-div">
                                        <div className="progress-six_single">
                                            <div className="pbmit-heading-subheading-style-2 service-pbmit-color choose-top-spacing">
                                                <h4 className="choose-title heading-fonts">GERMI Footprints</h4>
                                                <div className="progressbar-label2 service-pbmit-color normal-text">At GERMI, our commitment to excellence in environmental, petroleum, renewable, and new technology departments is unmatched, fostering a dynamic ecosystem of research excellence, industry collaboration, and technological advancement, driving impactful discoveries and shaping the future of energy research in our country.</div>
                                            </div>
                                            {/* <div className='row'> */}
                                            <div className='col-md-6 col-xl-6 choose-flex-width'>
                                                <div className="progressbar pbmit-progress-style-2">
                                                    <img src={ppicon} className="mapsm-icon" alt="dept-1" />
                                                    <span className="progress-label service-pbmit-color xxs-small-text">New Technology Management</span>
                                                    {/* <div className="progress progress-lg progress-percent-bg">
                                                    <div className="progress-bar aos aos-init aos-animate" data-aos="slide-right"
                                                        data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out"
                                                        role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"
                                                        style={{ width: '80%' }}>
                                                    </div>
                                                    <span className="progress-percent service-pbmit-color">80%</span>
                                                </div> */}
                                                </div>
                                                <div className="progressbar pbmit-progress-style-2 ">
                                                    <img src={Orange} className="mapsm-icon" alt="dept-1" />
                                                    <span className="progress-label service-pbmit-color xxs-small-text">Renewables Management</span>
                                                    {/* <div className="progress progress-lg progress-percent-bg">
<div className="progress-bar aos aos-init aos-animate" data-aos="slide-right"
    data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out"
    role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"
    style={{ width: '90%' }}>
</div>
<span className="progress-percent service-pbmit-color">90%</span>
</div> */}
                                                </div>
                                                <div className="progressbar pbmit-progress-style-2">
                                                    <img src={greenicon} className="mapsm-icon" alt="dept-1" />
                                                    <span className="progress-label service-pbmit-color xxs-small-text">Petroleum Management</span>
                                                    {/* <div className="progress progress-lg progress-percent-bg">
                                                    <div className="progress-bar aos aos-init aos-animate" data-aos="slide-right"
                                                        data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out"
                                                        role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                                                        style={{ width: '70%' }}>
                                                    </div>
                                                    <span className="progress-percent service-pbmit-color">70%</span>
                                                </div> */}
                                                </div>
                                                <div className="progressbar pbmit-progress-style-2 ">
                                                    <img src={redicon} className="mapsm-icon" alt="dept-1" />
                                                    <span className="progress-label service-pbmit-color xxs-small-text">Environment Management</span>
                                                    {/* <div className="progress progress-lg progress-percent-bg">
                                                    <div className="progress-bar aos aos-init aos-animate" data-aos="slide-right"
                                                        data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out"
                                                        role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"
                                                        style={{ width: '90%' }}>
                                                    </div>
                                                    <span className="progress-percent service-pbmit-color">90%</span>
                                                </div> */}
                                                </div>
                                                <div className="progressbar pbmit-progress-style-2">
                                                    <img src={Blue} className="mapsm-icon" alt="dept-1" />
                                                    <span className="progress-label service-pbmit-color xxs-small-text">Petroleum Research Management</span>
                                                    {/* <div className="progress progress-lg progress-percent-bg">
                                                    <div className="progress-bar aos aos-init aos-animate" data-aos="slide-right"
                                                        data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out"
                                                        role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"
                                                        style={{ width: '80%' }}>
                                                    </div>
                                                    <span className="progress-percent service-pbmit-color">80%</span>
                                                </div> */}
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className='col-md-6 choose-flex-child-div'>
                                        <img className="chooseUsImg" src={IndiaMap} alt="india's map" />
                                    </div>
                                    {/* <div className="col-md-6 ">
                                        <div className="pbmit-col-stretched-right choose-image-custom-css">
                                            <IndiaMap /> 
                                             <img src={MapImage} className="" alt="" />
                                        </div>
                                    </div> */}
                                    {/* <div className='india-map-info'>Number of Tenders</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container counter-bg-dark-div counter-radiant-effect-div">
                        <div className='counter-align counter-bottom-spacing'>
                            <div className="counter-six_bg_color counter-space-setter">
                                <div className="counter-six-space-container">
                                    <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
                                        <div className="row">
                                            <div className="col-md-6 col-xl-3">
                                                <div className="pbminfotech-ele-fid-style-6 ">
                                                    <div className="pbmit-fld-contents res-div">
                                                        <div className="pbmit-content-wrap choose-bottom-div">
                                                            <div className="pbmit-sbox-icon-wrapper">
                                                                <div className="service-icon-div">
                                                                    <img src={AboutImage3} className="service-icon" alt="dept-1" />
                                                                </div>

                                                                {/* <i className="pbmit-greenly-icon pbmit-greenly-icon-solar-panel"></i> */}
                                                            </div>
                                                            <h4 className="pbmit-fid-inner text-black counter-fonts">
                                                                {counterState && <TheCounter maxValue="250" duration="100" />}
                                                                <span className="pbmit-fid-sub"><sup>+</sup></span>
                                                            </h4>
                                                            <h3 className="pbmit-fid-title text-black">
                                                                <span className='choose-bottom-section-title static-small-text'>Clients</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3">
                                                <div className="pbminfotech-ele-fid-style-6 ">
                                                    <div className="pbmit-fld-contents res-div">
                                                        <div className="pbmit-content-wrap choose-bottom-div">
                                                            <div className="pbmit-sbox-icon-wrapper">
                                                                <div className="service-icon-div">
                                                                    <img src={AboutImage4} className="service-icon" alt="dept-1" />
                                                                </div>
                                                                {/* <i className="pbmit-greenly-icon pbmit-greenly-icon-windmill"></i> */}
                                                            </div>
                                                            <h4 className="pbmit-fid-inner text-black counter-fonts">
                                                                {counterState && <TheCounter maxValue="135" duration="300" />}
                                                                <span className="pbmit-fid-sub"><sup>+</sup></span>
                                                            </h4>
                                                            <h3 className="pbmit-fid-title text-black">
                                                                <span className='choose-bottom-section-title static-small-text'>SUCCESSFUL PROJECTS</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3">
                                                <div className="pbminfotech-ele-fid-style-6 ">
                                                    <div className="pbmit-fld-contents res-div">
                                                        <div className="pbmit-content-wrap choose-bottom-div">
                                                            <div className="pbmit-sbox-icon-wrapper">
                                                                <div className="service-icon-div">
                                                                    <img src={AboutImage2} className="service-icon" alt="dept-1" />
                                                                </div>

                                                                {/* <i className="pbmit-greenly-icon pbmit-greenly-icon-renewable-energy"></i> */}
                                                            </div>
                                                            <h4 className="pbmit-fid-inner text-black counter-fonts">
                                                                {counterState && <TheCounter maxValue="24" duration="300" />}
                                                                <span className="pbmit-fid-sub"><sup>+</sup></span>
                                                            </h4>
                                                            <h3 className="pbmit-fid-title text-black">
                                                                <span className='choose-bottom-section-title static-small-text'>States in india</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3">
                                                <div className="pbminfotech-ele-fid-style-6 ">
                                                    <div className="pbmit-fld-contents res-div">
                                                        {/* d-flex */}
                                                        <div className="pbmit-content-wrap choose-bottom-div">
                                                            <div className="pbmit-sbox-icon-wrapper">
                                                                <div className="service-icon-div">
                                                                    <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                                </div>

                                                                {/* <i className="pbmit-greenly-icon pbmit-greenly-icon-greenhouse"></i> */}
                                                            </div>
                                                            <h4 className="pbmit-fid-inner text-black counter-fonts">
                                                                {counterState && <TheCounter maxValue="32" duration="100" />}
                                                                {/* <span className="pbmit-fid-sub"><sup></sup></span> */}
                                                            </h4>
                                                            <h3 className="pbmit-fid-title text-black">
                                                                <span className='choose-bottom-section-title static-small-text'>Districts in gujarat</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ScrollTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}

export default Choose;
