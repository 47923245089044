import React, { useEffect, useRef, useState } from 'react'
import "./NewTechnologyManagement.css"
import './NewOtherDept.css'
import { Link, useLocation, useParams } from 'react-router-dom';
import ContactUsDepartment from '../ContactUsDepartment';
// import HomeNotices from '../../Home/HomeNotices/HomeNotices';
import HomeNotices from '../../Home2/HomeNotices/HomeNotices'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import breadcrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/RM.jpg'
import ScrollToTop from '../../ScrollToTop';
import layer from '../../../Images_1/Departments/Images/rmdmain.png'
import NewDepartmentTeam from '../NewDepartmentTeam/NewDepartmentTeam'
import NewSuccessStories from './NewSuccessStories/NewSuccessStories';
// import sImage1 from '../../../Images_1/Departments/Services/RM Images/Pre-Feasibility Studies.jpg'
// import sImage2 from '../../../Images_1/Departments/Services/RM Images/2 - Transaction Structure Advisory.jpg'
// import sImage3 from '../../../Images_1/Departments/Services/RM Images/3 - Project Management Consultancy.jpg'
// import sImage4 from '../../../Images_1/Departments/Services/RM Images/4 - Customized Solutions 1.jpg'
// import sImage5 from '../../../Images_1/Departments/Services/RM Images/5 - Collaborative Approach.jpg'
// import sImage6 from '../../../Images_1/Departments/Services/RM Images/6 - Commitment to Excellence.jpg'
// import sImage7 from '../../../Images_1/Departments/Services/RM Images/7 - Strategic Planning Facilitation.jpg'
// import sImage8 from '../../../Images_1/Departments/Services/RM Images/8 - Market Research and Analysis.jpg'
// import sImage9 from '../../../Images_1/Departments/Services/RM Images/9 - SWOT Analysis.jpg'
// import sImage10 from '../../../Images_1/Departments/Services/RM Images/10 - Scenario Planning.jpg'
// import sImage11 from '../../../Images_1/Departments/Services/RM Images/11 - Flexible Solutions.jpg'
// import sImage12 from '../../../Images_1/Departments/Services/RM Images/12 - Confidentiality and Integrity.jpg'
import ServiceImage1 from '../../../Images_1/Departments/DepartmentHomeImages/NewTechManagement.jpg'
import ServiceImage2 from '../../../Images_1/Departments/DepartmentHomeImages/RenewableManagement.jpg'
import ServiceImage3 from '../../../Images_1/Departments/DepartmentHomeImages/PetroluemManagement.jpg'
import ServiceImage4 from '../../../Images_1/Departments/DepartmentHomeImages/EnvironmentManagement.jpg'
import ServiceImage5 from '../../../Images_1/Departments/DepartmentHomeImages/PetroleumResearchManagement.jpg'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import HomeCounter from '../../Home2/HomeCounter/HomeCounter';
import HomeCounterNew from '../../Home2/HomeCounterNew/HomeCounterNew';
import OurDepartmentTeam from '../OurDepartmentTeam';
import pageData from "./json/RenewableDepartment.json"
import ComingSoonTeam from '../ComingSoonTeam';
import OnScrollAnimation from '../../OnScrollAnimation';
import HomeNoticesDepartment from '../../Home2/HomeNotices/HomeNoticesDepartment';

import ntm from '../../../Images_1/Departments/DepartmentHomeImages/NTM.png'
import pm from '../../../Images_1/Departments/DepartmentHomeImages/PM.png'
import em from '../../../Images_1/Departments/DepartmentHomeImages/EM.png'
import prm from '../../../Images_1/Departments/DepartmentHomeImages/PRM.png'
import TheCounter from '../TheCounter';
import ScrollTrigger from 'react-scroll-trigger';

const RenewableManagement = () => {
    const { id } = useParams();
    const { id2 } = useParams();

    const containerWrapperRef = useRef(null);
    const topWrapperRef = useRef(null);

    const RM = useRef([]);
    const RM1 = useRef([]);
    const RM2 = useRef([]);
    useEffect(() => {

        if (id == 5) {
            // if (topWrapperRef.current) {
            //     // Scroll the div into view
            //     topWrapperRef.current.scrollIntoView({ behavior: 'smooth' });

            //     // Set focus on the div
            //     topWrapperRef.current.focus();
            //   }
        } else {

            if (id == 1) {

                var index3 = pageData["services"].research.findIndex(
                    (x) => x.id === id2
                );
                if (index3 !== null && RM1.current[index3]) {
                    RM1.current[index3].scrollIntoView({ behavior: 'smooth' });
                    RM1.current[index3].focus();
                }

            } else if (id == 2) {


                var index3 = pageData["services"].training.findIndex(
                    (x) => x.id === id2
                );
                if (index3 !== null && RM2.current[index3]) {
                    RM2.current[index3].scrollIntoView({ behavior: 'smooth' });
                    RM2.current[index3].focus();
                }

            } else if (id == 0) {
                var index3 = pageData["services"].advisory.findIndex(
                    (x) => x.id == id2
                );
                if (index3 !== null) {
                    RM.current[index3].scrollIntoView({ behavior: 'smooth' });
                    RM.current[index3].focus();
                }
                // }
            }
        }
        // if (containerWrapperRef.current) {
        //   // Scroll the div into view
        //   containerWrapperRef.current.scrollIntoView({ behavior: 'smooth' });

        //   // Set focus on the div
        //   containerWrapperRef.current.focus();


        // }

    }, []);
    // const state = useLocation();
    const [SelectedService, setSelectedService] = useState(['', '', '']);
    const [selectedDiv, setSelectedDiv] = useState(id == 1 ? "R&D" : id == 2 ? "T&SD" : 'A&C');
    const activeBtnStyle = {
        // backgroundColor: '#d2611c',
        backgroundColor: '#7cba27',
        color: '#ffffff',
        transition: '0.4s ease-in-out',
    }

    const [counterState, setCounterState] = useState(false);

    return (
        <div>
            <ScrollToTop />
            {/* <DepartmentHeader styles="header-transparent header-style-2" searchDisable buttonStyle="bg-color-white" /> */}
            <div ref={topWrapperRef} className='breadcrumb-margin'>
                <BreadCrumbs className="breadcrumb-text-class" parent="Departments" child="Renewables Management" parentPath="/departments" imgPath={breadcrumbImage} />
            </div>
            <div className='new-tech-whole-section dep-sec-bg'>
                <section className='new-technology-department-about-sectionrm'>

                    <img className="new-department-inner-image new-tech-image-translateCustomSlider3" src={layer} />
                    {/* <div> */}
                    {/* <img className="new-department-text-image" src={title} /> */}
                    <div className='new-tech-title-spacing'>
                        <h1 className='new-tech-custom-slider-changing-text  new-tech-image-translateCustomSlider heading-fonts'>Renewables Management</h1>

                        <h6 className='new-tech-custom-slider-changing-paragraph normal-text new-tech-image-translateCustomSlider1' style={{ textAlign: "justify" }}>Renewables Management (RM) Department: Where Innovation Meets Sustainability! 
                            As a leading Renewable Project Management Consultancy firm in India, we specialize in providing comprehensive and cutting-edge services to propel renewable energy initiatives to new heights across various states of India. Our team of experts is dedicated to guiding clients through every stage of the project, ensuring a seamless transition towards a cleaner and greener future.</h6>
                        {/* <h6 className='new-tech-custom-slider-changing-paragraph normal-text new-tech-image-translateCustomSlider1' style={{ textAlign: "justify" }}>*Renewables Management (RM) Department, where innovation meets sustainability! As a leading Renewable Project Management Consultancy firm in India, we specialize in providing comprehensive and cutting-edge services to propel renewable energy initiatives to new heights in various states of India. Our team of experts is dedicated to guiding the clients through every stage of the project, ensuring a seamless transition towards a cleaner and greener future.</h6> */}
                    </div>
                    {/* </div> */}
                </section>
            </div>

            {/* <section>
                <HomeCounterNew />
            </section> */}

            <section className='dep-sec-bg'>
                <div>
                    <section className='home-counterNew'>
                        <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
                            <div className='top-padding-zero home-counterNew-container'>
                                {/* <h2 className='home-counterNew-headingNew'>Success in Numbers</h2> */}
                                <div className='counter-div-containerNew'>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={550} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>MW Solar PMC</span>
                                        {/* <span className='counter-textNew normal-text normal-text'>Inspection Completed across the Gujarat State</span> */}
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={10000} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>MW DPR for Solar park and Solar Project</span>
                                        {/* <span className='counter-textNew normal-text normal-text'>Trainde</span> */}
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={6500} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>Solar TPI</span>
                                        {/* <span className='counter-textNew normal-text normal-text'>Certified</span> */}
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={1700} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>Solar Park Consultancy</span>
                                        {/* <span className='counter-textNew normal-text normal-text'>and Training of Master Trainers</span> */}
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={350} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>MWH BESS Consultancy</span>
                                        {/* <span className='counter-textNew normal-text normal-text'>(G&G)</span> */}
                                    </div>
                                </div>
                            </div>
                        </ScrollTrigger>
                    </section>

                </div>
                {/* <HomeCounterNew /> */}
            </section>

            <section className='dep-sec-bg'>
                {/* <div ref={containerWrapperRef} className="heading-container">
                    <h4>Our Services</h4>
                    <h4 id="title1" className="title-animation-about-us">Services We Provide</h4>
                    <div className=" div-for-hr-animation"><hr id="hr1" className="animate-hr-tag-about-us" /><hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="rm1" title2="rm2" hr1="rm3" hr2="rm4" heading="Services We Provide" subheading="" />
                {/* <div className="Totalcontainer2">
                    <h4 style={{ fontSize: 22 }}>Total Services <span style={{ color: "#7dba28" }}> | </span> 12 </h4>
                </div> */}

                <div className='top-container'>
                    <div className='service-tab-container'>
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('A&C') }} style={selectedDiv == 'A&C' ? activeBtnStyle : {}}>Advisory</Link>
                        {/* <span style={selectedDiv == 'A&C' || selectedDiv == 'R&D' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('R&D') }} style={selectedDiv == 'R&D' ? activeBtnStyle : {}}>Research</Link>
                        {/* <span style={selectedDiv == 'R&D' || selectedDiv == 'T&SD' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts' onClick={() => { setSelectedDiv('T&SD') }} style={selectedDiv == 'T&SD' ? activeBtnStyle : {}}>Training</Link>
                    </div>
                    {/* <div className="Totalcontainer">
                        <span className='total-text'>Total Services <span style={{ color: "#7cba27" }}> | </span> {pageData.services.advisory.length + pageData.services.research.length + pageData.services.training.length}  </span>
                    </div> */}
                </div>

                <div className='dps-main'>
                    <div className='dps-full'>
                        <div id='A&C' style={selectedDiv == 'A&C' ? { display: 'block' } : { display: 'none' }}>


                            {
                                pageData && pageData["services"].advisory.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?

                                        <div div key={data.id} ref={(el) => (RM.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>

                                                    }

                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (RM.current[index] = el)} className='dps-row ' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                })
                            }




                            {/* <div className="dps-row" id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}}>
                                <div className={`dps-text-content normal-text  ${SelectedService[0] === 'srv1' ? "dp-bg-design" : ""}`} style={SelectedService[0] == 'srv1' ? { width: '100%', padding: '100px 40px 70px 40px' } : {}}>
                                    <div>
                                        <h4>Pre-Feasibility Studies and Preparation of DPR/DFR</h4>
                                        <span>
                                            Our pre-feasibility studies are conducted with meticulous attention to detail and a thorough understanding of project requirements. We assess the technical, financial, environmental, and regulatory aspects of the project to determine its feasibility and potential for success.
                                        </span>
                                        <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                            <span>
                                                Our services include market analysis, technical evaluation, financial modelling, risk assessment, and stakeholder consultation.</span>
                                            <span><b>Preparation of DPR/DFR:</b> We specialize in preparing detailed project reports (DPR) or detailed feasibility reports (DFR) that serve as comprehensive blueprints for project execution. Our reports include detailed project descriptions, technical specifications, financial analysis, risk management plans, environmental and social impact assessments, legal and regulatory compliance, implementation schedules, and monitoring and evaluation frameworks.</span>

                                            <span><b>Strategic Planning Facilitation:</b> Our strategic planning facilitation services are designed to guide organizations through the strategic planning process. We facilitate collaborative workshops and meetings, gather input from key stakeholders, and help organizations define their vision, mission, values, and strategic objectives.</span>

                                            <span><b>Market Research and Analysis:</b> We conduct in-depth market research and analysis to help organizations understand market dynamics, identify emerging trends, and assess competitive landscapes. Our team provides valuable insights that inform strategic decision-making and drive business growth.</span>

                                            <span><b>SWOT Analysis:</b> We conduct SWOT (Strengths, Weaknesses, Opportunities, Threats) analyses to help organizations assess their internal capabilities and external environments. Our SWOT analyses enable organizations to identify strategic priorities, capitalize on strengths, address weaknesses, seize opportunities, and mitigate threats.</span>

                                            <span><b>Scenario Planning:</b> We assist organizations in developing strategic scenarios to anticipate and prepare for potential future uncertainties and disruptions. Our scenario planning exercises help organizations identify risks, explore alternative futures, and develop proactive strategies to navigate changing environments effectively.</span>

                                            <span><b>Strategic Plan Development:</b> We work closely with organizations to develop comprehensive strategic plans that outline clear goals, objectives, strategies, and action plans. Our strategic plans provide a roadmap for organizations to align resources, prioritize initiatives, and achieve their long-term vision.</span>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                        </div>
                                    </div>
                                    <div style={SelectedService[0] == 'srv1' ? { display: 'none' } : { display: 'block' }} className='dps-numbering'><span>1</span></div>
                                </div>

                                <div style={SelectedService[0] == 'srv1' ? { display: 'none' } : { display: 'block' }} className='dps-img-content'>
                                    <img src={sImage1} alt="hello" />
                                </div>
                            </div>
                            <div className='dps-row' id='srv2' style={SelectedService[1] == 'srv2' ? { height: '100%' } : {}}>
                                <div className='dps-img-content' style={SelectedService[1] == 'srv2' ? { display: 'none' } : { display: 'block' }}>
                                    <img src={sImage2} alt="hello" />
                                </div>
                                <div className={`dps-text-content normal-text  ${SelectedService[1] === 'srv2' ? "dp-bg-design" : ""}`} style={SelectedService[1] == 'srv2' ? { width: '100%', padding: '100px 40px 70px 40px' } : {}}>
                                    <div>
                                        <h4>Transaction Structure Advisory</h4>
                                        <span>
                                            Our Transaction Structure Advisory services are designed to assist clients in structuring deals that maximize value and minimize risks. We work closely with our clients to understand their objectives and develop customized transaction structures that align with their strategic goals.
                                        </span>
                                        <div style={SelectedService[1] == 'srv2' ? { display: 'block' } : { display: 'none' }}>
                                            <span><b>Financial Modelling and Analysis:</b> We leverage sophisticated financial modeming techniques to analyse the financial implications of various transaction structures. Our team conducts in-depth analyses to evaluate the potential impact on cash flows, valuations, and financial performance, helping clients make informed decisions.</span>

                                            <span><b>Diligence Support:</b> We provide comprehensive due diligence support to help clients identify and assess risks associated with potential transactions. Our team conducts thorough reviews of financial statements, contracts, legal documents, and other relevant information to uncover potential issues and mitigate risks.</span>

                                            <span><b>Plan Development:</b> We work closely with organizations to develop comprehensive strategic plans that outline clear goals, objectives, strategies, and action plans. Our strategic plans provide a roadmap for organizations to align resources, prioritize initiatives, and achieve their long-term vision.</span>

                                            <span><b>Process Coordination (BPC):</b> we specialize in providing expert Bid Process Coordination (BPC) services to businesses and organizations involved in procurement and bidding processes. With a dedicated team of bid coordinators and procurement specialists, we offer comprehensive solutions to help our clients streamline their bidding processes and improve their chances of winning contracts.</span>

                                            <span><b>Strategy Development:</b> We work closely with clients to develop winning bid strategies tailored to their specific needs and objectives. Our team conducts thorough analyses of bid requirements, market dynamics, and competitor landscapes to identify key differentiators and maximize our clients' chances of success.</span>

                                            <span><b>Documentation Preparation:</b> We assist clients in preparing high-quality bid documentation, including proposals, bids, tenders, and other submission materials. Our team ensures that bid documents are clear, concise, and compelling, effectively communicating our clients' value proposition and capabilities to potential clients or buyers.</span>

                                            <span><b>Coordination and Management:</b> We provide end-to-end bid coordination and management services, overseeing all aspects of the bidding process from pre-bid planning to post-submission follow-up. Our team coordinates internal and external stakeholders, manages timelines and deadlines, and ensures that all bid requirements are met.</span>

                                            <span><b>and Risk Management:</b> We help clients navigate complex bid requirements, regulations, and compliance standards to ensure that bids are submitted accurately and on time. Our team also identifies and mitigates potential risks associated with the bidding process, helping clients minimize liabilities and maximize opportunities.</span>

                                            <span><b>Analysis and Feedback:</b> We conduct post-bid analysis and feedback sessions to evaluate bid performance and identify areas for improvement. Our team gathers feedback from clients, evaluators, and other stakeholders to inform future bidding strategies and enhance our clients' competitiveness in the marketplace.</span>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], 'srv2', SelectedService[2]]) }} style={SelectedService[1] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], '', SelectedService[2]]) }} style={SelectedService[1] == 'srv2' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                        </div>
                                    </div>

                                </div>
                                <div className='dps-numbering-2' style={SelectedService[1] == 'srv2' ? { display: 'none' } : { display: 'block' }}><span>2</span></div>

                            </div>
                            <div ref={PMC} className='dps-row' id='srv3' style={SelectedService[2] == 'srv3' ? { height: '100%' } : {}}>
                                <div className={`dps-text-content normal-text  ${SelectedService[2] === 'srv3' ? "dp-bg-design" : ""}`} style={SelectedService[2] == 'srv3' ? { width: '100%', padding: '100px 40px 70px 40px' } : {}}>
                                    <div>
                                        <h4>Project Management Consultancy (PMC)</h4>
                                        <span>
                                            Our Expert are specializing in providing expert Project Management Consultancy (PMC) services to clients across industries. With a dedicated team of project management professionals and industry experts, we offer comprehensive solutions to help our clients successfully plan, execute, and deliver their projects on time and within budget.
                                        </span>
                                        <div style={SelectedService[2] == 'srv3' ? { display: 'block' } : { display: 'none' }}>
                                            <span><b>Project Planning and Strategy:</b> We assist clients in developing robust project plans and strategies that align with their business objectives. Our team helps define project scopes, objectives, timelines, budgets, and resource requirements to ensure successful project delivery.</span>

                                            <span><b>Project Execution and Monitoring:</b> We provide hands-on support throughout the project execution phase, overseeing project activities, managing resources, and monitoring progress against milestones. Our proactive approach helps identify and address issues early to keep projects on track.</span>

                                            <span><b>Risk Management:</b> We help clients identify, assess, and mitigate project risks to minimize potential disruptions and ensure project success. Our risk management strategies are tailored to the specific needs and complexities of each project, helping clients navigate uncertainties effectively.</span>

                                            <span><b>Quality Assurance and Control:</b> We implement rigorous quality assurance and control measures to ensure that projects meet or exceed stakeholders' expectations. Our team conducts regular quality inspections, audits, and reviews to uphold the highest standards of quality throughout the project lifecycle.</span>

                                            <span><b>Stakeholder Management:</b> We help clients effectively engage and manage stakeholders throughout the project lifecycle. Our stakeholder management strategies focus on building positive relationships, fostering open communication, and addressing stakeholder concerns to ensure project success.</span>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], SelectedService[1], 'srv3']) }} style={SelectedService[2] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], SelectedService[1], '']) }} style={SelectedService[2] == 'srv3' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='dps-numbering' style={SelectedService[2] == 'srv3' ? { display: 'none' } : { display: 'block' }}><span>3</span></div>
                                <div className='dps-img-content' style={SelectedService[2] == 'srv3' ? { display: 'none' } : { display: 'block' }}>
                                    <img src={sImage3} alt="hello" />
                                </div>
                            </div>
                            <div ref={TPI} className='dps-row' id='srv4'>
                                <div className='dps-img-content'>
                                    <img src={sImage4} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text  dps-text-content-2'>
                                    <div>
                                        <h4>Third Party Inspections</h4>
                                        <span>
                                            <div>
                                                Feasibility study reports, detailed project reports and financial models for solar projects.
                                            </div>
                                            <div>
                                                Third party advisory and consultancy for solar projects.
                                            </div>
                                            <div>
                                                Third party engineering services and audits.

                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='dps-numbering-2'><span>4</span></div>

                            </div> */}
                        </div>
                        <div id='R&D' style={selectedDiv == 'R&D' ? { display: 'block' } : { display: 'none' }}>

                            {
                                pageData && pageData["services"].research.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?




                                        <div div key={data.id} ref={(el) => (RM1.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {
                                                            data.description.map(
                                                                (item) => (
                                                                    <p>{item}  </p>
                                                                )
                                                            )
                                                        }
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>

                                                    }

                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (RM1.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    // < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (RM1.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (RM1.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text   dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>;
                                })
                            }



                            {/* <div className='dps-row' id='srv5'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Graphene & 2D Material Based Super Capacitor</h4>
                                        
                                    </div>
                                </div>
                                <div className='dps-numbering'><span>1</span></div>
                                <div className='dps-img-content'>
                                  
                                </div>
                            </div> */}
                            {/* <div className='dps-row' id='srv6'>
                                <div className='dps-img-content'>
                                    <img src={sImage6} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text  dps-text-content-2'>
                                    <div>
                                        <h4>Commitment to Excellence</h4>
                                        <span>
                                            We are committed to excellence in everything we do. We adhere to rigorous quality standards, maintain the highest levels of professionalism, and strive to exceed our clients' expectations in every transaction.
                                        </span>
                                    </div>
                                </div>
                                <div className='dps-numbering-2'><span>2</span></div>

                            </div>
                            <div className='dps-row' id='srv7'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Strategic Planning Facilitation</h4>
                                        <span>
                                            Our strategic planning facilitation services are designed to guide organizations through the strategic planning process. We facilitate collaborative workshops and meetings, gather input from key stakeholders, and help organizations define their vision, mission, values, and strategic objectives.
                                        </span>
                                    </div>
                                </div>
                                <div className='dps-numbering'><span>3</span></div>
                                <div className='dps-img-content'>
                                    <img src={sImage7} alt="hello" />
                                </div>
                            </div>
                            <div className='dps-row' id='srv8'>
                                <div className='dps-img-content'>
                                    <img src={sImage8} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text  dps-text-content-2'>
                                    <div>
                                        <h4>Market Research and Analysis</h4>
                                        <span>
                                            We conduct in-depth market research and analysis to help organizations understand market dynamics, identify emerging trends, and assess competitive landscapes. Our team provides valuable insights that inform strategic decision-making and drive business growth.
                                        </span>
                                    </div>
                                </div>
                                <div className='dps-numbering-2'><span>4</span></div>

                            </div> */}
                        </div>
                        <div id='T&SD' style={selectedDiv == 'T&SD' ? { display: 'block' } : { display: 'none' }}>

                            {
                                pageData && pageData["services"].training.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?

                                        <div div key={data.id} ref={(el) => (RM2.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>

                                                    }

                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (RM2.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    // < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (RM2.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (RM2.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text   dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>;
                                })
                            }

                            {/* <div className='dps-row' id='srv9'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Training on Solar Design Software</h4>
                                       
                                    </div>
                                </div>
                                <div className='dps-numbering'><span>1</span></div>
                                <div className='dps-img-content'>
                                    
                                </div>
                            </div> */}
                            {/* <div className='dps-row' id='srv10'>
                                <div className='dps-img-content'>
                                    <img src={sImage10} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text  dps-text-content-2'>
                                    <div>
                                        <h4>Scenario Planning</h4>
                                        <span>
                                            We assist organizations in developing strategic scenarios to anticipate and prepare for potential future uncertainties and disruptions. Our scenario planning exercises help organizations identify risks, explore alternative futures, and develop proactive strategies to navigate changing environments effectively.
                                        </span>
                                    </div>
                                </div>
                                <div className='dps-numbering-2'><span>2</span></div>
                            </div>
                            <div className='dps-row' id='srv11'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Flexible Solutions</h4>
                                        <span>
                                            We understand that every project is unique, and we tailor our services to meet the specific requirements and objectives of each client. Whether it's a small-scale initiative or a large-scale project, we have the expertise and capabilities to deliver successful outcomes.
                                        </span>
                                    </div>
                                </div>
                                <div className='dps-numbering'><span>3</span></div>
                                <div className='dps-img-content'>
                                    <img src={sImage11} alt="hello" />
                                </div>
                            </div>
                            <div className='dps-row' id='srv12'>
                                <div className='dps-img-content'>
                                    <img src={sImage12} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text  dps-text-content-2'>
                                    <div>
                                        <h4>Confidentiality and Integrity</h4>
                                        <span>
                                            We adhere to the highest standards of confidentiality and integrity in all our bid coordination activities. Our clients can trust us to handle sensitive information with the utmost discretion and professionalism.
                                        </span>
                                    </div>
                                </div>
                                <div className='dps-numbering-2'><span>4</span></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            {/* Success Stories Start */}
            <section className='dep-sec-bg'>
                <NewSuccessStories departmentId={2} />
            </section>
            {/* Success Stories End */}

            {/* <section>
                <HomeCounter />
            </section> */}

            <section className='dep-sec-bg'>
                {/* <NewDepartmentTeam /> */}
                <OurDepartmentTeam teamData={pageData.team} department={2} />
                {/* <ComingSoonTeam /> */}
            </section>



            <section >
                <HomeNoticesDepartment department={2} />
            </section>


            {/* <section>
                <OurDepartmentTeam />
            </section> */}

            <section>
                <ContactUsDepartment department={2}/>
            </section>

            {/* <section className="section-lgb other-dep-check-out-div">
                <div className="container pbmit-col-stretched-yes pbmit-col-right">

                    <div className="row main-div">
                        <h2 className='pbmit-service-title data-direction dd-mb'>Other Depatments To Checkout</h2>
                        <div className="col-12">

                            <div className="pbmit-col-stretched-right">

                                <div className="swiper-slider" data-autoplay="true" data-loop="true" data-dots="false" data-arrows="false" data-columns="4" data-margin="30" data-effect="slide">
                                    <div className="swiper-wrapper">
                                        <Swiper
                                            modules={[Navigation, Scrollbar, A11y, Autoplay]}
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            

                                            loop={true}>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    

                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage1} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Renewable Management</Link>
                                                                        </h3>
                                                                        <div className="pbmit-service-category">
                                                                           
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage2} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Management</Link>
                                                                        </h3>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage3} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Environment Management</Link>
                                                                        </h3>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage4} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Research Management</Link>
                                                                        </h3>
                                                                        

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="rs-service-grid-3 pt-70 pb-120">
                {/* <div className="heading-container">
                    <h4 id="title1" className="title-animation-about-us">Other Departments to Checkout</h4>
                    <div className=" div-for-hr-animation"><hr id="hr1" className="animate-hr-tag-about-us" /><hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="odcrm1" title2="odcrm2" hr1="odcrm3" hr2="odcrm4" heading="Other Departments to Checkout" subheading="" />
                <div className='nod-container'>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage1} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={ntm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/new-technology-management/5/0">New Technology<br /> Management</Link></h4>
                                    {/* <p> New Technology Management </p> */}
                                    <Link to="/departments/new-technology-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage3} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={pm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/petroleum-management/5/0">Petroleum<br /> Management</Link></h4>
                                    {/* <p>Petroleum Management </p> */}
                                    <Link to="/departments/petroleum-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                      
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage5} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={prm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/petroleum-research-management/5/0">Petroleum Research<br /> Management</Link></h4>
                                    {/* <p>Petroleum Research Management</p> */}
                                    <Link to="/departments/petroleum-research-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage4} alt="image" />
                                </div>
                                <div className="rs-content">
                                    <div className="rs-icon">
                                        <img src={em} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/environment-management/5/0">Environment<br /> Management</Link></h4>
                                    {/* <p> Environment Management  </p> */}
                                    <Link to="/departments/environment-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default RenewableManagement;
