// import logo from './logo.svg';
// import "./Form.css";

function Form() {
  return (
    <div className="col-lg-9 col-md-9 col-sm-9 annouRightBox careerRightBox tab-content formBox" style={{ overflowX: "hidden" }}>
      <div id="CurrentOpening" className="tab-pane fade in active">

        <form action="jobapplications.php" method="post" name="form" id="TechnicalRE4Form" enctype="multipart/form-data">
          <table width="100%" border="0" cellpadding="2" cellspacing="1" bordercolordark="#FFFFFF" bordercolorlight="#FFFFFF" className="btext">

            <tbody><tr>
              <td valign="top" bordercolor="#F9F9F9">Position applied For : <span className="star">*</span></td>
              <td colspan="2"><div className="form-validation-field-0formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "23.2031px", left: "462.903px", right: "initial", marginTop: "-16.375px", display: "block" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><select name="post" className="txtfield validate[required]" data-errormessage-value-missing="Select Your Post." id="form-validation-field-0">
                <option value="" selected="" disabled="disabled">Select Post</option>
                HydroPowerEngineer-AssistantManager

                <option value="Manager-NTM">Manager</option>
                <option value="DyGeneralManager-NTM">Dy. General Manager</option>
                <option value="DyManager-NTM">Dy. Manager</option>

              </select></td>
            </tr>
              <tr>
                <td valign="top" bordercolor="#F9F9F9">Full Name <span className="star">*</span>: </td>
                <td colspan="2"><div className="form-validation-field-1formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "81.4219px", left: "462.903px", right: "initial", marginTop: "-16.375px", display: "block" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><input name="name" type="text" className="txtfield validate[required]" data-errormessage-value-missing="Write Your Full Name." id="form-validation-field-1" /></td>
              </tr>
              <tr>
                <td valign="top" bordercolor="#F9F9F9">Mobile Number <span className="star">*</span>: </td>
                <td colspan="2"><div className="form-validation-field-2formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "142.125px", left: "462.903px", right: "initial", marginTop: "-16.375px", display: "block" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><input name="mphone" type="number" min="0" className="txtfield validate[required]" data-errormessage-value-missing="Write Your Mobile Number." id="form-validation-field-2" /></td>
              </tr>
              <tr>
                <td valign="top" bordercolor="#F9F9F9">E-mail ID <span className="star">*</span>: </td>
                <td colspan="2"><div className="form-validation-field-3formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "202.828px", left: "462.903px", right: "initial", marginTop: "-16.375px" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><input type="email" name="email" className="txtfield validate[required]" data-errormessage-value-missing="Write Your Email." id="form-validation-field-3" /></td>
              </tr>
              <tr>
                <td valign="top" bordercolor="#F9F9F9">Correspondence Address (with pincode) <span className="star">*</span>: </td>
                <td colspan="2"><div className="form-validation-field-4formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "263.531px", left: "458.012px", right: "initial", marginTop: "-16.375px" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><textarea name="haddress" cols="22" rows="2" className="txtfield validate[required]" data-errormessage-value-missing="Write Your Address." id="form-validation-field-4" style={{ width: "200px", height: "35px" }}></textarea></td>
              </tr>
              <tr>
                <td valign="top" bordercolor="#F9F9F9">Current City/ State <span className="star">*</span>: </td>
                <td colspan="2"><div className="form-validation-field-5formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "334.047px", left: "462.903px", right: "initial", marginTop: "-16.375px", display: "block" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><input name="cityState" type="text" className="txtfield validate[required]" data-errormessage-value-missing="Enter Your City/ State." id="form-validation-field-5" /></td>
              </tr>
              <tr>
                <td valign="top" bordercolor="#F9F9F9">Native Place <span className="star">*</span>: </td>
                <td colspan="2"><div className="form-validation-field-6formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "394.75px", left: "462.903px", right: "initial", marginTop: "-16.375px", display: "block" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><input name="nativePlace" type="text" className="txtfield validate[required]" data-errormessage-value-missing="Enter Your Native Place." id="form-validation-field-6" /></td>
              </tr>
              <tr>
                <td valign="top" bordercolor="#F9F9F9">Current Designation <span className="star">*</span>: </td>
                <td colspan="2"><div className="form-validation-field-7formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "455.453px", left: "462.903px", right: "initial", marginTop: "-16.375px", display: "block" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><input name="designation" type="text" className="txtfield validate[required]" data-errormessage-value-missing="Enter Your Current Designation." id="form-validation-field-7" /></td>
              </tr>
              <tr>
                <td valign="top" bordercolor="#F9F9F9">Current Organisation <span className="star">*</span>: </td>
                <td colspan="2"><div className="form-validation-field-8formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "516.156px", left: "462.903px", right: "initial", marginTop: "-16.375px", display: "block" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><input name="organisation" type="text" className="txtfield validate[required]" data-errormessage-value-missing="Enter Your Current Organisation." id="form-validation-field-8" /></td>
              </tr>
              <tr>
                <td valign="top" bordercolor="#F9F9F9">Total Years of Experience <span className="star">*</span>: </td>
                <td colspan="2"><div className="form-validation-field-9formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "576.859px", left: "462.903px", right: "initial", marginTop: "-16.375px", display: "block" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9"></div><div className="line8"></div><div className="line7"></div><div className="line6"></div><div className="line5"></div><div className="line4"></div><div className="line3"></div><div className="line2"></div><div className="line1"></div></div></div><select name="experience" className="txtfield validate[required]" data-errormessage-value-missing="Select Your Total Years of Experience." id="form-validation-field-9">
                  <option value="" selected="">Years of Experience</option>
                  <option value=" 1 Year">1 Year</option><option value=" 2 Year">2 Year</option><option value=" 3 Year">3 Year</option><option value=" 4 Year">4 Year</option><option value=" 5 Year">5 Year</option><option value=" 6 Year">6 Year</option><option value=" 7 Year">7 Year</option><option value=" 8 Year">8 Year</option><option value=" 9 Year">9 Year</option><option value=" 10 Year">10 Year</option><option value=" 11 Year">11 Year</option><option value=" 12 Year">12 Year</option><option value=" 13 Year">13 Year</option><option value=" 14 Year">14 Year</option><option value=" 15 Year">15 Year</option><option value=" 16 Year">16 Year</option><option value=" 17 Year">17 Year</option><option value=" 18 Year">18 Year</option><option value=" 19 Year">19 Year</option><option value=" 20 Year">20 Year</option><option value=" 21 Year">21 Year</option><option value=" 22 Year">22 Year</option><option value=" 23 Year">23 Year</option><option value=" 24 Year">24 Year</option><option value=" 25 Year">25 Year</option>
                </select></td>
              </tr>
              <tr>
                <td colspan="3" valign="top" bordercolor="#F9F9F9">Monthly Salary Details (In Numbers Only) : </td>
                <td></td>
              </tr>
              <tr>
                <td>Net to Hand/ Take Home <span className="star">*</span> : <br></br>
                  <input type="number" name="p1" className="txtfield validate[required]" min="0" data-errormessage-value-missing="Write Your Monthly Salary Net to Hand." />
                </td>
                <td><b>Others Allowances/ Benefits</b> <span className="star">*</span> : <br></br>
                  <input type="number" name="p2" className="txtfield validate[required]" min="0" data-errormessage-value-missing="Write Your Others Allowances." />
                </td>
                <td><b>Gross Salary (Monthly)</b> <span className="star">*</span> : <br></br>
                  <input type="number" name="p3" className="txtfield validate[required]" min="0" data-errormessage-value-missing="Write Your Gross Salary." />
                </td>
              </tr>
            </tbody></table>

          <table>
            <tbody><tr>
              <td valign="top" bordercolor="#F9F9F9">Date of Birth<span className="star">* :</span></td>
              <td><div className="form-validation-field-10formError parentFormTechnicalRE4Form formError" style={{ opacity: "0.87", position: "absolute", top: "734.031px", left: "456.434px", right: "initial", marginTop: "-16.375px" }}><div className="formErrorArrow"><div className="line10"></div><div className="line9">
                {/* <!-- --> */}
              </div><div className="line8">
                  {/* <!-- --> */}
                </div><div className="line7">
                </div><div className="line6">
                </div><div className="line5">
                </div><div className="line4">
                </div><div className="line3">
                </div><div className="line2">
                </div><div className="line1">
                </div></div></div><input name="date" type="date" className="txtfield validate[required]" data-errormessage-value-missing="Select Your BirthDate." id="form-validation-field-10" /></td>
            </tr>
              <tr>
                <td colspan="3" valign="top" bordercolor="#F9F9F9">Educational Details <span className="star">*</span> : </td>
                <td></td>
              </tr>
              <tr>
              </tr></tbody></table>
          <div style={{ overflowX: "hidden" }}>
            <table>
              <tbody><tr>
                <th>Degree</th>  <th>Completed in Year</th>  <th>Board/ University</th>  <th>Subjects/ Specialization </th> <th>Percentage</th>
              </tr>
                <tr>
                  <td>Diploma </td>  <td><input type="month" name="edu1" className="txtfield eduField" /></td>  <td><input type="text" name="edu2" className="txtfield eduField" /></td>  <td><input type="text" name="edu3" className="txtfield eduField" /></td> <td><input type="text" name="edu4" className="txtfield eduField" /></td>
                </tr>
                <tr>
                  <td>Bachelor’s </td>
                  <td><input type="month" name="edu5" className="txtfield eduField" /></td>
                  <td><input type="text" name="edu6" className="txtfield eduField" /></td>
                  <td><input type="text" name="edu7" className="txtfield eduField" /></td>
                  <td><input type="text" name="edu8" className="txtfield eduField" /></td>
                </tr>
                <tr>
                  <td>Master’s</td>
                  <td><input type="month" name="edu9" className="txtfield eduField" /></td>
                  <td><input type="text" name="edu10" className="txtfield eduField" /></td>
                  <td><input type="text" name="edu11" className="txtfield eduField" /></td>
                  <td><input type="text" name="edu12" className="txtfield eduField" /></td>
                </tr>
                <tr>
                  <td>Doctoral</td>
                  <td><input type="month" name="edu13" className="txtfield eduField" /></td>
                  <td><input type="text" name="edu14" className="txtfield eduField" /></td>
                  <td><input type="text" name="edu15" className="txtfield eduField" /></td>
                  <td><input type="text" name="edu16" className="txtfield eduField" /></td>
                </tr>
              </tbody></table> </div>


          <div style={{ overflowX: "hidden" }}>
            <table>
              <tbody><tr>
                <td colspan="7" valign="top" bordercolor="#F9F9F9">Previous Work Experience, If any (In descending order) <span className="star">*</span> : </td>
              </tr>
                <tr>
                  <th>Sr. No.</th>  <th>Name of the<br></br> Institute/ Organization</th>  <th>Designation</th>  <th>Date of Joining</th>  <th>Date of Relieving</th>
                  {/* <!-- <th>No. of Years completed in organization</th> -->   */}
                  <th>Salary  per month</th>  <th>Main Job Profile</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td><input type="text" name="work1" className="txtfield workField" /></td>
                  <td><input type="text" name="work2" className="txtfield workField" /></td>
                  <td><input type="month" name="work3" className="txtfield workField" /></td>
                  <td><input type="month" name="work4" className="txtfield workField" /></td>
                  {/* <!-- <td><input type="text" name="work5" className="txtfield workField"></td> --> */}
                  <td><input type="text" name="work6" className="txtfield workField" /></td>
                  <td><input type="text" name="work7" className="txtfield workField" /></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td><input type="text" name="work8" className="txtfield workField" /></td>
                  <td><input type="text" name="work9" className="txtfield workField" /></td>
                  <td><input type="month" name="work10" className="txtfield workField" /></td>
                  <td><input type="month" name="work11" className="txtfield workField" /></td>
                  {/* <!-- <td><input type="text" name="work12" className="txtfield workField"></td> -->   */}
                  <td><input type="text" name="work13" className="txtfield workField" /></td>
                  <td><input type="text" name="work14" className="txtfield workField" /></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td><input type="text" name="work15" className="txtfield workField" /></td>
                  <td><input type="text" name="work16" className="txtfield workField" /></td>
                  <td><input type="month" name="work17" className="txtfield workField" /></td>
                  <td><input type="month" name="work18" className="txtfield workField" /></td>
                  {/* <!-- <td><input type="text" name="work19" className="txtfield workField"></td> -->   */}
                  <td><input type="text" name="work20" className="txtfield workField" /></td>
                  <td><input type="text" name="work21" className="txtfield workField" /></td>
                </tr>
                {/* <!-- <tr>
        <td>4</td>
        <td><input type="text" name="work22" className="txtfield workField" /></td>  
        <td><input type="text" name="work23" className="txtfield workField" /></td>  
        <td><input type="month" name="work24" className="txtfield workField" /></td>  
        <td><input type="month" name="work25" className="txtfield workField" /></td>  
        <td><input type="text" name="work26" className="txtfield workField" /></td>  
        <td><input type="text" name="work27" className="txtfield workField" /></td>  
        <td><input type="text" name="work28" className="txtfield workField" /></td>
      </tr>
      <tr>
        <td>5</td>
        <td><input type="text" name="work29" className="txtfield workField" /></td>  
        <td><input type="text" name="work30" className="txtfield workField" /></td>  
        <td><input type="month" name="work31" className="txtfield workField" /></td>  
        <td><input type="month" name="work32" className="txtfield workField" /></td>  
        <td><input type="text" name="work33" className="txtfield workField" /></td>  
        <td><input type="text" name="work34" className="txtfield workField" /></td>  
        <td><input type="text" name="work35" className="txtfield workField" /></td>
      </tr> --> */}
              </tbody></table></div>

          <table>
            <tbody><tr>
              <td valign="top" bordercolor="#F9F9F9">Minimum Notice Period Required to Join in case of selection: <span className="star">*</span></td>
              <td><input type="number" name="noticeP" className="validate[required]" min="0" /> Days</td>
            </tr>
              <tr />
              <td valign="top" bordercolor="#F9F9F9">Are you ready to relocate to any location in India?: <span className="star">*</span></td>
              <td><input type="radio" name="radio1" value="Yes" className="validate[required] radio" /> Yes &nbsp;&nbsp;&nbsp;&nbsp; <input type="radio" name="radio1" value="No" className="validate[required] radio" /> No</td>
              {/* </tr> */}
              <tr>
                <td valign="top" bordercolor="#F9F9F9"> Upload Resume/ CV<span className="star">*</span> (UPDATED CV - Word or PDF file only): </td>
                <td><input id="cv" onchange="return fileValidation()" name="cvmfile" type="file" className="txtfield validate[required]" accept="application/pdf, application/msword" /></td>

              </tr>

              <tr>
                <td>
                  <p><span style={{ color: "red" }}><strong>* Mandatory</strong></span></p>
                </td>
              </tr>
              <tr>
                <td><span className="blue1"><strong>DECLARATION</strong></span></td>
              </tr>
              <tr>
                <td colspan="7" valign="top"><p><strong><label><input name="agree" type="checkbox" value="yes" className="validate[required] checkbox" /></label>I hereby certify that the above information are true and correct to the best of my knowledge and in case of any discrepancy, the application will be disqualified.</strong></p></td>
              </tr>
              {/* <!-- <tr> 
        <td colspan="7" valign="top"><p><strong><label><input name="agree1" type="checkbox" value="yes" className="validate[required] checkbox" /></label>I hereby declare that I am aware about the terms of employment and I agreed to it.</strong></p></td>
      </tr> --> */}
            </tbody>
            {/* </tr> */}
          </table>

          <table align="center">
            <tbody><tr>
              <td>
                <input type="submit" name="submit" id="submit" className="txtfield" value="submit" />
              </td>
            </tr>
            </tbody></table>
        </form>



      </div>
    </div>
  );
}

export default Form;