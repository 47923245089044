import React, { useState } from "react";
import "./Patterns.css";
import { MdDateRange } from "react-icons/md";
import { Link } from "react-router-dom";

import Patternsdata from "./Patternsdata";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import BreadCrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/publications.jpg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IndividualPublication from "../../Resources/Publications/IndividualPublication";


export default function Patterns() {
  const initialTabs = [2024, 2023, 2022, 2021, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010]; // Initial years
  const [startIdx, setStartIdx] = useState(0); // Track the start index of visible tabs
  const tabsPerPage = 6; // Number of tabs per page
  const visibleTabs = initialTabs.slice(startIdx, startIdx + tabsPerPage); // Calculate visible tabs
  const [activeTab, setActiveTab] = useState(0);
  const [query, setQuery] = useState("");

  // const search_parameters = Object.keys(Object.assign({}, ...publicationData));
  // console.log(search_parameters);

  const handleNextClick = () => {
    if (startIdx + tabsPerPage < initialTabs.length) {
      setStartIdx(startIdx + tabsPerPage);
      setActiveTab(0); // Move to the next set of tabs
    }
  };

  const handlePrevClick = () => {
    if (startIdx - tabsPerPage >= 0) {
      setStartIdx(startIdx - tabsPerPage);
      setActiveTab(0); // Move to the previous set of tabs
    }
  };

  function search(theData) {
    let arr = [];
    if (query === "") return [];
    else {
      for (let i = 0; i < 11; i++) {
        const tmp = theData[i].filter((data, key) =>
          data["desc"].toString().toLowerCase().includes(query.toLowerCase())
          // console.log(data["desc"])
        )
        if (tmp.length > 0) {
          arr = arr.concat(tmp);
        }


      }
      // console.log(arr.length);
      return arr;

    }


  }

  function callPubli(theData) {
    return (
      <IndividualPublication
        year={""}
        srno={theData.srno}
        title={theData.title}
        desc={theData.desc}
        link={theData.link}
        extra={theData.extra}
      />
      // <></>
    );
  }
  function callPubli1(theData) {
    return (
      <></>
      // <IndividualPublication
      //   year={theData.year}
      //   srno={theData.srno}
      //   title={theData.title}
      //   desc={theData.desc}
      //   link={theData.link}
      //   extra={theData.extra}
      // />
    );
  }

  return (
    <>
      {/* Title Bar  */}
      <BreadCrumbs parent="Home" child="Our Patents" parentPath="/" imgPath={BreadCrumbImage} />




      {/* Title Bar End */}
      <div className="pb-main">


        <div className="Public-Hero-container">
          {/* <div className="Subhero-text-container">
          <p className="subhero-text">OUR PUBLICATIONS</p>
        </div> */}

        

       
        </div>

      

        <section className="Publication-Card-Container">
          <div className="Publication-card">
            <div className="Publication-card-content">
              <div className="Book-heading">Our Patents</div>
              {Patternsdata[0].map(callPubli)}
            </div>
          </div>
        </section>


      </div>

    </>
  );
}
