// File: src/components/HtmlRenderer.js
import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const HtmlRenderer = ({ htmlString }) => {
  const sanitizedHtmlString = DOMPurify.sanitize(htmlString);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHtmlString }}
    />
  );
};

HtmlRenderer.propTypes = {
  htmlString: PropTypes.string.isRequired,
};

export default HtmlRenderer;
