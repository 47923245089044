import React, { useEffect } from "react";
import "./AnnouncementDetail.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NoticeData from './NoticeData'
import ScrollToTop from "../../../ScrollToTop";
import BreadCrumbs from "../../../BreadCrumbs/BreadCrumbs";
// import BreadCrumbImage from '../../../../Images_1/CommonSection/Braedcrumbs/NewsCover.jpg';
import BreadCrumbImage from '../../../../Images_1/CommonSection/Braedcrumbs/NAM.jpg';
import { useDispatch, useSelector } from "react-redux";
import { AnnouncementById } from "../../../noticeCards/store/getAnnouncementByIdSlice";
import HtmlRenderer from "./HtmlRenderer";
import { BASE_URL_Image } from "../../../../constants";

document.body.scrollTop = 0;
const AnnouncementDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams();
  document.body.scrollTop = 0;
  const location = useLocation();
  const name = location.state.name;
  // const id = location.state.id;
  const content = "NoticeData[name][id - 1]";

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(AnnouncementById({"id":id}));

  }, []);

  const announcementdetail = useSelector((state) => state.AnnouncementById);

  // console.log("announcementdetail.data");
  // console.log(announcementdetail.data);

  return (
    <>
      <ScrollToTop />
      <BreadCrumbs parent="Home" child="Announcement Details" parentPath="/" imgPath={BreadCrumbImage} />
      {/* ---------------------------------------------------HEADING--------------------------------------------------------------------- */}

      <div className="bgmain">
        <div className="anHero-container">
          {/* <div className="effect-container glow-effect">
          <div className="circle-effect"></div>
          <div className="circle-effect"></div>
          <div className="circle-effect"></div>
        </div> */}

          <div id="" className="heading-container">

            {/* <h4 className="notice-heading-individual notice-heading-h4">{content["type"]} Details</h4> */}


            <h2 className="notice-heading-individual notice-heading-h2">
              {content["title"]}
            </h2>

          </div>
          <div className="adTimestamp-container">
            <div className="Author-content">{content["author"]}</div>
            <div className="Timestamp-content">{content["date"]}</div>
          </div>
          {announcementdetail.data != undefined ?
            <div className="news-detail-image">
              <img
                src={ BASE_URL_Image + announcementdetail.data.file
                }
                alt="Annoucement Image"
              />
            </div> : <></>}
        </div>

        {/* ---------------------------------------------------------CONTENT-------------------------------------------------------------- */}
        <div className="Content-container mb-30">
          <p className="Content-title">
            {announcementdetail.data && announcementdetail.data.title}
          </p>
          {/* <div
          className="Content-title-container"
        >
         
        </div> */}


          {/* 
        <div className="anContent-text-container">
          <p>
            {content["desc1"]}
          </p>
          <p>
            {content["desc2"]}
          </p>
        </div> */}

          <div className="anContent-text-container pb-news">
            <p className="anContent-text">
              <HtmlRenderer htmlString={announcementdetail.data && announcementdetail.data.description} />

            </p>
            {announcementdetail.data && announcementdetail.data.isTraining=="true"?
                                                                <button
                                                                onClick={()=>{window.open("/NTMTrainingForm/"+announcementdetail.data.id+"/"+announcementdetail.data.title)}} 
                                                                className='see-more-button-card '><h6 className=' text-color-card static-small-text'>Apply for Training</h6></button>
                                                                :<></>}
          </div>
         
        </div>

        {/* </div > */}

        {/* <div className="effect-container glow-effect">
        <div className="circle-effect"></div>
        <div className="circle-effect"></div>
        <div className="circle-effect"></div>
      </div> */}

      </div>

    </>
  );
};

export default AnnouncementDetail;
