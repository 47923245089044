import React, { useState } from 'react'
import './HomeCounter.css'
import TheCounter from "../TheCounter"
import ScrollTrigger from 'react-scroll-trigger'

function HomeCounter() {

    const [counterState, setCounterState] = useState(false)
    return (
        <div>
            <section className='home-counter'>
                <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
                    <div className='home-counter-container'>
                        {/* <h2 className='home-counter-heading'>Success in Numbers</h2> */}
                        <div className='counter-div-container'>
                            <div className='counter-div'>
                                <h3 className='counter-numbers counter-fonts'>
                                    {counterState && <TheCounter maxValue={10000} duration="300" />}
                                    <span className='counter-symbol'>{"+"}</span>
                                </h3>
                                <span className='counter-text normal-text sub-heading'>Professionals Trained</span>
                                <span className='counter-text normal-text'> in Energy Domain</span>
                            </div>

                            <div className='counter-div'>
                                <h3 className='counter-numbers counter-fonts'>
                                    {counterState && <TheCounter maxValue={10000} duration="300" />}
                                    <span className='counter-symbol'>{"+"}</span>
                                </h3>
                                <span className='counter-text normal-text sub-heading'> MW Solar Projects</span>
                                {/* <span className='counter-text'> (Advisory, PMC, TPI, R&D/Sponsored)</span> */}
                            </div>

                            <div className='counter-div'>
                                <h3 className='counter-numbers counter-fonts'>
                                    {counterState && <TheCounter maxValue={63} duration="300" />}
                                    <span className='counter-symbol'>{"+"}</span>
                                </h3>
                                <span className='counter-text normal-text sub-heading'>Petroleum Projects</span>
                                <span className='counter-text normal-text'>(G&G)</span>
                            </div>

                            <div className='counter-div'>
                                <h3 className='counter-numbers counter-fonts'>
                                    {counterState && <TheCounter maxValue={60} duration="300" />}
                                    <span className='counter-symbol'>{"+"}</span>
                                </h3>
                                <span className='counter-text normal-text sub-heading'>Industrial Environmental</span>
                                <span className='counter-text normal-text'>Audits Conducted</span>
                            </div>

                            <div className='counter-div'>
                                <h3 className='counter-numbers counter-fonts'>
                                    {counterState && <TheCounter maxValue={41} duration="300" />}
                                    <span className='counter-symbol'> {" (2)"}</span>
                                </h3>
                                <span className='counter-text normal-text sub-heading'>National and International</span>
                                <span className='counter-text normal-text'> Publications (Patents)</span>
                            </div>

                           
                           
                           
                        </div>
                    </div>
                </ScrollTrigger>
            </section>

        </div>
    )
}

export default HomeCounter;
