import "./App.css";
import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

// Import all components
import Layout from "./components/Layout";
import Bloggridview from "./components/Notifications/Blog/Bloggridview";
import Faq from "./components/Pages/Faq";
import History from "./components/Pages/History";
import ContactUs from "./components/ContactUs/ContactUs";
import SuccessStoriesSingle from "./components/Home2/SuccessStories/SuccessStoriesSingle";
import AboutUs_2 from "./components/AboutUs/AboutUs";
import OurTeam from "./components/Pages/OurTeam";
import TeamSingle from "./components/Pages/TeamSingle";
import PortfolioSingle from "./components/Projects/PortfolioSingle";
import Services from "./components/Services/Services";
import PrivacyPolicy from "./components/CommonSection/PrivacyPolicy";
import Sitemap from "./components/CommonSection/Sitemap/Sitemap";
import Publications from "./components/Resources/Publications/Publications";
import Form from "./components/Form/Form";
import Gallery from "./components/Gallery/Gallery";
import Careers from "./components/Carrers/Careers";
import ProjectReport from "./components/Resources/ProjectReport/ProjectReport";
import FCRA from "./components/FCRA/FCRA";
import GivePage from "./components/Give/GivePage";
import ResearchProjects from "./components/Activities/ResearchProject/ResearchProjects";
import Announcements from "./components/Notifications/Announcements";
import Tenders from "./components/Notifications/Tender/Tenders";
import News from "./components/Notifications/News/News";
import OurTeams from "./components/Our Team/OurTeam/OurTeams";
import GermiPatterns from "./components/Home2/Patterns/Patterns";
import VisitorCounter from "./components/Counter/VisitorCounter";
import AnnouncmentDetail from "./components/Notifications/Announcement/AnnouncementDetail/AnnouncementDetail";
import AnnouncementListView from "./components/Notifications/Announcement/AnnouncementListView/AnnouncementListView";
import SuccessStories from "./components/Activities/SuccessStoriesDetail/SuccessStoriesDetail";
import TeamDetails from "./components/Our Team/TeamDetails/TeamDetails";
import DepartmentDemo from "./components/Services/DepartmentDemo";
import Form2 from "./components/Form2/Form2";
import NewTechnologyManagement from "./components/Services/DepartmentDetails/NewTechnologyManagement";
import RenewableManagement from "./components/Services/DepartmentDetails/RenewableManagement";
import PetroleumManagement from "./components/Services/DepartmentDetails/PetroleumManagement";
import EnvironmentManagement from "./components/Services/DepartmentDetails/EnvironmentManagement";
import PetroleumResearchManagement from "./components/Services/DepartmentDetails/PetroleumResearchManagement";
import Home2 from "./components/Home2/Home";
import DepartmentDetail6 from "./components/Services/DepartmentDetails/RenewableManagement";
import Form3 from "./components/Form3/Form3";
import JobDetails from "./components/Jobdetails/JobDetails";
import AddApplicationForm from "./components/AddApplicationForm/AddApplicationForm";
import ManagementTeams from "./components/Our Team/OurTeam/ManagementTeam";
import AccountTeams from "./components/Our Team/OurTeam/AccountTeam";
import HradminTeams from "./components/Our Team/OurTeam/HradminTeam";
import TechnicalTeams from "./components/Our Team/OurTeam/TechnicalTeam";
import NewsListView from "./components/Notifications/Announcement/AnnouncementListView/NewsListView";
import TenderListView from "./components/Notifications/Announcement/AnnouncementListView/TenderListView";
import DgDesk from "./components/AboutUs/DgDesk/DgDesk";
import SampleAnalysisForm from "./components/SampleAnalysisForm/SampleAnalysisForm";
import ExpandableCardMain from "./components/ExpandableCard/ExpandableCardMain";
import SolarCalculator from "./components/SolarCalculator/SolarCalculator";
import EVCalculator from "./components/EVCalculator/EVCalculator";
import AddNewJobApplication from "./components/AddApplicationForm/AddNewJobApplication";
import NTMTrainingForm from "./components/NTMTrainingForm/NTMTrainingForm";
import Home2ServicesNew from "./components/Home2/HomeServicesNew/Home2ServicesNew";
import SocialMedia from "./components/Home2/SocialMedia/SocialMedia";

const TRACKING_ID = "G-117MQMCWM2";

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
  return null;
};

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  const route = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home2 /> },
        { path: "blog", element: <Bloggridview /> },
        { path: "contactus", element: <ContactUs /> },
        { path: "faq", element: <Faq /> },
        { path: "ourservices/:id", element: <Home2ServicesNew /> },
        { path: "ourteam", element: <OurTeam /> },
        { path: "history", element: <History /> },
        { path: "aboutus", element: <AboutUs_2 /> },
        { path: "ourteams", element: <OurTeams /> },
        { path: "ourmanagementteams", element: <ManagementTeams /> },
        { path: "ourAccountsteams", element: <AccountTeams /> },
        { path: "ourhradminteams", element: <HradminTeams /> },
        { path: "teamsingle", element: <TeamSingle /> },
        { path: "portfoliosingle", element: <PortfolioSingle /> },
        { path: "services", element: <Services /> },
        { path: "publications", element: <Publications /> },
        { path: "gallery", element: <Gallery /> },
        { path: "careers", element: <Careers /> },
        { path: "form", element: <Form /> },
        { path: "successstoriessingle", element: <SuccessStoriesSingle /> },
        { path: "projectreport", element: <ProjectReport /> },
        { path: "fcra", element: <FCRA /> },
        { path: "give", element: <GivePage /> },
        { path: "visitorcounter", element: <VisitorCounter /> },
        { path: "announcement", element: <Announcements /> },
        { path: "tender", element: <Tenders /> },
        { path: "news", element: <News /> },
        { path: "teamdetails/:id/:id2/:id3/:id4", element: <TeamDetails /> },
        { path: "GermiPatents", element: <GermiPatterns /> },
        { path: "privacypolicy", element: <PrivacyPolicy /> },
        { path: "sitemap", element: <Sitemap /> },
        { path: "announcementdetails/:id", element: <AnnouncmentDetail /> },
        { path: "announcementlist", element: <AnnouncementListView /> },
        { path: "newslist", element: <NewsListView /> },
        { path: "tenderlist", element: <TenderListView /> },
        { path: "successstories/:id/:did", element: <SuccessStories /> },
        { path: "researchprojects", element: <ResearchProjects /> },
        { path: "departments", element: <DepartmentDemo /> },
        { path: "departments/new-technology-management/:id/:id2", element: <NewTechnologyManagement /> },
        { path: "departments/renewable-management/:id/:id2", element: <RenewableManagement /> },
        { path: "departments/petroleum-management/:id/:id2", element: <PetroleumManagement /> },
        { path: "departments/environment-management/:id/:id2", element: <EnvironmentManagement /> },
        { path: "departments/petroleum-research-management/:id/:id2", element: <PetroleumResearchManagement /> },
        { path: "departmentdetail6", element: <DepartmentDetail6 /> },
        { path: "form2", element: <Form2 /> },
        { path: "form3", element: <Form3 /> },
        { path: "jobdetails", element: <JobDetails /> },
        { path: "applicationForm", element: <AddApplicationForm /> },
        { path: "applicationFormNew/:id/:id2/:id3/:id4/:id5/:id6", element: <AddNewJobApplication /> },
        { path: "technicalteams", element: <TechnicalTeams /> },
        { path: "dgdesk", element: <DgDesk /> },
        { path: "sampleanalysisform", element: <SampleAnalysisForm /> },
        { path: "NTMTrainingForm/:id/:id2", element: <NTMTrainingForm /> },
        { path: "solarcalculator", element: <SolarCalculator /> },
        { path: "socialmedia", element: <SocialMedia /> },
        { path: "evcalculator", element: <EVCalculator /> },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={route}>
        <TrackPageView />
      </RouterProvider>
    </>
  );
}

export default App;