import React from 'react'
import { InstagramEmbed, LinkedInEmbed } from 'react-social-media-embed';
import { FacebookEmbed } from 'react-social-media-embed';
import './SocialMedia.css'
import OnScrollAnimation from '../../OnScrollAnimation';
function SocialMedia() {
    return (
        <div className='socials-main-div'>
            <div className='sm-container clients-header-spacing'>
                <OnScrollAnimation title1="c1" title2="c2" hr1="c3" hr2="c4" heading="Facebook Feed" subheading="" />
             
                <div className="row sm-row  ">
                    <div className='col-md-6 col-xl-4 sm-mt'>
                        <FacebookEmbed url="https://www.facebook.com/photo/?fbid=443528502100061&set=a.117836018002646" height={400} />
                    </div>

                    <div className="col-md-6 col-xl-4 sm-mt">
                        <FacebookEmbed url="https://www.facebook.com/photo?fbid=415796654873246&set=a.117836018002646" height={400} />
                    </div>

                    <div className='col-md-6 col-xl-4 sm-mt'>
                        <FacebookEmbed url="https://www.facebook.com/photo?fbid=397804106672501&set=a.117836018002646" height={400} />
                    </div>
                </div>
                <OnScrollAnimation title1="c1" title2="c2" hr1="c3" hr2="c4" heading="Instragam Feed" subheading="" />
                <div className="row sm-row mt-5 pb-5 sm-mt">
                    <div className="col-md-6 col-xl-4">
                        <InstagramEmbed url="https://www.instagram.com/p/C6VwD2Iqy5q/" captioned height={400}/>
                    </div>

                    <div className="col-md-6 col-xl-4 sm-mt">
                        <InstagramEmbed url="https://www.instagram.com/p/C7D3Sy4Sg4-/" captioned height={400}/>
                    </div>
                    <div className="col-md-6 col-xl-4 sm-mt">
                        <InstagramEmbed url="https://www.instagram.com/p/C50ZJFRvbff/" captioned height={400}/>
                    </div>
                </div>
                <OnScrollAnimation title1="c1" title2="c2" hr1="c3" hr2="c4" heading="LinkedIn Feed" subheading="" />
             
                <div className="row sm-row mt-5 pb-5 sm-mt">
            <div className="col-md-6 col-xl-4 sm-mt">
                <LinkedInEmbed url="https://www.linkedin.com/embed/feed/update/urn:li:share:7222556572592271362" height={600} width={400} />
            </div>
            
            <div className="col-md-6 col-xl-4 sm-mt">
                <LinkedInEmbed url="https://www.linkedin.com/embed/feed/update/urn:li:share:7222555881807200256" height={600} width={400}  />
            </div>

            <div className="col-md-6 col-xl-4 sm-mt">
                <LinkedInEmbed url="https://www.linkedin.com/embed/feed/update/urn:li:share:7222555364330729472" height={600} width={400}  />
            </div>
        </div>
            </div>
        </div>
    )
}

export default SocialMedia
