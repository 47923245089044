





import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

function Faq() {
  return (
    <>
    
      <section>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="text-center">
								<h2 className="pbmit-title mb-3">Popular Questions</h2>
								<p>No two projects are remotely the same. We tailor our services and teams to meet your project goals and<br/> budget, providing the highest level of service in the most efficient manner.</p>
							</div>
						
							 <div>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>1 . How do I get started?</Accordion.Header>
                                                    <Accordion.Body>
													Discover you question from underneath or present your inquiry fromt the submit box. Growing businesses need strong tax management to meet current and future tax liabilities and we can help you achieve this, whatever challenges you face.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>2 . What fees do you charge?</Accordion.Header>
                                                    <Accordion.Body>
													Discover you question from underneath or present your inquiry fromt the submit box. Growing businesses need strong tax management to meet current and future tax liabilities and we can help you achieve this, whatever challenges you face.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>3 . How can I start accepting business</Accordion.Header>
                                                    <Accordion.Body>
													Discover you question from underneath or present your inquiry fromt the submit box. Growing businesses need strong tax management to meet current and future tax liabilities and we can help you achieve this, whatever challenges you face.
                                                    </Accordion.Body>
                                                </Accordion.Item>
												<Accordion.Item eventKey="3">
                                                    <Accordion.Header>4 . Which products are excluded from the returns policy?</Accordion.Header>
                                                    <Accordion.Body>
													Discover you question from underneath or present your inquiry fromt the submit box. Growing businesses need strong tax management to meet current and future tax liabilities and we can help you achieve this, whatever challenges you face.
                                                    </Accordion.Body>
                                                </Accordion.Item>
												<Accordion.Item eventKey="4">
                                                    <Accordion.Header>5 . Holisticly predominate extensible testing reliable ?</Accordion.Header>
                                                    <Accordion.Body>
													Discover you question from underneath or present your inquiry fromt the submit box. Growing businesses need strong tax management to meet current and future tax liabilities and we can help you achieve this, whatever challenges you face.
                                                    </Accordion.Body>
                                                </Accordion.Item>
												<Accordion.Item eventKey="5">
                                                    <Accordion.Header>6.Is your University Government or Private?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Discover you question from underneath or present your inquiry fromt the submit box. Growing businesses need strong tax management to meet current and future tax liabilities and we can help you achieve this, whatever challenges you face.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
						</div>
					</div>
				</div>
            </section>
			
            
           
    </>
  )
}

export default Faq
