import React from 'react'
import { useTheme } from '../ThemeContext';


function OnScrollAnimation(props) {
    const { isNightMode } = useTheme();
    // Function to check if scrollbar is visible
    const id1 = props.hr1;
    const id2 = props.hr2;
    const t1 = props.title1;
    const t2 = props.title2 == undefined ? "" : props.title2;
    const isWhite = props.isWhite == undefined ? "" : true;
    function isScrollbarVisible() {
        return document.documentElement.scrollHeight > window.innerHeight;
    }

    // Function to check if an element is in the viewport
    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    // Function to start animation when HR elements are in viewport
    function startAnimationOnScroll() {
        const hr1 = document.getElementById(id1);
        const hr2 = document.getElementById(id2);
        const title1 = document.getElementById(t1);
        const title2 = document.getElementById(t2);
        if (title1 && isInViewport(title1)) {
            title1.classList.add('title-animation-about-us');
        }
        if (title2 && isInViewport(title2)) {
            title2.classList.add('title-animation-about-us');
        }
        if (hr1 && isInViewport(hr1)) {
            hr1.classList.add('animate-hr-tag-about-us');
        }
        if (hr2 && isInViewport(hr2)) {
            hr2.classList.add('animate-hr-tag-about-us');
        }
    }

    // Check scrollbar visibility and start animation on scroll
    function handleScroll() {
        if (isScrollbarVisible()) {
            startAnimationOnScroll();
            window.removeEventListener('scroll', handleScroll); // Remove the scroll listener once animation starts
        }
    }

    // Check scrollbar visibility on page load and window resize
    window.addEventListener('DOMContentLoaded', handleScroll);
    window.addEventListener('resize', handleScroll);
    window.addEventListener('scroll', startAnimationOnScroll);

    return (
        <div>
            <div className='heading-container'>
                <h4 id={t1} className={isNightMode ? isWhite ? 'heading-container-white-fonts heading-fonts' : 'heading-fonts' : 'heading-container-white-fonts heading-fonts'}>{props.heading}</h4>
                <div style={props.subheading == undefined ? { display: "none" } : {}} id={t2}
                    className={isNightMode ? isWhite ? 'heading-container-white-fonts subheading-fonts' : 'subheading-fonts title-animation-about-us' : 'heading-container-white-fonts subheading-fonts'}>{props.subheading}</div>
                <div className='div-for-hr-animation'>
                    <hr id={id1} className={isWhite ? 'animate-hr-tag-about-us-white' : {}} />
                    <hr id={id2} className={isWhite ? 'animate-hr-tag-about-us-white' : {}} />
                </div>
            </div>
        </div>
    )
}

export default OnScrollAnimation
