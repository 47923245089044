import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const getAnnouncements = createAsyncThunk('getannouncementbydept', async(id) => {

   
  const response = await publicRequest.post('/getannouncementbydept',JSON.stringify(id));

  return response.data.data;
});

const DptAnnouncementDataSlice = createSlice({
  name: 'getannouncementbydept',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(getAnnouncements.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default DptAnnouncementDataSlice.reducer;