import React, { useState, useEffect } from 'react';

const AutoCounter = ({ maxValue, duration }) => {
  const [counter, setCounter] = useState(0);
  const [counter2, setCounter2] = useState(0);
  useEffect(() => {
    let timer;
    const startCounter = () => {
      timer = setInterval(() => {
        if (counter2 < 50) {
          setCounter((prevCounter) => prevCounter + 1);
          setCounter2((prevCounter) => prevCounter + 1);
        } else {
          setCounter(maxValue);
          clearInterval(timer);
        }
      }, 10);
    };

    // Start the counter
    startCounter();

    // Stop the counter and set it to maxValue after the specified duration
    // const stopTimer = setTimeout(() => {
    //   clearInterval(timer);
    //   setCounter(maxValue);
    // }, duration);

    // Cleanup function
    return () => {
      clearInterval(timer);
      // clearTimeout(stopTimer);
    };
  }, [counter, maxValue, duration]);

  return <span>{counter} </span>;
};

const TheCounter = (props) => {
  // Set the maximum value and duration as per your requirement
  const maxValue = props.maxValue;
  const duration = 5000; // 5000 milliseconds (5 seconds)

  return (
    <span>
      <AutoCounter maxValue={maxValue} duration={duration} />
    </span>
  );
};

export default TheCounter;