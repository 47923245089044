import React, { useState } from "react";
import "./Publication.css";
import { MdDateRange } from "react-icons/md";
import { Link } from "react-router-dom";

import publicationData from "./PublicationData";
import IndividualPublication from "./IndividualPublication";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import BreadCrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/publications.jpg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function Publications() {
  const initialTabs = [2024, 2023, 2022, 2021, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010]; // Initial years
  const [startIdx, setStartIdx] = useState(0); // Track the start index of visible tabs
  const tabsPerPage = 6; // Number of tabs per page
  const visibleTabs = initialTabs.slice(startIdx, startIdx + tabsPerPage); // Calculate visible tabs
  const [activeTab, setActiveTab] = useState(0);
  const [query, setQuery] = useState("");

  // const search_parameters = Object.keys(Object.assign({}, ...publicationData));
  // console.log(search_parameters);

  const handleNextClick = () => {
    if (startIdx + tabsPerPage < initialTabs.length) {
      setStartIdx(startIdx + tabsPerPage);
      setActiveTab(0); // Move to the next set of tabs
    }
  };

  const handlePrevClick = () => {
    if (startIdx - tabsPerPage >= 0) {
      setStartIdx(startIdx - tabsPerPage);
      setActiveTab(0); // Move to the previous set of tabs
    }
  };

  function search(theData) {
    let arr = [];
    if (query === "") return [];
    else {
      for (let i = 0; i < 11; i++) {
        const tmp = theData[i].filter((data, key) =>
          data["desc"].toString().toLowerCase().includes(query.toLowerCase())
          // console.log(data["desc"])
        )
        if (tmp.length > 0) {
          arr = arr.concat(tmp);
        }


      }
      // console.log(arr.length);
      return arr;

    }


  }

  function callPubli(theData) {
    return (
      <IndividualPublication
        year={""}
        srno={theData.srno}
        title={theData.title}
        desc={theData.desc}
        link={theData.link}
        extra={theData.extra}
      />);
  }
  function callPubli1(theData) {
    return (
      <IndividualPublication
        year={theData.year}
        srno={theData.srno}
        title={theData.title}
        desc={theData.desc}
        link={theData.link}
        extra={theData.extra}
      />);
  }

  return (
    <>
      {/* Title Bar  */}
      <BreadCrumbs parent="Home" child="Publications" parentPath="/" imgPath={BreadCrumbImage} />




      {/* Title Bar End */}
      <div className="pb-main">


        <div className="Public-Hero-container">
          {/* <div className="Subhero-text-container">
          <p className="subhero-text">OUR PUBLICATIONS</p>
        </div> */}

          <div className='publication-heading heading-fonts'>
            Explore our publications and embark on a journey of discovery with us
          </div>

          <div className="pb-search-box">

            <input
              placeholder="Search Publications"

              type="search"

              name="search-form"

              id="search-form"

              className="search-input"

              onChange={(e) => setQuery(e.target.value)}


            />

          </div>
        </div>

        <div className="tab-container">
          <button className="prev-btn" onClick={handlePrevClick}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" /></svg>
          </button>
          {visibleTabs.map((tab, index) => (
            <button
              key={index}
              className={`tab-btn normal-text ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </button>
          ))}
          <button className="next-btn" onClick={handleNextClick}>
            {/* <svg data-testid="ArrowForwardIosIcon"></svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" /></svg>
          </button>
        </div>

        <div style={query === "" ? { display: "none" } : {}}>
          <section className="Publication-Card-Container">
            <div className="Publication-card">
              <div className="Publication-card-content">
                <div className="Book-heading">Search Results for: {query} </div>
                {
                  // search(publicationData)
                  search(publicationData).map(callPubli1)
                }
              </div>
            </div>
          </section>
        </div>

        <section className="Publication-Card-Container">
          <div className="Publication-card">
            <div className="Publication-card-content">
              <div className="Book-heading">Publications</div>
              {publicationData[startIdx + activeTab].map(callPubli)}
            </div>
          </div>
        </section>


      </div>

    </>
  );
}
