import { useTheme } from '@emotion/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
	const [activeBtn, setActiveBtn] = useState('');
	const [openDropdown, setOpenDropdown] = useState(null); // Store the index of the currently open dropdown
	const [navbarCollapsed, setNavbarCollapsed] = useState(true);

	const handleIsOpen = (index) => {
		// If the clicked dropdown is already open, close it; otherwise, open the clicked dropdown and close others
		setOpenDropdown(openDropdown === index ? null : index);
	};

	const toggleNavbar = () => {
		setNavbarCollapsed(!navbarCollapsed);
	};

	const closeNavbar = () => {
		setNavbarCollapsed(true);
		setOpenDropdown(null)
	};

	const headerStyle = {
		color: "var(--pbmit-global-color)"
	};
	// const { isNightMode } = useTheme();
	return (
		<div className="site-navigation">
			<nav className="main-menu navbar-expand-xl navbar-light">
				<div className="container-fluid">
					<div className="navbar-header">
						<button
							className="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarNav"
							aria-controls="navbarNav"
							aria-expanded={!navbarCollapsed}
							aria-label="Toggle navigation"
							onClick={toggleNavbar}
						>
							<i className="pbmit-base-icon-menu"></i>
						</button>
					</div>

					<div className={`collapse navbar-collapse ${!navbarCollapsed ? 'show' : ''}`} id="navbarNav">
						<div className="navbar-nav">
							<ul className="navigation clearfix open">
								<li className="dropdown" onClick={() => { setActiveBtn('homeBtn'); closeNavbar(); }}>
									<Link to="/" style={activeBtn === 'homeBtn' ? headerStyle : {}}>Home</Link>
								</li>
								<li className="dropdown">
									<Link to="#" style={activeBtn === 'pagesBtn' ? headerStyle : {}} className={openDropdown === 0 ? "open" : ""}
									>About Us</Link>
									<ul className={openDropdown === 0 ? "open" : ""}>
										<li onClick={() => { setActiveBtn('pagesBtn'); closeNavbar(); }}>
											<Link to="/aboutus">About GERMI</Link>
										</li>
										<li onClick={() => { setActiveBtn('pagesBtn'); closeNavbar(); }}>
											<Link to="/dgdesk">DG's Desk</Link>
										</li>
										<li onClick={() => { setActiveBtn('pagesBtn'); closeNavbar(); }}>
											<Link to="/ourmanagementteams">Management Team</Link>
										</li>
										<li onClick={() => { setActiveBtn('ourteamBtn'); closeNavbar(); }}>
											<Link to="/technicalteams">Technical Team</Link>
										</li>
										<li onClick={() => { setActiveBtn('ourteamBtn'); closeNavbar(); }}>
											<Link to="/ourhradminteams">HR Admin - IT Team</Link>
										</li>
										<li onClick={() => { setActiveBtn('ourteamBtn'); closeNavbar(); }}>
											<Link to="/ourAccountsteams">Finance Team</Link>
										</li>
									
									</ul>
									<span className="righticon" onClick={() => handleIsOpen(0)}>
										<i className={openDropdown === 0 ? "pbmit-base-icon-up-open-big" : "pbmit-base-icon-down-open-big"}></i>
									</span>
								</li>
								{/* <li className="dropdown" onClick={() => { setActiveBtn('servicesBtn'); closeNavbar(); }}>
									<Link to="/ourservices/0" style={activeBtn === 'servicesBtn' ? headerStyle : {}}>Our Services</Link>
								</li> */}

									<li className="dropdown">
									<Link to="/ourservices/0" className={openDropdown === 5 ? "open" : ""} style={activeBtn === 'servicesBtn' ? headerStyle : {}}>Our Services</Link>
									<ul className={openDropdown === 5 ? "open" : ""}>
									<li onClick={() => { setActiveBtn('services1Btn'); closeNavbar(); }}>
										<Link to="/ourservices/1" style={activeBtn === 'services1Btn' ? headerStyle : {}}>Advisory & Consulting</Link>
										</li>
									<li onClick={() => { setActiveBtn('services2Btn'); closeNavbar(); }}>
										<Link to="/ourservices/2" style={activeBtn === 'services2Btn' ? headerStyle : {}}>Research & Development</Link>
										</li>
									<li onClick={() => { setActiveBtn('services3Btn'); closeNavbar(); }}>
										<Link to="/ourservices/3" style={activeBtn === 'services3Btn' ? headerStyle : {}}>Training & Skill Development</Link>
										</li>
										
										
									</ul>
									<span className="righticon" onClick={() => handleIsOpen(5)}>
										<i className={openDropdown === 5 ? "pbmit-base-icon-up-open-big" : "pbmit-base-icon-down-open-big"}></i>
									</span>
								</li>
								<li className="dropdown">
									<Link to="#" className={openDropdown === 1 ? "open" : ""} style={activeBtn === 'depBtn' ? headerStyle : {}}
									>Departments</Link>
									<ul className={openDropdown === 1 ? "open" : ""}>
										{/* <li onClick={() => { setActiveBtn('depBtn'); closeNavbar(); }}>
											<Link to="/departments">Department List</Link>
										</li> */}
										<li onClick={() => { setActiveBtn('depBtn'); closeNavbar(); }}>
											<Link to="departments/new-technology-management/5/0">New Technology Management</Link>
										</li>
										<li onClick={() => { setActiveBtn('depBtn'); closeNavbar(); }}>
											<Link to="departments/renewable-management/5/0">Renewables Management</Link>
										</li>
										<li onClick={() => { setActiveBtn('depBtn'); closeNavbar(); }}>
											<Link to="departments/petroleum-management/5/0">Petroleum Management</Link>
										</li>
										<li onClick={() => { setActiveBtn('depBtn'); closeNavbar(); }}>
											<Link to="departments/petroleum-research-management/5/0">Petroleum Research Management</Link>
										</li>
										<li onClick={() => { setActiveBtn('depBtn'); closeNavbar(); }}>
											<Link to="departments/environment-management/5/0">Environment Management</Link>
										</li>
									</ul>
									<span className="righticon" onClick={() => handleIsOpen(1)}>
										<i className={openDropdown === 1 ? "pbmit-base-icon-up-open-big" : "pbmit-base-icon-down-open-big"}></i>
									</span>
								</li>
								{/* <li className="dropdown" onClick={() => { setActiveBtn('activitiesBtn'); closeNavbar(); }}>
									<Link to="/successstories/101/1/" style={activeBtn === 'activitiesBtn' ? headerStyle : {}}>Success Stories</Link>
								</li> */}
								<li className="dropdown">
									<Link to="/successstories/101/1/" className={openDropdown === 5 ? "open" : ""} style={activeBtn === 'SuccessBtn' ? headerStyle : {}}>Success Stories</Link>
									<ul className={openDropdown === 5 ? "open" : ""}>
									<li onClick={() => { setActiveBtn('dept1Btn'); closeNavbar(); }}>
										<Link to="/successstories/101/1/" style={activeBtn === 'dept1Btn' ? headerStyle : {}}>New Technology Management</Link>
										</li>
									<li onClick={() => { setActiveBtn('dept2Btn'); closeNavbar(); }}>
										<Link to="/successstories/101/2/" style={activeBtn === 'dept2Btn' ? headerStyle : {}}>Renewables Management</Link>
										</li>
									<li onClick={() => { setActiveBtn('dept3Btn'); closeNavbar(); }}>
										<Link to="/successstories/101/3/" style={activeBtn === 'dept3Btn' ? headerStyle : {}}>Petroleum Management</Link>
										</li>
									<li onClick={() => { setActiveBtn('dept4Btn'); closeNavbar(); }}>
										<Link to="/successstories/101/5/" style={activeBtn === 'dept4Btn' ? headerStyle : {}}>Petroleum Research Management</Link>
										</li>
									<li onClick={() => { setActiveBtn('dept5Btn'); closeNavbar(); }}>
										<Link to="/successstories/101/4/" style={activeBtn === 'dept5Btn' ? headerStyle : {}}>Environment Management</Link>
										</li>
										
										
									</ul>
									<span className="righticon" onClick={() => handleIsOpen(5)}>
										<i className={openDropdown === 5 ? "pbmit-base-icon-up-open-big" : "pbmit-base-icon-down-open-big"}></i>
									</span>
								</li>
								{/* <li className="dropdown">
									<Link to="#"
										className={openDropdown === 2 ? "open" : ""} style={activeBtn === 'ourteamBtn' ? headerStyle : {}}
									>Our Team</Link>
									<ul className={openDropdown === 2 ? "open" : ""}>
										<li onClick={() => { setActiveBtn('ourteamBtn'); closeNavbar(); }}>
											<Link to="/technicalteams">Technical Team</Link>
										</li>
										<li onClick={() => { setActiveBtn('ourteamBtn'); closeNavbar(); }}>
											<Link to="/ourhradminteams">HR Admin Team</Link>
										</li>
										<li onClick={() => { setActiveBtn('ourteamBtn'); closeNavbar(); }}>
											<Link to="/ourAccountsteams">Accounts Team</Link>
										</li>
									</ul>
									<span className="righticon" onClick={() => handleIsOpen(2)}>
										<i className={openDropdown === 2 ? "pbmit-base-icon-up-open-big" : "pbmit-base-icon-down-open-big"}></i>
									</span>
								</li> */}
								<li className="dropdown">
									<Link to="#" className={openDropdown === 3 ? "open" : ""} style={activeBtn === 'notificationBtn' ? headerStyle : {}}>Notifications</Link>
									<ul className={openDropdown === 3 ? "open" : ""}>
										<li onClick={() => { setActiveBtn('notificationBtn'); closeNavbar(); }}>
											<Link to="announcementlist">Announcements</Link>
										</li>
										<li onClick={() => { setActiveBtn('notificationBtn'); closeNavbar(); }}>
											<Link to="tenderlist">Tenders</Link>
										</li>
										<li onClick={() => { setActiveBtn('notificationBtn'); closeNavbar(); }}>
											<Link to="newslist">News</Link>
										</li>
									</ul>
									<span className="righticon" onClick={() => handleIsOpen(3)}>
										<i className={openDropdown === 3 ? "pbmit-base-icon-up-open-big" : "pbmit-base-icon-down-open-big"}></i>
									</span>
								</li>

								<li className="dropdown">
									<Link to="#" className={openDropdown === 4 ? "open" : ""} style={activeBtn === 'resBtn' ? headerStyle : {}}>Resources</Link>
									<ul className={openDropdown === 4 ? "open" : ""}>
										<li onClick={() => { setActiveBtn('resBtn'); closeNavbar(); }}>
											<Link to="/publications">Our Publications</Link>
										</li>
										<li onClick={() => { setActiveBtn('resBtn'); closeNavbar(); }}>
											<Link to="/GermiPatents">Our Patents</Link>
										</li>
										<li onClick={() => { setActiveBtn('resBtn'); closeNavbar(); }}>
											<Link to="projectreport">Project Report</Link>
										</li>
										<li onClick={() => { setActiveBtn('donateBtn'); closeNavbar(); }}>
											<Link to="/fcra">FCRA</Link>
										</li>
										<li onClick={() => { setActiveBtn('resBtn'); closeNavbar(); }}>
											<Link to="solarcalculator">Solar Calculator</Link>
										</li>
										<li onClick={() => { setActiveBtn('resBtn'); closeNavbar(); }}>
											<Link to="evcalculator">EV Calculator</Link>
										</li>
										{/* <li onClick={() => { setActiveBtn('resBtn'); closeNavbar(); }}>
											<Link to="ourservices">NTM Training Form</Link>
										</li> */}
									</ul>
									<span className="righticon" onClick={() => handleIsOpen(4)}>
										<i className={openDropdown === 4 ? "pbmit-base-icon-up-open-big" : "pbmit-base-icon-down-open-big"}></i>
									</span>
								</li>
								<li onClick={() => { setActiveBtn('careersBtn'); closeNavbar(); }}>
									<Link to="/careers" style={activeBtn === 'careersBtn' ? headerStyle : {}}>Careers</Link>
								</li>

								{/* <li className="dropdown">
									<Link to="#" className={openDropdown === 5 ? "open" : ""} style={activeBtn === 'donateBtn' ? headerStyle : {}}>Contact Us</Link>
									<ul className={openDropdown === 5 ? "open" : ""}>
									<li onClick={() => { setActiveBtn('contactBtn'); closeNavbar(); }}>
										<Link to="/contactus" style={activeBtn === 'contactBtn' ? headerStyle : {}}>Contact</Link>
										</li>
										
										
									</ul>
									<span className="righticon" onClick={() => handleIsOpen(5)}>
										<i className={openDropdown === 5 ? "pbmit-base-icon-up-open-big" : "pbmit-base-icon-down-open-big"}></i>
									</span>
								</li> */}
								<li onClick={() => { setActiveBtn('donateBtn'); closeNavbar(); }}>
									<Link to="/give" style={activeBtn === 'donateBtn' ? headerStyle : {}}>Donate</Link>
								</li>
								<li onClick={() => { setActiveBtn('contactBtn'); closeNavbar(); }}>
									<Link to="/contactus" style={activeBtn === 'contactBtn' ? headerStyle : {}}>Contact Us</Link>
								</li>
								
								{/* <li onClick={() => { setActiveBtn('contactBtn'); closeNavbar(); }}>
								
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Navbar;
