import './Form2.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { MuiTelInput } from 'mui-tel-input'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import EducationTable from './EducationDetails';
import WorkDetails from './WorkDetails';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
const currencies = [
    {
        value: 'Mng',
        label: 'Manager',
    },
    {
        value: 'DGM',
        label: 'Dy. General Manager',
    },
    {
        value: 'DM',
        label: 'Dy. Manager',
    },

];
const experience = [
    { value: '1', label: '1 Year' },
    { value: '2', label: '2 Year' },
    { value: '3', label: '3 Year' },
    { value: '4', label: '4 Year' },
    { value: '5', label: '5 Year' },
    { value: '6', label: '6 Year' },
    { value: '7', label: '7 Year' },
    { value: '8', label: '8 Year' },
    { value: '9', label: '9 Year' },
    { value: '10', label: '10 Year' },
    { value: '10+', label: '>10 Year' },
];

function Form2() {
    const [value, setValue] = React.useState('')

    const handleChange = (newValue) => {
        setValue(newValue)
    }

    const [selectedFile, setSelectedFile] = React.useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }


    return (
        <>
        <div className='form-section'>
        <div className='form2-main-div'>
        <form>
                <div >
                    <div className="form2-mandatory form2-pl ">* Mandatory</div>
                    {/* <div className='text-salary form2-pt-10'>Personal Details:</div> */}
                    <div className='form2-row-div'>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-select-currency"
                                required
                                select
                                label="Select"
                                // defaultValue="EUR"
                                helperText="Position Applied for"
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Box
                            sx={{
                                m: 1,
                                width: 516,
                                maxWidth: '100%',
                            }}
                        >
                            <TextField required fullWidth label="Full Name" id="fullWidth" />
                        </Box>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off">
                            <MuiTelInput defaultCountry="IN" required label="Phone" fullWidth value={value} onChange={handleChange} />
                        </Box>
                    </div>
                    <div className='form2-row-div'>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField type="email" required id="outlined-basic" label="Email Id" variant="outlined" />

                        </Box>
                        <Box
                            sx={{
                                m: 1,
                                width: 516,
                                maxWidth: '100%',
                            }}
                        >
                            <TextField

                                className='form2-address'
                                required
                                fullWidth
                                id="outlined-multiline-flexible"
                                label="Correspondence Address (with pincode)"
                                multiline
                                maxRows={4}
                            />

                        </Box>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField required id="outlined-basic" label="Current City/State" variant="outlined" />
                        </Box>
                    </div>
                    <div className='form2-row-div'>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField required id="outlined-basic" label="Native Place" variant="outlined" />
                            <div style={{ display: "ruby" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker required label="Date of Birth" />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </Box>
                    </div>
                    <div className='text-salary form2-pt-10'>Employment Details:</div>
                    <div className='form2-row-div'>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '34ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField required id="outlined-basic" label="Current Designation" variant="outlined" />
                            <TextField required id="outlined-basic" label="Current Organization" variant="outlined" />
                            <TextField
                                required
                                id="outlined-select-currency"
                                select
                                label="Select"
                                defaultValue="1"
                                helperText="Experience in Years"
                            >
                                {experience.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Box>

                    </div>
                    <div className='text-salary'>Monthly Salary in Details(In Numbers Only):</div>
                    <div className='form2-row-div'>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '34ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                min="0"
                                id="outlined-number"
                                label="Net to Hand/ Take Home"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="outlined-number"
                                label="Others Allowances/ Benefits"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="outlined-number"
                                label="Gross Salary (Monthly)"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </div>

                    <div className='text-salary form2-pt-10'>Education Details:</div>
                    <EducationTable />
                    <div className='text-salary form2-pt-10'>Work Details:</div>
                    <WorkDetails />
                    <div className='form2-row-div form2-pt-10'>
                        <div className='text-salary form2-pr'>Minimum Notice Period Required to Join in case of selection: </div>
                        <TextField
                            min="0"
                            id="outlined-number"
                            label="Days"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>
                        <FormControl>
                            <div className='form2-row-div form2-pt-10 form2-pl'>
                                <div className='form2-pr'>
                                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Are you ready to relocate to any location in India?</FormLabel> */}
                                    Are you ready to relocate to any location in India?
                                </div>

                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />

                                </RadioGroup>
                            </div>

                        </FormControl>
                    </div>
                    {/* <Stack direction="row" alignItems="center" spacing={2}>
                    <Button variant="contained" component="label">
                        Upload
                        <input hidden accept="image/*" multiple type="file" />
                    </Button>
                </Stack> */}
                    <div className='form2-row-div form2-pt-10'>
                        <div className='text-salary form2-pr'>Upload Resume/ CV* (UPDATED CV - Word or PDF file only): </div>

                        <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
                            Upload
                            <input
                                hidden
                                type="file"
                                onChange={handleFileChange}
                            />
                        </Button>
                        {selectedFile && (
                            <p>Selected File: {selectedFile.name}</p>
                        )}
                    </div>
                    <div className='form2-row-div form2-pt-10 form2-check-l'>
                        <FormGroup>
                            <FormControlLabel required control={<Checkbox />} />
                        </FormGroup>
                        <div className=' form2-pr form2-declare-styling'>I hereby certify that the above information are true and correct to the best of my knowledge and in case of any discrepancy, the application will be disqualified. </div>


                    </div>
                    <div className='form2-row-div'>
                        <div className='form2-pt-10 form2-pl'>
                            <Button variant="contained" component="label" endIcon={<IosShareIcon />}>
                                Submit
                                <input
                                    hidden
                                    type="submit"
                                // onChange={handleFileChange}
                                />
                            </Button>
                        </div>
                        <div className='form2-pt-10 form2-pl'>
                            {/* <Button variant="contained" component="label" endIcon={<RestartAltIcon />}>
                                Reset
                                <input
                                    hidden
                                    type="reset"
                                // onChange={handleFileChange}
                                />
                            </Button> */}
                        </div>
                    </div>



                </div>
            </form>
        </div>
        </div>
        </>
    );
}

export default Form2;