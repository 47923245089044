import React from 'react'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs'
import BreadCrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/About-Us.png'
import './DgDesk.css'
function DgDesk() {
  return (
    <>
    <div className='dg-main-div'>
         <BreadCrumbs parent="Home" child="DG's Desk" parentPath="/" imgPath={BreadCrumbImage} />
        <h2 className='dg-title'>Coming Soon</h2>
    </div>
    </>
  )
}

export default DgDesk
