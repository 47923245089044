const publicationData = [
    //Year 2024
    [
        {
            year: "2024",
            srno: "1.",
            title: "",
            desc: "Impact of temperature and nutrition on biomass and phycobiliprotein of Desertifium Salkalinema from a cyanobacteria from hot water spring | Journal of Applied Phycology | Published 2024",
        },
        {
            year: "2024",
            srno: "2.",
            title: "",
            desc: "Senjaliya, Y., Oad, N., Chakroborty, S., Tripathi, B., Chandra, P., Tripathi, P.K., Solanki, A., Darwish, I.A., Rawat, S., Barik, A. and Kapadia, R., 2024. Rare Earth metal oxide nanoparticle-infused polymer nanocomposites for enhanced supercapacitor electrodes. Journal of Molecular Structure, 1307, p.137919.",
        },
        {
            year: "2024",
            srno: "3.",
            title: "",
            desc: "Oad, N., Senjaliya, Y., Pandya, D., Rawat, S., Kumar, M., Kapadia, R., Solanki, A., Tripathi, B. and Chandra, P., 2024. Synthesis of polyindole/gallium–gadolinium–aluminum garnet nano-composite for supercapacitor electrode. Journal of Materials Science: Materials in Electronics, 35(6), p.438.",
        },
        // {
        //     year: "2024",
        //     srno: "4.",
        //     title: "",
        //     desc: "Senjaliya, Y., Oad, N., Chakroborty, S., Tripathi, B., Chandra, P., Tripathi, P.K., Solanki, A., Darwish, I.A., Rawat, S., Barik, A. and Kapadia, R., 2024. Rare Earth metal oxide nanoparticle-infused polymer nanocomposites for enhanced supercapacitor electrodes. Journal of Molecular Structure, 1307, p.137919.",
        // },
        // {
        //     year: "2024",
        //     srno: "5.",
        //     title: "",
        //     desc: "Oad, N., Senjaliya, Y., Pandya, D., Rawat, S., Kumar, M., Kapadia, R., Solanki, A., Tripathi, B. and Chandra, P., 2024. Synthesis of polyindole/gallium–gadolinium–aluminum garnet nano-composite for supercapacitor electrode. Journal of Materials Science: Materials in Electronics, 35(6), p.438.",
        // },

    ],
    //Year 2023
    [
        {
            year: "2023",
            srno: "1.",
            title: "",
            desc: "Maximizing lipid extraction from Chlorella pyrenoidosa: A study on the effectiveness of cll disruption techniques | Research Journal of Biotechnology | Accepted 2023",
        },
        {
            year: "2023",
            srno: "2.",
            title: "",
            desc: "TiO2 deposited algal cellulose sheet for antimicrobial membrane | Nano Research & Applications | V 9, N 2:11, 2023",
        },
        {
            year: "2023",
            srno: "3.",
            title: "",
            desc: "rGO-ZnO nanowire deposited filamentous seaweed nanofibrous cellulose for paper supercapacitor | BioNanoScience, Springer | V 13, N 2 2023 pp 588-599",
        },
        {
            year: "2023",
            srno: "4.",
            title: "",
            desc: "Bhutiya, P.L., Kapadiya, R., Tripathi, B., Sanjaliya, Y., Rasheed, M.A., Rao, P.L.S. and Hasan, S.Z., 2023. rGO-ZnO nanowire deposited filamentous seaweed nanofibrous cellulose for paper supercapacitor. BioNanoScience, 13(2), pp.588-599.Volume 13, pages 588 –599, (2023)",
        }
    ],
    //Year 2022
    [
        {
            year: "2022",
            srno: "1.",
            title: "",
            desc: "Hydrochemistry of alluvial aquifers of Kasganj subdivision in parts of the central Ganga plain, U.P, India | E-Journal of Hydrology INC-IAH | V3, N1 2022 pp49-59",
        },
        {
            year: "2022",
            srno: "2.",
            title: "",
            desc: "Macro-seepage based potential new hydrocarbon prospects in Assam-Arakan Basin, India | Journal Scientific Reports Nature Publication | V 12, N 1 2022 pp 1-15",
        },
        {
            year: "2022",
            srno: "3.",
            title: "",
            desc: "Multiproxy geochemical characterization of Kommugudem Formation, Krishna Godavari basin, India: Implication on hydrocarbon potential and shale brittleness | Geological Journal Wiley Publication | V 57 N 4 2022 pp 1373-1390",
        },
        {
            year: "2022",
            srno: "4.",
            title: "",
            desc: "Hydrocarbon microseepage exploration in Kerkennah islands, eastern Tunisia: Integrated surface geochemical and geoelectrical prospecting approaches | Applied Geochemistry Elsevier Publication | Submitted 2022",
        }
    ],
    //Year 2021  
    [
        {
            year: "2021",
            srno: "1.",
            title: "Techno-economic analysis of district cooling system",
            desc: "Kush Thakar, Rajesh Patel, Gaurav Patel,  Techno-economic analysis of district cooling system: A case study, Journal of Cleaner Production, Volume 313, 2021, 127812, ISSN 0959-6526",
            link: "https://doi.org/10.1016/j.jclepro.2021.127812"
        },
        {
            year: "2021",
            srno: "2.",
            title: "",
            desc: "Organic petrology and geochemistry of Early Permian shales from the Krishna-Godavari Basin, India: Implications for Gondwana palaeo environment and climate | Geological Journal Wiley Publication | V 56, N 11 2021 pp 5621-5641",
        },
        {
            year: "2021",
            srno: "3.",
            title: "",
            desc: "Impact of sedimentary environment on pore parameters of thermally mature Permian shale: A study from Kommugudem Formation of Krishna Godavari Basin, India | Marine and Petroleum Geology | V 132 2021",
        },
        {
            year: "2021",
            srno: "4.",
            title: "",
            desc: "CO2 bio-sequestration studies on microalgae - an approach through sustainable biofuel production | Green Energy and Technology Springer Publication | Book Edition 2021 pp 275-286",
        }
    ],

    //Year 2019 
    [
        {
            year: "2019",
            srno: "1.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: " Dipal Patel, Vivek Thakar, Sivakumar Pandian, Manan Shah and Anirbid Sircar, 2019, A review of casing while drilling technology for oil and gas production with well control model and economic analysis, Petroleum, Elsevier, Available Online: 12th December, 2018",
            link: "https://doi.org/10.1016/j.petlm.2018.12.003"
        },
        {
            year: "2019",
            srno: "2.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Sumit Kumar Joshi, Pradip Kumar Haldiya, Manan Shah and Anirbid Sircar, 2019, Techno Economic Analysis and experimental analysis of biodiesel production from used cooking oil, Biophysical Economics and Resource Quality, Springer.",
            link: "https://doi.org/10.1007/s41247-018-0050-7"
        }
    ],

    //Year 2018       
    [
        {
            year: "2018",
            srno: "1.",
            desc: "Sivakumar, P., Sircar, Anirbid, Deka Barsha, Anumegalai, A.S., Moorthi, P.S., Yasvanthrajan, 2018, Flow improvers for assured flow of crude oil in midstream pipeline –A review, Journal of Petroleum Science and Engineering, Elsevier, (Online: 10th Jan, 2018) Vol. 164, May, 2018, pp.24-30 (Impact Factor: 2.739).",
            link: "https://doi.org/10.1016/j.petrol.2018.01.022"
        },

        {
            year: "2018",
            srno: "2.",
            desc: "Balasubramanian, R., Sircar, Anirbid, Sivakumar, P., Anbarasu, K., 2018, Production of biodiesel from dairy waste water sludge: A laboratory and pilot scale study, Egyptian Journal of Petroleum, Elsevier, Online on 2-3-2018; RG Journal Impact: 1.41 *",
        },

        {
            year: "2018",
            srno: "3.",
            desc: "Brahma, J. and Sircar, Anirbid, 2018, Design of safe well on the top of Atharamura anticline, Tripura, India, on the basis of predicted pore pressure from seismic velocity data, Journal Petroleum Exploration and Production Technology, Springer, Online Published on 01 February, 2018, (Impact Factor: 0.935).",
        },

        {
            year: "2018",
            srno: "4.",
            desc: "Shah, Manan, Vaidya, Dwijen and Sircar, Anirbid, 2018, Using Monte Carlo simulation to estimate geothermal resource in Dholera Geothermal Field, Published online on 27th March, 2018 in Multicale and Multidisciplinary modeling, Experiments and Design, Springer.",
        },

        {
            year: "2018",
            srno: "5.",
            desc: "Thakar, Vivek, Nambiar Sachin, Shah, Manan and Sircar, Anirbid, 2018, A model on dual string drilling-on the road to deepwaters, Modeling Earth Systems and Environment, Springer, Published Online on 12th April, 2018, Vol.4, Issue2, pp. 673-684.",
        },

        {
            year: "2018",
            srno: "6.",
            desc: "Balasubramanian, R., Sircar, Anirbid and Sivakumar, P., 2018, Conversion of Bio-Solids (scum) from Tannery Effluent treatment plant into biodiesel, Energy Sources, Part A: Recovery, Utilization and Environment Effects, Taylor and Francis., Published Online on 27 April, 2018, Vol. 40, Issue 8, pp. 959-967(IF:0.527).",
            link: "https://doi.org/10.1080/15567036.2018.1468507"
        },

        {
            year: "2018",
            srno: "7.",
            desc: "Sircar, A and Yadav, K. 2018, Expansion of hybrid LNG and CNG fuelling station, Gas Processing (May-June) issue, Gulf Publishers."
        },

        {
            year: "2018",
            srno: "8.",
            desc: "Shah, Manan, Sircar, Anirbid, Shaikh, Nahid, Patel, Karan, Patel, Thakar, Vivek, Sharma, Darshan, Sarkar, Prakhar, Vaidya, Dwijen, 2018, Groundwater for Sustainable Development, Elsevier, Volume 7, pp. 143-156., September, 2018, Elsevier, ISSN-2352-801X."
        },

        {
            year: "2018",
            srno: "9.",
            desc: "Patel, Karan, Shah, Manan and Sircar, Anirbid, 2018, Plasma Pulse Technology : An uprising EOR technique, Petroleum Research, Elsevier, Online on 3rd July, 2018. https://doi.org/10.1016/j.ptlrs.2018.05.001, Vol.3, Issue2, June, 2018,pp.180-188",
            link: "https://doi.org/10.1016/j.ptlrs.2018.05.001"
        },

        {
            year: "2018",
            srno: "10.",
            desc: "Padmanaban, S., Deepalakshmi, S., Sircar, A., Yasvanthrajan, N, Shim, Suk, Min, Sivakumar, P., 2018, Studies on Three Phase Three Dimensional Hybrid Electrochemical Reactor for treating textile effluent, Desalination and Water Treatment, Taylor and Francis (RG Impact factor:0.93)."
        },

        {
            year: "2018",
            srno: "11.",
            desc: "Sharma, Vaishali and Sircar, Anirbid, 2018, \"Mineralogical Assemblage of Cambay Shale of North Cambay Basin, Gujarat, India\" Journal of Petroleum Exploration and Production Technology, Springer, Online: 18th June, 2018. (IF:0.935)"
        },

        {
            year: "2018",
            srno: "12.",
            desc: "Vishnu Priya, M., Ramesh, K., Sivakumar, P., Balasubramanian, R., and Sircar, A., 2018, Kinetics and thermodynamic studies on the extraction of biofuel from Chlorella Vulgaris and the subsequent biodiesel production, Chemical Engineering Communications, Taylor and Francis (IF:1.282)."
        },

        {
            year: "2018",
            srno: "13.",
            desc: "Shah, Manan, Sircar Anirbid, Shaikh, N., Patel, K., Sharma, D. and Vaidya, D., 2018, Comprehensive Geochemical /Hydrochemical and Geo-Thermometry analysis of Unai Geothermal field , Gujarat, India, Accepted in Acta Geochimica , Springer. CJOG-D-18-00013R3; Online: 7th August, 2018."
        },

        {
            year: "2018",
            srno: "14.",
            desc: "Sharma, V., Sircar, A., Gupta, A., (2018), Hydraulic Fracturing Design and 3D Modeling: A Case Study from Cambay Shale and Eagleford Shale, Multiscale and Multidisciplinary Modeling, Experiments and Design, Springer Nature",
            link: "https://doi.org/10.1007/s41939-018-0014-z"
        },

        {
            year: "2018",
            srno: "15.",
            desc: "Sharma V. and Sircar A., (2018), Integrated Petrophysical and Geomechanical Characterization of Cambay shale, Cambay basin, India, Society of Exploration Geophysicists.",
            link: "https://doi.org/10.1190/RDP2018-41975450.1"
        },

        {
            year: "2018",
            srno: "16.",
            desc: "Manan Shah, Anirbid Sircar, Rajan Varsada, Sweta Vaishnani, Uttam Savaliya, Meet Faldu, Dwijen Vaidya and Prosun Bhattacharya, (2018), Assessment of geothermal water quality for industrial and irrigation purposes in the Unai geothermal field, Gujarat, India, Groundwater for Sustainable Development, Manan Shah, Harshil Kathriya, Milapkumar Kakadiya, Vinay Bhogara, Anirbid Sircar and Suchen Thakore, 2018, Model design of condenser for solar assisted geothermal cooling system using software simulation, Modelling earth system and environment http//doi.org/10.1007/S40808-018-0516-Z, Springer",
            link: "https://doi.org/10.1016/j.gsd.2018.08.006"
        },

        {
            year: "2018",
            srno: "17.",
            desc: "Senthilkumar, C., Krishnaraj, C., Sivakumar, P. and Sircar, A., 2018, Statistical optimization and kinetic study of biodiesel production from potential non-edible bio oil of wild radish, Chemical Engineering Communications, Taylor and Francis, Accepted, Impact Factor: 1.282.",
            link: "https://doi.org/10.1080/00986445"
        },

        {
            year: "2018",
            srno: "18.",
            desc: "Arunprasad As, Periyasamy S., Sivalumar, P., Sakthi Sarvanan A. and Sircar, A., 2018, Optimization and Kinetic studies (Euglena Sanguinea) using Calcium metoxide as catalyst, Energy Sources, Part A: Recovery, Utilization and Environmental effects, pp1-11, Taylor and Francis, Impact Factor:0.555.",
            link: "https://doi.org/10.1080/15567036.2018.1549124"
        }
    ],

    //Year 2017
    [
        {
            year: "2017",
            srno: "1.",
            desc: "Shah, M., Shah, S. and Sircar, A., 2017, \“A Comprehensive Overview on Recent Developments in Refracturing Technique for Shale Gas Reservoirs\” Published online in Journal of Natural Gas Science and Engineering, October 2017 Volume 46: pp.350-364 Elsevier. (Impact Factor:2.815).",
            link: "https://doi.org/10.1016/j.jngse."
        },
        {
            year: "2017",
            srno: "2.",
            desc: "Sharma, V., Sircar, A., Mohammed, N. and Patel, S., 2017, A treatise on Non-Darcy flow correlations in Porous Media, Journal of Petroleum and Environmental Biotechnology, Vol. 8, No.5, DOI: 10.4172/2157-7463.1000336. (Impact Factor:2.5)."
        },
        {
            year: "2017",
            srno: "3.",
            desc: "Brahma, J and Sircar, A., 2017, Design of Safe well in top of Atharamura Anticline , India on the basis of predicted pore pressure from seismic velocity data, SPE-MS-188492, One Petro."
        },
        {
            year: "2017",
            srno: "4.",
            desc: "Shah, M., Sircar, A., Mandlik, M. and Vaidya, D., 2017, Cement slurry design for geothermal exploratory well in Dholera field using a novel concept of multifunctional additives, Asian Journal of Science and Technology, ISSN: 0976-3376, Vo.08, Issue09, pp.5812-5816, September issue."
        },
        {
            year: "2017",
            srno: "5.",
            desc: "Manoharan, T.; Laksmanan, D. ; Mylsamy, K. ; Sivakumar, P. ; Sircar, Anirbid, 2017, Engineering Properties of Concrete with Partial Utilization of Used Foundry Sand, Waste Management, Elsevier, Available Online : 2nd Nov, 2017, doi.org/10.1016/j.wasman.2017.10.022 , Volume 71, January 2018, pp. 454-460 Elsevier, (Impact Factor: 5.262)."
        },
        {
            year: "2017",
            srno: "6.",
            desc: "Behura, S., Haokip, H., Goel, C., Nayak, S., Mahala, P., Kapadia, R. and Jani, O., 2017. Photovoltaic and impedance spectroscopic characteristics of heterojunction of graphene-PEDOT: PSS composite and n-silicon prepared via solution-based process. Materials Research Innovations, 21(5), pp.263-268."
        }
    ],

    //Year 2016
    [
        {
            year: "2016",
            srno: "1.",
            title: "",
            desc: "Tank, V., Bhutka, J. and Harinarayana, T. (2016) Wind Energy Generation and Assessment of Resources in India. Journal of Power and Energy Engineering, 4, 25-38.",
            link: "https://file.scirp.org/pdf/JPEE_2016102710441345.pdf"
        },
        {
            year: "2016",
            srno: "2.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Begum, S.K. and Harinarayana, T. “ Basement Configuration from Magnetotelluric Studies in Bhuj Earthquake Epicentral Zone, Gujarat, India.” Open Journal of Earthquake Research, Vol. 5, pp. 177-188 (2016)",
            link: "https://www.scirp.org/Journal/PaperInformation?PaperID=70187"
        }
        ,
        {
            year: "2016",
            srno: "3.",
            title: "",
            desc: "S. Behura, H. Haokip, C. Goel, S. Nayak, P. Mahala, R. Kapadia, and O. Jani, 'Photovoltaic and impedance spectroscopic characteristics of heterojunction of graphene-PEDOT:PSS composite and n-silicon prepared via solution based process,' Materials Research Innovations, doi: 10.1080/14328917.2016.1211477 (2016).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/downloads/BEHURAPUB27_Graphene12_MRI_20160802.pdf"
        }
        ,
        {
            year: "2016",
            srno: "4.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: " Bhutka, J., Gajjar, J. and Harinarayana, T., “Modelling of Solar Thermal Power Plant Using Parabolic Trough Collector” Journal of Power and Energy Engineering (JPEE), Vol. 4, pp 9-25 (2016)",
            link: "https://www.scirp.org/Journal/PaperInformation?PaperID=69363"
        },
        {
            year: "2016",
            srno: "5.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "P. Mahala, A. Kumar, S. Nayak, S. Behura*, C. Dhanavantri and O. Jani, 'Graphene, conducting polymer and their composites as transparent and current spreading electrode in GaN solar cells,' Superlattices and Microstructures, Vol. 92, p. 366-373 (2016)",
            link: "https://www.sciencedirect.com/science/article/abs/pii/S0749603616300775"
        },
        {
            year: "2016",
            srno: "6.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "Radhika J. Savalia, Shatrughan Yadav, Akash Davda, Sarika Kanojia, ”Performance Evaluation of 190 kWp Rooftop Solar Photovoltaic Plant in CMPDI, Ranchi”, International Journal of Advance Research in Engineering, Science & Technology, e-ISSN: 2393-9877, p-ISSN: 2394-2444, Special Issue for International Conference on Innovative Trends in Electrical & Computer Engineering 2016, (2016)",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/downloads/E179508.pdf"
        },
        {
            year: "2016",
            srno: "7.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "Shahare, S. and Harinarayana, T, Energy Efficient Air Conditioning System Using Geothermal Cooling-Solar Heating in Gujarat, India. Journal of Power and Energy Engineering, Vol. 4, pp. 57-71 (2016). DOI: 10.4236/jpee.2016.41004",
            link: "https://www.scirp.org/journal/paperinformation?paperid=63094"
        },
        {
            year: "2016",
            srno: "8.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "1. Photovoltaic Characteristics of ZnO/P3HT/PEDOT: PSS Hybrid Solar Cells Poster presentation in Material Research Society (MRS) Fall Meeting 2016, Boston, MA, USA"
        },
    ],

    // Year 2015
    [
        {
            year: "2015",
            srno: "1.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: " Abdul Azeez, K. K., K. Veeraswamy, A. K. Gupta, N. Babu, S. Chandrapuri, and T. Harinarayana, The electrical resistivity structure of lithosphere across the Dharwar craton nucleus and Coorg block of South Indian shield: Evidence of collision and modified and preserved lithosphere, J. Geophys. Res. Solid Earth, 120, pp. 6698–6721, (2015) doi:10.1002/2014JB011854.",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/publications.php#"
        },
        {
            year: "2015",
            srno: "2.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Reddy, C. and Harinarayana, T, Solar Thermal Energy Generation Potential in Gujarat and Tamil Nadu States, India. Energy and Power Engineering, Vol. 7, pp. 591-603 (2015) doi: 10.4236/epe.2015.713056.",
            link: "https://www.scirp.org/Journal/PaperInformation?PaperID=62525"
        },
        {
            year: "2015",
            srno: "3.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Gajjar, J. , Agravat, S. and Harinarayana, T, Solar PV Energy Generation Map of Karnataka, India. Smart Grid and Renewable Energy, Vol. 6, pp. 333-343. (2015) doi: 10.4236/sgre.2015.612027.",
            link: "https://www.scirp.org/Journal/PaperInformation?PaperID=62432"
        },
        {
            year: "2015",
            srno: "4.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Akhilesh Magal, Financing India’s 100 GW Solar Target, Academia (2015)",
            link: "https://www.academia.edu/19405404/Financing_Indias_100_GW_solar_target"
        },
        {
            year: "2015",
            srno: "5.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Akhilesh Magal, Making sense of the record low solar tariffs in India, Academia (2015)",
            link: "https://www.academia.edu/19402402/Making_sense_of_the_record_low_solar_tariffs_in_India"
        },
        {
            year: "2015",
            srno: "6.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Akhilesh Magal, Why Andhra Pradesh might be the next solar destination, Academia (2015)",
            link: "https://www.academia.edu/17847565/Why_Andhra_Pradesh_might_be_the_next_major_solar_destination"
        },
        {
            year: "2015",
            srno: "7.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Ankit Bhatt, Akhilesh Magal, Electric Vehicles in India: A comprehensive review on Policy, Market Outlook and Financial Viability, Academia (2015)",
            link: "https://www.academia.edu/19595787/Electric_Vehicles_in_India_A_Comprehensive_Review"
        },
        {
            year: "2015",
            srno: "8.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "P. L. Srinivasa Rao., M. A. Rasheed, Annapurna Boruah., Syed Zaheer Hasan., Prabhas Kumar Mohanty, and P.Madhulika. Role of Trace metals as Geochemical Proxy Indicator in Identifying the hydrocarbon prospective zones: A Case Study from Sanand area Of Cambay Basin. 11th Biennial international Conference & Exposition. Society of Petroleum Geophysicists (SPG) conference, Jaipur, Rajasthan (2015). (Accepted for Publication)."
        },
        {
            year: "2015",
            srno: "9.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "M. A. Rasheed, P. L. Srinivasa Rao, Annapurna Boruah, Syed Zaheer Hasan, Bougi Mohamoud Mohamed Seddik, and Disha Pandey. Significance of Microbial Anomalies in Identifying the Hydrocarbon Prospects in Parts of Cambay Basin, Gujarat. 11th Biennial international Conference & Exposition. Society of Petroleum Geophysicists (SPG) conference, Jaipur, Rajasthan (2015). (Accepted for Publication)."
        },
        {
            year: "2015",
            srno: "10.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Abdul Azee KK, S. Prabhakar E Rao, A.K. Gupta, Sharana Basava & Prof. T. Harinarayana, “Magnetotelluric study across the Schirmacher Oasis of Central Dronning Maud Land, East Antarctica: Electrical characteristics of the East African-Antarctic Orogen as a possible aid to link Gondwana fragments” Conference Paper at XII Internatinoal Symposium on Antarctica Earth Sciences (ISAES), Goa, India (2015)."
        },
        {
            year: "2015",
            srno: "11.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "M. A. Rasheed, P. L. Srinivasa Rao., Anand Patil., Harshit Patel, Shubhangi Kala, Annapurna Boruah., Syed Zaheer Hasan., P.H. Rao, T.Harinarayana. Identification of Hydrocarbon Microseepage Using Trace Metal Indicators in Petroliferous Region of South Cambay Basin, Gujarat, India. International Journal of Petroleum and Petrochemical Engineering (IJPPE) Vol.1, Issue 1, pp.12-19 (2015).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/Ank-Trace-IIJPPE-83.pdf"
        },
        {
            year: "2015",
            srno: "12.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "M. A. Rasheed, P.L.Srinivasa Rao, B. Annapurna, Syed Zaheer Hasan. Implication of Soil Gas Method for Prospecting of Hydrocarbon Microseepage. International Journal of Petroleum and Petrochemical Engineering (IJPPE) Vol. 1, Issue 1, 31-41 (2015).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/GC-published-IJPPE-82.pdf"
        }
        , {
            year: "2015",
            srno: "13.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Boruah Annapurna, Ganapathi S. Application of Micro Computer Tomography (µ CT) in Resolving Barren Measures Shale Properties. International Journal of Scientific & Engineering Research, Vol.6, Issue 4, pp. 1839-1843 (2015).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/Application-CT-81.pdf"
        }
        , {
            year: "2015",
            srno: "14.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Dr. Sagarkumar M. Agravat, 'Sunny Office Solution' - A Success Story on Gandhinagar Roof Top SPV Project, Energy Next, Vol. 5, Issue 8, Pg.26, June - 2015.",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/Energy%20Next_June%202015_sunny.pdf"
        }
        , {
            year: "2015",
            srno: "15.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Dr. Sagarkumar M. Agravat, Saravanan S., Shagufta S., Bela Jani,'A Review of Emergence of Canal Top Solar PV Plants as an Effective Tool to Take a Quantum Leap Towards Achieving Second Green Revolution', International Power Plant O&M Conference on 'Fuel Adequacy', February 13th - 15th, 2015, Organized by National Thermal Power Corporation (NTPC) - New Delhi, India.",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/IPS%202015%20Book-sagar2015.pdf"
        }
        , {
            year: "2015",
            srno: "16.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Syed Zaheer Hasan, M. A. Rasheed, “T. Harinarayana , Shale gas: A non-conventional hydrocarbon as future energy resource of India” Journal of the Geological Society of India, V. 85, Issue 3, pp. 322-330 (2015).",
            link: "https://link.springer.com/article/10.1007/s12594-015-0221-8?wt_mc=email.event.1.SEM.ArticleAuthorOnlineFirst"
        }
        , {
            year: "2015",
            srno: "17.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "T. Harinarayana, “Geothermal Energy : Vision 2020, Energy India 2020, p-250-260, 2015",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/77-geothermal1.pdf"
        }
        , {
            year: "2015",
            srno: "18.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. Nayak, S. K. Behura, B. P. Singh and S. Bhattacharjee, 'Flexible polymer-carbon nanotubes composite developed by in-situ polymerization technique,' Polymer Composites, doi:10.1002/pc.23483 (2015)",
        }
        , {
            year: "2015",
            srno: "19.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. K. Behura, Q. Yang, A. Hirose, S. Nayak, O. Jani and I. Mukhopdhyay, 'Electrical characteristics of horizontally and vertically oriented few-layer graphene on Si- based dielectrics, Journal of Nanoscience and Nanotechnology,' doi:10.1166/jnn.2015.11028 (2015).",
        }
        , {
            year: "2015",
            srno: "20.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "T. Harinarayana, “An innovative solution for power problem in India”, The National Conference on Indian Energy Sector Synergy with Energy, p. 7-11 (2015).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/74-Energy-2015.pdf"
        }
        , {
            year: "2015",
            srno: "21.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Sagarkumar M. Agravat, N. V. S. Manyam, T. Harinarayana, “Theoretical Study of Wind Turbine Model with a New Concept on Swept Area”, Energy and Power Engineering, 7, p. 127-134 (2015)",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/EPE_2015041016135698.pdf"
        }
        , {
            year: "2015",
            srno: "22.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Syed Zaheer Hasan , M. A. Rasheed and T. Harinarayana. Shale gas: A non-conventional hydrocarbon as future energy resource of India Journal of the Geological Society of India. Vol.85, pp.322-330 (2015).",
            link: "https://www.geosocindia.org/index.php/jgsi/article/view/60263"
        }
    ],

    //Year 2014
    [
        {
            year: "2014",
            srno: "1.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "Anthony E. Akpan, Mahesh Narayanan, T. Harinarayana, “Estimation of Subsurface Temperatures in the Tattapani Geothermal Field, Central India, from Limited Volume of Magnetotelluric Data and Borehole Thermograms Using a Constructive Back-Propagation Neural Network”, Earth Interactions, Vol. 8, Paper No. 6 (2014)",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/71-Tatapani-Akpan.pdf"
        },

        {
            year: "2014",
            srno: "2.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "T. Harinarayana, K. J. Kashyap, “Solar energy generation potential estimation in India and Gujarat, Andhra, Telangana states,” Smart Grid and Renewable Energy, 5, p. 275-289 (2014)",
            link: "https://www.scirp.org/journal/paperinformation?paperid=51455"
        },

        {
            year: "2014",
            srno: "3.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "R. Gopichandran, Shyam Asolekar, Omkar Jani, Dinesh Kumar, Anand Hiremath, a chapter on “Green Energy and Climate Change” for the book “An integrated approach to environmental management” accepted for publication by Wiley & Sons, Inc. (2014).",

        },

        {
            year: "2014",
            srno: "4.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "S. K. Behura, S. Nayak, Q. Yang, A. Hirose, O. Jani, “Chemical vapor deposited few-layer graphene as an electron field emitter” Journal of Nanoscience and Nanotechnology, doi:10.1166/jnn.2015.10627 (2015). [SCI IF 1.339]",
            link: "https://www.ingentaconnect.com/content/asp/jnn/2016/00000016/00000001/art00027."
        },

        {
            year: "2014",
            srno: "5.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "K. Tiwari, T. Harinarayana, “Increasing the Efficiency of Grid Tied Micro Wind Turbines in Low Wind Speed Regimes” Smart Grid and Renewable Energy, Vol. 5, p. 249-257 (2014).",
            link: "https://www.scirp.org/journal/articles?searchCode=Kshitij+Tiwari&searchField=authors&page=1"
        },

        {
            year: "2014",
            srno: "6.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "S. P. E. Rao, G. D. Naidu, T. Harinarayana, S. V. S. Sarma, A. K. Gupta, “An anomalous high conductivity upper crustal body detected underneath the Surajkund hot spring area from a magnetotellric study,” J. Ind. Geophys. Union, Vol. 18, p. 425-533 (2014).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/66-IGU-Jharkand-MT.pdf"
        },

        {
            year: "2014",
            srno: "7.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "Sagarkumar M. Agravat, \“Spectroscopic Study of Charge Transfer Complexes of Organic and Metal Organic Photoconductors,\" Sardar Patel University, Vallabh Vidyanagar, Gujarat, India (August 2013). [Ph.D. Thesis; Supervisor: Prof. A. T. Oza], Awarded 2014.",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/65-Sagarkumar-Thesis.pdf"
        },

        {
            year: "2014",
            srno: "8.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "T. Harinarayana, K. S. V. Vasavi, P. Sharma, \“Enhancement of Solar Energy Generation with Innovative Concepts, \”LAP Lambert Academic Publishing (August 2014). [Book]",
            link: "https://www.morebooks.de/shop-ui/shop/product/978-3-659-58168-7"
        },

        {
            year: "2014",
            srno: "9.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "Sanjay Kumar Behura, “Development of Graphene for Photovoltaic and Field Electron Emission Applications,” PDPU Gandhinagar (August 2014). [Ph.D. Thesis]",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/63-Sanjay-Behura-PhD-Thesis-Summary.pdf"
        },

        {
            year: "2014",
            srno: "10.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "T. Harinarayana, S. Karuppiah, S. K. Biswas, “Geotourism in Kutch Region, Gujarat: An Innovative Approach: Discovering India's geological wonders” LAP Lambert Academic Publishing (May 2014). [Book]",
            // link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/63-Sanjay-Behura-PhD-Thesis-Summary.pdf"
        },

        {
            year: "2014",
            srno: "11.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "J. G. Bhatt, O. K. Jani, \"E-Governance for Photovoltaic Power Grid: Solar City Gandhinagar, Gujarat, India,\" E-Governance of Smart Cities, Vol. 2, Advances in 21st Century Human Settlement, Ed. T. M. Vinod Kumar: Springer (2014). [Book Chapter]",
            link: "https://link.springer.com/chapter/10.1007/978-981-287-287-6_7"
        },

        {
            year: "2014",
            srno: "12.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "J. G. Bhatt, V. A. Shah, O. Jani, \“An Instrumentation Engineer's Review on Smart Grid: Critical Applications and Parameters\", Renewable and Sustainable Energy Reviews, Vol. 40, p. 1217-1239 (2014). [SCI IF: 5.510]",
            link: "https://www.sciencedirect.com/science/article/abs/pii/S136403211400639X"
        },

        {
            year: "2014",
            srno: "13.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "S. Nayak, S. Bhattacharjee, B. P. Singh, “A systematic study on the effect of acidic, basic and neutral additives on dispersion of multiwalled carbon nanotubes using a dimethylformamide solution” Adv. Nat. Sci.: Nanosci. Nanotechnol., Vol. 5 (2014).",
            // link: "https://www.sciencedirect.com/science/article/abs/pii/S136403211400639X"
        },

        {
            year: "2014",
            srno: "14.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "Annapurna Boruah, M. Abdul Rasheed and Syed Zaheer Hassan. Shale Gas: An Unconventional Hydrocarbon. International Journal of Scientific Research Vol. 3(9), p.355 – 356 (2014).",
            link: "https://www.worldwidejournals.com/international-journal-of-scientific-research-(IJSR)/file.php?val=September_2014_1409895811__179.pdf"
        },

        {
            year: "2014",
            srno: "15.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "M. A. Rasheed., Syed Zaheer Hasan., P.L.Srinivasa Rao., Annapurna Boruah., V.Sudarshan., B. Kumar., And T. Harinarayana. Application Of Geo-Microbial Prospecting Method For Finding Oil And Gas Reservoirs. Frontiers of Earth Sciences, DOI 10.1007/s11707-014-0448-5 (2014).",
            link: "https://link.springer.com/article/10.1007/s11707-014-0448-5"
        },

        {
            year: "2014",
            srno: "16.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "T. Harinarayana* and K. Veeraswamy, “Electromagnetic Signatures of Collision Zones in India,” Geotectonics, Vol. 48, No. 4, pp. 327–345, DOI: 10.1134/S0016852114040050 (2014).",
            // link: "https://link.springer.com/article/10.1007/s11707-014-0448-5"
        },

        {
            year: "2014",
            srno: "17.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "P. Mahala, S. K. Behura, A. Ray, C. Dhanavantri, O. Jani, “GaN/InxGa1-xN/GaN P-I-N Solar Cell with Indium Compositional Grading,” Optical and Quantum Electronics, doi:10.1007/s11082-014-9968-1 (2014). [SCI IF: 0.987",
            link: "https://link.springer.com/article/10.1007/s11082-014-9968-1"
        },

        {
            year: "2014",
            srno: "18.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "M. Venkateshwarlu., M .A. Rasheed., U.V.B. Reddy and A. Kiran Kumar. Assessment of Ground Water Quality in and Around Miryalaguda Area, Nalgonda District of Andhra Pradesh. International Journal of Plant, Animal and Environmental Sciences (2014) 4(2): 259-266",
            link: "https://www.ijpaes.com/admin/php/uploads/511_pdf.pdf"
        },

        {
            year: "2014",
            srno: "19.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "K. Batra, S. Nayak, S. K. Behura, O. Jani, “Optimizing Performance Parameters of Chemically-Derived Graphene/p-Si Heterojunction Solar Cell,” Journal of Nanoscience and Nanotechnology, doi:10.1166/jnn.2014.9818 (2014). [SCI IF: 1.149]",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/publications.php#"
        },

        {
            year: "2014",
            srno: "20.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "G. M. Siddhardha, T. Harinarayana, “Aircraft Disaster Management in Gujarat Using Geographic Information System,” International Journal of Engineering and Technical Research, Vol. 2, p. 28-36 (2014).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/52-Aircraft-paper-IJETR021523.pdf"
        },

        {
            year: "2014",
            srno: "21.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "P.Lakshmi Srinivasa Rao, M.A.Rasheed, S.Zaheer Hasan, P.H.Rao, T.Harinarayana, “ Role of Geochemistry in Coalbed Methane - A Review”, DOI 10.5923/j.geo.20140402.01, Geosciences, v. 4(2), p. 29-32 (2014).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/51-cbm-review-paper.pdf"
        },

        {
            year: "2014",
            srno: "22.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: " S. Roy, \" Prepaid Smart Metering \", Smart Energy : Complete Renewable Energy Intelligence ISSN 2348- 5027, Market trends, Volume 1, Issue 2, April/May 2014",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/Prepaid-smart-metering.pdf"
        },

        {
            year: "2014",
            srno: "23.",
            title: "T. Harinarayana, K. Sri Venkata Vasavi, “Solar Energy Generation Using Agriculture Cultivated Lands” Smart Grid and Renewable Energy, http://dx.doi.org/10.4236/sgre.2014.52004, 2014, 5, pp. 31-42 (2014).",
            desc: " T. Harinarayana, K. Sri Venkata Vasavi, “Solar Energy Generation Using Agriculture Cultivated Lands” Smart Grid and Renewable Energy, http://dx.doi.org/10.4236/sgre.2014.52004, 2014, 5, pp. 31-42 (2014).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/50%20SGRE_2014021115103012.pdf",
            extra: [
                {
                    link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/Solar-potential-on-cultivated-land.pdf",
                    content: "Findings highlighted in Smart Energy: Complete renewable Energy Intelligence ISSN 2348-5027, NEWS this month, \"Gujarat Scientist float novel concept for Solar power generation\". Volume 1, Issue 2, April/May 2014."
                }
            ]

        },

        {
            year: "2014",
            srno: "24.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "S. K. Behura, Q. Yang, A. Hirose, O. Jani, I. Mukhopadhyay, “Catalyst-free synthesis of silicon nanowires by oxidation and reduction process,” Journal of Materials Science, Vol. 49, p. 3592-3597 (2014).",
            link: "https://link.springer.com/article/10.1007/s10853-013-7476-5",
            extra: [
                {
                    link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/Simple,%20cheap%20way%20to%20make%20silicon%20nanowires_Nature%20India.pdf",
                    content: "Findings Highlighted in Nature India, DOI:10.1038/nindia.2014.18, February 11, 2014"
                }
            ]

        },

        {
            year: "2014",
            srno: "25.",
            title: "T. Harinarayana, K. Sri Venkata Vasavi, “Solar Energy Generation Using Agriculture Cultivated Lands” Smart Grid and Renewable Energy, http://dx.doi.org/10.4236/sgre.2014.52004, 2014, 5, pp. 31-42 (2014). \n Findings highlighted in Smart Energy: Complete renewable Energy Intelligence ISSN 2348-5027, NEWS this month, \"Gujarat Scientist float novel concept for Solar power generation \". Volume 1, Issue 2, April/May 2014.",
            desc: "M.A.Rasheed., M. Lakshmi., P.L.Srinivasa Rao., M. S. Kalpana., D. J. Patil and A. M. Dayal (2014). Evaluation of Petroleum Prospects using Geo-microbial prospecting method from Seabed Sediment Samples of Gulf of Mannar, Kerala-Konkan Offshore Basin, India. Int.J.Curr.Microbiol.App.Sci (2014) 3(2)): pp. 281-295 (2014).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/IJCMAS%20KK%20paper.pdf"
        },

        {
            year: "2014",
            srno: "26.",
            title: "T. Harinarayana, K. Sri Venkata Vasavi, “Solar Energy Generation Using Agriculture Cultivated Lands” Smart Grid and Renewable Energy, http://dx.doi.org/10.4236/sgre.2014.52004, 2014, 5, pp. 31-42 (2014). \n Findings highlighted in Smart Energy: Complete renewable Energy Intelligence ISSN 2348-5027, NEWS this month, \"Gujarat Scientist float novel concept for Solar power generation \". Volume 1, Issue 2, April/May 2014.",
            desc: "S. K. Behura, P. Mahala, S. Nayak, Q. Yang, I. Mukhopadhyay, O. Jani, “Fabrication of bi-layer graphene and theoretical simulation for its possible application in thin film solar cell,” Journal of Nanoscience and Nanotechnology, Vol. 14, p. 3022-3027 (2014). [SCI IF: 1.149]",
            link: "https://www.ingentaconnect.com/content/asp/jnn/2014/00000014/00000004/art00049"
        },

        {
            year: "2014",
            srno: "27.",
            title: "T. Harinarayana, K. Sri Venkata Vasavi, “Solar Energy Generation Using Agriculture Cultivated Lands” Smart Grid and Renewable Energy, http://dx.doi.org/10.4236/sgre.2014.52004, 2014, 5, pp. 31-42 (2014). \n Findings highlighted in Smart Energy: Complete renewable Energy Intelligence ISSN 2348-5027, NEWS this month, \"Gujarat Scientist float novel concept for Solar power generation \". Volume 1, Issue 2, April/May 2014.",
            desc: "S. Nayak, S. K. Behura, S. Bhattacharjee, B. P. Singh, O. Jani, I. Mukhopadhyay, “Transparent conductive MWCNTs-polymer composite for electrode applications”, Journal of Nanoscience and Nanotechnology, Vol. 14, p. 2816-2822 (2014). [SCI IF: 1.149]",
            link: "https://www.ingentaconnect.com/content/asp/jnn/2014/00000014/00000004/art00016?token=004a12a47c573d2570257050234a6c7a4042763b2b747c4e7547543c7e386f642f466f0d63"
        },

        {
            year: "2014",
            srno: "28.",
            title: "T. Harinarayana, K. Sri Venkata Vasavi, “Solar Energy Generation Using Agriculture Cultivated Lands” Smart Grid and Renewable Energy, http://dx.doi.org/10.4236/sgre.2014.52004, 2014, 5, pp. 31-42 (2014). \n Findings highlighted in Smart Energy: Complete renewable Energy Intelligence ISSN 2348-5027, NEWS this month, \"Gujarat Scientist float novel concept for Solar power generation \". Volume 1, Issue 2, April/May 2014.",
            desc: "Souvnik Roy, N. Jegadeesh, Pulkit Dhingra, Shatrughan Yadav, K. Ramachandran, Omkar Jani, \"A Feasibility study of Solar Photovoltaic system installation at a Sectional Valve Substation of a Gas Transmission network\", International Conference on Energy and Infrastructure Management Prospects & Challenges in Emerging Economies, 2014, pp. 215-225.",
            // link: "https://www.ingentaconnect.com/content/asp/jnn/2014/00000014/00000004/art00016?token=004a12a47c573d2570257050234a6c7a4042763b2b747c4e7547543c7e386f642f466f0d63"
        },

        {
            year: "2014",
            srno: "29.",
            title: "T. Harinarayana, K. Sri Venkata Vasavi, “Solar Energy Generation Using Agriculture Cultivated Lands” Smart Grid and Renewable Energy, http://dx.doi.org/10.4236/sgre.2014.52004, 2014, 5, pp. 31-42 (2014). \n Findings highlighted in Smart Energy: Complete renewable Energy Intelligence ISSN 2348-5027, NEWS this month, \"Gujarat Scientist float novel concept for Solar power generation \". Volume 1, Issue 2, April/May 2014.",
            desc: "P. C. Rath, S. Nayak, S. Bhattacharjee, L. Besra, B. P. Singh, “Nanotitania-coated multi-walled carbon nanotube composite by facile colloidal processing route for photocatalytic applications,” Composite Interfaces, Vol. 21, p. 251-262 (2014). [SCI IF: 0.63]",
            link: "https://www.tandfonline.com/doi/full/10.1080/15685543.2014.864530#.UsJ0F3mIrix"
        },

        {
            year: "2014",
            srno: "30.",
            title: "T. Harinarayana, K. Sri Venkata Vasavi, “Solar Energy Generation Using Agriculture Cultivated Lands” Smart Grid and Renewable Energy, http://dx.doi.org/10.4236/sgre.2014.52004, 2014, 5, pp. 31-42 (2014). \n Findings highlighted in Smart Energy: Complete renewable Energy Intelligence ISSN 2348-5027, NEWS this month, \"Gujarat Scientist float novel concept for Solar power generation \". Volume 1, Issue 2, April/May 2014.",
            desc: "S. K. Behura, S. Nayak, I. Mukhopadhyay, O. Jani, “Junction characteristics of chemically-derived graphene/p-Si heterojunction solar cell,” Carbon, Vol. 67, p. 766-774 (2014). [SCI IF: 5.868]",
            link: "https://www.sciencedirect.com/science/article/abs/pii/S0008622313010312"
        },

        {
            year: "2014",
            srno: "31.",
            title: "T. Harinarayana, K. Sri Venkata Vasavi, “Solar Energy Generation Using Agriculture Cultivated Lands” Smart Grid and Renewable Energy, http://dx.doi.org/10.4236/sgre.2014.52004, 2014, 5, pp. 31-42 (2014). \n Findings highlighted in Smart Energy: Complete renewable Energy Intelligence ISSN 2348-5027, NEWS this month, \"Gujarat Scientist float novel concept for Solar power generation \". Volume 1, Issue 2, April/May 2014.",
            desc: "A. Das, T. Harinarayana, “A Study on the Possible Oil Spill & Gas Leakage Vulnerable Zones of Gujarat State Using GIS Tools,” J. Ind. Geophys. Union, Vol. 18, p. 73-84 (2014).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/42%20igu%2018-1%20-Ananya%20Das%20paper.pdf"
        },

    ],


    //Year 2013
    [
        {
            year: "2013",
            srno: "1.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "K. K. Borah, S. Roy, T. Harinarayana “Optimization in site selection of wind turbine for energy using fuzzy logic system using GIS-A case study for Gujarat,” Open Journal of Optimization, Vol. 2, p. 116-122 (2013)."
        },

        {
            year: "2013",
            srno: "2.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. Dhubia, A. Bhatt, K. Poonam, P. Sen, P. H. Rao, S. Z. Hasan, T. Harinarayana, “An Integrated approach for faults and fractures delineation with dip and curvature attributes,” 10th Biennial International Conference and Exposition, Society of Petroleum Geophysicist, Kochi, Kerala, p. 265 (2013).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/40%20Santhosh%20SPG%20paper%20P265.pdf"
        },

        {
            year: "2013",
            srno: "3.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "M. A. Rasheed, A. Bharadwaj, S. Nanduri, S. Dhubia, S. Karalapati, S. Z. Hasan, P. H. Rao, “Integrated Surface Geochemical and 3D Seismic Studies to understand the Hydrocarbon Seepage Pattern,” 10th Biennial international Conference & Exposition, Society of Petroleum Geophysicists, Kochi, Kerala, p. 136 (2013).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/39%20Rasheed%20SPG%20paper%202013.pdf"
        },

        {
            year: "2013",
            srno: "4.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "B. A. Radha, M.A. Rasheed, N. Rao, D. J. Patil., A. M. Dayal, R. Muralikrishna, “Integrated light gaseous hydrocarbons and bacterial anomalies for identification of hydrocarbon seepage in Pranahita-Godavari Basin, Andhra Pradesh,” 10th Biennial international Conference & Exposition, Society of Petroleum Geophysicists, Kochi, Kerala, p. 165 (2013).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/38%20Anu%20SPG%202013%20paper.pdf"
        },

        {
            year: "2013",
            srno: "5.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "K. Swarna, S. K. Biswas, T. Harinarayana, “Development of Geotourism in Kutch region, Gujarat, India: An innovative approach,” Journal of Environmental Protection, Vol. 4, No.12, p. 1360-1372 (2013).",
            link: "https://www.scirp.org/journal/PaperInformation?PaperID=40553"
        },

        {
            year: "2013",
            srno: "6.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "A. Boruah, B. Kumar, P. H. Rao, T. Harinarayana, “Integrated Development Of Both Conventional And Unconventional Coal Fuels In India,”The International Journal Of Science & Technoledge, Vol. 1, Issue 5, p. 11-17 (2013).",
            link: "http://theijst.com/november2013/3.ST1311-008.pdf"
        },

        {
            year: "2013",
            srno: "7.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "D. N. Murthy, K. Veeraswamy, T. Harinarayana, U. K. Singh, M. Santosh, “Electrical structure beneath Schirmacher Oasis, East Antarctica: a magnetotelluric study,” Polar Research, Vol. 32, p. 17309 (2013). [SCI IF: 1.622]",
            link: "https://polarresearch.net/index.php/polar/article/view/17309"
        },

        {
            year: "2013",
            srno: "8.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "P. Mahala, A. Ray, O. Jani, C. Dhanavantri, “Theoretical study on the effect of graded InyGa1-yN layer on p-GaN/InyGa1-yN/n-GaN p-i-n solar cell,” Physica Status Solidi A: Applications and Materials Science, doi:10.1002/pssa.201330030 (2013). [SCI IF: 1.469]",
            link: "https://onlinelibrary.wiley.com/doi/abs/10.1002/pssa.201330030"
        },

        {
            year: "2013",
            srno: "9.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "J. Bala Chennaiah, M. A. Rasheed, M. Lakshmi, A. M. Dayal, D. J. Patil, “Assessment of Ground Water Quality in Ghatkesar and Bibinagar Areas of Andhra Pradesh, India,” International Journal of Plant, Animal and Environmental Sciences, Vol. 3, Issue 4, p. 123-130 (2013).",
            link: "https://ijpaes.com/admin/php/uploads/400_pdf"
        },

        {
            year: "2013",
            srno: "10.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "M. A. Rasheed, M. Lakshmi, M. S. Kalpana, A. M. Dayal, “The Microbial Activity in Development of Hydrocarbon Microseepage: An Indicator for Oil and Gas Exploration,” Geosciences Journal, Vol. 17, No. 3, p. 329 – 338 (2013). [SCI IF: 0.618]",
            // link: "https://ijpaes.com/admin/php/uploads/400_pdf"
        },

        {
            year: "2013",
            srno: "11.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. Z. Hasan, M. Y. Farooqui, P. H. Rao, K. Ramachandran, P. Tripathy, T. Harinarayana, “Petroliferous Basins and Shale Gas-An Unconventional Hydrocarbon Asset of India,” Geosciences, Vol. 3, p. 108-118 (2013).",
            link: "http://www.sapub.org/journal/currentissue.aspx?journalid=1016"
        },

        {
            year: "2013",
            srno: "12.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. Z. Hasan, M. Y. Farooqui, P. H. Rao, K. Ramachandran, P. Tripathy, T. Harinarayana, “Petroliferous Basins and Shale Gas-An Unconventional Hydrocarbon Asset of India,” Geosciences, Vol. 3, p. 108-118 (2013).",
            link: "http://www.currentscience.ac.in/Volumes/105/03/0330.pdf"
        },

        {
            year: "2013",
            srno: "13.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "M. A. Rasheed., P. L. Srinivasa Rao., M. Lakshmi, J. Bala Chennaiah, D.J.Patil and A. M. Dayal, “Assessment of Ground Water Quality Using ICP-MS and Microbiological methods in Uppal Industrial Area, Hyderabad, India,” International Research Journal, Weekly Science, Vol. 1, Issue 6, p. 1-17 (2013).",
            link: "http://www.weeklyscience.org/UploadedArticle/17.pdf"
        },

        {
            year: "2013",
            srno: "14.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "M. V. Prasanna, M. A. Rasheed,  D. J. Patil, A. M. Dayal, “Geo-microbiological Studies in Conjunction with Different Geo-scientific Studies for the Evaluation of Hydrocarbon Prospects in Proterozoic Vindhyan Basin, India,” Journal of Petroleum Science and Engineering, Vol. 108, p. 239-249 (2013). [SCI IF: 0.997]",
            link: "https://www.sciencedirect.com/science/article/pii/S0920410513001113"
        },

        {
            year: "2013",
            srno: "15.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "I. M. Taye, S. M. Agravat, T. Harinarayana, “Energy efficient grid management using geographical information system: A case study for western Gujarat,” American International Journal of Research Science, Technology, Engineering and Mathematics, Vol. 2, p. 23-28 (2013).",
            link: "http://iasir.net/AIJRSTEMpapers/AIJRSTEM13-109.pdf"
        },

        {
            year: "2013",
            srno: "16.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "K. K. Abdul Azeez, M. J. Unsworth, P. K. Patro, T. Harinarayana, R. S. Sashtry, “Resistivity Structure of the Central Indian Tectonic Zone (CITZ) from Multiple Magnetoteluric (MT) Profiles and Tectonic Implications,” Pure and Applied Geophysics, Vol. 170, p. 2231-2256 (2013). [SCI IF: 1.617]",
            link: "https://link.springer.com/article/10.1007/s00024-013-0649-y"
        },

        {
            year: "2013",
            srno: "17.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "P. Sharma, T. Harinarayana, “Solar energy generation potential along national highways,” International Journal of Energy and Environmental, 4:16 (2013). [Findings highlighted in Electrical Mirror, Vol. III, Issue III, p. 34-38, September 2013]",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/25%20Electic-mirror-rnd.pdf",
            extra: [
                {
                    link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/25%20Electic-mirror-rnd.pdf",
                    content: "[Findings highlighted in Electrical Mirror, Vol. III, Issue III, p. 34-38, September 2013]"
                }
            ]
        },


        {
            year: "2013",
            srno: "18.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. K. Behura, I. Mukhopadhyay, A. Hirose, Q. Yang, O. Jani, “Vertically-oriented few-layer graphene as an electron field-emitter,” Physical Status Solidi (A): Applications and Materials Science, Vol. 210, p. 1817-1821 (2013). [SCI IF: 1.469]",
            link: "https://onlinelibrary.wiley.com/doi/10.1002/pssa.201329172/abstract;jsessionid=4933B8C364852B62984C482E704AC145.f02t03",
            extra: [
                {
                    link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/24%20www.nature.com_nindia_2013_130531_full_nindia.2013.76.ht.pdf",
                    content: "[Findings Highlighted in Nature India, DOI:10.1038/nindia.2013.76; 31 May 2013]"
                }
            ]
        },

        {
            year: "2013",
            srno: "19.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. K. Behura, M. V. Rao, Q. Yang, A. Hirose, O. Jani, I. Mukhopadhyay, “Fabrication of multiple-layer graphene films on Cu/SiO2/Si substrate by hot-filament chemical vapor deposition,” AIP Conference Proceedings, Vol. 1538, p. 240-243 (2013).",
            link: "https://pubs.aip.org/aip/acp/article-abstract/1538/1/240/839101/Fabrication-of-multiple-layer-graphene-films-on-Cu?redirectedFrom=fulltext"
        },

        {
            year: "2013",
            srno: "20.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. K. Behura, I. Mukhopadhyay, O. Jani, Q. Yang, A. Hirose, “Synthesis of Graphene on Copper by Hot Filament Chemical Vapor Deposition,” Proceedings of 24th Canadian Congress of Applied Mechanics, University of Saskatchewan, Canada, June 2-6 (2013).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/22-BEHURAPUB1-CANCAM%202013-20130605.pdf"
        },

        {
            year: "2013",
            srno: "21.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. K. Behura, P. Mahala, A. Ray, I. Mukhopadhyay, O. Jani, “Theoretical simulation of photovoltaic response of Graphene-on-semiconductors,” Applied Physics A: Materials Science and Processing, Vol. 111, p. 1159-1163 (2013). [SCI IF: 1.545]",
            link: "https://link.springer.com/article/10.1007/s00339-012-7335-2"
        },

        {
            year: "2013",
            srno: "22.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "P. Mahala, S. K. Behura, A. S. Kushwaha, A. Ray, O. Jani, C. Dhanavantri, “A study on the 2D simulation of Pt/ InGaN/ GaN/ Metal Schottky junction solar cell,” Semiconductor Science and Technology, Vol. 28, p. 055012 (2013). [SCI IF: 1.921]",
            // link: "https://link.springer.com/article/10.1007/s00339-012-7335-2"
        },

        {
            year: "2013",
            srno: "23.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "K. Ramachandran, V. Babu, B. K. Behera, T. Harinarayana, “Source rock analysis, thermal maturation and hydrocarbon generation using rock-eval pyrolysis in parts of Krishna–Godavari basin, India: a case study,” J. Petrol. Explor. Prod. Technol., Vol. 3, p. 11-20 (2013).",
            link: "https://link.springer.com/article/10.1007/s13202-012-0041-y"
        },

        {
            year: "2013",
            srno: "24.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. L. Klemperer, B. M. Kennedy, S. R. Sastry, Y. Makovsky, T. Harinarayana and M. L. Leech, “Mantle fluids in the Karakoram fault: Helium isotope evidence,” Earth and Planetary Science Letters, Vol. 366, p. 59-70 (2013).",
            link: "https://www.sciencedirect.com/science/article/abs/pii/S0012821X13000216",
            extra: [
                {
                    link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/18-Geothermal-Nature-India-2013.pdf",
                    content: "[Findings Highlighted in Nature India, DOI:10.1038/nindia.2013.38, 21 March 2013]"
                }
            ]
        },

        {
            year: "2013",
            srno: "25.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "B. K. Rastogi, J. R. Kayal and T. Harinarayana, “The 2001 Bhuj Earthquake and Advances in Earthquake Sciences”, Natural Hazards, Vol. 65, p. 1023-1025 (2013).",
            link: "https://link.springer.com/article/10.1007/s11069-012-0431-y"
        },

        {
            year: "2013",
            srno: "26.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "R. Gopichandran, V. N. Shah, N. J. Patel, T. Harinarayana, “SMEs can overcome challenges and improve sustainability through preventive management strategies: some empirical evidences from a cluster of chemical industries in Western India,” Int. J. Globalisation and Small Business, Vol. 5, No. 3 (2013).",
            link: "https://www.inderscience.com/info/inarticle.php?artid=54894"
        },

        {
            year: "2013",
            srno: "27.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "M. Shireesha, T. Harinarayana, “Increased resolution of subsurface parameters from 1D magnetotelluric modeling,” Acta Geophysica, Vol. 61, p. 569-582 (2013). [SCI IF: 0.910]",
            link: "https://link.springer.com/article/10.2478/s11600-012-0099-4?no-access=true"
        },
    ],

    //Year 2012
    [
        {
            year: "2012",
            srno: "1.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "P. Mahala, A. Ray, O. Jani, C. Dhanavantri, “Optimization of InGaN/GaN p-i-n Solar cell”, TAPSUN Conference 2012 at CSIR-NPL, New Delhi.",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/14-TAPSUN-2012.pdf"
        },

        {
            year: "2012",
            srno: "2.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "S. K. Behura, O. Jani, I. Mukhopadhyay, “Exfoliated Bi-layer Graphene as an Alternative to Transparent and Conductive Film,” Proceedings of International Congress on Renewable Energy (ICORE-2012), p. 106-108, PDPU, India, Dec. 6-7 (2012).",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/13%20SANJAYPUB-Graphene002-ICORE-2012.pdf"
        },

        {
            year: "2012",
            srno: "3.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "T. Harinarayana, B. Hardage, A. Orange, “Controlled-source marine electromagnetic 2-D modeling gas hydrate studies,” Mar. Geophys. Res., Vol. 33, p. 239-250 (2012). [SCI IF: 1.055]",
            link: "https://link.springer.com/article/10.1007/s11001-012-9159-z#)"
        },

        {
            year: "2012",
            srno: "4.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "P. Sharma, T. Harinarayana, “Enhancement of energy generation from the two layer solar panels,” International Journal of Energy and Environmental Engineering, 3:12 (2012).",
            link: "https://link.springer.com/journal/40095",
            extra: [
                {
                    link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/11-1-solar%20panel-paper-2012.pdf",
                    content: "[Findings Highlighted in Nature India, DOI:10.1038/nindia.2012.185, 17 Dec. 2012]"
                },
                {
                    link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/11-2-Electrical-mirror.pdf",
                    content: "[Findings Highlighted in Electrical Mirror, Vol. II, Issue II, P. 38-40, Aug. 2012]"
                },
                {
                    link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/10-11-3%20EQ-International-Jan-Feb%202013--THN",
                    content: "[Findings Highlighted in EQ International, p. 50-51, Jan-Feb (2013)]"
                },
            ],
        },

        {
            year: "2012",
            srno: "5.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "GERMI's Innovative Research in Solar PV Installation in Gandhinagar, Gujarat. EQ International, p. 50-51, Jan-Feb (2013)",
            link: "https://germi-public.s3.ap-south-1.amazonaws.com/Publications/10-11-3%20EQ-International-Jan-Feb%202013.pdf"
        },

        {
            year: "2012",
            srno: "6.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "E. Chandrasekhar, G. Mathew, T. Harinarayana, “A new hypothesis for the deep subsurface structures near the Bhuj 2001 earthquake (Mw 7.6) hypocentre zone and its tectonic implications,” Geophysical Journal Int., Vol.190, p.761-768 (2012). [SCI IF: 2.42]",
            link: "https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1365-246X.2012.05532.x"
        },

        {
            year: "2012",
            srno: "7.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "D. Malleswari, K. K. Abdul Azeez, D. N. Murthy, T. Harinarayana, “Electrical and density signatures across Narmada–Son lineament zone (Central India) along the Malkapur–Mandhata profile,” Current Science, Vol. 102, No. 11, (2012). [SCI IF: 0.94]",
            link: "http://www.currentscience.ac.in/Volumes/102/11/1571.pdf"
        },
    ],

    //Year 2011
    [
        {
            year: "2011",
            srno: "1.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "M. Shireesha, T. Harinarayana, “Processing of magnetotelluric data – a comparative study with 4 and 6 element impedance tensor element,” Applied Geophysics, Vol. 8, No. 4, p. 285-292 (2011).",
            link: "https://rd.springer.com/article/10.1007/s11770-011-0303-3"
        },

        {
            year: "2011",
            srno: "2.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Pramila Mahala, Sanjay Kumar Behura, Abhijit Ray, Chenna Dhanavantri, Omkar Jani, “Effect of Indium concentration on Metal/n-InxGa1-xN Schottky Junction Solar Cell under low level injection,” Proceedings of 3rd International Conference on Advances in Energy Research (ICAER-2011), IIT Mumbai, Dec. 09-11 (2011).",
            link: "https://link.springer.com/article/10.1007/s11001-012-9159-z#"
        },

        {
            year: "2011",
            srno: "3.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Pramila Mahala, Sanjay Kumar Behura, Abhijit Ray, “Analytical estimate of open-circuit voltage of a Schottky-barrier solar cell under high level injection,” J. Nano- Electron. Phys., Vol. 3, No. 1 (Part 5), p. 979-991 (2011).",
            link: "https://jnep.sumdu.edu.ua/en/component/content/full_article/281"
        },


        {
            year: "2011",
            srno: "4.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "Sanjay Kumar Behura, Pramila Mahala, Abhijit Ray, “A model on the effect of injection levels over the open-circuit voltage of Schottky Barrier Solar Cells,” Journal of Electron Devices,Vol. 10, p. 471-482(2011).",
            link: "http://jeldev.org/10Behura.pdf"
        },
    ],


    //Year 2010
    [
        {
            year: "2010",
            srno: "1.",
            title: "A review on casing while drilling technology for oil and gas production",
            desc: "A. R. Bansal, G. Gabriel, V. P. Dimri, “Power law distribution of susceptibility and density and its relation to seismic properties: An example from the German Continental Deep Drilling Program (KTB),” Journal of Applied Geophysics, Vol. 72, p. 123-128 (2010).",
            link: "http://www.sciencedirect.com/science/article/pii/S0926985110000996"
        },

        {
            year: "2010",
            srno: "2.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "A. Chamoli, V. S. Rani, K. Srivastava, D. Srinagesh, V. P. Dimri, “Wavelet analysis of the seismograms for tsunami warning,” Nonlinear Processes in Geophysics, Vol. 17, p. 1-6 (2010).",
            link: "http://www.nonlin-processes-geophys.net/17/569/2010/npg-17-569-2010.pdf"
        },

        {
            year: "2010",
            srno: "3.",
            title: "Techno-economical and Experimental Analysis of Biodiesel Production from Used Cooking Oil",
            desc: "R. Gopichandran et al., “Integrated Biodiversity Conservation to Mainstream Agriculture Based Mitigation and Adaptation,” International Journal of Environmental Consumerism, Vol. 6, p. 36-40 (2010).",
            // link: "http://www.nonlin-processes-geophys.net/17/569/2010/npg-17-569-2010.pdf"
        }
    ]
]

export default publicationData;
