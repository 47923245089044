// Loader.js
import React from 'react';
import { ClipLoader } from 'react-spinners';
import './Loader.css';
import { Typography } from '@mui/material';
const Loader = ({ loading }) => {
  return (
   
    <div className="loader-container">
      <ClipLoader color="#7cba27" loading={loading} size={50} />
      <Typography sx={{ padding: "20px", fontSize: 20 }}>
                Please Wait...
              </Typography>
    </div>
    

  );
};

export default Loader;
