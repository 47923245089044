import React, { useState } from 'react'
import './HomeCounterNew.css'
import TheCounter from "../TheCounter"
import ScrollTrigger from 'react-scroll-trigger'

function HomeCounterNew() {

    const [counterState, setCounterState] = useState(false)
    return (
        <div>
            <section className='home-counterNew'>
                <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
                    <div className='home-counterNew-container top-padding-zero'>
                        {/* <h2 className='home-counterNew-headingNew'>Success in Numbers</h2> */}
                        <div className='counter-div-containerNew'>
                            <div className='counter-divNew'>
                                <h3 className='counter-numbersNew counter-fonts'>
                                    {counterState && <TheCounter maxValue={22372} duration="300" />}
                                    {/* <span className='counter-symbolNew'>{"+"}</span> */}
                                </h3>
                                <span className='counter-textNew normal-text normal-text sub-heading'>Sq. Km of</span>
                                <span className='counter-textNew normal-text'>Data Anaysis</span>
                            </div>
                            <div className='counter-divNew'>
                                <h3 className='counter-numbersNew counter-fonts'>
                                    {counterState && <TheCounter maxValue={63} duration="300" />}
                                    <span className='counter-symbolNew'>{"+"}</span>
                                </h3>
                                <span className='counter-textNew normal-text sub-heading'>Oil and Gas</span>
                                <span className='counter-textNew normal-text'>Field Analysis</span>
                            </div>
                            <div className='counter-divNew'>
                                <h3 className='counter-numbersNew counter-fonts'>
                                    {counterState && <TheCounter maxValue={50} duration="300" />}
                                    <span className='counter-symbolNew'>{"+"}</span>
                                </h3>
                                <span className='counter-textNew normal-text sub-heading'>Reservoir</span>
                                <span className='counter-textNew normal-text'>Modeling Projects</span>
                            </div>
                            <div className='counter-divNew'>
                                <h3 className='counter-numbersNew counter-fonts'>
                                    {counterState && <TheCounter maxValue={30} duration="300" />}
                                    <span className='counter-symbolNew'>{"+"}</span>
                                </h3>
                                <span className='counter-textNew normal-text sub-heading'>OALP</span>
                                <span className='counter-textNew normal-text'>block evaluation</span>
                            </div>
                            <div className='counter-divNew'>
                                <h3 className='counter-numbersNew counter-fonts'>
                                    {counterState && <TheCounter maxValue={63} duration="300" />}
                                    <span className='counter-symbolNew'>{"+"}</span>
                                </h3>
                                <span className='counter-textNew normal-text sub-heading'>Consultancy Projects</span>
                                {/* <span className='counter-textNew normal-text'>(G&G)</span> */}
                            </div>
                        </div>
                    </div>
                </ScrollTrigger>
            </section>

        </div>
    )
}

export default HomeCounterNew;
