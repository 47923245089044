import React, { useState } from 'react'
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Paper,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Slider,
    Divider,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText,
    FormControl,
    Button,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";

const buttonSx = {
    ...{
        bgcolor: blue[700],
        "&:hover": {
            bgcolor: blue[800],
        },
    },
};



function EmiCalculator(props) {

    const [calculated, setCalculated] = useState(false);
    const principle = props.principle;
    const govtSubsidy = props.subsidy
    const [subsidy, setSubsidy] = useState(props.subsidy);
    const [emi, setEmi] = useState(0);
    const [rate, setRate] = useState(0);
    const [period, setPeriod] = useState(0);
    const downPayment = principle * (subsidy / 100);
    const loanPayment = principle - downPayment;
    const buttonSx = {
        ...{
            bgcolor: blue[700],
            "&:hover": {
                bgcolor: blue[800],
            },
        },
    };

    const defaultValues = {
        loanIntRate: 5,
        loanPeriod: 1,
    };

    const calculateEmi = (data) => {
        const rate = parseFloat(data.loanIntRate);
        console.log(rate);
        const period = parseInt(data.loanPeriod);
        setRate(rate);
        setPeriod(period);
        let tenure = 12 * period;
        let r_pow = Math.pow(1 + (rate / 1200), tenure);
        let num_emi = (rate / 1200) * r_pow;
        let den_emi = r_pow - 1;
        setEmi(Math.round(loanPayment * (num_emi / den_emi)));
        setCalculated(true);
    }

    const validationSchema = Yup.object().shape({
        loanIntRate: Yup.string().required("Interest Rate is Required"),
        loanPeriod: Yup.string().required("loanPeriod is required"),

    });

    const { handleSubmit, setValue, control, reset, watch, formState } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(validationSchema),
    });

    const { isValid, dirtyFields, errors } = formState;
    return (
        <div>
            <form>
                <div className="main-div-2 static-normal-text" style={{ width: '100%' }}>
                    <div className="result-header static-subheading-text">
                        EMI Calculation
                    </div>

                    <div className="emi-result-div">
                        <label className="emi-label">Cost:</label>
                        <div className="emi-result">
                            <input name="principle" className="emi-result disable" placeholder={principle} disabled></input>
                        </div>
                    </div>

                    <div className="emi-result-div">
                        <label className="emi-label">Subsidy:</label>
                        <div className="emi-result">
                            <input name="subsidy" className="emi-result disable" placeholder={govtSubsidy} disabled></input>
                            <div className="small-text">(Based on current MNRE scheme)</div>
                        </div>
                    </div>

                    <div className="emi-result-div">
                        <label className="emi-label">Debt-Equity Ratio:</label>
                        <div className="emi-result rm-padding">
                            <div className="emi-result2"></div>
                            <input name="equity" className="emi-result-2div " value={100 - subsidy}

                                onInput={
                                    (event) => {
                                        event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                        if (event.target.value > 100) {
                                            event.target.value = 100
                                        }
                                    }
                                }
                                onChange={(event) => {
                                    setSubsidy(100 - event.target.value)
                                }}>

                            </input>
                            <span className = "colon-span" style={{ padding: '0px 5px' }}>:</span>
                            <input name="debt" className="emi-result-2div" value={subsidy}
                                onInput={
                                    (event) => {
                                        event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                        if (event.target.value > 100) {
                                            event.target.value = 100
                                        }
                                    }
                                }

                                onChange={
                                    (event) => {
                                        setSubsidy(event.target.value);
                                    }
                                }></input>
                            <div className="small-text">(Based on current subsidy)</div>
                        </div>
                    </div>

                    <div className="emi-result-div">
                        <label className="emi-label">Down-payment:</label>
                        <input className="regular-input" value={downPayment} readOnly></input>
                    </div>

                    <div className="emi-result-div">
                        <label className="emi-label">Loan amount:</label>
                        <input name="loanPayment" className="regular-input" value={principle - downPayment} readOnly
                        ></input>
                    </div>

                    <div className="emi-result-div2">
                        <label style={{ paddingBottom: '10px' }}>Loan Interest Rate (%):</label>
                        
                        <Controller
                        
                            name="loanIntRate"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                className='regular-input'
                                    {...field}
                                    error={!!errors.budget}
                                    required
                                    type="number"
                                    helperText={errors?.loanIntRate?.message}
                                    id="loanIntRate"
                                    variant="outlined"
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    onInput={
                                        (event)=>{
                                            if(event.target.value>100) event.target.value = 100;
                                        }
                                    }
                                />
                            )}
                        />
                        {/* <Controller
                            name="loanIntRate"
                            control={control}
                            render={({ field }) => (
                                <Slider
                                    {...field}
                                    error={!!errors.loanIntRate}
                                    required
                                    type="number"
                                    helperText={errors?.loanIntRate?.message}
                                    // autoFocus
                                    id="loanIntRate"
                                    variant="outlined"
                                    min={5}
                                    max={20}
                                    style={{ width: "100%" }}
                                />
                            )}
                        /> */}
                    </div>

                    <div className="emi-result-div2">
                        <label style={{ paddingBottom: '10px' }}>Loan Period <span>(In Years)</span>:</label>
                        <Controller
                            name="loanPeriod"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                className='regular-input'
                                    {...field}
                                    error={!!errors.budget}
                                    required
                                    type="number"
                                    helperText={errors?.loanPeriod?.message}
                                    id="loanPeriod"
                                    variant="outlined"
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    onInput = {
                                        (event)=>{
                                            if(event.target.value>100) event.target.value = 100;
                                        }
                                    }
                                />
                            )}
                        />
                        {/* <Controller
                            name="loanPeriod"
                            control={control}
                            render={({ field }) => (
                                <Slider
                                    {...field}
                                    error={!!errors.loanPeriod}
                                    required
                                    type="number"
                                    helperText={errors?.loanPeriod?.message}
                                    // autoFocus
                                    id="loanPeriod"
                                    variant="outlined"
                                    min={1}
                                    max={30}
                                    style={{ width: "100%" }}
                                />
                            )}
                        /> */}
                    </div>

                    {calculated &&
                        <p className = "sc-bg-color" style={{  margin: '0px', padding: '20px 10px' }}> EMI for Loan amount of Rs. {loanPayment} for loan period of {period} years @ {rate} % is Rs. {emi} / month</p>
                    }
                    <div
                    className='sc-bg-color'
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: 'center',
                            width: "100%",
                            padding: '0px 0px 20px 0px',
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={buttonSx}
                            style={{ backgroundColor: '#7cba27' }}
                            // disabled={loadingState || !clerification}
                            onClick={
                                handleSubmit(calculateEmi)
                            }
                        >
                            <div className='xss-small-text'>
                                Calculate EMI
                            </div>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EmiCalculator
