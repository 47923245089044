import React, { useEffect, useState } from "react";
// import FusePageCarded from "@fuse/core/FusePageCarded/FusePageCarded";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RingLoader } from "react-spinners";
import CircularProgress from "@mui/material/CircularProgress";
import "dayjs/locale/de";
import {
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Select,
  MenuItem,
  TextField,
  TableBody,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
  Box,
  Typography,
  FormLabel,
} from "@mui/material";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
// import { showMessage } from "app/store/fuse/messageSlice";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import BreadCrumbImage from "../../Images_1/CommonSection/Braedcrumbs/EM.jpg";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IosShareIcon from "@mui/icons-material/IosShare";

// import { IosShareIcon, RestartAltIcon, CloudUploadIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
// import FuseUtils from "@fuse/utils/FuseUtils";
import { EditorState } from "draft-js";
// import RichTextEditor from "app/shared-components/RichTextEditor";
import { lighten } from "@mui/system";
import WYSIWYGEditor from "../shared-components/WYSIWYGEditor";
// import { addCurrentOpening } from "./store/addCurrentOpeningSlice";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "./NTMTrainingForm.css";
// import { addapplication } from "./store/AddApplication";
import dayjs from "dayjs";
import { blue } from "@mui/material/colors";
import { toast } from "react-toastify";
import { AddNTMTrainingForm } from "./store/NTMTrainingFormSlice";
import Loader from "../../Loader";
import ScrollToTop from "../ScrollToTop";

const NTMTrainingForm = () => {

  const { id,id2 } = useParams();
    var imageCounteruser = 0;
    const [ufiles, setUFiles] = useState(null);
    const [userImages, setUserImages] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buttonSx = {
    ...{
      bgcolor: blue[700],
      "&:hover": {
        bgcolor: blue[800],
      },
    },
  };

  // const location = useLocation();
  // const openingData = location.state;

  const [loadingState, setLoadingState] = useState(false);

  // const [clerification, setClerification] = useState(false);
  const [clerification, setClerification] = useState(true);


  const defaultValues = {
    name: "",
    emailId: "",
    mobileNo: "",
    aadhaarcard: "",
    gender: "",
    qualification: "",
    instorcompanyname: "",
    designation: "",
    experience: "",
    address: "",
  };

  const validationSchema = Yup.object().shape({

    mobileNo: Yup.string().matches(/^[0-9]{10}$/, 'Phone number is not valid'),
    name: Yup.string().required("Name is required"),
    emailId: Yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email is not valid'),
    address: Yup.string().required("Address is required"),
    aadhaarcard:Yup.string()
    .required('Aadhaar number is required')
    .matches(/^[2-9]{1}[0-9]{11}$/, 'Invalid Aadhaar number format'),
    gender: Yup.string().required("Current state is required"),
    qualification: Yup.string().required("Purpose is required"),
    instorcompanyname: Yup.string().required("Sample Details is required"),
    designation: Yup.string().required("Sample Numbers is required"),
    experience: Yup.string().required("Reports is required"),

    
  });

  const { handleSubmit, setValue, control, reset, watch, formState } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const [selectedDobFile, setSelectedDobFile] = React.useState(null);

  const handleFileChangeDob = (event) => {
    setSelectedDobFile(event.target.files[0]);
    // setValue("dobProofFile", event.target.files[0].name);
  };

  // Here is On Submit Function for Button
  const generateGUID = () => {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  };
  const onSubmit = (data) => {
    console.log(data)
    setLoadingState(true);
   
    const formData = new FormData();
   
    
        formData.append("email", data.emailId);
        formData.append("aid", id);
        formData.append("title", id2);
        formData.append("fullname", data.name);
        formData.append("contactno", data.mobileNo);
        formData.append("aadharno", data.aadhaarcard);
        formData.append("gender", data.gender);
        formData.append("qualification", data.qualification);
        formData.append("instorcompanyname", data.instorcompanyname);
        formData.append("designation", data.designation);
        formData.append("experience", data.experience);
        formData.append("address", data.address);
        formData.append("document", ufiles);



    
    // var object = {};
    // formData.forEach(function (value, key) {
    //   object[key] = value;
    // });
    // var json = JSON.stringify(object);
    // console.log(json);
    dispatch(AddNTMTrainingForm(formData)).then((data) => {
      console.log(data);
      //window.close();
      reset();
      setUFiles(null)
      setUserImages([])
      imageCounteruser=0;
      if (data.payload && data.payload.status) {

        setLoadingState(false);
        toast.success(data.payload.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // navigate("/announcementlist") 
      }else{
        setLoadingState(false);
      }
      });
    
    
  };

  const handleClearInputs = (e) => {
    e.preventDefault();
    reset();
  };

  return( <>


    {loadingState ? <Loader loading={loadingState} />:<></>
 }
    <div>
      <ScrollToTop/>
      <BreadCrumbs
        parent="Home"
        child="NTM Training Application"
        parentPath="/"
        imgPath={BreadCrumbImage}
      />

      <div className="saf-heading">
        <h5>{id2}</h5>
      </div>

      <div
        className="m-40"
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <form style={{ width: "85%", alignSelf: "center" }}>
          <div>
            <Box
              sx={{
                width: "100%",
                borderRadius: 1,
                bgcolor: "#EEEEEE",
                padding: "20px",
              }}
            >
            
              <div className="flex mb-10">

              <Controller
                  name="emailId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-3 w-full"
                      error={!!errors.emailId}
                      required
                      helperText={errors?.emailId?.message}
                      label="Email ID"
                      // autoFocus
                      id="emailId"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-3 w-full"
                      error={!!errors.name}
                      required
                      helperText={errors?.name?.message}
                      label="Full Name of the Participant (As required on certificate) (In capitals)"
                      // autoFocus
                      id="name"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />
              


              </div>

              <div className="flex">


                <Controller
                  name="mobileNo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className=" mb-3 w-full"
                      error={!!errors.mobileNo}
                      required
                      maxLength="10"
                      helperText={errors?.mobileNo?.message}
                      label="Contact No."
                      // autoFocus
                      id="mobileNo"
                      variant="outlined"
                      inputProps={{
                        maxLength: 10,
                        pattern: "[0-9]*", // This allows only numeric input
                        inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace any non-numeric character
                      }}
                      style={{ width: "48%" }}
                    />
                  )}
                />

<Controller
                  name="aadhaarcard"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-3 w-full"
                      error={!!errors.aadhaarcard}
                      required
                      helperText={errors?.aadhaarcard?.message}
                      label="Aadhaar Number"
                      // autoFocus
                      id="aadhaarcard"
                      variant="outlined"
                      inputProps={{
                        maxLength: 12,
                        pattern: "[0-9]*", // This allows only numeric input
                        inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace any non-numeric character
                      }}
                      style={{ width: "48%" }}
                    />
                  )}
                />
              </div>
              <div className="flex">
              <div style={{ marginBottom:10 }}>
                  <div className="d-flex flex-column">
                    <Typography sx={{ padding: "5px", fontSize: 14 }}>
                    Upload Aadhaar Card : The size of the
                      document to be suggested* (*jpeg, max 2MB)
                    </Typography>
                    <div className="flex justify-start">
                      {
                        imageCounteruser == 0 && (
                          // <Controller
                          //   name="images"
                          //   control={control}
                          //   render={({ field: { onChange, value } }) => (
                           
                          <label htmlFor="button-file">
                            <input
                              accept="image/jpg,image/jpeg"
                              className="hidden"
                              id="button-file"
                              type="file"
                              onChange={async (e) => {
                                // alert("IMgae 7")
                                function readFileAsync() {
                                  return new Promise((resolve, reject) => {
                                    const file4 = e.target.files[0];
                                    // Check file size
                                    if(file4!=undefined){

                                 
                                    if (file4 && file4.size > 3 * 1024 * 1024) {
                                      toast.error("Latest Salary slip File size exceeds 2 MB", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                      return;

                                    }else{
                                      setUFiles(file4);

                                      const reader = new FileReader();

                                      reader.onload = () => {
                                        resolve({
                                          id: generateGUID(),
                                          url: `data:${file4.type};base64,${btoa(
                                            reader.result
                                          )}`,
                                          type: "image",
                                        });
                                      };
  
                                      reader.onerror = reject;
  
                                      reader.readAsBinaryString(file4);
                                    }
                                 
                                  }
                                  });
                                }

                                const newImage = await readFileAsync();

                                ++imageCounteruser;
                                setUserImages([newImage]);
                              }}
                            />
                          </label>
                         


                        )
                        //   )}
                        // />
                      }
                    </div>
                  </div>
               
                  </div>
   <div className="flex-colum">
   <Typography sx={{ fontSize: 14 }}>
                    Select Gender
                    </Typography>
<Controller
name="gender"
control={control}
render={({ field }) => (
  <FormControl
    component="fieldset"
    error={!!errors.gender}
  >
    <RadioGroup
      {...field}
      aria-label="gender"
      name="gender"
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      <FormControlLabel value="male" control={<Radio />} label="Male" />
      <FormControlLabel value="female" control={<Radio />} label="Female" />
    </RadioGroup>
    <FormHelperText>
      {errors?.gender?.message}
    </FormHelperText>
  </FormControl>
)}
/>

</div>
            </div>
              <div className="flex">
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-3 w-full"
                      error={!!errors.address}
                      required
                      helperText={errors?.address?.message}
                      label="Address"
                      // autoFocus
                      id="address"
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
                <div className="flex">
                  <Controller
                    name="qualification"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-3 w-full"
                        error={!!errors.qualification}
                        required
                        helperText={errors?.qualification?.message}
                        label="Highest Educational Qualification"
                        // autoFocus
                        id="qualification"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                
                </div>
                <div className="flex">
                <Controller
                    name="instorcompanyname"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-3 w-full"
                        error={!!errors.instorcompanyname}
                        required
                        helperText={errors?.instorcompanyname?.message}
                        label="Institute / Company Name"
                        // autoFocus
                        id="instorcompanyname"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                
                </div>
                <div className="flex">
                <Controller
                    name="designation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-3 w-full"
                        error={!!errors.designation}
                        required
                        helperText={errors?.designation?.message}
                        label="Designation"
                        // autoFocus
                        id="designation"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </div>
                <div className="flex">
                <Controller
                    name="experience"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="w-full"
                        error={!!errors.experience}
                        required
                        helperText={errors?.experience?.message}
                        label="Experience (in years)(Minimum 1 Year Experience Required)"
                        // autoFocus
                        id="experience"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </div>

             
             
            </Box>

         
           
           
          
            

            <div
              className="flex mb-10"
              style={{
                justifyContent: "flex-start",
                marginBottom: "20px",
                marginTop: "20px",
                gap: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={buttonSx}
                disabled={loadingState || !clerification}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
              {loadingState && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: blue[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              {/* <Button
                variant="contained"
                component="label"
                endIcon={<RestartAltIcon />}>
                Reset
                <input
                  hidden
                  type="reset"
                // onChange={handleFileChange}
                />
              </Button> */}
            </div>
          </div>
        </form>
      </div>
    </div>

</>
  );
};

export default NTMTrainingForm;
