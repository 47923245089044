import React from 'react'
import { Link } from 'react-router-dom'
// import project1 from '../../Images_1/Project Styles/PortfolioSingleImages/project-1b.jpg'
function PortfolioSingle() {
  return (
    <>
    
    <section className="section-lg portfolio-single">
				<div className="container">
					<div className="pbmit-portfolio-single">
						<div className="row">
							<div className="col-md-5 col-lg-5">
								<h4>About the project</h4>
								<div className="pbmit-portfolio-lines-wrapper">
									<ul className="pbmit-portfolio-lines-ul">
										<li className="pbmit-portfolio-line-li"> 
											<span className="pbmit-portfolio-line-title">Date: </span> August 20, 2018
										</li>
										<li className="pbmit-portfolio-line-li"> 
											<span className="pbmit-portfolio-line-title">Client: </span> Envato Group, US
										</li>
										<li className="pbmit-portfolio-line-li"> 
											<span className="pbmit-portfolio-line-title">Category: </span> 
											<Link to="#" rel="tag">Energy</Link>, 
											<Link to="#" rel="tag">Solar</Link>, 
											<Link to="#" rel="tag">Sun</Link></li>
										<li className="pbmit-portfolio-line-li"> 
											<span className="pbmit-portfolio-line-title">Address: </span> 2946 Angus Road, NY
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-7 col-lg-7">
								<div className="pbmit-short-description">
									<label className="pbmit-details-label">Project Information</label>
									With today’s technology, it’s easier than ever for photographers to create images that are not only breathtaking but also ignite our sense of wonder. Often, this is through photo manipulation in programs like Photoshop. But photographer Mathias Fast has recently introduced Link fantastic photography hack that will give your images Link fantastical, ethereal feel without you having to know your way around Link computer. (Although it helps.) He simply uses his phone screen to add Link reflective element to an image that makes an otherwise grounded composition feel like it’s floating. 
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 col-lg-12">
								<div className="pbmit-featured-wrapper">
									{/* <img src={project1} className="img-fluid" alt=""/> */}
								</div>
							</div>
						</div>
						<div className="pbmit-entry-content">
							<div className="row">
								<div className="col-md-4">
									<h4 className="heading-title">Benefits of Project</h4>
								</div>
								<div className="col-md-8">
									<p>Large solar panels are used to absorb the powerful solar rays emitted from the sun and convert these into power resources One-on-One real-time messaging is allowing users and prospects to reach beyond your inbox and voicemail and has quickly become the fastest and easiest way to communicate with one another. Brands and businesses all over the world are beginning to adapt messaging and chatbot platforms to help answer their prospects questions, delivering answers immediately and setting expectations.</p>
									<ul className="list-group list-group-borderless">
										<li className="list-group-item">
											<i className="fa fa-check"></i> The earth is Link planet of immense beauty
										</li>
										<li className="list-group-item">
											<i className="fa fa-check"></i> Sun is an incredible source of energy
										</li>
										<li className="list-group-item">
											<i className="fa fa-check"></i> Produce energy to generate electricity
										</li>
									</ul>
								</div>
							</div>
							<div className="pbmit-entry-content-bottom">
								<div className="row">
									<div className="col-md-4">
										<h4 className="heading-title">Technologies What We Used</h4>
									</div>
									<div className="col-md-8">
										<p>Our inboxes are overloaded with promotional emails and newsletters, and we simply don’t have enough time to read them all anymore. Personalized messaging on your website will engage and provoke users to converse with you, answer questions and build trust. Follow up those conversations with personalized emails that automatically keep your brand on top of your prospects mind and accelerate your sales process.</p>
										<p>Prospects are more likely to buy if you’re ready to sell. Web Contact forms create little assurance that Link user will receive Link prompt answer and will often fill your inbox with unwanted spam and solicitations.</p>
									</div>
								</div> 
							</div>
							<nav className="navigation post-navigation">
								<div className="nav-links">
									<div className="nav-previous">
										<Link to="#" rel="prev">
											<span className="pbmit-portfolio-nav-icon">
												<i className="pbmit-base-icon-left-open"></i>
											</span> 
											<span className="pbmit-portfolio-nav-wrapper">
												<span className="pbmit-portfolio-nav-head">Previous Portfolio</span>
												<span className="pbmit-portfolio-nav nav-title">Solar Roof Project</span> 
											</span>
										</Link>
									</div>
									<div className="nav-next">
										<Link to="#" rel="next">
											<span className="pbmit-portfolio-nav-wrapper">
												<span className="pbmit-portfolio-nav-head">Next Portfolio</span>
												<span className="pbmit-portfolio-nav nav-title">Powerful Equipment</span> 
											</span> 
											<span className="pbmit-portfolio-nav-icon">
												<i className="pbmit-base-icon-right-open"></i>
											</span>
										</Link>
									</div>
								</div>
							</nav>
						</div>
					</div>
				</div>
			</section>
            
    </>
  )
}

export default PortfolioSingle