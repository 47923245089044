import React, { Component } from 'react'
import './AboutCompany.css'
import image3 from '../../../Images_1/AboutUs/AboutCompanyImages/About_3.png';
import OnScrollAnimation from '../../OnScrollAnimation';
export class AboutCompany extends Component {
    render() {
        return (
            <div>
                {/* <div className='aboutcompany-bg-div'>
                    <img className='aboutcompany-bg' src={aboutimage} />
                </div> */}
                <section className='aboutcompany-main-div'>
                    <section className='aboutcompany-section'>
                        <div className='aboutcompany-content-div'>
                            <div className='about-company-image-div'>
                                {/* <div className='aboutcompany-hero-img-div'>
                                    <img className='aboutcompany-hero-img-2' src={image2} />
                                    <img className='aboutcompany-hero-img-1' src={image1} />
                                </div> */}
                                <img className='aboutcompany-hero-img-3' src={image3} />
                            </div>
                            <div className='aboutcompany-text-div'>
                                {/* <h2>About GERMI</h2> */}
                                {/* <div className="heading-container">
                                    <h4 id="title2" className="title-animation-about-us">About GERMI</h4>
                                    <div className="div-for-hr-animation">
                                        <hr id="hr1" className="animate-hr-tag-about-us" />
                                        <hr id="hr2" className="animate-hr-tag-about-us" />
                                    </div>
                                </div> */}
                                <OnScrollAnimation title1="ag1" title2="ag2" hr1="ag3" hr2="ag4" heading="About GERMI" subheading="" />
                                <div className='aboutcompany-us-subtitle normal-text'>
                                    Gujarat Energy Research & Management Institute (GERMI) is a centre of excellence in the energy sector, promoted by Gujarat State Petroleum Corporation Limited (GSPC), a Government of Gujarat Undertaking.
                                </div>
                                <div className='aboutcompany-us-subtitle normal-text'>GERMI has four mandates : <b> Research and Development, Consultancy, Training, Education.</b></div>
                                <ul className='span-div'>
                                    <li className='normal-text'>A Regsitered Society and Trust under the Societies Registration Act, 1860 and the Bombay Trust Act, 1950 respectively.</li>
                                    <li className='normal-text'>Recognized as Scientific and Industrial Research Organization (SIRO) by the Department of Scientific and Industrial Research (DSIR), Government of India.</li>
                                    <li className='normal-text'>A Schedule-I Environment Auditor recognized by the Gujarat Pollution Control Board (GPCB) and an Energy Auditor Consultant recognized by the Gujarat Energy Development Agency (GEDA).</li>
                                    <li className='normal-text'>Having Laboratories accredited by National Accrediation Board for Testing and Calibration Laboratories (NABL) as per (ISO/IEC 17025:2017).</li>
                                    <li className='normal-text'>ISO 9001:2015 certified organization for : Research and Development Advisory, Management and technical services such as consultancy and third party inspection (TPI) and To provide professional and vocational training in fields of energy and energy-related areas including petroleum, environment and renewable energy.</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        )
    }
}

export default AboutCompany;
