import React from 'react'
import EmployeeDetail from '../../AboutUs/EmployeeDetail/EmployeeDetail';
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import '../OurTeam/OurTeam.css'
import BreadCrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/NTM.jpg'
import { useLocation, useParams } from 'react-router-dom';
import teamData from "../json/Team.json";
import envDepData from "../../Services/DepartmentDetails/json/EnvironmentDepartment.json";
import petDepData from "../../Services/DepartmentDetails/json/PetroluemDepartment.json";
import prmDepData from "../../Services/DepartmentDetails/json/PetroluemresearchDepartment.json";
import ntmDepData from "../../Services/DepartmentDetails/json/NewTechnologyDepartment.json";
import renDepData from "../../Services/DepartmentDetails/json/RenewableDepartment.json";
import ScrollToTop from '../../ScrollToTop';
function TeamDetails() {
    // const depName = ["RM", "NTM", "PM","EM","PRM"];
    const { id } = useParams();
    const { id2 } = useParams();
    const { id3 } = useParams();
    const { id4 } = useParams();

    // console.log(id+"--"+"--"+id2+"--"+id3+"--"+id4)
    var teamdatalist = [];

    if (id2 == "dep") {
        if (id3 == "1") {
            teamdatalist = ntmDepData.team;
        }
        else if (id3 == "2") {
            teamdatalist = renDepData.team;
        }
        else if (id3 == "3") {
            teamdatalist = petDepData.team;
        }
        else if (id3 == "4") {
            teamdatalist = envDepData.team;
        }
        else {
            teamdatalist = prmDepData.team;
        }
    }
    else if (id2 == "technical") {

        if (id3 == "PRM") {
            teamdatalist = teamData[id2][id4].PRM;
        } else if (id3 == "PM") {
            teamdatalist = teamData[id2][id4].PM;
        } else if (id3 == "NTM") {
            teamdatalist = teamData[id2][id4].NTM;

        } else if (id3 == "RM") {
            teamdatalist = teamData[id2][id4].RM;

        } else if (id3 == "EM") {
            teamdatalist = teamData[id2][id4].EM;
        }

    } else if (id2 == "hradmin") {

        teamdatalist = teamData[id2];

    } else {
        teamdatalist = teamData[id2];
    }


    var index3 = teamdatalist.findIndex(
        (x) => x.id == id
    );

    return (

        <div className='tds-main'>
            <ScrollToTop />
            <section className="team-header-spacing">
                <BreadCrumbs parent="Our Team" child="Team Details" path="/" imgPath={BreadCrumbImage} />
            </section>


            <div className="stack-upper">
                <EmployeeDetail state={teamdatalist[index3]} />
            </div>

        </div>
    )
}

export default TeamDetails