import React, { useState, useEffect } from 'react'
import './Careers.css'
import { Link } from 'react-router-dom'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import BreadCrumbImage from '../../Images_1/CommonSection/Braedcrumbs/gallery.jpg';
import watg from '../../Images_1/Careers/carrer.jpg'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HomeIcon from '@mui/icons-material/Home';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { Career } from './store/CareersDataSlice';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

function Careers() {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Career());
    }, []);
    const career = useSelector((state) => state.careerdata);
    const careerData = career?.data?.data;

    const [selectedDiv, setSelectedDiv] = useState('career1');

    return (
        <div>
            {/* Title Bar */}
            <BreadCrumbs parent="Home" child="Careers" parentPath="/" imgPath={BreadCrumbImage} />
            {/* Title Bar End */}

            {/* <section className="section-lgx">
                <div className="container">
                    <div className="row align-top">
                        <div className="col-lg-4 service-left-col order-2 order-lg-1">
                            <div className="careers-container">
                                <div className="Categories-Container">
                                    <div className="List-Grid-Card-Categories">
                                        <div>
                                            <div className="all-post-list">
                                                <ul>
                                                    <li className="post-active"
                                                    ><Link to="#" style={selectedDiv == 'career1' ? { color: '#b1e346' } : {}}
                                                        onClick={() => {
                                                            setSelectedDiv('career1');
                                                        }}>WORKING @ GERMI</Link></li>
                                                    <li><Link to="#" style={selectedDiv == 'career2' ? { color: '#b1e346' } : {}}
                                                        onClick={() => {
                                                            setSelectedDiv('career2');
                                                        }}>CURRENT OPENINGS AT GERMI</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 service-right-col order-1">
                            <div className='career-content-container'>

                                <div className='career-content-div' style={selectedDiv == 'career1' ? { display: 'block' } : { display: 'none' }}>
                                    <div className='career-heading-div'>
                                        <h3 className='career-heading'>Working @ GERMI</h3>
                                    </div>
                                    <div>
                                        <p className='career-text'>At GERMI, we have a community of energetic, enthusiastic and ever-learning individuals from a diverse background. We are a fast growing company that breathes innovation and strives to bring an un-parallel change in the society in the means of sustainable energy.</p>
                                        <p className='career-text'>We work with government organizations, public sector units and private small and large industry players in the energy sector through research partnerships, policy-regulatory and advisory support, training and delivering solutions to their business case problems.</p>
                                        <p className='career-text'>GERMI welcomes individuals who are Thinkers & Doers. People with passion, curiosity & conviction, people who are eager to break away from conventional roles and do 'jobs never done before'.</p>
                                        <p className='career-text'>GERMI offers many opportunities in the various modes of recruitment round the year. Join us to accelerate the movement of renewable energy and energy-efficient solutions for the country.</p>
                                    </div>
                                    <div className='career-image-div'>
                                        <img className='career-image' src={watg} alt='image' />
                                    </div>
                                </div>

                                <div className='career-content-div' style={selectedDiv == 'career2' ? { display: 'block' } : { display: 'none' }}>
                                    <div className='career-heading-div'>
                                        <h3 className='career-heading'>Current Openings at GERMI</h3>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <h3>No Current Openings</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='career-main-section'>
                <div className='career-main-div'>
                    <div className='career-working-div'>
                        <div className='career-working'>
                            <div className='career-working-content-div'>
                                <div className="heading-container">
                                    <h4 id="title2" className="title-animation-about-us heading-fonts">Working @ GERMI</h4>
                                    <div className="div-for-hr-animation">
                                        <hr id="hr1" className="animate-hr-tag-about-us" />
                                        <hr id="hr2" className="animate-hr-tag-about-us" />
                                    </div>
                                </div>
                                <div>
                                    <p className='career-normal-text'>At GERMI, we are a community of energetic, enthusiastic and ever-learning individuals
                                        from diverse backgrounds. We are a fast growing organization that breathes innovation and strives to bring an un-parallel
                                        change in the Society in the domain of sustainable energy.</p>
                                    <p className='career-normal-text'>
                                        We work with government organizations, public sector undertakings and private industry
                                        players, both small and large in the energy sector through research partnerships, policy-regulatory, advisory support,
                                        training & skill development and delivering solutions to their business case problems.</p>
                                    <p className='career-normal-text'>GERMI welcomes individuals who are Thinkers & Doers.
                                        People with passion, curiosity & conviction, people who are eager to break away from
                                        conventional roles and do 'jobs never done before'.</p>
                                    <p className='career-normal-text'>
                                        GERMI offers opportunities round the year.
                                        Join us to accelerate the movement of conventional, renewables and Non-renewable solutions for the country.
                                    </p>
                                </div>
                            </div>
                            <div className='career-working-image-div'>
                                <img className='career-working-image' src={watg} alt='image' />
                            </div>
                        </div>
                    </div>
                    <div className='career-jobs-div'>
                        <div className='career-top-div'>
                            <div className='career-top-heading-div'>
                                <div className="heading-container">
                                    <h4 id="title2" className="title-animation-about-us heading-fonts">Careers at GERMI</h4>
                                    <h2 id="title2" className="title-animation-about-us subheading-fonts">Current Openings</h2>
                                    <div className="div-for-hr-animation">
                                        <hr id="hr1" className="animate-hr-tag-about-us" />
                                        <hr id="hr2" className="animate-hr-tag-about-us" />
                                    </div>
                                </div>
                                {/* <div>
                                    <h4 className='career-top-heading-1'>Our Jobs</h4>
                                    <h2 className='career-top-heading-2'>Current Available Positions at GERMI</h2>
                                </div> */}
                            </div>
                            {/* <div className='career-top-desc-div'>
                                <p className='career-top-desc'>"Same Important Notice Text here "</p>
                            </div> */}
                        </div>
                        <div className='career-bottom-div'>
                            <div className='career-job-role-div'>

                                {
                                    careerData && careerData.map((data) => <div className='career-job-single'>
                                        <div className='location-div twenty-sixteen-fonts'>
                                       
                                            <span>{data.positionname}</span>
                                            <span className='xxs-small-text'>{data.positiontype}</span>
                                          
                                        </div>
                                        <div className='info-div'>
                                            <span className='xxs-small-text'><AddLocationIcon fontSize='small' />{data.location}</span>
                                        </div>
                                        <div className='info-div'>
                                            <span className='xxs-small-text'><AccountBalanceWalletIcon fontSize='small' />{data.positionremuneration}</span>
                                        </div>
                                        <div className='info-div'>
                                            <span className='xxs-small-text' dangerouslySetInnerHTML={{ __html: data.minqualification }}></span>
                                        </div>
                                        <div className='info-div'>
                                            <span className='xxs-small-text'>{"(Last date of application: "+dayjs(data.lastdate).format("DD/MM/YYYY")+" till "+dayjs(data.lasttime).format("hh:mm A")+" )"+" "}</span>
                                        </div>
                                       
                                        <div className='apply-button-text-card'>
                                            <button className='apply-button static-small-text'><Link className='link-color' to="/jobdetails" state={data}>More Details</Link></button>
                                        </div>
                                     
                                      
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Careers
