import React from 'react'
import Header from '../CommonSection/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../CommonSection/Footer'
import { useTheme } from '../../ThemeContext'

function MainLayout() {
  const { isNightMode } = useTheme();
  return (
    <div>
      <div id="main-content" className={isNightMode ? '' : 'theme-dark-mode'}>
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div>
  )
}

export default MainLayout
