import React from "react";
import "./GivePage.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import GSECL from '../../Images_1/Donation/Image3.jpeg';
import Prayas from '../../Images_1/Donation/Image4.jpeg';
import Shakti from '../../Images_1/Donation/Image6.jpeg';
import Sterling from '../../Images_1/Donation/Image7.jpeg';
import gspc from '../../Images_1/Home/ClientsImages/Central/gspc.png';
import GSPL from '../../Images_1/Home/ClientsImages/Central/gp.png';


// import Donate from '../../Images_1/Donation/Image8.jpeg';
import BreadCrumbImageDonate from '../../Images_1/CommonSection/Braedcrumbs/donate.jpg';
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import { addDonation } from "./store/AddDonate";
import Loader from "../../Loader";




const GivePage = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState("");
  const [type, setType] = useState("");
  const [donationAmount, setDonationAmount] = useState("");
  const [message, setMessage] = useState("");
  const validateEmail = (inputEmail) => {
    // Simple email regex pattern
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(inputEmail);
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    if (inputEmail === '' || validateEmail(inputEmail)) {
      setEmailError(''); // Valid email
    } else {
      setEmailError('Invalid email address');
    }
  };
  return (
    <>
 {loading ? <Loader loading={loading} />:<></>}
      <div className="give-main">
        <BreadCrumbs parent="Home" child="" parentPath='/' imgPath={BreadCrumbImageDonate} />
        <div className="give-main-2-div">
          <div className="Form-container-2 give-upper-block mt-5">
            <div className="Grid-block">
              <div className="Grid-Content-bg">
                <div className="Grid-Content-content">
                  <p className="normal-text">
                    Donations to GERMI receive tax exemption of up to 150% under the
                    section 35 A (i) of the Income Tax Act. Your donation goes a long
                    way in supporting socially relevant research projects with a
                    strong India specific focus.
                  </p>
                </div>
              </div>
            </div>


            <div className="Grid-icon">
              <div className="Grid-icon-content">
                <b><p className="normal-text">
                  In the past, GERMI has received funds from many organizations
                  below.
                </p></b>
                <hr></hr>
              </div>
              <div className="icons">
                <img src={GSECL} alt="GSECL logo" />
                <img src={Prayas} alt="Prayas logo" />
                <img src={Shakti} alt="Shakti logo" />
                <img src={Sterling} alt="Sterling logo" />
                <img src={gspc} alt="gspc logo" />
                <img src={GSPL} alt="GSPL logo" />
              </div>
            </div>
          </div>

          <div className="Form-container mt-5">
            <div className="Form-bg">
              <div className="form">
                <form>
                  <label className="label-in-give normal-text">Full Name </label>
                  <input className="text-in-give" type="text" value={fullName} onChange={(text) => setFullName(text.target.value)} /> <br />

                  <div className="give-flex">
                    <div className="give-flex-block">
                      <label className="label-in-give normal-text">Email</label>
                      <div>
      <input
        className="text-in-give"
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder="Enter your email"
      />
      {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
    </div>
                    
                      {/* <input className="text-in-give" type="email" value={email} onChange={text => setEmail(text.target.value)} />
                    */}
                   
                    </div>
                    <div className="give-flex-block">
                      <label className="label-in-give normal-text">Phone</label>
                      <input className="text-in-give" type="text" value={phoneNumber}  maxLength={10}
                      onChange={e => {
                        const value = e.target.value;
                        // Remove any non-digit characters
                        const sanitizedValue = value.replace(/\D/g, '');
                        // Limit to 10 digits
                        if (sanitizedValue.length <= 10) {
                          setPhoneNumber(sanitizedValue);
                        }
                      }
                    }
                      // onChange={text => setPhoneNumber(text.target.value)}
                      
                      />
                    </div>
                  </div>


                  <label className="label-in-give normal-text">Type of Donation</label>
                  <select style={{borderColor:"7cba27"}} className="text-in-give" value={type} onChange={text => setType(text.target.value)} >
                    <option value="Select Option"></option>
                    <option value="Donation to GERMI">Donation to GERMI</option>
                    <option value="Project Sponsership">Project Sponsership</option>
                    <option value="Training Fees">Training Fees</option>
                  </select>
                  <br />


                  <label className="label-in-give normal-text">Amount</label>
                  <input className="text-in-give" type="text" value={donationAmount} 
                  
                  // onChange={text => setDonationAmount(text.target.value)}
                  
                  onChange={e => {
                    const value = e.target.value;
                    // Remove any non-digit characters
                    const sanitizedValue = value.replace(/\D/g, '');
                    // Limit to 10 digits
                    if (sanitizedValue.length <= 10) {
                      setDonationAmount(sanitizedValue)
                    }
                  }
                }

                  /> <br />


                  <label className="label-in-give normal-text">Message</label>
                  <input className="text-in-give" type="text" value={message} onChange={text => setMessage(text.target.value)} /> <br />
                  <div className="text-in-give-green" id="msg-div"></div>
                  <button className="text-in-give" type="submit" onClick={(e) => {
                    e.preventDefault();
                    if (fullName.trim() == "") {
                      alert("Please enter your name.");
                      return;
                    }
                    if (emailError!="" || email.trim() == "") {
                      alert("Please enter Valid email.");
                      return;
                    }
                    if (type.trim() == "" || type === "Select Option") {
                      alert("Please select type of donation.");
                      return;
                    }
                    if (donationAmount.trim() == "") {
                      alert("Please enter donation amount.");
                      return;
                    }
                    setLoading(true)
                    var data = {
                      "fullname": fullName,
                      "email": email,
                      "phone": phoneNumber,
                      "typeofdonate": type,
                      "donationamount": donationAmount,
                      "message": message,
                    };
                    // console.log(data);
                    dispatch(addDonation(data)).then(_ => {
                      setLoading(false)
                      setFullName("");
                      setEmail("");
                      setPhoneNumber("");
                      setType("");
                      setDonationAmount("");
                      setMessage("");
                    });

                  }}>Submit</button>
                </form>
              </div>
            </div>
            {/* <div className="Form-Image">
            <img src={Donate} alt="" />
          </div> */}
          </div>
        </div>

      </div>

            
    </>
  );
};

export default GivePage;
