import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../../../constants";

export const addcontactus = createAsyncThunk(
  "addcontactus",
  async (data) => {
     const json=JSON.stringify(data)
    // console.log("here")
    const res = await publicRequest.post("/contactus", json);
    if (res) {
      // console.log(res);
      if (res.data.status) {
        
        document.getElementById('contact-status').innerText = res.data.message;
        setTimeout(function() {
          document.getElementById('contact-status').innerText = '';
      }, 10000);
      }
      return res.data
    }

  }
);


const contactusSlice = createSlice({
  name: "addcontactus",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addcontactus.fulfilled, (state, action) => {
      return action.payload
    })
  }
});

export default contactusSlice.reducer;  