import React from "react";
// import ServiceImage1 from '../../../Images_1/AboutUs/ExperienceImages/service-01.jpg'
// import ServiceImage2 from '../../../Images_1/AboutUs/ExperienceImages/service-02.jpg'
// import ServiceImage3 from '../../../Images_1/AboutUs/ExperienceImages/service-03.jpg'
// import ServiceImage4 from '../../../Images_1/AboutUs/ExperienceImages/service-04.jpg'
// import ServiceImage5 from '../../../Images_1/AboutUs/ExperienceImages/service-05.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

function Experience() {
  return (
    <div className="container">
      <section className="experience-achievement-section">
        <div className='heading-container'>
          <h2 id="title1" className='title-animation-about-us'>Experience and Achievements</h2>
          <div className=' div-for-hr-animation'>
            <hr id='hr1' className='animate-hr-tag-about-us' />
            <hr id='hr2' className='animate-hr-tag-about-us' />
          </div>
        </div>
        <div className="experience-achievement-container ">
          <Swiper className="experience-achievement-swiper-main-div"
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={10}
            slidesPerView={3}
            Navigation={true}
            // autoplay={{ delay: 5000, disableOnInteraction: true }}
            loop={true}>
            <SwiperSlide>
              <div className="experience-achievement-div">
                <div className="experience-achievement-image-div">
                  {/* <img className="experience-achievement-image" src={ServiceImage1} /> */}
                </div>
                <div className="experience-achievement-text-div">
                  <div>
                    <h4 className="experience-achievement-heading">Awarded Best Solar Training Centre</h4>
                  </div>
                  <div className="experience-achievement-desc">
                    <span>GERMI was awarded the The Best Solar Training Centre in India by the Skill Council for Green Jobs, Government of India at Inter-solar Exhibition 2017.</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="experience-achievement-div">
                <div className="experience-achievement-image-div">
                  {/* <img className="experience-achievement-image" src={ServiceImage2} /> */}
                </div>
                <div className="experience-achievement-text-div">
                  <div>
                    <h4 className="experience-achievement-heading">5 MW Rooftop Program</h4>
                  </div>
                  <div className="experience-achievement-desc">
                    <span>5 MW rooftop program was voted the best project under MNRE</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="experience-achievement-div">
                <div className="experience-achievement-image-div">
                  {/* <img className="experience-achievement-image" src={ServiceImage3} /> */}
                </div>
                <div className="experience-achievement-text-div">
                  <div>
                    <h4 className="experience-achievement-heading">Project Management</h4>
                  </div>
                  <div className="experience-achievement-desc">
                    <span>GERMI has assisted as a project management consultant for over 1,000 MW of both rooftop and land based solar power projects</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="experience-achievement-div">
                <div className="experience-achievement-image-div">
                  {/* <img className="experience-achievement-image" src={ServiceImage4} /> */}
                </div>
                <div className="experience-achievement-text-div">
                  <div>
                    <h4 className="experience-achievement-heading">Advisory body to the Government of Gujarat</h4>
                  </div>
                  <div className="experience-achievement-desc">
                    <span>GERMI serves as an advisory body to the Government of Gujarat</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="experience-achievement-div">
                <div className="experience-achievement-image-div">
                  {/* <img className="experience-achievement-image" src={ServiceImage5} /> */}
                </div>
                <div className="experience-achievement-text-div">
                  <div>
                    <h4 className="experience-achievement-heading">Upstream oil exploration sector to identify oil reserves</h4>
                  </div>
                  <div className="experience-achievement-desc">
                    <span>GERMI regularly assists companies engaged in the upstream oil exploration sector to identify oil reserves in the country</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </div>
  );
}

export default Experience;
