import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import teamData from "../json/Team.json";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "./OurTeam.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import BreadCrumbImage from "../../../Images_1/CommonSection/Braedcrumbs/team.jpg";
import { useNavigate } from "react-router-dom";
import OnScrollAnimation from "../../OnScrollAnimation";
import OrganizationChart from "../../AboutUs/OrganizationChart/OrganizationChart";
function ManagementTeams() {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <BreadCrumbs
          parent="Home"
          child="Management Team"
          parentPath="/"
          imgPath={BreadCrumbImage}
        />
      </div>

      <section className="section-lgb tm-main">
        <div className="container-team pbmit-col-stretched-yes pbmit-col-right">
        <div className="main-div-team1">
       
          <div className="main-div-teamtx">
            {/* <div className='heading-container' style={{ color: "#18181B" }}>
                            <h4 className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }} >Our Team</h4>
                            <h2 id="title1" className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }}>Management Team</h2>
                            <div className=' div-for-hr-animation'>
                                <hr id='hr1' className='animate-hr-tag-about-us' />
                                <hr id='hr2' className='animate-hr-tag-about-us' />
                            </div>
                        </div> */}

            <OnScrollAnimation
              title1="mt1"
              title2="mt2"
              hr1="mt3"
              hr2="mt4"
              heading="Management Team"
              subheading=""
            />

            <div className="team-grid-divtx">
              <div className="pbmit-service-style-4">
                <div className="pbminfotech-post-item team-block-alignment">
                  <div className="pbmit-featured-wrapper img-border img-alignment">
                    <Link  onClick={() =>
                        window.open("teamdetails/1/management/0/0")
                      }>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        teamData["management"][0].imageSrc
                      }
                      className="img-fluid img-radius img-team-dept-style"
                      alt=""
                    />
                    </Link>
                    {/* <img src={team1} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                  </div>
                  <div className="team-content-div mgmt-team-content-div">
                    <div className="team-about-div">
                      <h3 className="team-name-class twenty-sixteen-fonts">
                        Shri Mukesh Ambani
                      </h3>
                      <h4 className="team-desig-class small-text">
                        Chairman and Managing Trustee, GERMI
                      </h4>
                      <h4 className="team-desig-class small-text">
                        Chairman and Managing Director Reliance Industries Ltd.
                      </h4>
                    </div>
                    {/* <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>

              <div className="pbmit-service-style-4">
                <div className="pbminfotech-post-item team-block-alignment">
                <div className="pbmit-featured-wrapper img-border img-alignment">
                   <Link   
                    // onClick={() =>
                    //     window.open("teamdetails/9/management/0/0")
                    //   }
                      >
                    <img
                   
                      src={
                        process.env.PUBLIC_URL +
                        teamData["management"][8].imageSrc
                      }
                      className="img-fluid img-radius img-team-dept-style"
                      alt=""
                    />
                    </Link>
                    {/* <img src={team8} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                  </div>
                  <div className="team-content-div mgmt-team-content-div">
                    <div className="team-about-div">
                    <h3 className="team-name-class twenty-sixteen-fonts">
                    Smt. Anita Karwal-I.A.S (Retd.)
                      </h3>
                      <h4 className="team-desig-class small-text">
                      Vice Chairperson and Managing Trustee, GERMI
                      </h4>
                    </div>
                    {/* <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>

              <div className="pbmit-service-style-4">
                <div className="pbminfotech-post-item team-block-alignment">
                  <div className="pbmit-featured-wrapper img-border img-alignment">
                    
                    <Link   onClick={() =>
                        window.open("teamdetails/3/management/0/0")
                      }>
                    <img
                    
                      src={
                        process.env.PUBLIC_URL +
                        teamData["management"][2].imageSrc
                      }
                      className="img-fluid img-radius img-team-dept-style"
                      alt=""
                    />
                    </Link>
                    {/* <img src={team3} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                  </div>
                  <div className="team-content-div mgmt-team-content-div">
                    <div className="team-about-div">
                      <h3 className="team-name-class twenty-sixteen-fonts">
                        Shri Rajesh Sivadasan
                      </h3>
                      <h4 className="team-desig-class small-text">
                        Secretary & Trustee, GERMI
                      </h4>
                      <h4 className="team-desig-class small-text">
                        Chief Financial Officer - Gujarat State Petroleum
                        Corporation
                      </h4>
                    </div>
                    {/* <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>

              <div className="pbmit-service-style-4">
                <div className="pbminfotech-post-item team-block-alignment">
                  <div className="pbmit-featured-wrapper img-border img-alignment">
                   <Link   onClick={() =>
                        window.open("teamdetails/4/management/0/0")
                      }>
                    <img
                    
                      src={
                        process.env.PUBLIC_URL +
                        teamData["management"][3].imageSrc
                      }
                      className="img-fluid img-radius img-team-dept-style"
                      alt=""
                    />
                    </Link>
                    {/* <img src={team4} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                  </div>
                  <div className="team-content-div mgmt-team-content-div">
                    <div className="team-about-div">
                      <h3 className="team-name-class twenty-sixteen-fonts">
                        Dr. Biswajit Roy
                      </h3>
                      <h4 className="team-desig-class small-text">
                        Director General, GERMI
                      </h4>
                    </div>
                    {/* <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>

              <div className="pbmit-service-style-4">
                <div className="pbminfotech-post-item team-block-alignment">
                  <div className="pbmit-featured-wrapper img-border img-alignment">
                   <Link  
                  //  onClick={() =>
                  //       window.open("teamdetails/5/management/0/0")
                  //     }
                      >
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        teamData["management"][4].imageSrc
                      }
                      className="img-fluid img-radius img-team-dept-style"
                      alt=""
                    />
                    </Link>
                    {/* <img src={team5} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                  </div>
                  <div className="team-content-div mgmt-team-content-div">
                    <div className="team-about-div">
                      <h3 className="team-name-class twenty-sixteen-fonts">
                      Shri S. J. Haider, IAS
                      </h3>
                      <h4 className="team-desig-class small-text">
                      Additional Chief Secretary, Energy And Petrochemicals Department,
                      </h4>
                    </div>
                    {/* <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>

              <div className="pbmit-service-style-4">
                <div className="pbminfotech-post-item team-block-alignment">
                  <div className="pbmit-featured-wrapper img-border img-alignment">
                    <Link  onClick={() =>
                        window.open("teamdetails/6/management/0/0")
                      }>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        teamData["management"][5].imageSrc
                      }
                      className="img-fluid img-radius img-team-dept-style"
                      alt=""
                    />
                    </Link>
                    {/* <img src={team6} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                  </div>
                  <div className="team-content-div mgmt-team-content-div">
                    <div className="team-about-div">
                      <h3 className="team-name-class twenty-sixteen-fonts">
                        Shri Milind Torawane, IAS
                      </h3>
                      <h4 className="team-desig-class small-text">
                        Managing Director, Gujarat State Petroleum Corporation
                        Ltd.
                      </h4>
                    </div>
                    {/* <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>

              <div className="pbmit-service-style-4">
                <div className="pbminfotech-post-item team-block-alignment">
                  <div className="pbmit-featured-wrapper img-border img-alignment">
                    <Link  
                    
                    // onClick={() =>
                    //     window.open("teamdetails/7/management/0/0")
                    //   }
                      >
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        teamData["management"][6].imageSrc
                      }
                      className="img-fluid img-radius img-team-dept-style"
                      alt=""
                    />
                    </Link>
                    {/* <img src={team7} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                  </div>
                  <div className="team-content-div mgmt-team-content-div">
                    <div className="team-about-div">
                      <h3 className="team-name-class twenty-sixteen-fonts">
                        Dr. R. A. Mashelkar
                      </h3>
                      <h4 className="team-desig-class small-text">
                        FRS, President, Global Research Alliance & CSIR
                        Bhatnagar, Fellow, National Chemical
                      </h4>
                    </div>
                    {/* <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>

              <div className="pbmit-service-style-4">
                <div className="pbminfotech-post-item team-block-alignment">
                  <div className="pbmit-featured-wrapper img-border img-alignment">
                   <Link    
                   
                  //  onClick={() =>
                  //       window.open("teamdetails/8/management/0/0")
                  //     }
                      >
                    <img
                   
                      src={
                        process.env.PUBLIC_URL +
                        teamData["management"][7].imageSrc
                      }
                      className="img-fluid img-radius img-team-dept-style"
                      alt=""
                    />
                    </Link>
                    {/* <img src={team8} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                  </div>
                  <div className="team-content-div mgmt-team-content-div">
                    <div className="team-about-div">
                      <h3 className="team-name-class twenty-sixteen-fonts">
                        Shri Parimal Nathwani
                      </h3>
                      <h4 className="team-desig-class small-text">
                        Group President - Corporate Affairs, Reliance Industries
                        Limited, Ahmedabad.
                      </h4>
                    </div>
                    {/* <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>
             
            </div>

           
          </div>
          <section>
              <OrganizationChart />
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ManagementTeams;
