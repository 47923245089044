import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const getTender = createAsyncThunk('gettenderbydept', async(id) => {

   
  const response = await publicRequest.post('/gettenderbydept',JSON.stringify(id));

  return response.data.data;
});

const DptTenderDataSlice = createSlice({
  name: 'gettenderbydept',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(getTender.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default DptTenderDataSlice.reducer;