import React, { useState, useEffect } from 'react';
import './VisitorCounter.css'; // Import CSS file for styling

const VisitorCounter = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const incrementCount = () => {
      setCount(prevCount => prevCount + 1);
    };

    const interval = setInterval(incrementCount, 10000); // Adjust speed of animation here

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="visitor-counter">
      <div className="counter-container">
      {/* <br/> */}
        {/* <span className="label">Number of Users Visited</span> */}
        <span className="count">{count.toLocaleString()} </span>
        Number of Users Visited
      </div>
    </div>
  );
};

export default VisitorCounter;