import React from 'react'
import { Link } from 'react-router-dom'

// import PhoneIcon from '@mui/icons-material/Phone';
// import EmailIcon from '@mui/icons-material/Email';
// import { useNavigate } from "react-router-dom";
// import team4 from '../../images/team4.png'
// import team5 from '../../images/team5.png'
// import team6 from '../../images/team6.png'
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay } from 'swiper/modules'

// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './OurDepartmentTeam.css'
// import TwitterIcon from '@mui/icons-material/Twitter';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import OnScrollAnimation from '../OnScrollAnimation'

function OurDepartmentTeam(props) {
    const depNo = props.department;
    var teamData = props.teamData;
    return (
        <div>
            <div>
                {/* <div className="pbmit-title-bar-wrapper">
                <div className="title-adjustments">
                    <div className="pbmit-title-bar-content">
                        <div className="pbmit-title-bar-content-inner">
                            <div className="pbmit-tbar ">
                            <div className="pbmit-breadcrumb title-adjustments">
                                <div className="pbmit-breadcrumb-inner  title-adjustments1">
                                    <span><Link title="" to="/" className="home"><span>GERMI</span></Link></span>
                                    <span className="sep"><i className="pbmit-base-icon-angle-right"></i></span>
                                    <span className="post-root post post-post current-item">Our Team</span>
                                    
                                </div>
                            </div>
                                <div className="pbmit-tbar-inner container title-adjustments2">
                                    <h1 className="pbmit-tbar-title ">Our Team</h1>
                                </div>
                           
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}

            </div>
            <section className="section-lgb sectionbg">
                <div className="container pbmit-col-stretched-yes pbmit-col-right">

                    <div className="row main-div" >
                        {/* <div className='heading-container'>
                            <h4 className='title-animation-about-us'>Our Team</h4>
                            <h2 id="title1" className='title-animation-about-us'>Meet Our Team</h2>
                            <div className=' div-for-hr-animation'>
                                <hr id='hr1' className='animate-hr-tag-about-us' />
                                <hr id='hr2' className='animate-hr-tag-about-us' />
                            </div>
                        </div> */}
                        <OnScrollAnimation title1="ot1" title2="ot2" hr1="ot3" hr2="ot4" heading="Our Team" subheading="" />


                        <div className="col-12">

                            <div className="pbmit-col-stretched-right">

                                <div className="swiper-slider" data-autoplay="true" data-loop="true" data-dots="false" data-arrows="false" data-columns="4" data-margin="30" data-effect="slide">
                                    <div className="swiper-wrapper-Team">
                                        {
                                            teamData && teamData.map((data) => <div className="team-member-box">
                                                {/* <!-- Slide1 --> */}
                                                <article className="pbmit-service-style-4">
                                                    <div className="pbminfotech-post-item team-block-alignment">
                                                        <div className="img-alignment pbmit-featured-wrapper img-border">
                                                            {data.type && data.type=="HOD"?
                                                            <Link onClick={ 
                                                                () => window.open( window.location.origin + "/teamdetails/"  + data.id + "/dep/" + depNo + "/0")
                                                               }>
                                                            <img 
                                                                 src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                        </Link>
                                                        :<img 
                                                        src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                              }
                                                        </div>
                                                        <div className="pbminfotech-box-content">
                                                            <div className="pbminfotech-box-content-inner">
                                                                <div className="pbmit-service-wrap data-direction">
                                                                    <div className="member-tag-title subheading-fonts">
                                                                        {data.name}
                                                                    </div>
                                                                   
                                                                    <div className="pbmit-service-category">
                                                                        <span className="dep-team small-text">
                                                                            <span rel="tag">{data.jobPost}</span>
                                                                        </span>
                                                                    </div>


                                                                    <div className="Icons-centering">
                                                                        <div className="our-team-social-area">

                                                                            {/* <Link to={"tel:" + data.contactno} target="_blank">{data.contactno}
                                                                                <span><i></i></span>
                                                                            </Link> */}
                                                                            {/* <Link to={"mailto:" + data.emailid} target="_blank">{data.emailid}
                                                                                <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                                            </Link> */}
                                                                                {data.type && data.type=="HOD"?
                                                                            <><span className="dep-team small-text">{data.emailid }</span>
                                                                            <span className="dep-team small-text">{data.contactno}</span></>:
                                                                            <></>}
                                                                            {/* <span className="dep-team small-text">{data.contactno}</span> */}

                                                                            {/* <ul className="our-team-social-links">

                                                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                                                    <Link to={"tel:"+data.contactno} target="_blank"><PhoneIcon />
                                                                                        <span><i></i></span>
                                                                                    </Link>
                                                                                </li>&nbsp;
                                                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                                                    <Link to={"mailto:"+data.emailid} target="_blank"><EmailIcon />
                                                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                                                    </Link>
                                                                                </li>&nbsp; */}
                                                                            {/* <li className="pbmit-social-li pbmit-social-linkedin">
                                                                                    <Link to={data.linkedin} target="_blank">
                                                                                        <span><i><LinkedInIcon /></i></span>
                                                                                    </Link>
                                                                                </li> </ul> */}
                                                                        </div></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>

                                            </div>)
                                        }
                                        {/* <div className="team-member-box">
                                            <!-- Slide1 -->

                                            <article className="pbmit-service-style-4">
                                                <div className="pbminfotech-post-item">
                                                    <div className="pbmit-featured-wrapper img-border">
                                                        <img src={team1} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                    </div>
                                                    <div className="pbminfotech-box-content">
                                                        <div className="pbminfotech-box-content-inner">
                                                            <div className="pbmit-service-wrap data-direction">
                                                                <h3 className="pbmit-service-title">
                                                                    <Link to="services-details.html">Cody Fisher</Link>
                                                                </h3>
                                                                <div className="pbmit-service-category">
                                                                    <span className="pbmit-service-cat">
                                                                        <Link className='member-tag' to="service.html" rel="tag">Developer</Link>
                                                                    </span>
                                                                </div>


                                                                <div className="Icons-centering">
                                                                    <div className="our-team-social-area">
                                                                        <ul className="our-team-social-links">

                                                                            <li className="pbmit-social-link pbmit-social-facebook">
                                                                                <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                                                    <span><i></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-twitter">
                                                                                <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                                                    <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-linkedin">
                                                                                <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                                                    <span><i><LinkedInIcon /></i></span>
                                                                                </Link>
                                                                            </li></ul></div></div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>

                                        <div className="team-member-box">
                                            <!-- Slide2 -->
                                            <article className="pbmit-service-style-4">
                                                <div className="pbminfotech-post-item">
                                                    <div className="pbmit-featured-wrapper img-border">
                                                        <img src={team2} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                    </div>
                                                    <div className="pbminfotech-box-content">
                                                        <div className="pbminfotech-box-content-inner">
                                                            <div className="pbmit-service-wrap data-direction">
                                                                <h3 className="pbmit-service-title">
                                                                    <Link to="services-details.html">Cody Fisher</Link>
                                                                </h3>
                                                                <div className="pbmit-service-category">
                                                                    <span className="pbmit-service-cat">
                                                                        <Link className='member-tag' to="service.html" rel="tag">Developer</Link>
                                                                    </span>
                                                                </div>
                                                                <div className="">
                                                                    <div className="our-team-social-area">
                                                                        <ul className="our-team-social-links">

                                                                            <li className="pbmit-social-link pbmit-social-facebook">
                                                                                <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                                                    <span><i></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-twitter">
                                                                                <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                                                    <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-linkedin">
                                                                                <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                                                    <span><i><LinkedInIcon /></i></span>
                                                                                </Link>
                                                                            </li></ul></div></div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>

                                        <div className="team-member-box">
                                            <!-- Slide3 -->
                                            <article className="pbmit-service-style-4">
                                                <div className="pbminfotech-post-item">
                                                    <div className="pbmit-featured-wrapper img-border">
                                                        <img src={team3} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                    </div>
                                                    <div className="pbminfotech-box-content">
                                                        <div className="pbminfotech-box-content-inner">
                                                            <div className="pbmit-service-wrap data-direction">
                                                                <h3 className="pbmit-service-title">
                                                                    <Link to="services-details.html">Cody Fish</Link>
                                                                </h3>
                                                                <div className="pbmit-service-category">
                                                                    <span className="pbmit-service-cat">
                                                                        <Link className='member-tag' to="service.html" rel="tag">Developer</Link>
                                                                    </span>
                                                                </div>
                                                                <div className="">
                                                                    <div className="our-team-social-area">
                                                                        <ul className="our-team-social-links">

                                                                            <li className="pbmit-social-link pbmit-social-facebook">
                                                                                <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                                                    <span><i></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-twitter">
                                                                                <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                                                    <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-linkedin">
                                                                                <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                                                    <span><i><LinkedInIcon /></i></span>
                                                                                </Link>
                                                                            </li></ul></div></div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
 
                                        <div className="team-member-box">
                                            <article className="pbmit-service-style-4">
                                                <div className="pbminfotech-post-item">
                                                    <div className="pbmit-featured-wrapper img-border">
                                                        <img src={team1} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                    </div>
                                                    <div className="pbminfotech-box-content">
                                                        <div className="pbminfotech-box-content-inner">
                                                            <div className="pbmit-service-wrap data-direction">
                                                                <h3 className="pbmit-service-title">
                                                                    <Link to="services-details.html">Cody Fisher</Link>
                                                                </h3>
                                                                <div className="pbmit-service-category">
                                                                    <span className="pbmit-service-cat">
                                                                        <Link className='member-tag' to="service.html" rel="tag">Developer</Link>
                                                                    </span>
                                                                </div>


                                                                <div className="Icons-centering">
                                                                    <div className="our-team-social-area">
                                                                        <ul className="our-team-social-links">

                                                                            <li className="pbmit-social-link pbmit-social-facebook">
                                                                                <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                                                    <span><i></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-twitter">
                                                                                <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                                                    <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-linkedin">
                                                                                <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                                                    <span><i><LinkedInIcon /></i></span>
                                                                                </Link>
                                                                            </li></ul></div></div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>

                                        <div className="team-member-box">
                                            <article className="pbmit-service-style-4">
                                                <div className="pbminfotech-post-item">
                                                    <div className="pbmit-featured-wrapper img-border">
                                                        <img src={team2} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                    </div>
                                                    <div className="pbminfotech-box-content">
                                                        <div className="pbminfotech-box-content-inner">
                                                            <div className="pbmit-service-wrap data-direction">
                                                                <h3 className="pbmit-service-title">
                                                                    <Link to="services-details.html">Cody Fisher</Link>
                                                                </h3>
                                                                <div className="pbmit-service-category">
                                                                    <span className="pbmit-service-cat">
                                                                        <Link className='member-tag' to="service.html" rel="tag">Developer</Link>
                                                                    </span>
                                                                </div>
                                                                <div className="">
                                                                    <div className="our-team-social-area">
                                                                        <ul className="our-team-social-links">

                                                                            <li className="pbmit-social-link pbmit-social-facebook">
                                                                                <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                                                    <span><i></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-twitter">
                                                                                <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                                                    <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-linkedin">
                                                                                <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                                                    <span><i><LinkedInIcon /></i></span>
                                                                                </Link>
                                                                            </li></ul></div></div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>

                                        <div className="team-member-box">
                                            <article className="pbmit-service-style-4">
                                                <div className="pbminfotech-post-item">
                                                    <div className="pbmit-featured-wrapper img-border">
                                                        <img src={team3} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                    </div>
                                                    <div className="pbminfotech-box-content">
                                                        <div className="pbminfotech-box-content-inner">
                                                            <div className="pbmit-service-wrap data-direction">
                                                                <h3 className="pbmit-service-title">
                                                                    <Link to="services-details.html">Cody Fish</Link>
                                                                </h3>
                                                                <div className="pbmit-service-category">
                                                                    <span className="pbmit-service-cat">
                                                                        <Link className='member-tag' to="service.html" rel="tag">Developer</Link>
                                                                    </span>
                                                                </div>
                                                                <div className="">
                                                                    <div className="our-team-social-area">
                                                                        <ul className="our-team-social-links">

                                                                            <li className="pbmit-social-link pbmit-social-facebook">
                                                                                <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                                                    <span><i></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-twitter">
                                                                                <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                                                    <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                                                </Link>
                                                                            </li>&nbsp;
                                                                            <li className="pbmit-social-li pbmit-social-linkedin">
                                                                                <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                                                    <span><i><LinkedInIcon /></i></span>
                                                                                </Link>
                                                                            </li></ul></div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default OurDepartmentTeam
