import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import image01 from '../../../Images_1/Activities/ResearchProjectsImages/image-01.jpg'
// import image02 from '../../../Images_1/Activities/ResearchProjectsImages/image-02.jpg'
// import image03 from '../../../Images_1/Activities/ResearchProjectsImages/image-03.jpg'
// import image04 from '../../../Images_1/Activities/ResearchProjectsImages/image-04.jpg'
// import image05 from '../../../Images_1/Activities/ResearchProjectsImages/image-05.jpg'
// import image06 from '../../../Images_1/Activities/ResearchProjectsImages/image-06.jpg'
// import image07 from '../../../Images_1/Activities/ResearchProjectsImages/image-07.jpg'
// import image08 from '../../../Images_1/Activities/ResearchProjectsImages/image-08.jpg'
// import image09 from '../../../Images_1/Activities/ResearchProjectsImages/image-09.jpg'
// import image10 from '../../../Images_1/Activities/ResearchProjectsImages/image-10.jpg'
// import image11 from '../../../Images_1/Activities/ResearchProjectsImages/image-11.jpg'
// import image12 from '../../../Images_1/Activities/ResearchProjectsImages/image-12.jpg'
// import image13 from '../../../Images_1/Activities/ResearchProjectsImages/image-13.jpg'
// import image14 from '../../../Images_1/Activities/ResearchProjectsImages/image-14.jpg'
// import image15 from '../../../Images_1/Activities/ResearchProjectsImages/image-15.jpg'
// import image16 from '../../../Images_1/Activities/ResearchProjectsImages/image-16.jpg'
// import image17 from '../../../Images_1/Activities/ResearchProjectsImages/image-17.jpg'
// import image18 from '../../../Images_1/Activities/ResearchProjectsImages/image-18.jpg'
import './ResearchProject.css'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';

function ResearchProjects() {

    const [selecteddiv, setSelecteddiv] = useState('div-1');
    const selectedStyle = { backgroundColor: "#7cba27", color: "white" };

    return (
        <div>
            <div>
                {/* Page Wrapper */}
                <div className="page-wrapper">
                    {/* Title Bar */}
                    <BreadCrumbs parent="Activities" child="Research And Development" parentPath="/"/>
                    {/* Title Bar End */}

                    {/* Page Content */}
                    <div className="page-content">

                        {/* Project Details */}
                        <section className="section-lgx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 activities-left-col order-2 order-lg-1">
                                        <aside className="activities-sidebar">
                                            <aside className="widget post-list">
                                                <h2 className="widget-title" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setSelecteddiv('maindiv');
                                                }}>Research Projects</h2>
                                                <div className="all-post-list">
                                                    <ul>
                                                    <li className="post-active">
                                                        <h3 className='sub-activities-title'>Oil and Gas Research</h3>
                                                        <Link to="#to_top"   style={selecteddiv == 'div-1' ? selectedStyle : {}}target="_parent"
                                                            onClick={() => {
                                                                setSelecteddiv('div-1');
                                                            }}>Petroleum Research Laboratory</Link></li>
                                                        <li><Link to="#to_top" style={selecteddiv == 'div-2' ? selectedStyle : {}}target="_parent"
                                                            onClick={() => {
                                                                setSelecteddiv('div-2');
                                                            }}>Data Interpretation Center</Link></li>
                                                    </ul>
                                                    <ul>
                                                        <h3 className='sub-activities-title'>Research Infrastructure</h3>
                                                        <li><Link to="#to_top" style={selecteddiv == 'div-3' ? selectedStyle : {}}target="_parent"
                                                            onClick={() => {
                                                                setSelecteddiv('div-3');
                                                            }}>Research and Development Group Activities</Link></li>
                                                    </ul>
                                                </div>
                                            </aside>

                                            <aside className="widget post-list">
                                                <h2 className="widget-title">R & D Infrastructure</h2>
                                                <div className="all-post-list">
                                                    <ul>
                                                        <h3 className='sub-activities-title'>Oil and Gas Research</h3>
                                                        <li className="post-active"><Link to="#to_top"style={selecteddiv == 'div-4' ? selectedStyle : {}} target="_parent"
                                                            onClick={() => {
                                                                setSelecteddiv('div-4');
                                                            }}>Petroleum Research Laboratory</Link></li>
                                                        <li><Link to="#to_top"style={selecteddiv == 'div-5' ? selectedStyle : {}} target="_parent"
                                                            onClick={() => {
                                                                setSelecteddiv('div-5');
                                                            }}>Data Interpretation Center</Link></li>
                                                    </ul>

                                                    <ul>
                                                        <h3 className='sub-activities-title'>Renewable Energy Research</h3>
                                                        <li><Link to="#to_top"style={selecteddiv == 'div-6' ? selectedStyle : {}} target="_parent"
                                                            onClick={() => {
                                                                setSelecteddiv('div-6');
                                                            }}>Solar Laboratory Facilities</Link></li>
                                                        <li><Link to="#to_top"style={selecteddiv == 'div-7' ? selectedStyle : {}} target="_parent"
                                                            onClick={() => {
                                                                setSelecteddiv('div-7');
                                                            }}>Environment Laboratory Facilities</Link></li>
                                                    </ul>
                                                </div>
                                            </aside>
                                        </aside>
                                    </div>

                                    <div id='to_top' className="col-lg-8 activities-right-col order-1">
                                        <div className='div-1' style={selecteddiv == 'div-1' ? { display: 'block' } : { display: 'none' }}>
                                            <h3 className="pbmit-title">
                                                Research Projects {">"} Oil and Gas Research {">"} Petroleum Research Laboratory
                                            </h3>
                                            <p>
                                                GERMI has a fully equipped petroleum research laboratory to carry out routine and advanced core analysis, source rock potential characterization, surface geochemical prospecting, coal characterization and crude oil mapping. The laboratory has a basic objective of serving as a centre of excellence in research and development in the field of oil and gas. The laboratory aims to address the challenges of meeting India’s growing demand for affordable oil and gas by conducting basic and applied research on hydrocarbon energy resources and economic impacts of their production and use.
                                            </p>
                                            <div className='activities-grid-div'>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image01} alt="img-1" /> */}
                                                    <p className='activities-text'>Development of Synthetic Combustion Fuel using Algae as a Resource</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image02} alt="img-1" /> */}
                                                    <p className='activities-text'>Surface geochemical prospecting for hydrocarbon exploration in parts of Cambay basin</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image03} alt="img-1" /> */}
                                                    <p className='activities-text'>Laboratory scale development of technology for algal fuel production</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image16} alt="img-1" /> */}
                                                    <p className='activities-text'>Integrated microbial and geochemical methodology for the detection hydrocarbon microseepage in petroliferous region</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image17} alt="img-1" /> */}
                                                    <p className='activities-text'>Geochemical analysis of lignite and shale samples of GMDC operated lignite coal mines</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image18} alt="img-1" /> */}
                                                    <p className='activities-text'>Trace metal anomalies associated with hydrocarbon seepage in Sanand East and Miroli area of Cambay basin</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='div-2' style={selecteddiv == 'div-2' ? { display: 'block' } : { display: 'none' }}>
                                            <h3 className="pbmit-title">
                                                Research Projects {">"} Oil and Gas Research {">"} Data Interpretation Center
                                            </h3>

                                            <p>
                                                The objective of this wing is to serve as a centre for excellence in research and development of increasingly efficient and well adopted technologies to extract, upgrade, manage and commercialize the oil and gas resources. The R & D involves geophysical and geological data interpretation. The center provides a unique environment that incubates multidisciplinary collaborations among GERMI scientists for developing and exploring new technologies for understanding of hydrocarbon plays.
                                            </p>

                                            <div className='activities-grid-div'>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image04} alt="img-1" /> */}
                                                    <p className='activities-text'>R&D Project with ONGC on Pore Pressure Prediction using Seismic Velocities & Reverse Time Migration</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image05} alt="img-1" /> */}
                                                    <p className='activities-text'>Workflow development for post stack data enhancement and fault and fracture analysis</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image06} alt="img-1" /> */}
                                                    <p className='activities-text'>Channels and thin sand delineation</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image07} alt="img-1" /> */}
                                                    <p className='activities-text'>Multi Attribute Analysis for delineation of channels</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image08} alt="img-1" /> */}
                                                    <p className='activities-text'>TOC prediction through core data, well logs and seismics</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image09} alt="img-1" /> */}
                                                    <p className='activities-text'>Texture attributes for reservoir characterization</p>    
                                                </div>
                                            </div>
                                        </div>

                                        <div className='div-3' style={selecteddiv == 'div-3' ? { display: 'block' } : { display: 'none' }}>
                                            <h3 className="pbmit-title">
                                                Research Projects {">"} Research Infrastructure {">"}Research and Development Group Activities
                                            </h3>

                                            <p>
                                                GERMI’s renewable energy research and development group is focused on doing fundamental and applied research for supporting the society, industry and government.
                                            </p>

                                            <div className='activities-grid-div'>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image10} alt="img-1" /> */}
                                                    <p className='activities-text'>Pilot demonstration cum performance assessment of 150 TR solar thermal AC project at administrative building of Gandhinagar thermal power station.</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image11} alt="img-1" /> */}
                                                    <p className='activities-text'>Design of Innovative Structures for Canal Top SPV</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image12} alt="img-1" /> */}
                                                    <p className='activities-text'>Micro grid implementation at CMPDI (A Coal India Ltd. Subsidiary)</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image13} alt="img-1" /> */}
                                                    <p className='activities-text'>Design of innovative space cooling technology through earth tube heat exchanger concept</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image14} alt="img-1" /> */}
                                                    <p className='activities-text'>Renewable energy demonstration for coastal regions of Gujarat</p>
                                                </div>
                                                <div className='activities-flex'>
                                                    {/* <img className='activities-image' src={image15} alt="img-1" /> */}
                                                    <p className='activities-text'>Fundamental research on graphene and 2D material for understanding its application in corrosion science.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='div-4' style={selecteddiv == 'div-4' ? { display: 'block' } : { display: 'none' }}>
                                            <h3 className="pbmit-title">
                                                R & D Infrastructure {">"} Oil and Gas Research {">"} Petroleum Research Laboratory
                                            </h3>

                                            <p>
                                                GERMI has a fully equipped petroleum research laboratory to carry out routine and advanced core analysis, source rock potential characterization, surface geochemical prospecting, coal characterization and crude oil mapping. The laboratory has a basic objective of serving as a centre of excellence in research and development in the field of oil and gas. The laboratory aims to address the challenges of meeting India’s growing demand for affordable oil and gas by conducting basic and applied research on hydrocarbon energy resources and economic impacts of their production and use.
                                            </p>
                                        </div>

                                        <div className='div-5' style={selecteddiv == 'div-5' ? { display: 'block' } : { display: 'none' }}>
                                            <h3 className="pbmit-title">
                                                R & D Infrastructure {">"} Oil and Gas Research {">"} Data Interpretation Center
                                            </h3>

                                            <p>
                                                GERMI has a fully equipped petroleum research laboratory to carry out routine and advanced core analysis, source rock potential characterization, surface geochemical prospecting, coal characterization and crude oil mapping. The laboratory has a basic objective of serving as a centre of excellence in research and development in the field of oil and gas. The laboratory aims to address the challenges of meeting India’s growing demand for affordable oil and gas by conducting basic and applied research on hydrocarbon energy resources and economic impacts of their production and use.
                                            </p>

                                            <img />
                                        </div>

                                        <div className='div-6' style={selecteddiv == 'div-6' ? { display: 'block' } : { display: 'none' }}>
                                            <h3 className="pbmit-title">
                                                R & D Infrastructure {">"} Renewable Energy Research {">"} Solar Laboratory Facilities
                                            </h3>

                                            <p>
                                                GERMI has a fully equipped petroleum research laboratory to carry out routine and advanced core analysis, source rock potential characterization, surface geochemical prospecting, coal characterization and crude oil mapping. The laboratory has a basic objective of serving as a centre of excellence in research and development in the field of oil and gas. The laboratory aims to address the challenges of meeting India’s growing demand for affordable oil and gas by conducting basic and applied research on hydrocarbon energy resources and economic impacts of their production and use.
                                            </p>
                                        </div>

                                        <div className='div-7' style={selecteddiv == 'div-7' ? { display: 'block' } : { display: 'none' }}>
                                            <h3 className="pbmit-title">
                                                R & D Infrastructure {">"} Renewable Energy Research {">"} Environment Laboratory Facilities
                                            </h3>

                                            <p>
                                                GERMI has a fully equipped petroleum research laboratory to carry out routine and advanced core analysis, source rock potential characterization, surface geochemical prospecting, coal characterization and crude oil mapping. The laboratory has a basic objective of serving as a centre of excellence in research and development in the field of oil and gas. The laboratory aims to address the challenges of meeting India’s growing demand for affordable oil and gas by conducting basic and applied research on hydrocarbon energy resources and economic impacts of their production and use.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Project Details End */}

                    </div>
                    {/* Page Content End */}

                </div>
                {/* Page Wrapper End */}
            </div >
        </div >
    )
}

export default ResearchProjects