import React from "react"
import "./EmployeeDetail.css"
import svg_dots from "../../../Images_1/CommonSection/Team/checkmark.svg"
import { Link } from "react-router-dom";
//import employee1 from "../../../Images_1/Teams/TeamDetailImages/teamSingle.png"
function EmployeeDetail(props) {
    // console.log(props.state)
    return (
        <>

            <div className="employee-main">

                <div className="employee-box col-xl-3">
                    <div className="emp-img-space shadow-nav">
                        <img className="eimg-size" src={process.env.PUBLIC_URL + props.state.imageSrc} />
                    </div>

                </div>

                <div className="employee-box col-xl-6">
                    <div className="margin-b-12">
                       
                        <p className="employee-name ">{props.state.name}</p>
                        <p className="employee-tagline ">{props.state.jobPost}</p>
                        <p className="employee-tagline">{props.state.jobPostA} </p>
                        <div className="singlePage aboutus-fonts">
                            {/* <p>{props.state.about}
                            </p> */}
                        </div>
                        <div className="mb-6 eflex items-center">
                            {/* <div className="row">
                                <div className="col-auto">
                                    <svg width="30" height="30" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" fill="" className="fill-[#E6FFB1] dark:fill-[#212220]"></rect>
                                        <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="" className="stroke-paragraph dark:stroke-primary"></rect>
                                        <path d="M20.9295 13.0312C20.4031 9.21192 16.8759 6.54067 13.0479 7.06591C9.21986 7.58741 6.54251 11.1103 7.06519 14.9296C7.49387 18.0473 9.94937 20.5047 13.0742 20.9399C13.3863 20.9812 13.6984 21 14.0143 21C15.2815 21.0037 16.5262 20.6586 17.6129 20.002C17.9326 19.8107 18.0378 19.3942 17.8423 19.0753C17.6505 18.7564 17.2331 18.6514 16.9135 18.8465C15.3454 19.7844 13.4202 19.9082 11.7468 19.1766C10.0735 18.445 8.85887 16.9481 8.4866 15.1622C8.11433 13.3764 8.63325 11.523 9.87792 10.1874C11.1226 8.85175 12.9388 8.2027 14.7513 8.44281C16.4322 8.66417 17.9213 9.62837 18.8087 11.069C19.6961 12.5097 19.8917 14.2693 19.3352 15.8676C19.1885 16.2577 18.8125 16.5091 18.3988 16.5016C17.8423 16.5016 17.3911 16.0514 17.3911 15.4961V11.3204C17.3911 10.949 17.0865 10.6451 16.7142 10.6451C16.3419 10.6451 16.0373 10.949 16.0373 11.3204V11.3279C14.9882 10.54 13.5781 10.4237 12.4199 11.0353C11.258 11.6468 10.5585 12.8736 10.6187 14.1793C10.6789 15.4886 11.4949 16.6404 12.7057 17.1432C13.9203 17.6421 15.3116 17.3983 16.2818 16.5166C16.6728 17.3307 17.4964 17.8485 18.3988 17.8522C19.3916 17.8635 20.2828 17.2445 20.6174 16.3103C20.9784 15.256 21.0875 14.1305 20.9295 13.0312ZM14.003 16.0476C13.1833 16.0476 12.4425 15.5524 12.1266 14.7983C11.8107 14.0404 11.9875 13.17 12.5666 12.5923C13.1456 12.0145 14.0218 11.8382 14.7776 12.1533C15.5372 12.4685 16.0298 13.2076 16.0298 14.0254C16.0336 15.1397 15.1236 16.0476 14.003 16.0476Z" fill="" className="fill-paragraph dark:fill-primary"></path>
                                    </svg>
                                </div>
                                <div className="col-auto">
                                    <a> {props.state.emailid}</a>
                                </div>
                            </div> */}



                        </div>
                        {/* <div className="mb-12 eflex items-center">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
                                <rect x="0.5" y="0.5" width="27.0034" height="27" rx="13.5" fill="" className="fill-[#E6FFB1] dark:fill-[#212220]"></rect>
                                <rect x="0.5" y="0.5" width="27.0034" height="27" rx="13.5" stroke="" className="stroke-paragraph dark:stroke-primary"></rect>
                                <path d="M8.43025 15.0039C8.89669 14.5374 9.51185 14.2805 10.1743 14.2805C10.8368 14.2805 11.452 14.5374 11.9184 15.0039L12.2699 15.3554C12.3578 15.4433 12.4727 15.4906 12.6012 15.4906C12.7296 15.4906 12.8445 15.4433 12.9324 15.3554L15.3593 12.9285C15.4471 12.8407 15.4945 12.7257 15.4945 12.5973C15.4945 12.4689 15.4471 12.3539 15.3593 12.2661L15.0077 11.9145C14.0478 10.9546 14.0478 9.38629 15.0077 8.42636L15.7108 7.72332C16.1772 7.25688 16.7991 7 17.4549 7C18.1174 7 18.7325 7.25688 19.199 7.72332L19.7262 8.2506C20.8416 9.35925 21.2472 11.0155 20.8619 12.9083C20.5036 14.6929 19.4829 16.4978 17.9889 17.9918C16.1029 19.8778 13.7098 21 11.5737 21C10.2284 21 9.07921 20.5606 8.24773 19.7291L7.72044 19.2018C6.76052 18.2419 6.76052 16.6736 7.72044 15.7137L8.43025 15.0039ZM8.80205 18.1202L9.32933 18.6475C9.87013 19.1883 10.6475 19.4722 11.5804 19.4722C13.3177 19.4722 15.3187 18.5123 16.9208 16.9102C18.2052 15.6258 19.0773 14.098 19.3747 12.6041C19.6519 11.2318 19.395 10.0623 18.6514 9.32545L18.1241 8.79817C17.9484 8.6224 17.7118 8.521 17.4549 8.521C17.198 8.521 16.9614 8.61564 16.7856 8.79817L16.0826 9.50121C15.7108 9.87301 15.7108 10.4746 16.0826 10.8397L16.4341 11.1912C16.8059 11.5698 17.0155 12.07 17.0155 12.5973C17.0155 13.1313 16.8127 13.6248 16.4341 14.0034L14.0073 16.4302C13.6355 16.802 13.1352 17.0116 12.6012 17.0116C12.0671 17.0116 11.5737 16.802 11.1951 16.4302L10.8436 16.0787C10.6678 15.9029 10.4312 15.8015 10.1743 15.8015C9.91745 15.8015 9.68085 15.8962 9.50509 16.0787L8.80205 16.7817C8.43025 17.1535 8.43025 17.7552 8.80205 18.1202Z" fill="" className="fill-paragraph dark:fill-primary"></path>
                            </svg>

                            <a href="tel:+393035000000"> {props.state.contactno}</a>
                            
                        </div> */}
                        <div className="team-detail-body">

                            {
                                // console.log(props.state.detail)
                                props.state.detail && props.state.detail.map(
                                    (aDetail) => (
                                        <p>{aDetail}</p>
                                    ))
                            }

                            <p>{props.state.headA}</p>
                            <ul className="team-ul">
                                {
                                    props.state.list && props.state.list.map(
                                        (aList) => (
                                            <li>
                                                <img className="eimg-liimage" src={svg_dots} />
                                                <p className=" litext">{aList} </p>
                                            </li>
                                        )
                                    )
                                }



                            </ul>

                            <p>{props.state.headB}</p>
                            <ul>
                                {
                                    props.state.listcompany && props.state.listcompany.map(
                                        (aList) => (
                                            <li>
                                                <img className="eimg-liimage" src={svg_dots} />
                                                <p className=" litext">{aList} </p>
                                            </li>
                                        )
                                    )
                                }
                            </ul>
                            <p>{props.state.detailB}</p>



                            <p>{props.state.headC}</p>
                            <p>{props.state.detailC}</p>
                        </div>

                    </div>
                </div>
            </div>

            {/* </div> */}
        </>
    );
}

export default EmployeeDetail;