import React from 'react'
import { Link } from 'react-router-dom'
// import team1 from '../../../Images_1/Teams/OurTeamImages/AccountsTeam/AbhimanyuGupta.jpg'
// import team2 from '../../../Images_1/Teams/OurTeamImages/AccountsTeam/viral.jpg'
// import team3 from '../../../Images_1/Teams/OurTeamImages/AccountsTeam/dushyantGohil.jpg'
import teamData from "../json/Team.json";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './OurTeam.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import BreadCrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/team.jpg'
import { BASE_URL_Link } from '../../../constants'
import OnScrollAnimation from '../../OnScrollAnimation'
function AccountTeams() {
    const navigate = useNavigate()
    return (
        <div>
            <div>
                <BreadCrumbs parent="Home" child="Finance Team" parentPath="/" imgPath={BreadCrumbImage} />
            </div>

            <section className="section-lgb tm-main">
                <div className="container-team pbmit-col-stretched-yes pbmit-col-right">
                    <div className="row main-div">
                        {/* <div className='heading-container' style={{ color: "#18181B" }}>
                            <h4 className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }} >Our Team</h4>
                            <h2 id="title1" className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }}>Accounts Team</h2>
                            <div className=' div-for-hr-animation'>
                                <hr id='hr1' className='animate-hr-tag-about-us' />
                                <hr id='hr2' className='animate-hr-tag-about-us' />
                            </div>
                        </div> */}
                        <OnScrollAnimation title1="at1" title2="at2" hr1="at3" hr2="at4" heading="Finance Team" subheading="" />

                        <div className='team-grid-div'>

                            {
                                teamData["account"] && teamData["account"].map((data, index) => {


                                    return (
                                        <div className="pbmit-service-style-4">
                                            <div className="pbminfotech-post-item team-block-alignment">
                                                <div className="pbmit-featured-wrapper img-border img-alignment">
                                                    <img 
                                                    // onClick={() =>

                                                        // window.open( "teamdetails/" + data.id + "/account/0/0")


                                                    // } 
                                                    
                                                    src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                    {/* <img src={team1} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                                </div>
                                                <div className='team-content-div'>
                                                    <div className='team-about-div'>
                                                        <h3 className='team-name-class twenty-sixteen-fonts'>{data.name}</h3>
                                                        <h4 className='team-desig-class small-text'>{data.jobPost}</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}






                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AccountTeams
