import React, { useState } from "react";

import { MdDateRange } from "react-icons/md";
import { Link } from "react-router-dom";

import FaqData from "../Pages/FaqData";
//import BreadCrumbImage from '../../Images_1/CommonSection/Braedcrumbs/publications.jpg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IndividualPublication from "../Resources/Publications/IndividualPublication";
import "../Resources/Publications/publication.json";
// import "../Resources/Publications/Publication.css";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import BreadCrumbImage from '../../Images_1/CommonSection/Braedcrumbs/gallery.jpg';

export default function FCRA() {
  const initialTabs = [2024,2023,2022,2021,2020, 2019, 2018, 2017]; // Initial years
  const [startIdx, setStartIdx] = useState(0); // Track the start index of visible tabs
  const tabsPerPage = 6; // Number of tabs per page
  const visibleTabs = initialTabs.slice(startIdx, startIdx + tabsPerPage); // Calculate visible tabs
  const [activeTab, setActiveTab] = useState(0);
  const [query, setQuery] = useState("");


  const handleNextClick = () => {
    if (startIdx + tabsPerPage < initialTabs.length) {
      setStartIdx(startIdx + tabsPerPage);
      setActiveTab(0); // Move to the next set of tabs
    }
  };

  const handlePrevClick = () => {
    if (startIdx - tabsPerPage >= 0) {
      setStartIdx(startIdx - tabsPerPage);
      setActiveTab(0); // Move to the previous set of tabs
    }
  };

  function search(theData) {
    let arr = [];
    if (query === "") return [];
    else {
      for (let i = 0; i < 11; i++) {
        const tmp = theData[i].filter((data, key) =>
          data["desc"].toString().toLowerCase().includes(query.toLowerCase())
          // console.log(data["desc"])
        )
        if (tmp.length > 0) {
          arr = arr.concat(tmp);
        }


      }
      // console.log(arr.length);
      return arr;

    }


  }

  function callPubli(theData) {
    return (
      <IndividualPublication
        year={""}
        srno={theData.srno}
        title={theData.title}
        desc={theData.desc}
        link={theData.link}
        extra={theData.extra}
      />);
  }
  function callPubli1(theData) {
    return (
      <IndividualPublication
        year={theData.year}
        srno={theData.srno}
        title={theData.title}
        desc={theData.desc}
        link={theData.link}
        extra={theData.extra}
      />);
  }

  return (
    <>
      {/* Title Bar  */}
      <BreadCrumbs parent="Home" child="FCRA" parentPath="/" imgPath={BreadCrumbImage} />




      {/* Title Bar End */}
      <div className="pb-main">


        <div className="Public-Hero-container">
          {/* <div className="Subhero-text-container">
          <p className="subhero-text">OUR PUBLICATIONS</p>
        </div> */}

          <div className='publication-heading heading-fonts'>
           
          </div>

         
        </div>

        <div className="tab-container">
          <button className="prev-btn" onClick={handlePrevClick}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" /></svg>
          </button>
          {visibleTabs.map((tab, index) => (
            <button
              key={index}
              className={`tab-btn normal-text ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </button>
          ))}
          <button className="next-btn" onClick={handleNextClick}>
            {/* <svg data-testid="ArrowForwardIosIcon"></svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" /></svg>
          </button>
        </div>

        <div style={query === "" ? { display: "none" } : {}}>
          <section className="Publication-Card-Container">
            <div className="Publication-card">
              <div className="Publication-card-content">
                <div className="Book-heading">Search Results for: {query} </div>
                {
                
                  search(FaqData).map(callPubli1)
                }
              </div>
            </div>
          </section>
        </div>

        <section className="Publication-Card-Container">
          <div className="Publication-card">
            <div className="Publication-card-content">
              <div className="Book-heading">FCRA</div>
              {FaqData[startIdx + activeTab].map(callPubli)}
            </div>
          </div>
        </section>


      </div>

    </>
  );
}



// import React, { useState } from "react";
// import "./FCRA.css";
// import { Link } from 'react-router-dom'
// import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
// import BreadCrumbImage from '../../Images_1/CommonSection/Braedcrumbs/gallery.jpg';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import OnScrollAnimation from "../OnScrollAnimation";

// const FCRA = () => {

//   let fcraLink = 'https://germi-public.s3.ap-south-1.amazonaws.com/FCRA/';

//   return (
//     <div>
//       <BreadCrumbs parent="Home" child="FCRA" parentPath='/' imgPath={BreadCrumbImage} />

//       <section className="fcra-section">
//       <OnScrollAnimation title1="c2" title2="c2" hr1="c3" hr2="c4" heading="Quaterly Reports" subheading="" />
//         <div className="fcra-main-div">
//           <div className="fcra-grid">
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + 'April%202020-%20June%202020.pdf'} target='_blank'>April 2020- June 2020<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + 'January%202020-March%202020.pdf'} target='_blank'>January 2020-March 2020<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + 'October%202019-December%202019.pdf'} target='_blank'>October 2019-December 2019<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class" to={fcraLink + '01072019%20TO%2030092019.pdf'} target='_blank'>July 2019-September 2019<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + '01042019%20TO%2030062019.pdf'} target='_blank'>April 2019-June 2019<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + '01012019%20TO%2031032019.pdf'} target='_blank'>January 2019-March 2019<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + '01102018%20TO%2031122018.pdf'} target='_blank'>October 2018-December 2018<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + '01072018%20TO%2030092018.pdf'} target='_blank'>July 2018-September 2018<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + '01042018%20TO%2030062018.pdf'} target='_blank'>April 2018-June 2018<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + '01012018%20TO%2031032018.pdf'} target='_blank'>October 2018-December 2018<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + '01102017%20TO%2031122017.pdf'} target='_blank'>October 2017-December 2017<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + '01072017%20TO%2030092017.pdf'} target='_blank'>July 2017-September 2017<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//             <div className="fcra-link-div">
//               <Link className="link-class static-small-text" to={fcraLink + '01042017%20TO%2030062017.pdf'} target='_blank'>April 2017-June 2017<NavigateNextIcon className="fcra-icon" fontSize="medium" /></Link>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default FCRA