import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import AboutImage1 from '../../../Images_1/Home/AboutImages/NTM.png'
import AboutImage2 from '../../../Images_1/Home/AboutImages/RM.png'
import AboutImage3 from '../../../Images_1/Home/AboutImages/PM.png'
import AboutImage4 from '../../../Images_1/Home/AboutImages/EM.png'
import AboutImage5 from '../../../Images_1/Home/AboutImages/PRM.png'
import { Link } from 'react-router-dom';
import './HomeAboutUs.css'
import { useDispatch, useSelector } from 'react-redux'
import OnScrollAnimation from '../../OnScrollAnimation';
import { useTheme } from '../../../ThemeContext';

function HomeAboutUs() {
    
    const { isNightMode } = useTheme();
    return (
        <div>
            <section className={isNightMode?'section-lgaboutus aboutus-bg-div':'aboutus-bg-div-dark'}>
                <div className='home-aboutus-main-div'>
                    {/* <div className='heading-container'>
                        <h4 id="title1" className='title-animation-about-us'>About Us</h4>
                        <div id="title2" className='title-animation-about-us'>"Comprising five dynamic departments, GERMI pioneers Energy Research and Consultancy across diverse disciplines, driving innovation and sustainable solutions for the future."</div>
                        <div className='div-for-hr-animation'>
                            <hr id='hr2' className='animate-hr-tag-about-us' />
                            <hr id='hr1' className='animate-hr-tag-about-us' />
                        </div>
                    </div> */}

                    <OnScrollAnimation title1="about1" title2="about2" hr1="about3" hr2="about4" heading="About Us" subheading="Comprising five dynamic departments, GERMI pioneers Energy Research and Consultancy across diverse disciplines, driving innovation and sustainable solutions for the future." />
                    <div className='home-aboutus-content-container-div'>
                        <div className='aboutus-block'>
                            <div className='home-aboutus-content-container'>
                                <div className="icon-box">
                                    <div className="home-aboutus-content-img icon-div-shadow">
                                        <Link to="departments/new-technology-management/5/0">
                                            <img src={AboutImage1} className="aboutus-icon" alt="dept-1" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="home-aboutus-text-container">
                                    <h2 className="aboutus-title normal-text"><Link to="departments/new-technology-management/5/0">New Technology Management</Link></h2>
                                    {/* <div className="pbmit-heading-desc">Vast experience in power evacu ation, land pro curement.</div> */}
                                </div>
                            </div>
                        </div>

                        <div className='aboutus-block'>
                            <div className='home-aboutus-content-container'>
                                <div className="icon-box">
                                    <div className="home-aboutus-content-img icon-div-shadow">
                                        <Link to="departments/renewable-management/5/0">
                                            <img src={AboutImage2} className="aboutus-icon" alt="dept-1" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="home-aboutus-text-container">
                                    <h2 className="aboutus-title normal-text"><Link to="departments/renewable-management/5/0">Renewables Management</Link></h2>
                                    {/* <div className="pbmit-heading-desc">Vast experience in power evacu ation, land pro curement.</div> */}
                                </div>
                            </div>
                        </div>

                        <div className='aboutus-block'>
                            <div className='home-aboutus-content-container'>
                                <div className="icon-box">
                                    <div className="home-aboutus-content-img icon-div-shadow">
                                        <Link to="departments/petroleum-management/5/0">
                                            <img src={AboutImage5} className="aboutus-icon" alt="dept-1" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="home-aboutus-text-container">
                                    <h2 className="aboutus-title normal-text"><Link to="departments/petroleum-management/5/0">Petroleum Management</Link></h2>
                                    {/* <div className="pbmit-heading-desc">Vast experience in power evacu ation, land pro curement.</div> */}
                                </div>
                            </div>
                        </div>
                        <div className='aboutus-block'>
                            <div className='home-aboutus-content-container'>
                                <div className="icon-box">
                                    <div className="home-aboutus-content-img icon-div-shadow">
                                        <Link to="departments/petroleum-research-management/5/0">
                                            <img src={AboutImage3} className="aboutus-icon" alt="dept-1" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="home-aboutus-text-container">
                                    <h2 className="aboutus-title normal-text"><Link to="departments/petroleum-research-management/5/0">Petroleum Research Management</Link></h2>
                                    {/* <div className="pbmit-heading-desc">Vast experience in power evacu ation, land pro curement.</div> */}
                                </div>
                            </div>
                        </div>
                        <div className='aboutus-block'>
                            <div className='home-aboutus-content-container'>
                                <div className="icon-box">
                                    <div className="home-aboutus-content-img icon-div-shadow">
                                        <Link to="departments/environment-management/5/0">
                                            <img src={AboutImage4} className="aboutus-icon" alt="dept-1" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="home-aboutus-text-container">
                                    <h2 className="aboutus-title normal-text"><Link to="departments/environment-management/5/0">Environment Management</Link></h2>
                                    {/* <div className="pbmit-heading-desc">Vast experience in power evacu ation, land pro curement.</div> */}
                                </div>
                            </div>
                        </div>

                      
                        {/* <div className='aboutus-block'>
                            <div className='home-aboutus-content-container'>
                                <div className="icon-box">
                                    <div className="home-aboutus-content-img icon-div-shadow">
                                        <img src={AboutImage6} className="aboutus-icon" alt="dept-1" />
                                    </div>
                                </div>
                                <div className="home-aboutus-text-container">
                                    <h2 className="aboutus-title">HR & Admin Finance</h2>
                                   
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomeAboutUs;