import React, { useEffect, useState } from "react";
// import FusePageCarded from "@fuse/core/FusePageCarded/FusePageCarded";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RingLoader } from "react-spinners";
import CircularProgress from "@mui/material/CircularProgress";
import "dayjs/locale/de";
import {
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Select,
  MenuItem,
  TextField,
  TableBody,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
  Box,
} from "@mui/material";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
// import { showMessage } from "app/store/fuse/messageSlice";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import BreadCrumbImage from "../../Images_1/CommonSection/Braedcrumbs/EM.jpg";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IosShareIcon from "@mui/icons-material/IosShare";

// import { IosShareIcon, RestartAltIcon, CloudUploadIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
// import FuseUtils from "@fuse/utils/FuseUtils";
import { EditorState } from "draft-js";
// import RichTextEditor from "app/shared-components/RichTextEditor";
import { lighten } from "@mui/system";
import WYSIWYGEditor from "../shared-components/WYSIWYGEditor";
// import { addCurrentOpening } from "./store/addCurrentOpeningSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "./SampleAnalysisForm.css";
// import { addapplication } from "./store/AddApplication";
import dayjs from "dayjs";
import { blue } from "@mui/material/colors";
import { addSampleAnalysis } from "./store/AddSampleAnalysis";
import Loader from "../../Loader";

const SampleAnalysisForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buttonSx = {
    ...{
      bgcolor: blue[700],
      "&:hover": {
        bgcolor: blue[800],
      },
    },
  };

  // const location = useLocation();
  // const openingData = location.state;

  const [loadingState, setLoadingState] = useState(false);

  // const [clerification, setClerification] = useState(false);
  const [clerification, setClerification] = useState(true);


  const defaultValues = {

    mobileNo: "",
    name: "",
    organization: "",
    emailId: "",
    address: "",
    currentCity: "",
    currentState: "",
    purpose: "",
    sampleDetails: "",
    sampleType: "",
    sampleSource: "",
    sampleLocation: "",
    sampleNumbers: "",
    reports: undefined,
    testParameters: "",
    remarks: undefined,
    sampletype: "",
    dateOfCollection: undefined,
    timeOfCollection: undefined,
    submittedSampleNumbers: "",
    sampleQuantity: "",
    sampleCondition: "",
    submittedBy: "",
    billingName: "",
    billingMobileNo: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingEmailId: "",
    billingAmount: "",
    modeOfPayment: "",
    reportingName: "",
    reportingMobileNo: "",
    designation: "",
    reportingEmailId: "",
    reportingAddress: "",
    reportingCity: "",
    reportingState: "",
  };

  const validationSchema = Yup.object().shape({

    mobileNo: Yup.string().matches(/^[0-9]{10}$/, 'Phone number is not valid'),
    name: Yup.string().required("Name is required"),
    organization: Yup.string().required("Organization is required"),
    emailId: Yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email is not valid'),
    address: Yup.string().required("Address is required"),
    currentCity: Yup.string().required("Current city is required"),
    currentState: Yup.string().required("Current state is required"),
    purpose: Yup.string().required("Purpose is required"),
    sampleDetails: Yup.string().required("Sample Details is required"),
    sampleType: Yup.string().required("Sample Type is required"),
    sampleSource: Yup.string().required("Sample Source is required"),
    sampleLocation: Yup.string().required("Sample Location is required"),
    sampleNumbers: Yup.string().required("Sample Numbers is required"),
    reports: Yup.string().required("Reports is required"),
    testParameters: Yup.string().required("Test Parameters is required"),
    remarks: Yup.string().required("Remarks is required"),
    dateOfCollection: Yup.date().required("Date of Collection is required"),
    submittedSampleNumbers: Yup.string().required("Sample Numbers is required"),
    sampleQuantity: Yup.string().required("Sample Quantity is required"),
    sampleCondition: Yup.string().required("Sample Condition is required"),
    submittedBy: Yup.string().required("Submitted By is required"),
    billingName: Yup.string().required("Billing Name is required"),
    billingMobileNo: Yup.string().matches(/^[0-9]{10}$/, 'Billing Mobile number is not valid'),
    billingAddress: Yup.string().required("Billing Address required"),
    billingCity: Yup.string().required("Billing City is required"),
    billingState: Yup.string().required("Billing State is required"),
    billingEmailId: Yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Billing Email is not valid'),
    billingAmount: Yup.string().required("Billing Amount is required"),
    modeOfPayment: Yup.string().required("Payment Mode is required"),
    reportingName: Yup.string().required("Reporting Name is required"),
    reportingMobileNo: Yup.string().matches(/^[0-9]{10}$/, 'Reporting Mobile number is not valid'),
    designation: Yup.string().required("Designation is required"),
    reportingEmailId: Yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Reporting Email is not valid'),
    reportingAddress: Yup.string().required("Reporting Address is required"),
    reportingCity: Yup.string().required("Reporting City is required"),
    reportingState: Yup.string().required("Reporting State is required"),
  });

  const { handleSubmit, setValue, control, reset, watch, formState } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = useState(false);
  const { isValid, dirtyFields, errors } = formState;

  const [selectedDobFile, setSelectedDobFile] = React.useState(null);

  const handleFileChangeDob = (event) => {
    setSelectedDobFile(event.target.files[0]);
    // setValue("dobProofFile", event.target.files[0].name);
  };

  // Here is On Submit Function for Button

  const onSubmit = (data) => {
    console.log(data)
    setLoadingState(true);
    setLoading(true)
    var today = new Date();
    var birthDate = new Date(data.dateOfCollection);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    const formData = new FormData();

    formData.append("mobile", data.mobileNo);
    formData.append("name", data.name);
    formData.append("organization", data.organization);
    formData.append("email", data.emailId);
    formData.append("address", data.address);
    formData.append("city", data.currentCity);
    formData.append("state", data.currentState);
    formData.append("purpose", data.purpose);
    formData.append("sampledetail", data.sampleDetails);
    formData.append("sampletype", data.sampleType);
    formData.append("smaplesource", data.sampleSource);
    formData.append("samplelocation", data.sampleLocation);
    formData.append("noofsample", data.sampleNumbers);
    // formData.append("reportrequiredon", data.reports);
    const reports = dayjs(data.reports);
    formData.append(
      "reportrequiredon",
      `${reports.$y}-${(reports.$M + 1)
        .toString()
        .padStart(2, "0")}-${reports.$D.toString().padStart(2, "0")}`
    );
    formData.append("testparameters", data.testParameters);
    formData.append("remarks", data.remarks);
    const dateOfCollection = dayjs(data.dateOfCollection);
    formData.append(
      "dateofcollection",
      `${dateOfCollection.$y}-${(dateOfCollection.$M + 1)
        .toString()
        .padStart(2, "0")}-${dateOfCollection.$D.toString().padStart(2, "0")}`
    );
    formData.append("timeofcollection", dayjs(data.timeOfCollection).format("hh:mm A"));
    formData.append("snoofsample", data.submittedSampleNumbers);
    formData.append("sampleqty", data.sampleQuantity);
    formData.append("samplecondition", data.sampleCondition);
    formData.append("submittedby", data.submittedBy);
    formData.append("billingname", data.billingName);
    formData.append("billingmobile", data.billingMobileNo);
    formData.append("billingaddress", data.billingAddress);
    formData.append("billingcity", data.billingCity);
    formData.append("billingstate", data.billingState);
    formData.append("billingemail", data.billingEmailId);
    formData.append("billingamount", data.billingAmount);
    formData.append("modeofpayment", data.modeOfPayment);
    formData.append("reportingname", data.reportingName);
    formData.append("reportingmobile", data.reportingMobileNo);
    formData.append("designation", data.designation);
    formData.append("reportingemail", data.reportingEmailId);
    formData.append("reportingaddress", data.reportingAddress);
    formData.append("reportingcity", data.reportingCity);
    formData.append("reportingstate", data.reportingState);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    var json = JSON.stringify(object);
    console.log(json);
    dispatch(addSampleAnalysis(json)).then((data) => {
      setLoading(false)
      console.log(data);
      reset();
      // window.close();
    });
    setLoadingState(false);
  };

  const handleClearInputs = (e) => {
    e.preventDefault();
    reset();
  };

  return (

    <div>
       {loading ? <Loader loading={loading} />:<></>}
      <BreadCrumbs
        parent="Home"
        child="Environment Management"
        parentPath="/"
        imgPath={BreadCrumbImage}
      />

      <div className="saf-heading">
        <h5>CUSTOMER'S REQUEST FOR SERVICES</h5>
        <h4>Enquiry form from customer related to sample analysis</h4>
        <p>
          Complete all parts of this form to ensure your sample is accepted for
          testing. See reverse side for Collection and shipping instructions.
        </p>
      </div>

      <div
        className="m-40"
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <form style={{ width: "85%", alignSelf: "center" }}>
          <div>
            <Box
              sx={{
                width: "100%",
                borderRadius: 1,
                bgcolor: "#EEEEEE",
                padding: "20px",
              }}
            >
              <div className="text-salary saf-pt-10 ">Requested by</div>
              <div className="flex mb-10">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.name}
                      required
                      helperText={errors?.name?.message}
                      label="Name"
                      // autoFocus
                      id="name"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />
                <Controller
  name="mobileNo"
  control={control}
  render={({ field }) => (
    <TextField
      {...field}
      className="mb-5 w-full"
      error={!!errors.mobileNo}
      required
      helperText={errors?.mobileNo?.message}
      label="Mobile No."
      id="mobileNo"
      variant="outlined"
      inputProps={{
        maxLength: 10,
        pattern: "[0-9]*", // This allows only numeric input
        inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
      }}
      onInput={(e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace any non-numeric character
      }}
      style={{ width: "48%" }}
    />
  )}
/>
                {/* <Controller
                  name="mobileNo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className=" mb-5 w-full"
                      error={!!errors.mobileNo}
                      required
                      maxLength="10"
                      helperText={errors?.mobileNo?.message}
                      label="Mobile No."
                      // autoFocus
                      id="mobileNo"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      style={{ width: "48%" }}
                    />
                  )}
                /> */}


              </div>

              <div className="flex">
                <Controller
                  name="organization"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.organization}
                      required
                      helperText={errors?.organization?.message}
                      label="Organization"
                      // autoFocus
                      id="organization"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />

                <Controller
                  name="emailId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.emailId}
                      required
                      helperText={errors?.emailId?.message}
                      label="Email ID"
                      // autoFocus
                      id="emailId"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />
              </div>

              <div className="flex mb-10">
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.address}
                      required
                      helperText={errors?.address?.message}
                      label="Address (with pincode)"
                      // autoFocus
                      id="address"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />
                <div style={{ width: "48%" }} className="flex mb-10">
                  <Controller
                    name="currentCity"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-5 w-full"
                        error={!!errors.currentCity}
                        required
                        helperText={errors?.currentCity?.message}
                        label="Current City"
                        // autoFocus
                        id="currentCity"
                        variant="outlined"
                        style={{ width: "48%" }}
                      />
                    )}
                  />
                  <Controller
                    name="currentState"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="w-full"
                        error={!!errors.currentState}
                        required
                        helperText={errors?.currentState?.message}
                        label="Current State"
                        // autoFocus
                        id="currentState"
                        variant="outlined"
                        style={{ width: "48%" }}
                      />
                    )}
                  />
                </div>
              </div>
            </Box>

            <Box
              sx={{
                width: "100%",
                borderRadius: 1,
                bgcolor: "#EEEEEE",
                padding: "20px",
                marginTop: "20px",
              }}
            >
              <div className="text-salary saf-pt-10">Sample Information</div>
              <div className="flex mb-10">
                <Controller
                  name="purpose"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.purpose}
                      required
                      helperText={errors?.purpose?.message}
                      label="Purpose"
                      // autoFocus
                      id="purpose"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />
                <Controller
                  name="sampleDetails"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.sampleDetails}
                      required
                      helperText={errors?.sampleDetails?.message}
                      label="Sample Details"
                      // autoFocus
                      id="sampleDetails"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />

                <Controller
                  name="sampleType"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.sampleType}
                      required
                      helperText={errors?.sampleType?.message}
                      label="Sample Type"
                      // autoFocus
                      id="sampleType"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />
              </div>

              <div className="flex mt-5 mb-10">
                <Controller
                  name="sampleSource"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.sampleSource}
                      required
                      helperText={errors?.sampleSource?.message}
                      label="Sample Source"
                      // autoFocus
                      id="sampleSource"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />

                <Controller
                  name="sampleLocation"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.sampleLocation}
                      required
                      helperText={errors?.sampleLocation?.message}
                      label="Sampling Location"
                      // autoFocus
                      id="sampleLocation"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />

                <Controller
                  name="sampleNumbers"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.sampleNumbers}
                      required
                      helperText={errors?.sampleNumbers?.message}
                      label="Number of Samples"
                      // autoFocus
                      id="sampleNumbers"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />
              </div>

              <div className="flex mt-5 mb-10">
                <Controller
                  name="reports"
                  control={control}
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en"
                    >
                      <DatePicker
                        {...field}
                        className="mb-2 saf-date-width"
                        error={!!errors.reports}
                        required
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            helperText: errors?.reports?.message,
                          },
                        }}
                        label="Reports Required on"
                        // autoFocus
                        id="reports"
                        variant="outlined"
                        style={{ width: "30%" }}
                      />
                    </LocalizationProvider>
                  )}
                />

                {/* <Controller
                  name="reports"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.reports}
                      required
                      helperText={errors?.reports?.message}
                      label="Reports Required on"
                      // autoFocus
                      id="reports"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                /> */}

                <Controller
                  name="testParameters"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.testParameters}
                      required
                      helperText={errors?.testParameters?.message}
                      label="Test Parameters"
                      // autoFocus
                      id="testParameters"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />

                <Controller
                  name="remarks"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.remarks}
                      required
                      helperText={errors?.remarks?.message}
                      label="Remarks"
                      // autoFocus
                      id="remarks"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: "100%",
                borderRadius: 1,
                bgcolor: "#EEEEEE",
                padding: "20px",
                marginTop: "20px",
              }}
            >
              <div className="text-salary saf-pt-10">
                If Sample is submitted to the Laboratory
              </div>

              <div className="flex mb-10">


                <Controller
                  name="dateOfCollection"
                  control={control}

                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en"
                    >
                      <DatePicker
                        {...field}
                        className="saf-date-width"
                        error={!!errors.dateOfCollection}
                        required
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            helperText: errors?.dateOfCollection?.message,
                          },
                        }}
                        label="Date of Collection"
                        // autoFocus
                        id="dateOfCollection"
                        variant="outlined"
                        style={{ width: "30%" }}
                      />
                    </LocalizationProvider>
                  )}
                />



                <Controller
                  name="timeOfCollection"
                  control={control}
                  // defaultValue="12:00:00" // Set default value here
                  render={({ field }) => {
                    // Initialize field.value if it's not already set

                    return (
                      <FormControl >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['TimePicker']}>
                            <TimePicker
                              id="timeOfCollection"
                              fullWidth
                              {...field}
                              variant="outlined"
                              className="w-full"
                              label="Time of Collection"
                              error={!!errors.timeOfCollection}
                              style={{ width: "50%", paddingTop: '0px' }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <FormHelperText>
                          {errors?.timeOfCollection?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />


                <Controller
                  name="submittedSampleNumbers"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-full"
                      error={!!errors.submittedSampleNumbers}
                      required
                      helperText={errors?.submittedSampleNumbers?.message}
                      label="Number of Samples"
                      // autoFocus
                      id="submittedSampleNumbers"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />
              </div>

              <div className="flex mt-5 mb-10">
                <Controller
                  name="sampleQuantity"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.sampleQuantity}
                      required
                      helperText={errors?.sampleQuantity?.message}
                      label="Sample Quantity"
                      // autoFocus
                      id="sampleQuantity"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />

                <Controller
                  name="sampleCondition"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.sampleCondition}
                      required
                      helperText={errors?.sampleCondition?.message}
                      label="Sample Condition"
                      // autoFocus
                      id="sampleCondition"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />

                <Controller
                  name="submittedBy"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.submittedBy}
                      required
                      helperText={errors?.submittedBy?.message}
                      label="Submitted by"
                      // autoFocus
                      id="submittedBy"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: "100%",
                borderRadius: 1,
                bgcolor: "#EEEEEE",
                padding: "20px",
                marginTop: "20px",
              }}
            >
              <div className="text-salary saf-pt-10">Billing Details</div>

              <div className="flex mb-10">
                <Controller
                  name="billingName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.billingName}
                      required
                      helperText={errors?.billingName?.message}
                      label="Billing Name"
                      // autoFocus
                      id="billingName"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />

                <Controller
                  name="billingMobileNo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className=" mb-5 w-full"
                      error={!!errors.billingMobileNo}
                      required
                      helperText={errors?.billingMobileNo?.message}
                      label="Mobile No."
                      // autoFocus
                      id="billingMobileNo"
                      variant="outlined"
                      style={{ width: "48%" }}
                      inputProps={{
                        maxLength: 10,
                        pattern: "[0-9]*", // This allows only numeric input
                        inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace any non-numeric character
                      }}
                    />
                  )}
                />
              </div>

              <div className="flex mb-10">
                <Controller
                  name="billingAddress"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.billingAddress}
                      required
                      helperText={errors?.billingAddress?.message}
                      label="Billing Address (with pincode)"
                      // autoFocus
                      id="billingAddress"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />
                <div style={{ width: "48%" }} className="flex mb-10">
                  <Controller
                    name="billingCity"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-5 w-full"
                        error={!!errors.billingCity}
                        required
                        helperText={errors?.billingCity?.message}
                        label="Billing City"
                        // autoFocus
                        id="billingCity"
                        variant="outlined"
                        style={{ width: "48%" }}
                      />
                    )}
                  />
                  <Controller
                    name="billingState"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-5 w-full"
                        error={!!errors.billingState}
                        required
                        helperText={errors?.billingState?.message}
                        label="Billing State"
                        // autoFocus
                        id="billingState"
                        variant="outlined"
                        style={{ width: "48%" }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="flex ">
                <Controller
                  name="billingEmailId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.billingEmailId}
                      required
                      helperText={errors?.billingEmailId?.message}
                      label="Billing Email ID"
                      // autoFocus
                      id="billingEmailId"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />

                <Controller
                  name="billingAmount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-2 w-full"
                      error={!!errors.billingAmount}
                      required
                      type="number"
                      helperText={errors?.billingAmount?.message}
                      label="Billling Amount"
                      // autoFocus
                      id="billingAmount"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />

                <Controller
                  name="modeOfPayment"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-full"
                      error={!!errors.modeOfPayment}
                      required
                      helperText={errors?.modeOfPayment?.message}
                      label="Mode of Payment"
                      // autoFocus
                      id="modeOfPayment"
                      variant="outlined"
                      style={{ width: "30%" }}
                    />
                  )}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: "100%",
                borderRadius: 1,
                bgcolor: "#EEEEEE",
                padding: "20px",
                marginTop: "20px",
              }}
            >
              <div className="text-salary saf-pt-10">
                Reporting Details
              </div>

              <div className="flex mb-10">
                <Controller
                  name="reportingName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.reportingName}
                      required
                      helperText={errors?.reportingName?.message}
                      label="Reporting Name"
                      // autoFocus
                      id="reportingName"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />

                <Controller
                  name="reportingMobileNo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className=" mb-5 w-full"
                      error={!!errors.reportingMobileNo}
                      required
                      helperText={errors?.reportingMobileNo?.message}
                      label="Reporting Mobile No."
                      // autoFocus
                      id="reportingMobileNo"
                      variant="outlined"
                      style={{ width: "48%" }}
                      inputProps={{
                        maxLength: 10,
                        pattern: "[0-9]*", // This allows only numeric input
                        inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace any non-numeric character
                      }}
                    />
                  )}
                />
              </div>

              <div className="flex mb-10">
                <Controller
                  name="designation"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.designation}
                      required
                      helperText={errors?.designation?.message}
                      label="Designation"
                      // autoFocus
                      id="designation"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />

                <Controller
                  name="reportingEmailId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.reportingEmailId}
                      required
                      helperText={errors?.reportingEmailId?.message}
                      label="Reporting Email ID"
                      // autoFocus
                      id="reportingEmailId"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />
              </div>

              <div className="flex mb-10">
                <Controller
                  name="reportingAddress"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-5 w-full"
                      error={!!errors.reportingAddress}
                      required
                      helperText={errors?.reportingAddress?.message}
                      label="Reporting Address (with pincode)"
                      // autoFocus
                      id="reportingAddress"
                      variant="outlined"
                      style={{ width: "48%" }}
                    />
                  )}
                />
                <div style={{ width: "48%" }} className="flex mb-10">
                  <Controller
                    name="reportingCity"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-5 w-full"
                        error={!!errors.reportingCity}
                        required
                        helperText={errors?.reportingCity?.message}
                        label="Reporting City"
                        // autoFocus
                        id="reportingCity"
                        variant="outlined"
                        style={{ width: "48%" }}
                      />
                    )}
                  />
                  <Controller
                    name="reportingState"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-5 w-full"
                        error={!!errors.reportingState}
                        required
                        helperText={errors?.reportingState?.message}
                        label="Reporting State"
                        // autoFocus
                        id="reportingState"
                        variant="outlined"
                        style={{ width: "48%" }}
                      />
                    )}
                  />
                </div>
              </div>
            </Box>

            {/* <div
              className="flex mb-10"
              style={{ justifyContent: "flex-start" }}
            >
              <Controller
                name="clerification"
                control={control}
                render={({ field }) => (
                  <FormGroup
                    {...field}
                    style={{ marginLeft: "10px", width: "fit-content" }}
                  >
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          id="clerification"
                          checked={clerification}
                          onChange={(value) => {
                            setClerification(value.target.checked);
                          }}
                        />
                      }
                    />
                  </FormGroup>
                )}
              />
              <div className=" saf-pr saf-declare-styling">
                I hereby certify that the above information are true and correct
                to the best of my knowledge and in case of any discrepancy, the
                application will be disqualified.{" "}
              </div>
            </div> */}

            <div
              className="flex mb-10"
              style={{
                justifyContent: "flex-start",
                marginBottom: "20px",
                marginTop: "20px",
                gap: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={buttonSx}
                disabled={loadingState || !clerification}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
              {loadingState && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: blue[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              {/* {loadingState ? <RingLoader color="#7cba27" /> : <Button variant="contained" disabled={!clerification} component="label" endIcon={<IosShareIcon />} style={{ marginLeft: "10px" }}>
                                Submit
                                <input
                                hidden
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                // onChange={handleFileChange}
                                />
                            </Button>}
                            <div style={{ width: "2%" }} /> */}
              {/* <Button
                variant="contained"
                component="label"
                endIcon={<RestartAltIcon />}
              >
                Reset
                <input
                  hidden
                  type="reset"
                // onChange={handleFileChange}
                />
              </Button> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SampleAnalysisForm;
// http://43.204.72.146/

// {
//     "mobile":"8956452156",
//     "name":"test",
//     "organization":"test",
//     "email":"test@gmil.com",
//     "address":"test",
//     "city":"test",
//     "state":"test",
//     "purpose":"test",
//     "sampledetail":"test",
//     "sampletype":"test",
//     "smaplesource":"test",
//     "samplelocation":"test",
//     "noofsample":"2",
//     "reportrequiredon":"2024-03-01",
//     "testparameters":"test",
//     "remarks":"test",
//     "dateofcollection":"2024-03-01",
//     "timeofcollection":"02:10:00",
//     "submittedby":"test",
//     "billingname":"test",
//     "billingmobile":"9632587452",
//     "billingaddress":"test",
//     "billingcity":"test",
//     "billingamount":"852",
//     "modeofpayment":"test",
//     "reportingname":"test",
//     "reportingmobile":"4512586325",
//     "designation":"test",
//     "reportingcity":"test",
//     "reportingstate":"test"
//  }
