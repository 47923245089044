import React from 'react'

function TimelineData(props) {
    const date = props.date;
    const desc = props.desc;
    return (
        <>

            <div className="tm-inner">
                <div className="tm-date">{date}</div>
                <div className="tm-inner-content">
                    <p className='normal-text-fast-change'>
                        {desc}
                    </p>
                </div>
            </div>

        </>
    )
}

export default TimelineData
