import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';


import Services from '../Services/Services'
import Experience from "./Experience/Experience";
import Vision from "./Vision/Vision";
import Timeline from "./Timeline/Timeline";
import "./Experience/Experience.css";
import "./AboutUs.css";
// import MissionImg from "../../Images_1/AboutUs/VisionImages/mission.png";
// import VisionImg from "../../Images_1/AboutUs/VisionImages/vision.png";
// import ValuesImg from "../../Images_1/AboutUs/VisionImages/value.png";
import ReasonToChoose from "./ReasonToChoose/ReasonToChoose";
import Employee_Detail from "./EmployeeDetail/EmployeeDetail";
import AboutCompany from "./AboutCompany/AboutCompany";
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import BreadCrumbImage from '../../Images_1/CommonSection/Braedcrumbs/About-Us.png'
import OrganizationChart from "./OrganizationChart/OrganizationChart";
import ManagementTeams from "../Our Team/OurTeam/ManagementTeam";

function AboutUs_2() {
  return (
    <>
    
      <div className="aboutus-fonts abt-main">
     
        {/* Title Bar  */}
        <BreadCrumbs parent="Home" child="About Us" parentPath="/" imgPath={BreadCrumbImage} />
        {/* Title Bar End */}

        {/* About Company Start */}
        <section >
          <AboutCompany />
        </section>

        {/* About Company End */}

        {/* Our Department Start*/}
        {/* <section className="asection-spacing">
          <div className="container">
            <div className="text-center">
              <h2 className="pbmit-title aboutus-heading-fonts">Our Departments</h2>
            </div>
          </div>
          <div className="department_container">
            <Services />
          </div>
        </section> */}
        {/* Our Department End*/}

        {/*Employee Detail Start */}
        {/* <section className="anEmployee asection-spacing ebottom-border">
          <div className="effect-container stack-lower">
            <div className="circle-effect"></div>
            <div className="circle-effect"></div>
            <div className="circle-effect"></div>
          </div>
          <div className="stack-upper">
            <Employee_Detail />
          </div>
        </section> */}
        {/* Employee Detail End */}

        {/* Mission, Vission, Value Start */}
        <section>
          <Vision />
        </section>
        {/* Mission, Vission, Value End */}

        {/* Organization Chart Start */}
        {/* <section>
          <OrganizationChart />
        </section> */}
        {/* Organization Chart End */}

        {/* Reason to Choose Start */}
        {/* <section className="section-lg asection-spacing" >
          <div className="container">
            <div className="text-center">
              <h2 className="pbmit-title aboutus-heading-fonts">Main Reasons To Choose Us</h2>
            </div>
            <div className="effect-container">
              <div className="circle-effect"></div>
              <div className="circle-effect"></div>
              <div className="circle-effect"></div>
            </div>
            <ReasonToChoose />
          </div>
        </section> */}
        {/* Reason to Choose End */}

        {/* Experience & Achievement Start */}
        {/* <section>
          <Experience />
        </section> */}
        {/* Experience & Achievement End */}

        {/* Timeline Start */}
        <section className="asection-spacing">
          <Timeline />
        </section>

        {/* <section>
          <ManagementTeams />
        </section> */}
        {/* Timeline End */}
      </div>
    </>
  );
}

export default AboutUs_2;
