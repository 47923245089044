import React, { useState } from "react";
// import MissionImage from '../../../Images_1/AboutUs/VisionImages/mission_image.jpg';
// import values from "../../../Images_1/AboutUs/VisionImages/value.png";
import MissionImage from '../../../Images_1/AboutUs/AboutCompanyImages/VisionImages/mission.jpg'
import VisionImage from '../../../Images_1/AboutUs/AboutCompanyImages/VisionImages/vision.jpg'
import VisionImage1 from '../../../Images_1/AboutUs/AboutCompanyImages/VisionImages/bg-1.jpg'
import VisionImage1_1 from '../../../Images_1/AboutUs/AboutCompanyImages/VisionImages/bg-1-2.jpg'
import MissionImage2 from '../../../Images_1/AboutUs/AboutCompanyImages/VisionImages/bg-2.jpg'
import MissionImage2_1 from '../../../Images_1/AboutUs/AboutCompanyImages/VisionImages/bg-2-2.jpg'
import './Vision.css'
import OnScrollAnimation from "../../OnScrollAnimation";

function Vision() {

  function isScrollbarVisible() {
    return document.documentElement.scrollHeight > window.innerHeight;
  }

  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function startAnimationOnScroll() {
    const id0 = document.getElementById('startAnimation1');
    const id1 = document.getElementById('startAnimation2');
    const id2 = document.getElementById('VMimg1');
    const id3 = document.getElementById('VMtext1');
    const id4 = document.getElementById('VMimg2');
    const id5 = document.getElementById('VMtext2');
    if (id0 && isInViewport(id0)) {
      id2.classList.add('visionAndmissionClass1-2');
    }
    if (id0 && isInViewport(id0)) {
      id3.classList.add('visionAndmissionClass');
    }
    if (id1 && isInViewport(id1)) {
      id4.classList.add('visionAndmissionClass2-2');
    }
    if (id1 && isInViewport(id1)) {
      id5.classList.add('visionAndmissionClass2-1');
    }
  }

  function handleScroll() {
    if (isScrollbarVisible()) {
      startAnimationOnScroll();
      window.removeEventListener('scroll', handleScroll); // Remove the scroll listener once animation starts
    }
  }

  window.addEventListener('DOMContentLoaded', handleScroll);
  window.addEventListener('resize', handleScroll);
  window.addEventListener('scroll', startAnimationOnScroll);

  const [selectedStyle, setSelectedStyle] = useState('');

  const getBackgroundImage = () => {
    if (selectedStyle === 'visionBG1_1') {
      return { backgroundImage: `url(${VisionImage1_1})` };
    } else if (selectedStyle === 'missionBG2_1') {
      return { backgroundImage: `url(${MissionImage2_1})` };
    } else if (selectedStyle === 'missionBG2') {
      return { backgroundImage: `url(${MissionImage2})` };
    } else {
      return { backgroundImage: `url(${VisionImage1})` };
    }
  };

  return (
    <div>
      {/* <div className="mission-area pb-120 pt-100" id="our-misson">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="mission-content ">
                <span><img src="assets/img/shape/about-5-shape-1.svg" alt="" /></span>
                <p>e are in business to develop an SEO software that allows anyone to independently optimize and
                  promote a website on the web, regardless of the level of expertise.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mission-shape p-relative d-none d-lg-block">
                <div className="mission-shape-1">
                  <img src="assets/img/shape/smill.png" alt="triangle" />
                </div>
              </div>
            </div>
          </div>
          <div className="mission-two">
            <div className="row">
              <div className="col-lg-4">
                <div className="mission-shape p-relative  d-none d-lg-block">
                  <div className="mission-shape-2">
                    <img src="assets/img/shape/mission-shape-1.png" alt="" />
                  </div>
                  <div className="mission-shape-3">
                    <img src="assets/img/shape/mission-shape-2.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="mission-content">
                  <span><img src="assets/img/shape/about-5-shape-2.svg" alt="" /></span>
                  <p>We aim to constantly improve the user experience, functionality, and support to provide the
                    best possible options for search engine optimization.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <section className="ms-section ms-bg">
       <div className="ms-main-div">
          
          <div className="ms-card-div-container">
            <div className="ms-card-div">
              <div className="ms-card-div-top">
                <img className="ms-svgs" src={vision} alt="mission_svg" />
                <h3 className="ms-heading">Our Vision</h3>
              </div>

              <div className="ms-card-div-bottom">
                <p className="ms-desc">GERMI envisions to become a world class research, development, advisory and training organization in the field of conventional and renewable energy</p>
              </div>
            </div>
            <div className="ms-card-div">
              <div className="ms-card-div-top">
                <img className="ms-svgs" src={Mission} alt="mission_svg" />
                <h3 className="ms-heading">Our Mission</h3>
              </div>

              <div className="ms-card-div-bottom">
                <p className="ms-desc">GERMI has a threefold mission across both the Oil & Gas and Renewable Energy Sectors.<br />•⁠ To conduct societally relevant research in India.<br></br>•⁠ To advise Governments, Industry and Not-for-Profits on specific energy topics.<br></br>•⁠ To train and skill manpower with skills that are relevant to industry.</p>
              </div>
            </div>
            <div className="ms-card-div">
              <div className="ms-card-div-top">
                <img className="ms-svgs" src={values} alt="mission_svg" />
                <h2 className="ms-card-number">03</h2>
              </div>
              <h3 className="ms-heading">Our Values</h3>
              <div className="ms-card-div-bottom">
                <p className="ms-desc">Innovation, Quality, and Customer Satisfaction. These values are at the heart of everything, and the company strives to deliver exceptional after-sale services and a commitment to customer satisfaction</p>
              </div>
            </div>
          </div>
        </div> 
      </section > */}

      {/* <section className="mv-main">
     
        <OnScrollAnimation title1="mv1" title2="mv2" hr1="mv3" hr2="mv4" heading="Vision & Mission" subheading="" />

        <div className="project-overview-1 project-overview-padding">
          <div className="row align-items-center gx-0 mission-block-size">
            <div id="startAnimation1"></div>
            <div className="col-lg-6">
              <div className="project-overview-image float-right">
                <img src={VisionImage} id="VMimg1" className="wow" alt="img" />
              </div>
            </div>
      
            <div className="col-lg-6">
              <div id="VMtext1" className="project-overview-details  text-secondary wow">
                <div className="mission-font-color">
                  <h5 className="vision-display-5 fw-extra-bold mb-0 mission-font-color">Our Vision</h5>
                  <p className="project-overview-description mb-0 normal-text">
                    GERMI envisions to become a world class research, development, advisory and training organization in the field of conventional and renewable energy
                  </p>
                 
                </div>
              </div>
            </div>
          
          </div>
        
        </div>

        <div className="project-overview-2 project-overview-padding vision-alignment">
          <div className="row flex-row-reverse align-items-center gx-0 mission-block-size">
            <div id="startAnimation2"></div>
            <div className="col-lg-6">
              <div className="project-overview-image">
                <img id="VMimg2" src={MissionImage} className="wow" alt="img" />
              </div>
            </div>
          
            <div className="col-lg-6">
              <div id="VMtext2" className="project-overview-details text-secondary position-relative wow">
                <div className="mission-font-color">
                  <h5 className="vision-display-5 fw-extra-bold mb-0 mission-font-color">Our Mission</h5>
                  <p className="project-overview-description mb-0 normal-text">GERMI has a threefold mission across both the Oil & Gas and Renewable Energy Sectors.
                    <ul className="project-overview-list list-unstyled mb-0 d-flex flex-column gap-10">
                      <li className="d-flex align-items-center">
                        •⁠ To conduct societally relevant research in India.
                      </li>
                      <li className="d-flex align-items-center">
                        •⁠ To advise Governments, Industry and Not-for-Profits on specific energy topics.
                      </li>
                      <li className="d-flex align-items-center">
                        •⁠ To train and skill manpower with skills that are relevant to industry.
                      </li>
                    </ul></p>

                
                </div>
              </div>
            </div>
          
          </div>
        
        </div>
      
      </section> */}

      <section className="vam-section">
        <div className="vam-bg-div" style={getBackgroundImage()}>
          <div className="vam-grid-div">
            <div className="vision-div" onMouseOver={() => {
              setSelectedStyle('visionBG1_1')
            }}
              onMouseOut={() => setSelectedStyle('')}>
              <h3 className="fontWhite contentBottom">Our Vision</h3>
              <p className="fontWhite hiddenContent">GERMI envisions to become a world class research, development, advisory and training organization in the field of conventional and renewable energy</p>
            </div>
            <div className="mission-div" onMouseOver={() => {
              setSelectedStyle('missionBG2_1')
            }}
              onMouseOut={() => setSelectedStyle('missionBG2')}>
              <h3 className="fontWhite contentBottom">Our Mission</h3>
              <p className="fontWhite">
                <span className="hiddenContent">•⁠ To conduct societally relevant research in India.</span><br></br>
                <span className="hiddenContent">•⁠ To advise Governments, Industry and Not-for-Profits on specific energy topics.</span><br></br>
                <span className="hiddenContent">•⁠ To train and skill manpower with skills that are relevant to industry.</span><br></br>
              </p>
            </div>
          </div>
        </div>
      </section>

    </div >
  )
}

export default Vision