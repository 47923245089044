import React from 'react'
import './Publication.css'
import { Link } from 'react-router-dom'
function IndividualPublication(props) {
  return (
    <>
      <div className="Book-content-row">
        <div className="Book-content">
          {/* <div className="Book-content-heading">
                  <p>{props.srno} {props.title}</p>
                </div> */}
                <div className='year-color'>{props.year}</div>
          <div className="Book-content-text normal-text">
            {props.srno} &nbsp;
            {props.link === undefined && props.desc}
            {!(props.link === undefined) && <Link  to={ props.link} target="_blank">{props.desc}</Link>}
            
            {props.extra != undefined && props.extra.map((data ) => 
                <div >  <Link to = {data["link"]} target="_blank">{data["content"]}</Link></div>
            )
            }

          </div>

          
        </div>
        <div className="Publication-card-buttons">
          
        </div>
      </div>
    </>
  )
}

export default IndividualPublication
