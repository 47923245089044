import React, { useEffect, useRef, useState } from 'react'
import "./NewTechnologyManagement.css"
import './NewOtherDept.css'
import { Link, useLocation, useParams } from 'react-router-dom';
import ContactUsDepartment from '../ContactUsDepartment';
// import HomeNotices from '../../Home/HomeNotices/HomeNotices';
import HomeNoticesDepartment from '../../Home2/HomeNotices/HomeNoticesDepartment'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import breadcrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/NTM.jpg'
import ScrollToTop from '../../ScrollToTop';
import layer from '../../../Images_1/Departments/Images/ntmdmain.png'
import NewDepartmentTeam from '../NewDepartmentTeam/NewDepartmentTeam'
import NewSuccessStories from './NewSuccessStories/NewSuccessStories';
import ServiceImage1 from '../../../Images_1/Departments/DepartmentHomeImages/NewTechManagement.jpg'
import ServiceImage2 from '../../../Images_1/Departments/DepartmentHomeImages/RenewableManagement.jpg'
import ServiceImage3 from '../../../Images_1/Departments/DepartmentHomeImages/PetroluemManagement.jpg'
import ServiceImage4 from '../../../Images_1/Departments/DepartmentHomeImages/EnvironmentManagement.jpg'
import ServiceImage5 from '../../../Images_1/Departments/DepartmentHomeImages/PetroleumResearchManagement.jpg'
import TheCounter from "../TheCounter"
import ScrollTrigger from 'react-scroll-trigger'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import HomeCounterNew from '../../Home2/HomeCounterNew/HomeCounterNew';
import OurDepartmentTeam from '../OurDepartmentTeam';
import pageData from "./json/NewTechnologyDepartment.json"
import ComingSoonTeam from '../ComingSoonTeam';
import OnScrollAnimation from '../../OnScrollAnimation';
import rm from '../../../Images_1/Departments/DepartmentHomeImages/RM.png'
import pm from '../../../Images_1/Departments/DepartmentHomeImages/PM.png'
import em from '../../../Images_1/Departments/DepartmentHomeImages/EM.png'
import prm from '../../../Images_1/Departments/DepartmentHomeImages/PRM.png'

const RenewableManagement = () => {

    const { id } = useParams();
    const { id2 } = useParams();
    const containerWrapperRef = useRef(null);
    const topWrapperRef = useRef(null);
    const NTM = useRef([]);
    const NTM1 = useRef([]);
    const NTM2 = useRef([]);

    useEffect(() => {

        if (id == 5) {
            // if (topWrapperRef.current) {
            //     // Scroll the div into view
            //     topWrapperRef.current.scrollIntoView({ behavior: 'smooth' });

            //     // Set focus on the div
            //     topWrapperRef.current.focus();
            //   }
        } else {
            if (id == 1) {


                var index3 = pageData["services"].research.findIndex(
                    (x) => x.id == id2
                );
                // console.log(index3 + "index")
                if (index3 !== null && NTM1.current[index3]) {



                    NTM1.current[index3].scrollIntoView({ behavior: 'smooth' });
                    NTM1.current[index3].focus();
                }

            } else if (id == 2) {


                var index3 = pageData["services"].training.findIndex(
                    (x) => x.id == id2
                );
                if (index3 !== null && NTM2.current[index3]) {
                    NTM2.current[index3].scrollIntoView({ behavior: 'smooth' });
                    NTM2.current[index3].focus();
                }

            } else {
                // console.log(pageData["services"].advisory)
                var index3 = pageData["services"].advisory.findIndex(
                    (x) => x.id == id2
                );
                // console.log(index3 + "index" + id2)
                if (index3 !== null && NTM.current[index3]) {
                    NTM.current[index3].scrollIntoView({ behavior: 'smooth' });
                    NTM.current[index3].focus();
                }
            }
        }
    }, []);
    const state = useLocation();
    const [SelectedService, setSelectedService] = useState(['', '', '']);
    const [selectedDiv, setSelectedDiv] = useState(id == 1 ? "R&D" : id == 2 ? "T&SD" : 'A&C');
    const activeBtnStyle = {
        // backgroundColor: '#d2611c',
        backgroundColor: '#7cba27',
        color: '#ffffff',
        transition: '0.4s ease-in-out',
    }
    const [counterState, setCounterState] = useState(false)

    return (
        <div>
            <ScrollToTop />
            {/* <DepartmentHeader styles="header-transparent header-style-2" searchDisable buttonStyle="bg-color-white" /> */}
            <div ref={topWrapperRef} className='breadcrumb-margin'>
                <BreadCrumbs className="breadcrumb-text-class" parent="Departments" child="New Technology Management" parentPath="/departments" imgPath={breadcrumbImage} />
            </div>
            <div className='new-tech-whole-section dep-sec-bg' >
                <section className='new-technology-department-about-section'>
                    <img className="new-department-inner-image new-tech-image-translateCustomSlider3" src={layer} />
                    {/* <div> */}
                    {/* <img className="new-department-text-image" src={title} /> */}
                    <div className='new-tech-title-spacing'>
                        <h1 className='new-tech-custom-slider-changing-text  new-tech-image-translateCustomSlider heading-fonts'>New Technology Management</h1>

                        <h6 className='new-tech-custom-slider-changing-paragraph normal-text new-tech-image-translateCustomSlider1' style={{ textAlign: "justify" }} >The New Technology Management Department is dedicated to leading-edge and emerging technologies, presently concentrating on the swiftly evolving domains of renewable energy, e-mobility and testing. Through research, skilling and advisory services, the department strives to propel developments in these arenas, positioning itself as a leader in technological advancement and fostering a greener, more sustainable future.</h6>
                    </div>
                    {/* </div> */}

                </section>
            </div>

            <section className='dep-sec-bg'>
                <div>
                    <section className='home-counterNew'>
                        <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
                            <div className='home-counterNew-container top-padding-zero'>
                                {/* <h2 className='home-counterNew-headingNew'>Success in Numbers</h2> */}
                                <div className='counter-div-containerNew'>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={300} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Trainings</span>
                                        <span className='counter-textNew normal-text'>Conducted</span>
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={10000} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Professionals</span>
                                        <span className='counter-textNew normal-text'>Trained</span>
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={1000} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Trainers</span>
                                        <span className='counter-textNew normal-text'>Certified</span>
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={30} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Training of Trainers</span>
                                        <span className='counter-textNew normal-text'>and Training of Master Trainers</span>
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={120} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Projects </span>
                                        <span className='counter-textNew normal-text sub-heading'> Executed</span>
                                        {/* <span className='counter-textNew normal-text'>(G&G)</span> */}
                                    </div>
                                </div>
                            </div>
                        </ScrollTrigger>
                    </section>

                </div>
                {/* <HomeCounterNew /> */}
            </section>

            <section className='dep-sec-bg'>
                {/* <div ref={containerWrapperRef} className="heading-container">
                    <h4>Our Services</h4>
                </div> */}
                <OnScrollAnimation title1="ntm1" title2="ntm2" hr1="ntm3" hr2="ntm4" heading="Services We Provide" subheading="" />

                <div className='top-container'>
                    <div className='service-tab-container'>
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('A&C') }} style={selectedDiv == 'A&C' ? activeBtnStyle : {}}>Advisory</Link>
                        {/* <span style={selectedDiv == 'A&C' || selectedDiv == 'R&D' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('R&D') }} style={selectedDiv == 'R&D' ? activeBtnStyle : {}}>Research</Link>
                        {/* <span style={selectedDiv == 'R&D' || selectedDiv == 'T&SD' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts' onClick={() => { setSelectedDiv('T&SD') }} style={selectedDiv == 'T&SD' ? activeBtnStyle : {}}>Training</Link>
                    </div>
                    {/* <div className="Totalcontainer">
                        <span className='total-text'>Total Services <span style={{ color: "#7cba27" }}> | </span> {pageData.services.advisory.length + pageData.services.research.length  + pageData.services.training.length} </span>
                    </div> */}
                </div>


                <div className='dps-main'>
                    <div className='dps-full'>
                        <div id='A&C' style={selectedDiv == 'A&C' ? { display: 'block' } : { display: 'none' }}>
                            {
                                pageData && pageData["services"].advisory.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?
                                        <div div key={data.id} ref={(el) => (NTM.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (NTM.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                })
                            }
                        </div>

                        <div id='R&D' style={selectedDiv == 'R&D' ? { display: 'block' } : { display: 'none' }}>
                            {
                                pageData && pageData["services"].research.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?
                                        <div div key={data.id} ref={(el) => (NTM1.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (NTM1.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>;
                                })
                            }
                        </div>

                        <div id='T&SD' style={selectedDiv == 'T&SD' ? { display: 'block' } : { display: 'none' }}>
                            {
                                pageData && pageData["services"].training.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?


                                        <div div key={data.id} ref={(el) => (NTM2.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (NTM2.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>;
                                })
                            }
                        </div>
                    </div>
                </div>
            </section >

            {/* Success Stories Start */}
            <section className='dep-sec-bg'>
                <NewSuccessStories departmentId={1} />
            </section >
            {/* Success Stories End */}

            <section className='dep-sec-bg'>
                {/* <NewDepartmentTeam /> */}
                <OurDepartmentTeam teamData={pageData.team} department={1} />
                {/* <ComingSoonTeam /> */}
            </section >

            <section >
                <HomeNoticesDepartment department={1} />
            </section>

            {/* <section>
                <OurDepartmentTeam />
            </section> */}


            <section>
                <ContactUsDepartment department={1} />
            </section>

            {/* <section className="section-lgb other-dep-check-out-div">
                <div className="container pbmit-col-stretched-yes pbmit-col-right">

                    <div className="row main-div">
                        <h2 className='pbmit-service-title data-direction dd-mb'>Other Depatments To Checkout</h2>
                        <div className="col-12">

                            <div className="pbmit-col-stretched-right">

                                <div className="swiper-slider" data-autoplay="true" data-loop="true" data-dots="false" data-arrows="false" data-columns="4" data-margin="30" data-effect="slide">
                                    <div className="swiper-wrapper">
                                        <Swiper
                                            modules={[Navigation, Scrollbar, A11y, Autoplay]}
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            

                                            loop={true}>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    

                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage1} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Renewable Management</Link>
                                                                        </h3>
                                                                        <div className="pbmit-service-category">
                                                                           
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage2} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Management</Link>
                                                                        </h3>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage3} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Environment Management</Link>
                                                                        </h3>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage4} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Research Management</Link>
                                                                        </h3>
                                                                        

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* </div> */}
            <section className="rs-service-grid-3 pt-70 pb-120">
                {/* <div className="heading-container">
                    <h4 id="title1" className="title-animation-about-us">Other Departments to Checkout</h4>
                    <div className=" div-for-hr-animation"><hr id="hr1" className="animate-hr-tag-about-us" /><hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="odcntm1" title2="odcntm2" hr1="odcntm3" hr2="odcntm4" heading="Other Departments to Checkout" subheading="" />
                <div className='nod-container'>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage2} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={rm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/renewable-management/5/0">Renewable<br />Management</Link></h4>
                                    {/* <p> Renewable Management </p> */}
                                    <Link to="/departments/renewable-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage3} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={pm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/petroleum-management/5/0">Petroleum<br /> Management</Link></h4>
                                    {/* <p>Petroleum Management </p> */}
                                    <Link to="/departments/petroleum-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage5} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={prm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/petroleum-research-management/5/0">Petroleum Research<br /> Management</Link></h4>
                                    {/* <p>Petroleum  Management </p> */}
                                    <Link to="/departments/petroleum-research-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage4} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={em} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/environment-management/5/0">Environment<br /> Management</Link></h4>
                                    {/* <p> Environment Management  </p> */}
                                    <Link to="/departments/environment-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default RenewableManagement;
