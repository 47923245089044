import "./EVCalculator.css";
import React, { useState } from "react";
import bikeImage from '../../Images_1/Resources/EvCalculator/bike-image.jpg';
import carImage from '../../Images_1/Resources/EvCalculator/car-image.jpg'
import {
  Paper,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { blue } from "@mui/material/colors";
import fuelData from './fueldata.json'
const states = {
  "Andhra Pradesh": "1",
  "Arunachal Pradesh": "2",
  Assam: "3",
  Bihar: "4",
  Chandigarh: "5",
  Chhattisgarh: "6",
  "Dadra and Nagar Haveli": "7",
  "Daman and Diu": "8",
  Delhi: "9",
  Gujarat: "10",
  Haryana: "11",
  "Himachal Pradesh": "12",
  Jharkhand: "13",
  Karnataka: "14",
  Kerala: "15",
  "Madhya Pradesh": "16",
  Maharashtra: "17",
  Manipur: "18",
  Meghalaya: "19",
  Mizoram: "20",
  Nagaland: "21",
  Odisha: "22",
  Pondicherry: "23",
  Punjab: "24",
  Rajasthan: "25",
  Sikkim: "26",
  "Tamil Nadu": "27",
  Telangana: "28",
  Tripura: "29",
  "Uttar Pradesh": "30",
  Uttarakhand: "31",
  "West Bengal": "32",

};

const fuels = {
  "Petrol": 0,
  "Diesel": 1
}
const EVCalculator = () => {
  const buttonSx = {
    ...{
      bgcolor: blue[700],
      "&:hover": {
        bgcolor: blue[800],
      },
      '& .MuiFormControlLabel-label': {
        fontSize: 'var(--global-font-size-sub-title-xs)', // Adjust the size as needed
      },
    },
  };

  const defaultValues = {
    evRange: 10,
    batteryCapacity: 10,
    monthlyTravel: 100,
    chargingCost: 4,
    fulePrice: 50,
    mileage: 5,
    state: "",
    fuleType: "Petrol",
  };

  const [fuelPrice, setFuelPrice] = useState(97.11);
  const [dieselPrice, setDieselPrice] = useState(97.11);
  const [petrolPrice, setPetrolPrice] = useState(109.25);
  const [selectedState, setSelectedState] = useState("1");
  const [fuelType, setFuelType] = useState("Diesel");
  const [evRange, setEvRange] = useState(200);
  const [mileage, setMileage] = useState(10);
  const [darkMode, setDarkMode] = useState(false);
  const [minBattery, setMinBattery] = useState(10);
  const [maxBattery, setMaxBattery] = useState(200);
  const [battery, setBattery] = useState(10);
  const [evAnnualCost, setEvAnnualCost] = useState(0);
  const [evCostPerKm, setEvCostPerKm] = useState(0);
  const [regAnnualCost, setRegAnnualCost] = useState(0);
  const [regCostPerKm, setRegCostPerKm] = useState(0);
  const [vehicle, setVehicle] = useState(0);
  const [calculated, setCalculated] = useState(false);
  const { handleSubmit, setValue, control, reset, watch, formState } = useForm({
    defaultValues,
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = (data) => {
    // console.log(data);
    let chargingCost = Number(data.chargingCost);
    let batteryCapacity = Number(battery);
    let theEvRange = Number(evRange);
    let evCPK = (chargingCost * batteryCapacity) / theEvRange;
    let monthlyTravel = Number(data.monthlyTravel);
    let fulePrice = Number(fuelPrice);
    let aMileage = Number(mileage);
    let regCPK = fulePrice / aMileage;
    setEvCostPerKm(Number(evCPK).toFixed(1));
    setEvAnnualCost(Number(evCPK * 12 * monthlyTravel).toFixed(1));
    setRegCostPerKm(Number(regCPK).toFixed(1));
    setRegAnnualCost(Number(regCPK * 12 * monthlyTravel).toFixed(1));
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCalculated(true);
  };

  const renderForm = () => (
    <div className="ev-form">
      <div className="ev-choose-type">
        <div style={vehicle == 0 ? { borderColor: "#7cba27" } : {}}> <button onClick={(event) => {
          event.preventDefault();
          setVehicle(0);
          setEvRange(200);
          setMileage(10);
          setMinBattery(10);
          setMaxBattery(200);
          setBattery(15);
        }}>
          <img src={carImage} /></button></div>
        <div style={vehicle == 1 ? { borderColor: "#7cba27" } : {}}>
          <button onClick={(event) => {
            event.preventDefault();
            setVehicle(1);
            setEvRange(100);
            setMileage(40);
            setMinBattery(2);
            setMaxBattery(50);
            setBattery(3);
          }}>
            <img src={bikeImage} /></button></div>
      </div>
      <form>
        <div className="form-container-ev">

          <div className="form-container-ev-div">
            <div className="head-title static-subheading-text">Electric Vehicle</div>
            <div className="field-container">
              <div className="field-content static-normal-text">
                <div>EV Range</div>
                <Controller
                  name="evRange"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.evRange}
                      required
                      size="small"
                      type="number"
                      helperText={errors?.evRange?.message}
                      // label="Area"
                      // autoFocus
                      id="evRange"
                      variant="outlined"
                      className="ev-input-size "
                      value={evRange}
                      onChange={(event) => {
                        setEvRange(event.target.value);
                        setValue("evRange", event.target.value);
                      }}
                      onInput={(event) => {
                        if (event.target.value < 10) event.target.value = 10;
                        if (event.target.value > 900) event.target.value = 900;
                      }}
                    />
                  )}
                />
              </div>
              <Controller
                name="evRange"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    error={!!errors.evRange}
                    required
                    type="number"
                    helperText={errors?.evRange?.message}
                    // autoFocus
                    id="evRange"
                    value={evRange}
                    min={10}
                    max={900}
                    onChange={(event) => {
                      setEvRange(event.target.value);
                      setValue("evRange", event.target.value);
                    }}
                    variant="outlined"
                    style={{
                      paddingTop: "20px",
                      width: "90%",
                      color: "#7cba27"
                    }}
                  />
                )}
              />
              <div className="field-content static-normal-text">
                <div>10 Km</div>
                <div>900 Km</div>
              </div>
            </div>
            <div className="field-container static-normal-text">
              <div className="field-content">
                <div>Battery Capacity</div>
                <Controller
                  name="batteryCapacity"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.batteryCapacity}
                      required
                      size="small"
                      type="number"
                      helperText={errors?.batteryCapacity?.message}
                      value={battery}
                      // label="Area"
                      // autoFocus

                      id="batteryCapacity"
                      variant="outlined"
                      className="ev-input-size"
                      onChange={
                        (event) => {
                          setBattery(event.target.value);
                          setValue("batteryCapacity", event.target.value);
                        }
                      }
                      onInput={(event) => {
                        if (event.target.value < minBattery) event.target.value = minBattery;
                        if (event.target.value > maxBattery) event.target.value = maxBattery;
                      }}
                    />
                  )}
                />
              </div>
              <Controller
                name="batteryCapacity"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    error={!!errors.batteryCapacity}
                    required
                    type="number"
                    helperText={errors?.batteryCapacity?.message}
                    value={battery}
                    // autoFocus
                    id="batteryCapacity"
                    min={minBattery}
                    max={maxBattery}
                    onChange={
                      (event) => {
                        setBattery(event.target.value);
                        setValue("batteryCapacity", event.target.value);
                      }
                    }
                    variant="outlined"
                    style={{
                      paddingTop: "20px",
                      width: "90%",
                      color: "#7cba27"
                    }}
                  />
                )}
              />
              <div className="field-content">
                <div>{minBattery} kWh</div>
                <div>{maxBattery} kWh</div>
              </div>
            </div>
            <div className="field-container static-normal-text">
              <div className="field-content">
                <div>Monthly Travel (in km)</div>
                <Controller
                  name="monthlyTravel"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.monthlyTravel}
                      required
                      size="small"
                      type="number"
                      helperText={errors?.monthlyTravel?.message}
                      // label="Area"
                      // autoFocus
                      id="monthlyTravel"
                      variant="outlined"
                      className="ev-input-size"
                      onInput={(event) => {
                        if (event.target.value < 100) event.target.value = 100;
                        if (event.target.value > 10000) event.target.value = 10000;
                      }}
                    />
                  )}
                />
              </div>
              <Controller
                name="monthlyTravel"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    error={!!errors.monthlyTravel}
                    required
                    type="number"
                    helperText={errors?.monthlyTravel?.message}
                    // autoFocus
                    id="monthlyTravel"
                    min={100}
                    max={10000}
                    variant="outlined"
                    style={{
                      paddingTop: "20px",
                      width: "90%",
                      color: "#7cba27"
                    }}
                  />
                )}
              />
              <div className="field-content">
                <div>100 Km</div>
                <div>10000 Km</div>
              </div>
            </div>
            <div className="field-container static-normal-text">
              <div className="field-content">
                <div>EV Charging Cost (₹ / kWh)</div>
                <Controller
                  name="chargingCost"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.chargingCost}
                      required
                      size="small"
                      type="number"
                      helperText={errors?.chargingCost?.message}
                      // label="Area"
                      // autoFocus
                      id="chargingCost"
                      variant="outlined"
                      className="ev-input-size"
                    />
                  )}
                />
              </div>
              <Controller
                name="chargingCost"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    error={!!errors.chargingCost}
                    required
                    type="number"
                    helperText={errors?.chargingCost?.message}
                    // autoFocus
                    id="chargingCost"
                    min={4}
                    max={100}
                    variant="outlined"
                    style={{
                      paddingTop: "20px",
                      width: "90%",
                      color: "#7cba27"
                    }}
                    onInput={(event) => {
                      if (event.target.value < 4) event.target.value = 4;
                      if (event.target.value > 100) event.target.value = 100;
                    }}
                  />
                )}
              />
              <div className="field-content">
                <div>₹4 / kWh</div>
                <div>₹100 / kWh</div>
              </div>
            </div>
          </div>
          <Divider
            sx={{
              opacity: 1,
            }}
            color="black"
            orientation="vertical"
            flexItem
          />
          <div className="form-container-ev-div">
            <div className="head-title static-subheading-text">Conventional Vehicle</div>
            <div className="field-container">
              <div className="field-content static-normal-text">
                <div>Choose your State</div>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      style={{ width: "50%" }}
                      variant="outlined"
                      error={!!errors.state}
                    >
                      <InputLabel id="state-lable">Select State</InputLabel>
                      <Select
                        labelId="state-lable"
                        {...field}
                        className="mb-2"
                        label="Select State"
                        // autoFocus
                        id="state"
                        value={selectedState}
                        onChange={
                          (event) => {
                            setSelectedState(event.target.value);

                            const a = fuelData.fuel.filter((i) => {
                              return i.state === event.target.value;
                            })
                            setPetrolPrice(a[0].Petrol);
                            setDieselPrice(a[0].Diesel);
                            fuelType == "Diesel" ? setFuelPrice(a[0].Diesel) : setFuelPrice(a[0].Petrol);
                          }
                        }
                        required
                      >
                        {/* <MenuItem value="">Select Experience (in years)</MenuItem> */}
                        {Object.keys(states).map((i) => (
                          <MenuItem value={states[i]}>{i}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.PositionType?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="field-container">
              <div className="field-content static-normal-text">
                <div>Fuel Type</div>
                <Controller
                  name="fuleType"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      style={{ width: "50%" }}
                      variant="outlined"
                      error={!!errors.fuleType}
                    >
                      <InputLabel id="fule-type-lable">Select Fule</InputLabel>
                      <Select
                        labelId="fule-type-lable"
                        {...field}
                        className="mb-2"
                        label="Select Fule"
                        // autoFocus
                        id="fuleType"
                        value={fuelType}
                        onChange={
                          (event) => {
                            setFuelType(event.target.value);
                            event.target.value === "Petrol" ? setFuelPrice(petrolPrice) : setFuelPrice(dieselPrice);
                          }
                        }

                        required
                      >
                        {/* <MenuItem value="">Select Experience (in years)</MenuItem> */}
                        {Object.keys(fuels).map((i) => (
                          <MenuItem value={i}>{i}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.PositionType?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="field-container static-normal-text">
              <div className="field-content">
                <div>Fuel price</div>
                <Controller
                  name="fulePrice"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.fulePrice}
                      required
                      size="small"
                      type="number"
                      value={fuelPrice}
                      helperText={errors?.fulePrice?.message}
                      // label="Area"
                      // autoFocus
                      id="fulePrice"
                      variant="outlined"
                      className="ev-input-size"
                      onChange={(event) => {
                        setFuelPrice(event.target.value);
                        setValue("fulePrice", event.target.value);
                      }}
                      onInput={(event) => {
                        if (event.target.value < 50) event.target.value = 50;
                        if (event.target.value > 200) event.target.value = 200;
                      }}
                    />
                  )}
                />
              </div>
              <Controller
                name="fulePrice"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    error={!!errors.fulePrice}
                    required
                    type="number"
                    helperText={errors?.fulePrice?.message}
                    value={fuelPrice}
                    // autoFocus
                    id="fulePrice"
                    min={50}
                    max={200}
                    variant="outlined"
                    style={{
                      paddingTop: "20px",
                      width: "90%",
                      color: "#7cba27"
                    }}
                    onChange={(event) => {
                      setFuelPrice(event.target.value);
                      setValue("fulePrice", event.target.value);
                    }}
                  />
                )}
              />
              <div className="field-content">
                <div>₹50 / l</div>
                <div>₹200 / l</div>
              </div>
            </div>
            <div className="field-container static-normal-text">
              <div className="field-content">
                <div>Avg. mileage</div>
                <Controller
                  name="mileage"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.mileage}
                      required
                      size="small"
                      type="number"
                      helperText={errors?.mileage?.message}
                      // label="Area"
                      // autoFocus
                      value={mileage}
                      onChange={
                        (event) => {
                          setMileage(event.target.value);
                          setValue("mileage", event.target.value);
                        }
                      }
                      onInput={(event) => {
                        if (event.target.value < 5) event.target.value = 5;
                        if (event.target.value > 100) event.target.value = 100;
                      }}
                      id="mileage"
                      variant="outlined"
                      style={{
                        width: "15%",
                      }}
                    />
                  )}
                />
              </div>
              <Controller
                name="mileage"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    error={!!errors.mileage}
                    required
                    type="number"
                    helperText={errors?.mileage?.message}
                    // autoFocus
                    value={mileage}
                    id="mileage"
                    min={5}
                    max={100}
                    variant="outlined"

                    style={{
                      paddingTop: "20px",
                      width: "90%",
                      color: "#7cba27"
                    }}
                    onChange={
                      (event) => {
                        setMileage(event.target.value);
                        setValue("mileage", event.target.value);
                      }
                    }
                  />
                )}
              />
              <div className="field-content">
                <div>5 Km / l</div>
                <div>100 Km / l</div>
              </div>
            </div>
            <div
              className="static-normal-text"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
                <Button
                        variant="contained"
                        sx={buttonSx}
                        style={{ width: "max-content", backgroundColor: '#7cba27', marginTop:"20px" }}
                        // disabled={loadingState || !clerification}
                        onClick={
                          handleSubmit(onSubmit)


                          //   () => {
                          //   setCalculated(true);
                          // }
                        }
                      >
                        <div className="xxs-small-text">Calculate</div>
                      </Button>
              {/* <Button
                variant="contained"
                sx={buttonSx}
                style={{ width: "25%", backgroundColor: '#7cba27' }}
                // disabled={loadingState || !clerification}
                onClick={handleSubmit(onSubmit)}
              >
                <div className="xxs-small-text">Calculate</div>

              </Button> */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  const renderResult = () => (
    <div className="ev-result static-normal-text">
      <div className="form-container-ev">
        <div className="form-container-ev-div">
          <div className="head-title static-subheading-text">Electric Vehicle</div>
          <div className="result-content">
            <div>Annual Cost</div>
            <div className="result-container">₹{evAnnualCost}</div>
          </div>
          <div className="result-content">
            <div>Cost per KM</div>
            <div className="result-container">₹{evCostPerKm}</div>
          </div>
        </div>
        <Divider
          sx={{
            opacity: 1,
          }}
          color="black"
          orientation="vertical"
          flexItem
        />
        <div className="form-container-ev-div">
          <div className="head-title static-subheading-text">Conventional Vehicle</div>
          <div className="result-content">
            <div>Annual Cost</div>
            <div className="result-container">₹{regAnnualCost}</div>
          </div>
          <div className="result-content">
            <div>Cost per KM</div>
            <div className="result-container">₹{regCostPerKm}</div>
          </div>
        </div>
      </div>
      <div className="result-content-main">
        <div>Annual Savings</div>
        <div className="result-container">₹{Number(regAnnualCost - evAnnualCost).toFixed(1)}</div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={buttonSx}
            style={{ width: "100%", marginBottom: "20px", marginTop: "20px", backgroundColor: '#7cba27' }}
            // disabled={loadingState || !clerification}
            onClick={() => {
              setCalculated(false);
            }}
          >
            <div>
              Calculate Again
            </div>

          </Button>
        </div>
      </div>

    </div>
  );

  return (
    <>
      <div
        className="m-40 ev-main-div"
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          margin: "50px 0px",
        }}
      >
        <form style={{ width: "85%", alignSelf: "center" }}>
          <Paper>
            {calculated ? renderResult() : renderForm()}{" "}
          </Paper>
        </form>
      </div>
    </>
  );
};

export default EVCalculator;
