import solarData from './solarData.json';


function calculatePlantSizeArea(unit, area, percent) {
    if (unit === "sqfeet") {
        area = area * 0.092903
    }
    let capacity = (area * percent) / 1e3;
    return Number(capacity).toFixed(1);

}

function calculatePlantSizeBudget(budget, category, customer) {
    let capacity = 1;
    // console.log(budget, category, customer);
    for (let i = 0; i < solarData.data.length; i++) {
        if (solarData.data[i].category === category && solarData.data[i].customer === customer) {
            var b = budget;
            var o = 1e3 * solarData.data[i].cost;
            var r = solarData.data[i].rangeFrom * o
            var l = solarData.data[i].rangeTo * o;
            b >= r && b <= l && (capacity = b / o)
            // console.log(b, o, capacity);
        }
    }
    return capacity > 1 && (capacity = Number(capacity).toFixed(1)), capacity
}

function getCategoryFromState(state) {
    var category;
    for (let i = 0; i < solarData.data_state_catg.length; i++) {
        if (solarData.data_state_catg[i].state === state)
            category = solarData.data_state_catg[i].category;
    }
    return category;
}

function getBenchmark(capacity, category, customer){
    let benchmark = 0;
    capacity = parseInt(Math.round(capacity));
    for(let i=0;i<solarData.data.length;i++){
        if(solarData.data[i].category === category
             && solarData.data[i].customer === customer && capacity >= solarData.data[i].rangeFrom 
            && capacity <= solarData.data[i].rangeTo){
                benchmark = solarData.data[i].cost*1e3;
                break;
            }
    }
    if(benchmark == 0){
        for(let i=0;i<solarData.data.length;i++){
            if(solarData.data[i].category === category
                  && capacity >= solarData.data[i].rangeFrom 
                && capacity <= solarData.data[i].rangeTo){
                    benchmark = solarData.data[i].cost*1e3;
                }
        }
    }
    return benchmark;
}

function getSubsidy(capacity, category, customer){
    capacity = parseInt(Math.round(capacity));
    let subsidy = 0;
    for(let i=0;i < solarData.data.length;i++){
        if(solarData.data[i].category === category && solarData.data[i].customer === customer && capacity>=solarData.data[i].rangeFrom && capacity <=solarData.data[i].rangeTo){
            subsidy = solarData.data[i].subsidy;
        }
    }
    return subsidy;
}

function getIrradiationEle(state){
    let generate;
    for(let i=0;i<solarData.data_irradiation_ele.length;i++){
        if(state===solarData.data_irradiation_ele[i].state){
            generate = solarData.data_irradiation_ele[i].irradiation_ele;
            // console.log(generate);
        }
    }
    return generate;
}

function getIrradiation(state){
    let irradiate;
    for(let i=0;i<solarData.data_irradiation.length;i++){
        if(state===solarData.data_irradiation[i].state){
            irradiate = solarData.data_irradiation[i].irradiation;
        }
    }
    return irradiate;
}

export {calculatePlantSizeArea, calculatePlantSizeBudget, getCategoryFromState, getBenchmark, getSubsidy, getIrradiationEle, getIrradiation};
