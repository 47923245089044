import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const Career = createAsyncThunk('careerdata', async() => {
  const response = await publicRequest.get('/openinglist');
  return response.data;
});

const CareersDataSlice = createSlice({
  name: 'careerdata',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(Career.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default CareersDataSlice.reducer;