import React from 'react'
import { Link } from 'react-router-dom'
// import team1 from '../../../Images_1/Teams/OurTeamImages/HrAdminTeam/DharmikLay.jpg'
// import team2 from '../../../Images_1/Teams/OurTeamImages/HrAdminTeam/narinderjit.jpg'
// import team3 from '../../../Images_1/Teams/OurTeamImages/HrAdminTeam/KrupaliMehta.jpg'
// import team4 from '../../../Images_1/Teams/OurTeamImages/HrAdminTeam/bhavyaji.jpg'
// import team5 from '../../../Images_1/Teams/OurTeamImages/HrAdminTeam/sandhya.jpg'
// import team6 from '../../../Images_1/Teams/OurTeamImages/HrAdminTeam/nileshPatel.jpg'
// import team7 from '../../../Images_1/Teams/OurTeamImages/HrAdminTeam/sunita.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import { useNavigate } from "react-router-dom";
import teamData from "../json/Team.json";

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './OurTeam.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import BreadCrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/team.jpg'
import { BASE_URL_Link } from '../../../constants'
import OnScrollAnimation from '../../OnScrollAnimation'
function HradminTeams() {
    const navigate = useNavigate()
    return (
        <div>
            <div>
                <BreadCrumbs parent="Home" child="HR Admin - IT Team" parentPath="/" imgPath={BreadCrumbImage} />
            </div>

            <section className="section-lgb tm-main">
                <div className="container-team pbmit-col-stretched-yes pbmit-col-right">
                    <div className="row main-div-team">
                        {/* <div className='heading-container' style={{ color: "#18181B" }}>
                            <h4 className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }} >Our Team</h4>
                            <h2 id="title1" className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }}>HR & Admin Team</h2>
                            <div className=' div-for-hr-animation'>
                                <hr id='hr1' className='animate-hr-tag-about-us' />
                                <hr id='hr2' className='animate-hr-tag-about-us' />
                            </div>
                        </div> */}
                        <OnScrollAnimation title1="hrt1" title2="hrt2" hr1="hrt3" hr2="hrt4" heading="HR Admin - IT Team" subheading="" />

                        <div className='team-grid-div-2'>
                            <div className="pbmit-service-style-4">
                                <div className="pbminfotech-post-item team-block-alignment">
                                    <div className="pbmit-featured-wrapper img-border img-alignment">
                                       
                                    <Link onClick={() =>
                                                       teamData["hradmin"][0].type && teamData["hradmin"][0].type=="HOD"?
                                                       window.open("teamdetails/" + teamData["hradmin"][0].id + "/" + "hradmin" + "/0/0"):<></>
}>
    
                                        <img  src={process.env.PUBLIC_URL + teamData["hradmin"][0].imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                        {/* <img src={team1} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                    </Link>
                                    </div>
                                    <div className='team-content-div'>
                                        <div className='team-about-div'>
                                            <h3 className='team-name-class twenty-sixteen-fonts'>{teamData["hradmin"][0].name}</h3>
                                            {/* <h4 className='team-desig-class small-text'>{teamData["hradmin"][0].jobPost}</h4> */}
                                            <h3 className='team-name-class small-text'>{teamData["hradmin"][0].jobPost}</h3>
                                                            { teamData["hradmin"][0].type && teamData["hradmin"][0].type=="HOD"?
                                                           <> <h3 className='team-name-class small-text'>{teamData["hradmin"][0].emailid }</h3><h3 className='team-name-class small-text'>{ teamData["hradmin"][0].contactno}</h3>
                                                           </> :
                                                            <></>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className='team-grid-div'>
                            {
                                teamData["hradmin"] && teamData["hradmin"].map((data, index) => {


                                    if (index != 0) {
                                        return (

                                            <div className="pbmit-service-style-4">
                                                <div className="pbminfotech-post-item team-block-alignment">
                                                    <div className="pbmit-featured-wrapper img-border img-alignment">
                                                    {data.type && data.type=="HOD"?  <Link onClick={() =>
                                                      
window.open("teamdetails/" + data.id + "/" + "hradmin" + "/0/0")
}>
                                                        <img  src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                                        {/* <img src={team2} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                                        </Link>:<img  src={process.env.PUBLIC_URL + data.imageSrc} className="img-fluid img-radius img-team-dept-style" alt="" />
                                    }
                                                    </div>
                                                    <div className='team-content-div'>
                                                        <div className='team-about-div'>
                                                            <h3 className='team-name-class twenty-sixteen-fonts'>{data.name}</h3>
                                                            {/* <h4 className='team-desig-class small-text'>{data.jobPost}</h4> */}
                                                            <h3 className='team-name-class small-text'>{data.jobPost}</h3>
                                                            { data.type && data.type=="HOD"?
                                                           <> <h3 className='team-name-class small-text'>{data.emailid }</h3><h3 className='team-name-class small-text'>{ data.contactno}</h3>
                                                           </> :
                                                            <></>}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HradminTeams
