import './Form3.css';
import * as React from 'react';
import Table from '@mui/material/Table';

import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { MuiTelInput } from 'mui-tel-input'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import EducationTable from './EducationDetails';
import WorkDetails from './WorkDetails';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addapplication } from './store/AddApplication';

const experience_data = [
    { value: '0', label: '0 Year' },
    { value: '1', label: '1 Year' },
    { value: '2', label: '2 Year' },
    { value: '3', label: '3 Year' },
    { value: '4', label: '4 Year' },
    { value: '5', label: '5 Year' },
    { value: '6', label: '6 Year' },
    { value: '7', label: '7 Year' },
    { value: '8', label: '8 Year' },
    { value: '9', label: '9 Year' },
    { value: '10', label: '10 Year' },
    { value: '11', label: '11 Year' },
    { value: '12', label: '12 Year' },
    { value: '13', label: '13 Year' },
    { value: '14', label: '14 Year' },
    { value: '15', label: '15 Year' },
    { value: '16', label: '16 Year' },
    { value: '17', label: '17 Year' },
    { value: '18', label: '18 Year' },
    { value: '19', label: '19 Year' },
    { value: '20', label: '20 Year' },
    { value: '21', label: '21 Year' },
    { value: '22', label: '22 Year' },
    { value: '23', label: '23 Year' },
    { value: '24', label: '24 Year' },
    { value: '25', label: '25 Year' },

];

const isAvailable = [
    {
        value: true,
        label: 'Yes',
    },
    {
        value: false,
        label: 'No',
    },

];

function Form3() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const data = location.state;
    const [positionAppliedFor, setPositionAppliedFor] = React.useState(data.positionname);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [middleName, setMiddleName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobileNo, setMobileNo] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [currentCity, setCurrentCity] = React.useState('');
    const [currentState, setCurrentState] = React.useState('');
    const [nativeState, setNativeState] = React.useState('');
    const [dateOfBirth, setDateOfBirth] = React.useState('');
    const [minNoticePeriod, setMinNoticePeriod] = React.useState('');
    const [readyToRelocate, setReadyToRelocate] = React.useState(true);
    const [currentDesignation, setCurrentDesignation] = React.useState("");
    const [currentOrganization, setCurrentOrganization] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [netToHandSalary, setNetToHandSalary] = React.useState("");
    const [grossSalary, setGrossSalary] = React.useState("");
    const [hscCompletedYear, setHscCompletedYear] = React.useState("");
    const [hscBoardUniversity, setHscBoardUniversity] = React.useState("");
    const [hscSubjects, setHscSubjects] = React.useState("");
    const [hscPercentage, setHscPercentage] = React.useState("");

    const [diplomaCompletedYear, setDiplomaCompletedYear] = React.useState("");
    const [diplomaBoardUniversity, setDiplomaBoardUniversity] = React.useState("");
    const [diplomaSubjects, setDiplomaSubjects] = React.useState("");
    const [diplomaPercentage, setDiplomaPercentage] = React.useState("");

    const [graduationCompletedYear, setGraduationCompletedYear] = React.useState("");
    const [graduationBoardUniversity, setGraduationBoardUniversity] = React.useState("");
    const [graduationSubjects, setGraduationSubjects] = React.useState("");
    const [graduationPercentage, setGraduationPercentage] = React.useState("");

    const [postGraduationCompletedYear, setPostGraduationCompletedYear] = React.useState("");
    const [postGraduationBoardUniversity, setPostGraduationBoardUniversity] = React.useState("");
    const [postGraduationSubjects, setPostGraduationSubjects] = React.useState("");
    const [postGraduationPercentage, setPostGraduationPercentage] = React.useState("");

    const [pGDiplomaCompletedYear, setPGDiplomaCompletedYear] = React.useState("");
    const [pGDiplomaBoardUniversity, setPGDiplomaBoardUniversity] = React.useState("");
    const [pGDiplomaSubjects, setPGDiplomaSubjects] = React.useState("");
    const [pGDiplomaPercentage, setPGDiplomaPercentage] = React.useState("");

    const [mbaCompletedYear, setMbaCompletedYear] = React.useState("");
    const [mbaBoardUniversity, setMbaBoardUniversity] = React.useState("");
    const [mbaSubjects, setMbaSubjects] = React.useState("");
    const [mbaPercentage, setMbaPercentage] = React.useState("");

    const [doctoralCompletedYear, setDoctoralCompletedYear] = React.useState("");
    const [doctoralBoardUniversity, setDoctoralBoardUniversity] = React.useState("");
    const [doctoralSubjects, setDoctoralSubjects] = React.useState("");
    const [doctoralPercentage, setDoctoralPercentage] = React.useState("");

    const [organizationName1, setOrganizationName1] = React.useState("");
    const [designation1, setDesignation1] = React.useState("");
    const [dateOfJoining1, setDateOfJoining1] = React.useState(new Date());
    const [dateOfRelieving1, setDateOfRelieving1] = React.useState(new Date());
    const [lengthOfService1, setLengthOfService1] = React.useState(0);
    const [salaryPerMonth1, setSalaryPerMonth1] = React.useState(0);
    const [mainJobProfile1, setMainJobProfile1] = React.useState("");
    const [expCertificate1, setExpCertificate1] = React.useState(false);

    const [organizationName2, setOrganizationName2] = React.useState("");
    const [designation2, setDesignation2] = React.useState("");
    const [dateOfJoining2, setDateOfJoining2] = React.useState("");
    const [dateOfRelieving2, setDateOfRelieving2] = React.useState("");
    const [lengthOfService2, setLengthOfService2] = React.useState(0);
    const [salaryPerMonth2, setSalaryPerMonth2] = React.useState(0);
    const [mainJobProfile2, setMainJobProfile2] = React.useState("");
    const [expCertificate2, setExpCertificate2] = React.useState(false);

    const [organizationName3, setOrganizationName3] = React.useState("");
    const [designation3, setDesignation3] = React.useState("");
    const [dateOfJoining3, setDateOfJoining3] = React.useState("");
    const [dateOfRelieving3, setDateOfRelieving3] = React.useState("");
    const [lengthOfService3, setLengthOfService3] = React.useState(0);
    const [salaryPerMonth3, setSalaryPerMonth3] = React.useState(0);
    const [mainJobProfile3, setMainJobProfile3] = React.useState("");
    const [expCertificate3, setExpCertificate3] = React.useState(false);

    const [selectedFile, setSelectedFile] = React.useState(null);
    const [selectedDobFile, setSelectedDobFile] = React.useState(null);
    const [selectedFileSalary, setSelectedFileSalary] = React.useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(positionAppliedFor);
        // create validations for this form where firstname, lastname, email, mobileno, address and city are required
        const formData = new FormData();
        formData.append("openingid", data.id);
        formData.append("positionname", positionAppliedFor);
        formData.append("mobileno", mobileNo);
        formData.append("firstname", firstName);
        formData.append("middlename", middleName);
        formData.append("lastname", lastName);
        formData.append("emailid", email);
        formData.append("address", address);
        formData.append("city", currentCity);
        formData.append("currentstate", currentState);
        formData.append("nativestate", nativeState);
        formData.append("birthdate", `${dateOfBirth.$y}-${dateOfBirth.$M.toString().padStart(2, '0')}-${dateOfBirth.$D.toString().padStart(2, '0')}`);
        formData.append("currentdesignation", currentDesignation);
        formData.append("currentorgaization", currentOrganization);
        formData.append("experience", experience);
        formData.append("nettohandsalary", netToHandSalary);
        formData.append("grosssalary", grossSalary);
        formData.append("noticeperiod", minNoticePeriod);
        formData.append("relocatelocation", readyToRelocate);
        formData.append("hsccompyear", `${hscCompletedYear.$y}-${hscCompletedYear.$M.toString().padStart(2, '0')}-${hscCompletedYear.$D.toString().padStart(2, '0')}`);
        formData.append("hscboarduni", hscBoardUniversity);
        formData.append("hscsubjspecial", hscSubjects);
        formData.append("hscpercentage", hscPercentage);
        formData.append("dipyear", `${diplomaCompletedYear.$y}-${diplomaCompletedYear.$M.toString().padStart(2, '0')}-${diplomaCompletedYear.$D.toString().padStart(2, '0')}`);
        formData.append("dipboarduni", diplomaBoardUniversity);
        formData.append("dipsubjspecial", diplomaSubjects);
        formData.append("dippercentage", diplomaPercentage);
        formData.append("gradustionyear", `${graduationCompletedYear.$y}-${graduationCompletedYear.$M.toString().padStart(2, '0')}-${graduationCompletedYear.$D.toString().padStart(2, '0')}`);
        formData.append("gradustionboarduni", graduationBoardUniversity);
        formData.append("gradustionsubjspecial", graduationSubjects);
        formData.append("gradustionpercentage", graduationPercentage);
        formData.append("pgyear", `${postGraduationCompletedYear.$y}-${postGraduationCompletedYear.$M.toString().padStart(2, '0')}-${postGraduationCompletedYear.$D.toString().padStart(2, '0')}`);
        formData.append("pgboarduni", postGraduationBoardUniversity);
        formData.append("pgsubjspecial", postGraduationSubjects);
        formData.append("pgpercentage", postGraduationPercentage);
        formData.append("pgdipyear", `${pGDiplomaCompletedYear.$y}-${pGDiplomaCompletedYear.$M.toString().padStart(2, '0')}-${pGDiplomaCompletedYear.$D.toString().padStart(2, '0')}`);
        formData.append("pgdipboarduni", pGDiplomaBoardUniversity);
        formData.append("pgdipsubjspecial", pGDiplomaSubjects);
        formData.append("pgdippercentage", pGDiplomaPercentage);
        formData.append("mbayear", `${mbaCompletedYear.$y}-${mbaCompletedYear.$M.toString().padStart(2, '0')}-${mbaCompletedYear.$D.toString().padStart(2, '0')}`);
        formData.append("mbaboarduni", mbaBoardUniversity);
        formData.append("mbasubjspecial", mbaSubjects);
        formData.append("mbapercentage", mbaPercentage);
        formData.append("docyear", `${doctoralCompletedYear.$y}-${doctoralCompletedYear.$M.toString().padStart(2, '0')}-${doctoralCompletedYear.$D.toString().padStart(2, '0')}`);
        formData.append("docboarduni", doctoralBoardUniversity);
        formData.append("docsubjspecial", doctoralSubjects);
        formData.append("docpercentage", doctoralPercentage);
        formData.append("finstname", organizationName1);
        formData.append("fdesignation", designation1);
        formData.append("fdateofjoin", `${dateOfJoining1.$y}-${dateOfJoining1.$M.toString().padStart(2, '0')}-${dateOfJoining1.$D.toString().padStart(2, '0')}`);
        formData.append("fdateofrel", `${dateOfRelieving1.$y}-${dateOfRelieving1.$M.toString().padStart(2, '0')}-${dateOfRelieving1.$D.toString().padStart(2, '0')}`);
        formData.append("flengthofervice", lengthOfService1);
        formData.append("fsalarypermonth", salaryPerMonth1);
        formData.append("fmainjobprofile", mainJobProfile1);
        formData.append("fworkexp", expCertificate1);
        formData.append("sinstname", organizationName2);
        formData.append("sdesignation", designation2);
        formData.append("sdateofjoin", `${dateOfJoining2.$y}-${dateOfJoining2.$M.toString().padStart(2, '0')}-${dateOfJoining2.$D.toString().padStart(2, '0')}`);
        formData.append("sdateofrel", `${dateOfRelieving2.$y}-${dateOfRelieving2.$M.toString().padStart(2, '0')}-${dateOfRelieving2.$D.toString().padStart(2, '0')}`);
        formData.append("slengthofervice", lengthOfService2);
        formData.append("ssalarypermonth", salaryPerMonth2);
        formData.append("smainjobprofile", mainJobProfile2);
        formData.append("sworkexp", expCertificate2);
        formData.append("tinstname", organizationName3);
        formData.append("tdesignation", designation3);
        formData.append("tdateofjoin", `${dateOfJoining3.$y}-${dateOfJoining3.$M.toString().padStart(2, '0')}-${dateOfJoining3.$D.toString().padStart(2, '0')}`);
        formData.append("tdateofrel", `${dateOfRelieving3.$y}-${dateOfRelieving3.$M.toString().padStart(2, '0')}-${dateOfRelieving3.$D.toString().padStart(2, '0')}`);
        formData.append("tlengthofervice", lengthOfService3);
        formData.append("tsalarypermonth", salaryPerMonth3);
        formData.append("tmainjobprofile", mainJobProfile3);
        formData.append("tworkexp", expCertificate3);
        formData.append("proofofbirth", selectedDobFile)
        formData.append("latetsalryslip", selectedFileSalary)
        formData.append("cv", selectedFile)
        dispatch(addapplication(formData)).then((data) => { navigate("/careers") });
    }
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const handleFileChangeDob = (event) => {
        setSelectedDobFile(event.target.files[0]);
    }

    const handleFileChangeSalary = (event) => {
        setSelectedFileSalary(event.target.files[0]);
    }


    return (
        <>
            <div className='form-section'>
                <div className='form2-main-div'>
                    <form>
                        <div >
                            <div className="form2-mandatory form2-pl ">* Mandatory</div>
                            {/* <div className='text-salary form2-pt-10'>Personal Details:</div> */}
                            <div className='form2-row-div'>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '33ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="outlined-select-currency"
                                        required
                                        disabled
                                        autoFocus
                                        value={positionAppliedFor}
                                        // defaultValue="EUR"
                                        helperText="Position Applied for"
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '33ch' },
                                    }}
                                    noValidate
                                    autoComplete="off">
                                    <MuiTelInput defaultCountry="IN" required label="Mobile No" fullWidth value={mobileNo} onChange={(newValue) => { setMobileNo(newValue) }} />
                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '32.5ch' },
                                    }}
                                    noValidate
                                    autoComplete="off">
                                    <MuiTelInput defaultCountry="IN" required label="Confirm Mobile No" fullWidth value={mobileNo} onChange={(newValue) => { setMobileNo(newValue) }} />
                                </Box>
                            </div>
                            <div className='form2-row-div'>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '33ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField required id="outlined-basic" label="First Name" variant="outlined" value={firstName} onChange={(newValue) => { setFirstName(newValue.target.value) }} />
                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '33ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField required id="outlined-basic" label="Middle Name" variant="outlined" value={middleName} onChange={(newValue) => { setMiddleName(newValue.target.value) }} />
                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '32.5ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField required id="outlined-basic" label="Last Name" variant="outlined" value={lastName} onChange={(newValue) => { setLastName(newValue.target.value) }} />
                                </Box>
                            </div>
                            <div className='form2-row-div'>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '50ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField type="email" required id="outlined-basic" label="Email Id" variant="outlined" value={email} onChange={(newValue) => { setEmail(newValue.target.value) }} />

                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '50ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField type="email" required id="outlined-basic" label="Confirm Email Id" variant="outlined" value={email} onChange={(newValue) => { setEmail(newValue.target.value) }} />

                                </Box>

                            </div>
                            <div className='form2-row-div'>
                                <Box
                                    sx={{
                                        m: 1,
                                        width: '50ch',
                                        maxWidth: '100%',
                                    }}
                                >
                                    <TextField

                                        className='form2-address'
                                        required
                                        fullWidth
                                        id="outlined-multiline-flexible"
                                        label="Correspondence Address (with pincode)"
                                        multiline
                                        maxRows={4}
                                        value={address}
                                        onChange={(newValue) => setAddress(newValue.target.value)}
                                    />

                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '24.25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField required id="outlined-basic" label="Current City" variant="outlined" value={currentCity} onChange={(newValue) => setCurrentCity(newValue.target.value)} />
                                    <TextField required id="outlined-basic" label="Current State" variant="outlined" value={currentState} onChange={(newValue) => setCurrentState(newValue.target.value)} />
                                </Box>
                            </div>

                            <div className='form2-row-div'>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '23.5ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField required id="outlined-basic" label="Native State" variant="outlined" value={nativeState} onChange={(newValue) => setNativeState(newValue.target.value)} />
                                    <div style={{ display: "ruby" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker required label="Date of Birth" value={dateOfBirth} onChange={(newValue) => setDateOfBirth(newValue)} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        <div className='form2-pr text-salary form2-pr'>Proof of DOB:</div>

                                        <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
                                            Upload
                                            <input
                                                hidden
                                                type="file"
                                                onChange={handleFileChangeDob}
                                            />
                                        </Button>
                                        {selectedDobFile && (
                                            <p>Selected File: {selectedDobFile.name}</p>
                                        )}
                                    </div>

                                </Box>
                            </div>
                            <div className='text-salary form2-pt-10'>Employment Details:</div>
                            <div className='form2-row-div'>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '34ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField required id="outlined-basic" label="Current Designation" variant="outlined" value={currentDesignation} onChange={(newValue) => setCurrentDesignation(newValue.target.value)} />
                                    <TextField required id="outlined-basic" label="Current Organization" variant="outlined" value={currentOrganization} onChange={(newValue) => setCurrentOrganization(newValue.target.value)} />
                                    <TextField
                                        required
                                        id="outlined-select-currency"
                                        select
                                        label="Select"
                                        defaultValue="0"
                                        value={experience}
                                        onChange={(newValue) => setExperience(newValue.target.value)}
                                        helperText="Experience in Years"
                                    >
                                        {experience_data.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                </Box>

                            </div>
                            <div className='text-salary'>Monthly Salary in Details(In Numbers Only):</div>
                            <div className='form2-row-div'>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '34ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        min="0"
                                        id="outlined-number"
                                        label="Net to Hand/ Take Home"
                                        type="number"
                                        value={netToHandSalary}
                                        onChange={(newValue) => setNetToHandSalary(newValue.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        id="outlined-number"
                                        label="Gross Salary (Monthly)"
                                        value={grossSalary}
                                        onChange={(newValue) => setGrossSalary(newValue.target.value)}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                </Box>
                                <div style={{ display: "ruby" }}>
                                    <div className='form2-pr text-salary form2-pr'>Latest Salary Slip:</div>

                                    <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
                                        Upload
                                        <input
                                            hidden
                                            type="file"
                                            onChange={handleFileChangeSalary}
                                        />
                                    </Button>
                                    {selectedFileSalary && (
                                        <p>Selected File: {selectedFileSalary.name}</p>
                                    )}
                                </div>

                            </div>

                            <div className='text-salary form2-pt-10'>Education Details:</div>
                            {/* <EducationTable educationDetails={educationDetails} setEducationDetails={setEducationDetails} /> */}
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Degree</TableCell>
                                            <TableCell align="right">Completed in Year</TableCell>
                                            <TableCell align="right">Board / University</TableCell>
                                            <TableCell align="right">Subjects / Specialization</TableCell>
                                            <TableCell align="right">Percentage</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                HSC
                                            </TableCell>

                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="Year" value={hscCompletedYear} onChange={(newValue) => setHscCompletedYear(newValue)} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={hscBoardUniversity} onChange={(newValue) => setHscBoardUniversity(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={hscSubjects} onChange={(newValue) => setHscSubjects(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-number"
                                                    // label="Gross Salary (Monthly)"
                                                    type="number"
                                                    value={hscPercentage}
                                                    onChange={(newValue) => setHscPercentage(newValue.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                Diploma
                                            </TableCell>

                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="Year" value={diplomaCompletedYear} onChange={(newValue) => setDiplomaCompletedYear(newValue)} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={diplomaBoardUniversity} onChange={(newValue) => setDiplomaBoardUniversity(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={diplomaSubjects} onChange={(newValue) => setDiplomaSubjects(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-number"
                                                    // label="Gross Salary (Monthly)"
                                                    type="number"
                                                    value={diplomaPercentage}
                                                    onChange={(newValue) => setDiplomaPercentage(newValue.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>



                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                Graduation
                                            </TableCell>

                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="Year" value={graduationCompletedYear} onChange={(newValue) => setGraduationCompletedYear(newValue)} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={graduationBoardUniversity} onChange={(newValue) => setGraduationBoardUniversity(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={graduationSubjects} onChange={(newValue) => setGraduationSubjects(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-number"
                                                    // label="Gross Salary (Monthly)"
                                                    type="number"
                                                    value={graduationPercentage}
                                                    onChange={(newValue) => setGraduationPercentage(newValue.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>


                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                Post Graduation
                                            </TableCell>

                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="Year" value={postGraduationCompletedYear} onChange={(newValue) => setPostGraduationCompletedYear(newValue)} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={postGraduationBoardUniversity} onChange={(newValue) => setPostGraduationBoardUniversity(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined"
                                                    value={postGraduationSubjects}
                                                    onChange={(newValue) => setPostGraduationSubjects(newValue.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-number"
                                                    // label="Gross Salary (Monthly)"
                                                    type="number"
                                                    value={postGraduationPercentage}
                                                    onChange={(newValue) => setPostGraduationPercentage(newValue.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>


                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                PG Diploma
                                            </TableCell>

                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="Year" value={pGDiplomaCompletedYear} onChange={(newValue) => setPGDiplomaCompletedYear(newValue)} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={pGDiplomaBoardUniversity} onChange={(newValue) => setPGDiplomaBoardUniversity(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={pGDiplomaSubjects} onChange={(newValue) => setPGDiplomaSubjects(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-number"
                                                    // label="Gross Salary (Monthly)"
                                                    value={pGDiplomaPercentage}
                                                    onChange={(newValue) => setPGDiplomaPercentage(newValue.target.value)}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>


                                        </TableRow>

                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                MBA
                                            </TableCell>

                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="Year"
                                                            value={mbaCompletedYear}
                                                            onChange={(newValue) => setMbaCompletedYear(newValue)} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={mbaBoardUniversity} onChange={(newValue) => setMbaBoardUniversity(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={mbaSubjects} onChange={(newValue) => setMbaSubjects(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-number"
                                                    // label="Gross Salary (Monthly)"
                                                    type="number"
                                                    value={mbaPercentage}
                                                    onChange={(newValue) => setMbaPercentage(newValue.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>



                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                Doctoral
                                            </TableCell>

                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="Year" value={doctoralCompletedYear} onChange={(newValue) => setDoctoralCompletedYear(newValue)} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={doctoralBoardUniversity} onChange={(newValue) => setDoctoralBoardUniversity(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={doctoralSubjects} onChange={(newValue) => setDoctoralSubjects(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-number"
                                                    // label="Gross Salary (Monthly)"
                                                    type="number"
                                                    value={doctoralPercentage}
                                                    onChange={(newValue) => setDoctoralPercentage(newValue.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>



                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* <WorkDetails data={workDetails} /> */}
                            <div className='text-salary form2-pt-10'>Work Details:</div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sr. No</TableCell>
                                            <TableCell align="center">Name of the Institute/Organization</TableCell>
                                            <TableCell align="center">Designation</TableCell>
                                            <TableCell align="center">Date of Joining</TableCell>
                                            <TableCell align="center">Date of Relieving</TableCell>
                                            <TableCell align="center">Total Length of Service</TableCell>
                                            <TableCell align="center">Salary Per Month</TableCell>
                                            <TableCell align="center">Main Job Profile</TableCell>
                                            <TableCell align="center">Work Experience Certificate Available</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                1
                                            </TableCell>

                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={organizationName1}
                                                    onChange={(newValue) => setOrganizationName1(newValue.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined"
                                                    value={designation1}
                                                    onChange={(newValue) => setDesignation1(newValue.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="DOJ"
                                                            value={dateOfJoining1}
                                                            onChange={(newValue) => {
                                                                setDateOfJoining1(newValue);
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>

                                            </TableCell>
                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="DOR"
                                                            value={dateOfRelieving1}
                                                            onChange={(newValue) => {
                                                                setDateOfRelieving1(newValue);
                                                            }}

                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    min="0"
                                                    id="outlined-number"
                                                    // label="Number"
                                                    type="number"
                                                    value={lengthOfService1}
                                                    onChange={(newValue) => setLengthOfService1(newValue.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    min="0"
                                                    id="outlined-number"
                                                    value={salaryPerMonth1}
                                                    onChange={(newValue) => setSalaryPerMonth1(newValue.target.value)}
                                                    // label="Number"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={mainJobProfile1} onChange={(newValue) => setMainJobProfile1(newValue.target.value)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-select-currency"
                                                    required
                                                    select
                                                    value={expCertificate1}
                                                    onChange={(newValue) => setExpCertificate1(newValue.target.value)}
                                                // label="Select"
                                                // defaultValue="EUR"
                                                // helperText="Position Applied for"
                                                >
                                                    {isAvailable.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                2
                                            </TableCell>

                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined" value={organizationName2} onChange={(newValue) => setOrganizationName2(newValue.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined"
                                                    value={designation2}
                                                    onChange={(newValue) => setDesignation2(newValue.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="DOJ"
                                                            value={dateOfJoining2}
                                                            onChange={(newValue) => {
                                                                setDateOfJoining2(newValue);
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>

                                            </TableCell>
                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="DOR"
                                                            value={dateOfRelieving2}
                                                            onChange={(newValue) => {
                                                                setDateOfRelieving2(newValue);
                                                            }}

                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    min="0"
                                                    id="outlined-number"
                                                    // label="Number"
                                                    type="number"
                                                    value={lengthOfService2}
                                                    onChange={(newValue) => setLengthOfService2(newValue.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    min="0"
                                                    id="outlined-number"
                                                    // label="Number"
                                                    value={salaryPerMonth2}
                                                    onChange={(newValue) => setSalaryPerMonth2(newValue.target.value)}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined"
                                                    value={mainJobProfile2}
                                                    onChange={(newValue) => setMainJobProfile2(newValue.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-select-currency"
                                                    required
                                                    select
                                                    value={expCertificate2}
                                                    onChange={(newValue) => setExpCertificate2(newValue.target.value)}
                                                // label="Select"
                                                // defaultValue="EUR"
                                                // helperText="Position Applied for"
                                                >
                                                    {isAvailable.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                3
                                            </TableCell>

                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined"
                                                    value={organizationName3}
                                                    onChange={(newValue) => setOrganizationName3(newValue.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined"
                                                    value={designation3}
                                                    onChange={(newValue) => setDesignation3(newValue.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="DOJ"
                                                            value={dateOfJoining3}
                                                            onChange={(newValue) => setDateOfJoining3(newValue)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>

                                            </TableCell>
                                            <TableCell align="right">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker views={['year', 'month']}
                                                            label="DOR"
                                                            value={dateOfRelieving3}
                                                            onChange={(newValue) => setDateOfRelieving3(newValue)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    min="0"
                                                    id="outlined-number"
                                                    value={lengthOfService3}
                                                    onChange={(newValue) => setLengthOfService3(newValue.target.value)}
                                                    // label="Number"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    min="0"
                                                    id="outlined-number"
                                                    value={salaryPerMonth3}
                                                    onChange={(newValue) => setSalaryPerMonth3(newValue.target.value)}
                                                    // label="Number"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField required id="outlined-basic" variant="outlined"
                                                    value={mainJobProfile3}
                                                    onChange={(newValue) => setMainJobProfile3(newValue.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="outlined-select-currency"
                                                    required
                                                    select
                                                    value={expCertificate3}
                                                    onChange={(newValue) => setExpCertificate3(newValue.target.value)}
                                                // label="Select"
                                                // defaultValue="EUR"
                                                // helperText="Position Applied for"
                                                >
                                                    {isAvailable.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer >
                            <div className='form2-row-div form2-pt-10'>
                                <div className='text-salary form2-pr'>Minimum Notice Period Required to Join in case of selection: </div>
                                <TextField
                                    min="0"
                                    id="outlined-number"
                                    label="Days"
                                    value={minNoticePeriod}
                                    onChange={(newValue) =>
                                        setMinNoticePeriod(newValue.target.value)}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div>
                                <FormControl>
                                    <div className='form2-row-div form2-pt-10 form2-pl'>
                                        <div className='form2-pr'>
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Are you ready to relocate to any location in India?</FormLabel> */}
                                            Are you ready to relocate to any location in India?
                                        </div>

                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={readyToRelocate}
                                            onChange={(newValue) => setReadyToRelocate(newValue.target.value)}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={false} control={<Radio />} label="No" />

                                        </RadioGroup>
                                    </div>

                                </FormControl>
                            </div>

                            <div className='form2-row-div form2-pt-10'>
                                <div className='text-salary form2-pr'>Upload Resume/ CV* (UPDATED CV - Word or PDF file only): </div>

                                <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
                                    Upload
                                    <input
                                        hidden
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                </Button>
                                {selectedFile && (
                                    <p>Selected File: {selectedFile.name}</p>
                                )}
                            </div>
                            <div className='form2-row-div form2-pt-10 form2-check-l'>
                                <FormGroup>
                                    <FormControlLabel required control={<Checkbox />} />
                                </FormGroup>
                                <div className=' form2-pr form2-declare-styling'>I hereby certify that the above information are true and correct to the best of my knowledge and in case of any discrepancy, the application will be disqualified. </div>


                            </div>
                            <div className='form2-row-div'>
                                <div className='form2-pt-10 form2-pl'>
                                    <Button variant="contained" component="label" endIcon={<IosShareIcon />}>
                                        Submit
                                        <input
                                            hidden
                                            type="submit"
                                            onClick={handleSubmit}
                                        // onChange={handleFileChange}
                                        />
                                    </Button>
                                </div>
                                <div className='form2-pt-10 form2-pl'>
                                    {/* <Button variant="contained" component="label" endIcon={<RestartAltIcon />}>
                                        Reset
                                        <input
                                            hidden
                                            type="reset"
                                        // onChange={handleFileChange}
                                        />
                                    </Button> */}
                                </div>
                            </div>



                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Form3;