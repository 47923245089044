import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const getsingleapplication = createAsyncThunk('singleapplication', async(data) => {
    const jsondata=JSON.stringify(data);
  const response = await publicRequest.post('/openinglistbyid',jsondata);
  return response.data;
});
// export const singleapplication = createAsyncThunk(
//     "singleapplication",
//     async (data) => {
//       // console.log("here")
//       const res = await publicRequest.post("/openinglistbyid", data);
//       if (res) {
//         // console.log(res);
//         if (res.data.status) {
//           alert(res.data.message)
//         }
//         return res.data
//       }
  
//     }
//   );
const SingleApplicationDataSlice = createSlice({
  name: 'singleapplication',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(getsingleapplication.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default SingleApplicationDataSlice.reducer;