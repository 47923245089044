import React, { useEffect, useState } from 'react';
import './ScrollButton.css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
const ScrollButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <button onClick={scrollToTop} className="floating-btn">
      <div className='float-arrow-align'>
        <ArrowUpwardIcon></ArrowUpwardIcon>
      </div>
      </button>
  );
}
export default ScrollButton;