import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import './NewSuccessStories.css'
// import Algae from '../../../../images/StroriesSingleImages/Algae Harvesting.jpg'
import NewSuccessStoriesData from './NewSuccessStoriesData';
// import ServiceImage1 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Rooftop Programme.jpg'
// import ServiceImage2 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/MW-Scale Solar Projects.jpg'
// import ServiceImage3 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Canal-top Solar Projects.jpg'
// import ServiceImage4 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Algae Harvesting.jpg'
// import ServiceImage5 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Micro-grid-solar.jpg'
// import ServiceImage6 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Solarization of Modhera Sun Temple.jpg'
// import ServiceImage7 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Modhera Solar city Project.jpg'
// import ServiceImage8 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Solar Plant on Narmada Branch Canal.jpg'
// import ServiceImage9 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Solar MasterPlan for GIFT City.jpg'
// import ServiceImage10 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Solar Power Reminvitive.jpg'
// import ServiceImage6 from '../../SuccessStories/images/Sun_Temple_Success.jpg'
// import ServiceImage2 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Micro-grid.png'
// import ServiceImage9 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Gifty_City.png'
// import ServiceImage1 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/Rooftop Programme-png.png'
// import ServiceImage8 from '../../../../Images_1/Activities/SuccessStoriesSingleImages/narmada-canal-project.png'
// import sliderData from '../../json/success_stories.json'
import SuccessStories from '../../../../json/successStoriesData.json';
import OnScrollAnimation from '../../../OnScrollAnimation';

function NewSuccessStories() {

    var sliderData = SuccessStories.filter((data) => data.forHome);
    return (
        <>
            <div className='nss-main-section'>
                <div className='nss-bottom-div'>
                    <div className='nss-bottom-dark-div'></div>

                    {/* <div className='heading-container'>
                        <h4 className='title-animation-about-us heading-container-white-fonts'>Success Stories</h4>
                        <div id="title1" className='title-animation-about-us heading-container-white-fonts'>Explore our success stories in energy research, featuring groundbreaking discoveries, innovative technologies, and transformative solutions for sustainable energy.</div>
                        <div className=' div-for-hr-animation'>
                            <hr id='hr1' className='animate-hr-tag-about-us' />
                            <hr id='hr2' className='animate-hr-tag-about-us' />
                        </div>
                    </div> */}
                    <OnScrollAnimation isWhite="true" title1="ss1" title2="ss2" hr1="ss3" hr2="ss4" heading="Success Stories" subheading="Explore our success stories in energy research, featuring groundbreaking discoveries, innovative technologies, and transformative solutions for sustainable energy." />

                </div>
                <div className='nss-main-indi-box'>
                    <Swiper
                        modules={[Pagination, Autoplay]}
                        spaceBetween={10} slidesPerView={1} loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: true,
                        }}
                        pagination={{ clickable: true }}
                    >

                        {sliderData && sliderData.map((data) =>
                            <SwiperSlide>
                                <NewSuccessStoriesData
                                    imageSrc={process.env.PUBLIC_URL + data.imageSrc}
                                    title={data.title}
                                    desc={data.description}
                                    id={data.id}
                                    did={data.departmentId}
                                />

                            </SwiperSlide>
                        )}

                        {/* <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage7}
                                title="Modhera Solar City Project"
                                desc="GERMI offered Bid Services, Project Management Consultancy for this project, the project is on the verge of completion."
                            />

                        </SwiperSlide>
                        <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage6}
                                title="Solarization of Modhera Sun Temple"
                                desc="GERMI offered bid services and Project Management Consultancy
                                The project was recently inaugurated by the Honourable Prime Minister Shri Narendra Modi."
                            />

                        </SwiperSlide>
                        <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage8}
                                title="Narmada Branch Canal Project (India's 1st)"
                                desc="The Canal Solar Power Project is a solar canal project launched in Gujarat, India, 
                                to use the 532 km (331 mi) long network of Narmada canals across the state for setting up solar panels to generate electricity. 
                                It was the first ever such project in India."
                            />

                        </SwiperSlide>
                        <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage9}
                                title="Solar Masterplan For Gift City"
                                desc="Inline with the objective of Ministry of New and Renewable Energy (MNRE)
                                 “Development of Solar Cities”, the group developed a road map for GIFT City based
                                  in Gandhinagar Gujarat, for practising renewable energy and energy efficiency."
                            />

                        </SwiperSlide>
                        <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage1}
                                title="5 MW Gandhinagar Rooftop Programme (India's 1st)"
                                desc="India's first megawatt-scale rooftop programme, which was successfully executed end-to-end by GERMI.
                                This programme is globally recognized through selection among the top 10 deals of Asia-Pacific for 2012 and the Earth Care Award-2015."
                            />

                        </SwiperSlide>
                        <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage5}
                                title="MW-Scale Solar Project"
                                desc="The sanctum sanctorum is designed in a way that the first rays of
                            rising sun lit up the image of Surya during solar equinox days and on summer 
                            solstice day, the sun shines directly above the temple at noon casting no shadow"
                            />

                        </SwiperSlide>
                        <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage3}
                                title="Canal-Top Solar Projects"
                                desc="The aim of the project is to study various designs of canal top solar photovoltaic 
                                structure and designing a cost effective model for economic generation of solar energy from canal top.
                                The objective is to demonstrate three different innovative designs of canal top structure."
                            />

                        </SwiperSlide>
                        <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage4}
                                title="Algae Harvesting"
                                desc="The sanctum sanctorum is designed in a way that the first rays of
                            rising sun lit up the image of Surya during solar equinox days and on summer solstice day, the sun shines directly above the temple at noon casting no shadow"
                            />

                        </SwiperSlide>
                        <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage2}
                                title="Micro-Grid Solar"
                                desc="The project include establishing rooftop PV installations on various buildings; 
                            retrofitting of diesel generators with governors; voltage stabilizers for entire campus;
                             battery backup for entire campus; microgrid controller with hardware."
                            />

                        </SwiperSlide>
                        <SwiperSlide>
                            <NewSuccessStoriesData
                                imageSrc={ServiceImage10}
                                title="Solar Power as Remunerative Crop"
                                desc="Reduce non-technical power losses through 'cooperative' approach.
                                    Incentivise optimized utilization of ground water.
                                    Additional source of income for farmers (hedge against crop failure).
                                    (Future) Support demand response for the Indian power grid."
                            />

                        </SwiperSlide> */}

                    </Swiper>

                </div>
            </div>
        </>
    )
}

export default NewSuccessStories
