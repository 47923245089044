import React, { useState } from 'react'
import './OrganizationChart.css'
import OrganizationBox from './OrganizationBox'
// import lvl1Image from '../../../Images_1/CommonSection/Header/GERMI_Logo.png'
// import lvl1Image from '../../../Images_1/NewDepartmentTeam/new-member-1.png'
import { Link, useNavigate } from 'react-router-dom'
import OnScrollAnimation from '../../OnScrollAnimation'
function OrganizationChart() {
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    window.onresize = function () {
        setWidth(window.innerWidth);
    }
    return (

        <div>


            <div className={width < 1200 ? "container oc oc-container" : "oc oc-container"}>
                {/* <div className='heading-container'>
                    <h2 id="title1" className='title-animation-about-us'>Organizational Chart of GERMI</h2>
                    <div className=' div-for-hr-animation'>
                        <hr id='hr1' className='animate-hr-tag-about-us' />
                        <hr id='hr2' className='animate-hr-tag-about-us' />
                    </div>
                </div> */}
                {/* <div className="heading-container">
                    <h4 id="title2" className="title-animation-about-us">Organizational Chart of GERMI</h4>
                    <div className="div-for-hr-animation">
                        <hr id="hr1" className="animate-hr-tag-about-us" />
                        <hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="oc1" title2="oc2" hr1="oc3" hr2="oc4" heading="Organizational Chart of GERMI" subheading="" />
                <h1 className="level-1 rectangle org-heading-fonts">Vice Chairman  & Managing Trustee
                </h1>
                <ol className="level-2-wrapper">
                    <li >
                        <h2 className="level-21 rectangle static-normal-text">Director General</h2>
                        <ol className="level-3-wrapper1">
                            <li>
                                <Link to="/departments/new-technology-management/5/0">
                                    <h3 className="level-3 rectangle org-normal-text">New Technology Management</h3>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => navigate("/departments/renewable-management/5/0")}>
                                    <h3 className="level-3 rectangle org-normal-text">Renewable Energy Management</h3>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => navigate("/departments/petroleum-management/5/0")}>
                                    <h3 className="level-3 rectangle org-normal-text">Petroleum Management</h3>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => navigate("/departments/petroleum-research-management/5/0")}>
                                    <h3 className="level-3 rectangle org-normal-text">Petroleum Research Management</h3>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => navigate("/departments/environment-management/5/0")}>
                                    <h3 className="level-3 rectangle org-normal-text">Environment Management</h3>
                                </Link>
                            </li>
                         
                           
                        </ol>
                    </li>
                    <li>
                        <h2 className="level-2 rectangle static-normal-text">Secretary & Trustee</h2>
                        <ol className={width < 1200 ? "level-3-wrapper1" : "level-3-wrapper"}>
                            <li>
                                <h3 className="level-3 rectangle org-normal-text">Finance</h3>
                            </li>
                            <li>
                                <h3 className="level-3 rectangle org-normal-text">HR Admin - IT Team</h3>
                            </li>
                        </ol>
                    </li>

                </ol>
            </div>
            {/* <div className='heading-container'>
                <h2 id="title1" className='title-animation-about-us'>GERMI is organized into two research wings</h2>
                <div className=' div-for-hr-animation'>
                    <hr id='hr1' className='animate-hr-tag-about-us' />
                    <hr id='hr2' className='animate-hr-tag-about-us' />
                </div>
            </div>
            <div className='ogc-main-box'>
                <div className='ogc-lvl-1'>
                    <OrganizationBox
                        leftColor="#7cca38"
                        rightColor="#7cba27"
                        imgSrc={lvl1Image}
                        mbHeight="350px"
                        mbWidth="198px"
                        text = "GERMI" />
                </div>
                <div className='ogc-lvl-2'>
                    <OrganizationBox
                        leftColor="#ff6f00"
                        rightColor="#db6a00"
                        imgSrc={lvl1Image}
                        mbHeight="300px"
                        mbWidth="169.7px"
                        text = "Oil and Gas Research Wings" />
                    <OrganizationBox
                        leftColor="#ff6f00"
                        rightColor="#db6a00"
                        imgSrc={lvl1Image}
                        mbHeight="300px"
                        mbWidth="169.7px"
                        text = "Renewable Energy and Environment Research Wing" />
                </div>
                <div className='ogc-lvl-3'>
                    <div className='lvl-3-row'>
                        <OrganizationBox
                            leftColor="#00337C"
                            rightColor="#13005A"
                            imgSrc={lvl1Image}
                            mbHeight="250px"
                            mbWidth="141.4px"
                            text = "Petroleum Laboratory" />
                        <OrganizationBox
                            leftColor="#00337C"
                            rightColor="#13005A"
                            imgSrc={lvl1Image}
                            mbHeight="250px"
                            mbWidth="141.4px"
                            text = "Seismic Data Interpretation Center" />
                    </div>
                    <div className='lvl-3-row'>
                        <OrganizationBox
                            leftColor="#00337C"
                            rightColor="#13005A"
                            imgSrc={lvl1Image}
                            mbHeight="250px"
                            mbWidth="141.4px"
                            text = "Research & Development" />
                        <OrganizationBox
                            leftColor="#00337C"
                            rightColor="#13005A"
                            imgSrc={lvl1Image}
                            mbHeight="250px"
                            mbWidth="141.4px"
                            text = "Advisory & Consulting" />
                        <OrganizationBox
                            leftColor="#00337C"
                            rightColor="#13005A"
                            imgSrc={lvl1Image}
                            mbHeight="250px"
                            mbWidth="141.4px"
                            text = "Project Management Consulting" />
                        <OrganizationBox
                            leftColor="#00337C"
                            rightColor="#13005A"
                            imgSrc={lvl1Image}
                            mbHeight="250px"
                            mbWidth="141.4px"
                            text = "Training and Skill Development" />
                        <OrganizationBox
                            leftColor="#00337C"
                            rightColor="#13005A"
                            imgSrc={lvl1Image}
                            mbHeight="250px"
                            mbWidth="141.4px"
                            text = "Environment and Energy Efficiency" />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default OrganizationChart
