const patternsdata = [
    //Year 2024
    [
       {
            year: "2024",
            srno: "1.",
            title: "",
            desc: "Senjaliya, Y., Oad, N., Chakroborty, S., Tripathi, B., Chandra, P., Tripathi, P.K., Solanki, A., Darwish, I.A., Rawat, S., Barik, A. and Kapadia, R., 2024. Rare Earth metal oxide nanoparticle-infused polymer nanocomposites for enhanced supercapacitor electrodes. Journal of Molecular Structure, 1307, p.137919.",
        },
        {
            year: "2024",
            srno: "2.",
            title: "",
            desc: "Oad, N., Senjaliya, Y., Pandya, D., Rawat, S., Kumar, M., Kapadia, R., Solanki, A., Tripathi, B. and Chandra, P., 2024. Synthesis of polyindole/gallium–gadolinium–aluminum garnet nano-composite for supercapacitor electrode. Journal of Materials Science: Materials in Electronics, 35(6), p.438.",
        },
       
    ]
]

export default patternsdata;
