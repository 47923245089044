import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './BreadCrumbs.css'
function BreadCrumbs(props) {
    const parent = props.parent;
    const child = props.child;
    const parentPath = props.parentPath;
    const imgPath = props.imgPath;
    var flag = false;
    const customStyle = {
        backgroundColor: "transparent",
        /* breadcrumbimage url */
        backgroundImage: "url(" + imgPath + ")",
        backgroundRepeat: "no - repeat",
        backgroundPosition: "center center",
        backgroundSize: "100%",
        backgroundAttachment: "scroll",
        position: "relative",
        aspectRatio: "auto 1360 / 281"
    }
    const fontColor = {
        color: "#ffffff"
    }
    const arr = ["About Us", "New Technology Management", "Renewables Management", "Environment Management",
        "Petroleum Research Management", "Petroleum Management", "Departments", "Publications", "Our Team", "Team Details", "Gallery", "Careers", "FCRA",
        "Job Details"]
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] == child) flag = true;
    }
    return (

        <div>
            {/* Title Bar  */}
            <div style={customStyle} className="pbmit-title-bar-wrapper">
                <div className="breadcrumb-text-align container">
                    <div className="breadcrumb-title-bar-content">
                        <div className="breadcrumb-content-adjustment">
                            <div className="pbmit-tbar">
                                <div className="pbmit-tbar-inner container">
                                    <h1 style={flag ? fontColor : {}} className="pbmit-tbar-title mb-0 breadcrumb-text-class">{child}</h1>
                                </div>
                            </div>

                            {/* <div className="pbmit-breadcrumbs">
                                <div className="pbmit-breadcrumb-inner">
                                    <span><Link title="" to={parentPath} className="home"><span style={flag ? fontColor : {}}>{parent}</span></Link></span>
                                    <span style={flag ? fontColor : {}} className="padding-rl-20">|</span>
                                    <span className="post-root post post-post current-item">{child}</span>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
            {/* Title Bar End */}
        </div>
    )
}

export default BreadCrumbs