import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import Modhera from '../../Home/SuccessStories/images/Sun_Temple_Success.jpg'
// import modhersolarcity from '../../Home/SuccessStories/images/Modhera_City_Success.jpg'
// import advisory from '../../Home/SuccessStories/images/Advisory_Success.jpeg'
// import algaeharvesting from '../../Home/SuccessStories/images/Algae_Success.jpeg'
// import canaltopproject from '../../Home/SuccessStories/images/Canal_Top_Success.jpeg'
// import microgridsolar from '../../Home/SuccessStories/images/Micro_Grid_Success.jpeg'
// import mwscaleproject from '../../Home/SuccessStories/images/MW_Solar_Success.jpeg'
// import narmadabranchcanalproject from '../../Home/SuccessStories/images/Narmada_Canal_Success.jpeg'
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import StoriesCover from '../../../Images_1/CommonSection/Braedcrumbs/StoriesCover.jpg'
// import rooftopprogramme from '../../../Images_1/Activities/SuccessStoriesSingleImages/Rooftop Programme-png.png'
import "./SuccessStoriesDetailList.css";
import ScrollToTop from "../../ScrollToTop";
import successStories from "../../../json/successStoriesData.json"

const SuccessStoriesSingle = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 4;
  const totalItems = 12;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // const {id} = props

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [selecteddiv, setSelecteddiv] = useState(location.state ?? 1);
  const selectedStyle = { color: "#7cba27" };

  const handleNavigate = (data) => () => {
    navigate("/announcementdetails/" + data[0], { state: { id: data[0], name: data[1] } });
  };

  return (
    <div>
      <ScrollToTop />
      <BreadCrumbs parent="Home" child="Success Stories Details" parentPath="/" imgPath={StoriesCover} />

      <section className="success-stories-single-section">
        <div className="success-stories-single-main-div">

          <div className="ssd-list-div">
            <div className="ssd-list-bg">
              <div className="ssd-border-div">
                <div className="ssd-all-post-list">
                  <ul>
                    {
                      successStories.map((data) => <li className="ssd-post-active"
                      ><Link to="#" style={selecteddiv == data.id ? selectedStyle : {}}
                        onClick={() => {
                          setSelecteddiv(data.id);
                        }}>{data.title}</Link></li>)
                    }
                    {/* <li className="ssd-post-active"
                    ><Link to="#" style={selecteddiv == 'ssd-1' ? selectedStyle : {}}
                      onClick={() => {
                        setSelecteddiv('ssd-1');
                      }}>Modhera Sun Temple</Link></li>

                    <li><Link to="#" style={selecteddiv == 'ssd-2' ? selectedStyle : {}}
                      onClick={() => {
                        setSelecteddiv('ssd-2');
                      }}>Modhera Solar City</Link></li>
                    <li><Link to="#" style={selecteddiv == 'ssd-3' ? selectedStyle : {}}
                      onClick={() => {
                        setSelecteddiv('ssd-3');
                      }}>Advisory</Link></li>
                    <li><Link to="#" style={selecteddiv == 'ssd-4' ? selectedStyle : {}}
                      onClick={() => {
                        setSelecteddiv('ssd-4');
                      }}>Algae Harvesting</Link></li>
                    <li><Link to="#" style={selecteddiv == 'ssd-5' ? selectedStyle : {}}
                      onClick={() => {
                        setSelecteddiv('ssd-5');
                      }}>Narmada Branch Canal Project</Link></li>
                    <li><Link to="#" style={selecteddiv == 'ssd-6' ? selectedStyle : {}}
                      onClick={() => {
                        setSelecteddiv('ssd-6');
                      }}>Micro Grid Solar Project</Link></li>
                    <li><Link to="#" style={selecteddiv == 'ssd-7' ? selectedStyle : {}}
                      onClick={() => {
                        setSelecteddiv('ssd-7');
                      }}>Canal Top Project</Link></li>
                    <li><Link to="#" style={selecteddiv == 'ssd-8' ? selectedStyle : {}}
                      onClick={() => {
                        setSelecteddiv('ssd-8');
                      }}>MW Scale Project</Link></li>
                    <li><Link to="#" style={selecteddiv == 'ssd-9' ? selectedStyle : {}}
                      onClick={() => {
                        setSelecteddiv('ssd-9');
                      }}>Rooftop Programme</Link></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="ssd-content-div">
            {
              successStories.map((data) => (<div className="ssd-div" style={selecteddiv == data.id ? {} : { display: 'none' }}>
                <div className="ssd-content-heading">
                  <h2>{data.title}</h2>
                </div>
                <div className="ssd-content-image-div">
                  <img className="ssd-content-image" src={data.imageSrc} />
                </div>
                <div className="ssd-content-text">
                  <p>
                    {data.description}
                  </p>

                  {/* <p>
                    Nulla at volutpat diam ut venenatis tellus in. Neque volutpat ac tincidunt vitae semper. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nunc vel risus commodo viverra maecenas accumsan lacus vel. Eget est lorem ipsum dolor sit. Mi sit amet mauris commodo quis imperdiet. Ut sem nulla pharetra diam sit. Vulputate enim nulla aliquet porttitor. Sit amet commodo nulla facilisi nullam. Amet consectetur adipiscing elit pellentesque habitant morbi. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas erat. Felis imperdiet proin fermentum leo vel orci porta non. Tincidunt nunc pulvinar sapien et. Eros donec ac odio tempor orci dapibus. Iaculis eu non diam phasellus vestibulum lorem. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Amet facilisis magna etiam tempor orci eu.
                  </p> */}
                </div>

              </div>
              ))
            }

            {/* <div className="ssd-div" style={selecteddiv == 'ssd-2' ? {} : { display: 'none' }}>
              <div className="ssd-content-heading">
                <h2>Modhera Solar City</h2>
              </div>
              <div className="ssd-content-image-div">
                <img className="ssd-content-image" src={modhersolarcity} />
              </div>
              <div className="ssd-content-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut porttitor leo a diam. Libero justo laoreet sit amet cursus sit amet dictum sit. Elit ullamcorper dignissim cras tincidunt. Hendrerit dolor magna eget est lorem. Lacus sed viverra tellus in. Neque aliquam vestibulum morbi blandit. At in tellus integer feugiat scelerisque varius morbi enim. Neque volutpat ac tincidunt vitae semper quis lectus nulla. Purus sit amet luctus venenatis lectus. Tincidunt praesent semper feugiat nibh sed pulvinar proin. Morbi quis commodo odio aenean sed adipiscing diam donec. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.
                </p>

                <p>
                  Nulla at volutpat diam ut venenatis tellus in. Neque volutpat ac tincidunt vitae semper. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nunc vel risus commodo viverra maecenas accumsan lacus vel. Eget est lorem ipsum dolor sit. Mi sit amet mauris commodo quis imperdiet. Ut sem nulla pharetra diam sit. Vulputate enim nulla aliquet porttitor. Sit amet commodo nulla facilisi nullam. Amet consectetur adipiscing elit pellentesque habitant morbi. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas erat. Felis imperdiet proin fermentum leo vel orci porta non. Tincidunt nunc pulvinar sapien et. Eros donec ac odio tempor orci dapibus. Iaculis eu non diam phasellus vestibulum lorem. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Amet facilisis magna etiam tempor orci eu.
                </p>
              </div>

            </div>

            <div className="ssd-div" style={selecteddiv == 'ssd-3' ? {} : { display: 'none' }}>
              <div className="ssd-content-heading">
                <h2>Advisory</h2>
              </div>
              <div className="ssd-content-image-div">
                <img className="ssd-content-image" src={advisory} />
              </div>
              <div className="ssd-content-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut porttitor leo a diam. Libero justo laoreet sit amet cursus sit amet dictum sit. Elit ullamcorper dignissim cras tincidunt. Hendrerit dolor magna eget est lorem. Lacus sed viverra tellus in. Neque aliquam vestibulum morbi blandit. At in tellus integer feugiat scelerisque varius morbi enim. Neque volutpat ac tincidunt vitae semper quis lectus nulla. Purus sit amet luctus venenatis lectus. Tincidunt praesent semper feugiat nibh sed pulvinar proin. Morbi quis commodo odio aenean sed adipiscing diam donec. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.
                </p>

                <p>
                  Nulla at volutpat diam ut venenatis tellus in. Neque volutpat ac tincidunt vitae semper. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nunc vel risus commodo viverra maecenas accumsan lacus vel. Eget est lorem ipsum dolor sit. Mi sit amet mauris commodo quis imperdiet. Ut sem nulla pharetra diam sit. Vulputate enim nulla aliquet porttitor. Sit amet commodo nulla facilisi nullam. Amet consectetur adipiscing elit pellentesque habitant morbi. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas erat. Felis imperdiet proin fermentum leo vel orci porta non. Tincidunt nunc pulvinar sapien et. Eros donec ac odio tempor orci dapibus. Iaculis eu non diam phasellus vestibulum lorem. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Amet facilisis magna etiam tempor orci eu.
                </p>
              </div>

            </div>

            <div className="ssd-div" style={selecteddiv == 'ssd-4' ? {} : { display: 'none' }}>
              <div className="ssd-content-heading">
                <h2>Algae Harvesting</h2>
              </div>
              <div className="ssd-content-image-div">
                <img className="ssd-content-image" src={algaeharvesting} />
              </div>
              <div className="ssd-content-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut porttitor leo a diam. Libero justo laoreet sit amet cursus sit amet dictum sit. Elit ullamcorper dignissim cras tincidunt. Hendrerit dolor magna eget est lorem. Lacus sed viverra tellus in. Neque aliquam vestibulum morbi blandit. At in tellus integer feugiat scelerisque varius morbi enim. Neque volutpat ac tincidunt vitae semper quis lectus nulla. Purus sit amet luctus venenatis lectus. Tincidunt praesent semper feugiat nibh sed pulvinar proin. Morbi quis commodo odio aenean sed adipiscing diam donec. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.
                </p>

                <p>
                  Nulla at volutpat diam ut venenatis tellus in. Neque volutpat ac tincidunt vitae semper. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nunc vel risus commodo viverra maecenas accumsan lacus vel. Eget est lorem ipsum dolor sit. Mi sit amet mauris commodo quis imperdiet. Ut sem nulla pharetra diam sit. Vulputate enim nulla aliquet porttitor. Sit amet commodo nulla facilisi nullam. Amet consectetur adipiscing elit pellentesque habitant morbi. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas erat. Felis imperdiet proin fermentum leo vel orci porta non. Tincidunt nunc pulvinar sapien et. Eros donec ac odio tempor orci dapibus. Iaculis eu non diam phasellus vestibulum lorem. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Amet facilisis magna etiam tempor orci eu.
                </p>
              </div>

            </div>

            <div className="ssd-div" style={selecteddiv == 'ssd-5' ? {} : { display: 'none' }}>
              <div className="ssd-content-heading">
                <h2>Narmada Branch Canal Project</h2>
              </div>
              <div className="ssd-content-image-div">
                <img className="ssd-content-image" src={narmadabranchcanalproject} />
              </div>
              <div className="ssd-content-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut porttitor leo a diam. Libero justo laoreet sit amet cursus sit amet dictum sit. Elit ullamcorper dignissim cras tincidunt. Hendrerit dolor magna eget est lorem. Lacus sed viverra tellus in. Neque aliquam vestibulum morbi blandit. At in tellus integer feugiat scelerisque varius morbi enim. Neque volutpat ac tincidunt vitae semper quis lectus nulla. Purus sit amet luctus venenatis lectus. Tincidunt praesent semper feugiat nibh sed pulvinar proin. Morbi quis commodo odio aenean sed adipiscing diam donec. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.
                </p>

                <p>
                  Nulla at volutpat diam ut venenatis tellus in. Neque volutpat ac tincidunt vitae semper. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nunc vel risus commodo viverra maecenas accumsan lacus vel. Eget est lorem ipsum dolor sit. Mi sit amet mauris commodo quis imperdiet. Ut sem nulla pharetra diam sit. Vulputate enim nulla aliquet porttitor. Sit amet commodo nulla facilisi nullam. Amet consectetur adipiscing elit pellentesque habitant morbi. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas erat. Felis imperdiet proin fermentum leo vel orci porta non. Tincidunt nunc pulvinar sapien et. Eros donec ac odio tempor orci dapibus. Iaculis eu non diam phasellus vestibulum lorem. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Amet facilisis magna etiam tempor orci eu.
                </p>
              </div>

            </div>

            <div className="ssd-div" style={selecteddiv == 'ssd-6' ? {} : { display: 'none' }}>
              <div className="ssd-content-heading">
                <h2>Micro Grid Solar Project</h2>
              </div>
              <div className="ssd-content-image-div">
                <img className="ssd-content-image" src={microgridsolar} />
              </div>
              <div className="ssd-content-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut porttitor leo a diam. Libero justo laoreet sit amet cursus sit amet dictum sit. Elit ullamcorper dignissim cras tincidunt. Hendrerit dolor magna eget est lorem. Lacus sed viverra tellus in. Neque aliquam vestibulum morbi blandit. At in tellus integer feugiat scelerisque varius morbi enim. Neque volutpat ac tincidunt vitae semper quis lectus nulla. Purus sit amet luctus venenatis lectus. Tincidunt praesent semper feugiat nibh sed pulvinar proin. Morbi quis commodo odio aenean sed adipiscing diam donec. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.
                </p>

                <p>
                  Nulla at volutpat diam ut venenatis tellus in. Neque volutpat ac tincidunt vitae semper. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nunc vel risus commodo viverra maecenas accumsan lacus vel. Eget est lorem ipsum dolor sit. Mi sit amet mauris commodo quis imperdiet. Ut sem nulla pharetra diam sit. Vulputate enim nulla aliquet porttitor. Sit amet commodo nulla facilisi nullam. Amet consectetur adipiscing elit pellentesque habitant morbi. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas erat. Felis imperdiet proin fermentum leo vel orci porta non. Tincidunt nunc pulvinar sapien et. Eros donec ac odio tempor orci dapibus. Iaculis eu non diam phasellus vestibulum lorem. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Amet facilisis magna etiam tempor orci eu.
                </p>
              </div>

            </div>

            <div className="ssd-div" style={selecteddiv == 'ssd-7' ? {} : { display: 'none' }}>
              <div className="ssd-content-heading">
                <h2>Canal Top Project</h2>
              </div>
              <div className="ssd-content-image-div">
                <img className="ssd-content-image" src={canaltopproject} />
              </div>
              <div className="ssd-content-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut porttitor leo a diam. Libero justo laoreet sit amet cursus sit amet dictum sit. Elit ullamcorper dignissim cras tincidunt. Hendrerit dolor magna eget est lorem. Lacus sed viverra tellus in. Neque aliquam vestibulum morbi blandit. At in tellus integer feugiat scelerisque varius morbi enim. Neque volutpat ac tincidunt vitae semper quis lectus nulla. Purus sit amet luctus venenatis lectus. Tincidunt praesent semper feugiat nibh sed pulvinar proin. Morbi quis commodo odio aenean sed adipiscing diam donec. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.
                </p>

                <p>
                  Nulla at volutpat diam ut venenatis tellus in. Neque volutpat ac tincidunt vitae semper. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nunc vel risus commodo viverra maecenas accumsan lacus vel. Eget est lorem ipsum dolor sit. Mi sit amet mauris commodo quis imperdiet. Ut sem nulla pharetra diam sit. Vulputate enim nulla aliquet porttitor. Sit amet commodo nulla facilisi nullam. Amet consectetur adipiscing elit pellentesque habitant morbi. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas erat. Felis imperdiet proin fermentum leo vel orci porta non. Tincidunt nunc pulvinar sapien et. Eros donec ac odio tempor orci dapibus. Iaculis eu non diam phasellus vestibulum lorem. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Amet facilisis magna etiam tempor orci eu.
                </p>
              </div>

            </div>

            <div className="ssd-div" style={selecteddiv == 'ssd-8' ? {} : { display: 'none' }}>
              <div className="ssd-content-heading">
                <h2>MW Scale Project</h2>
              </div>
              <div className="ssd-content-image-div">
                <img className="ssd-content-image" src={mwscaleproject} />
              </div>
              <div className="ssd-content-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut porttitor leo a diam. Libero justo laoreet sit amet cursus sit amet dictum sit. Elit ullamcorper dignissim cras tincidunt. Hendrerit dolor magna eget est lorem. Lacus sed viverra tellus in. Neque aliquam vestibulum morbi blandit. At in tellus integer feugiat scelerisque varius morbi enim. Neque volutpat ac tincidunt vitae semper quis lectus nulla. Purus sit amet luctus venenatis lectus. Tincidunt praesent semper feugiat nibh sed pulvinar proin. Morbi quis commodo odio aenean sed adipiscing diam donec. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.
                </p>

                <p>
                  Nulla at volutpat diam ut venenatis tellus in. Neque volutpat ac tincidunt vitae semper. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nunc vel risus commodo viverra maecenas accumsan lacus vel. Eget est lorem ipsum dolor sit. Mi sit amet mauris commodo quis imperdiet. Ut sem nulla pharetra diam sit. Vulputate enim nulla aliquet porttitor. Sit amet commodo nulla facilisi nullam. Amet consectetur adipiscing elit pellentesque habitant morbi. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas erat. Felis imperdiet proin fermentum leo vel orci porta non. Tincidunt nunc pulvinar sapien et. Eros donec ac odio tempor orci dapibus. Iaculis eu non diam phasellus vestibulum lorem. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Amet facilisis magna etiam tempor orci eu.
                </p>
              </div>

            </div>

            <div className="ssd-div" style={selecteddiv == 'ssd-9' ? {} : { display: 'none' }}>
              <div className="ssd-content-heading">
                <h2>Rooftop Programme</h2>
              </div>
              <div className="ssd-content-image-div">
                <img className="ssd-content-image" src={rooftopprogramme} />
              </div>
              <div className="ssd-content-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut porttitor leo a diam. Libero justo laoreet sit amet cursus sit amet dictum sit. Elit ullamcorper dignissim cras tincidunt. Hendrerit dolor magna eget est lorem. Lacus sed viverra tellus in. Neque aliquam vestibulum morbi blandit. At in tellus integer feugiat scelerisque varius morbi enim. Neque volutpat ac tincidunt vitae semper quis lectus nulla. Purus sit amet luctus venenatis lectus. Tincidunt praesent semper feugiat nibh sed pulvinar proin. Morbi quis commodo odio aenean sed adipiscing diam donec. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.
                </p>

                <p>
                  Nulla at volutpat diam ut venenatis tellus in. Neque volutpat ac tincidunt vitae semper. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nunc vel risus commodo viverra maecenas accumsan lacus vel. Eget est lorem ipsum dolor sit. Mi sit amet mauris commodo quis imperdiet. Ut sem nulla pharetra diam sit. Vulputate enim nulla aliquet porttitor. Sit amet commodo nulla facilisi nullam. Amet consectetur adipiscing elit pellentesque habitant morbi. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas erat. Felis imperdiet proin fermentum leo vel orci porta non. Tincidunt nunc pulvinar sapien et. Eros donec ac odio tempor orci dapibus. Iaculis eu non diam phasellus vestibulum lorem. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Amet facilisis magna etiam tempor orci eu.
                </p>
              </div>

            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default SuccessStoriesSingle
