import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const News = createAsyncThunk('Newsdata', async() => {
  const response = await publicRequest.get('/newslist');

  return response.data.data;
});

const NewsDataSlice = createSlice({
  name: 'Newsdata',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(News.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default NewsDataSlice.reducer;
