import React from "react";
import './Timeline.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import '../../../Images_1/AboutUs/AboutCompanyImages/TimelineImages/tm-bacground.png'
import TimelineData from './TimelineData'
import OnScrollAnimation from "../../OnScrollAnimation";
// function Timeline() {
//     return (

//         <div className="timeline Milestones-bottom-spacing">
//             <div className="text-center aheading-space">
//                 <h2 className="pbmit-title aboutus-heading-fonts Milestones-top-spacing">Milestones</h2>
//             </div>
//             <div className="container_timeline left">
//                 {/* <div className="date">15 Dec</div> */}
//                 {/* <i className="icon fa fa-home"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading">
//                         <h2>September 2003</h2>
//                     </div>
//                     <div className="timeline-content">
//                         <p>
//                             GERMI-MoUs signed in vibrant Gujarat Business Summit
//                         </p>
//                     </div>

//                 </div>
//             </div>
//             <div className="container_timeline right">
//                 {/* <div className="date">22 Oct</div> */}
//                 {/* <i className="icon fa fa-gift"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading-right">
//                         <h2>April 2004</h2>
//                     </div>
//                     <div className="timeline-content-right">
//                         <p>
//                             GERMI registered as Society and Trust
//                         </p>
//                     </div>
//                 </div>
//             </div>
//             <div className="container_timeline left">
//                 {/* <div className="date">15 Dec</div> */}
//                 {/* <i className="icon fa fa-home"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading">
//                         <h2>April 2007</h2>
//                     </div>
//                     <div className="timeline-content">
//                         <p>
//                             PDPU Act notified by the Govt. of Gujarat
//                         </p>
//                     </div>

//                 </div>
//             </div>
//             <div className="container_timeline right">
//                 {/* <div className="date">18 May</div> */}
//                 {/* <i className="icon fa fa-running"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading-right">
//                         <h2>October 2007</h2>
//                     </div>
//                     <div className="timeline-content-right">
//                         <p>
//                             Inauguration of Pandit Deendayal Petroleum University
//                         </p>
//                     </div>
//                 </div>
//             </div>
//             <div className="container_timeline left">
//                 {/* <div className="date">15 Dec</div> */}
//                 {/* <i className="icon fa fa-home"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading">
//                         <h2>September 2009</h2>
//                     </div>
//                     <div className="timeline-content">
//                         <p>
//                             Ph.D. Program launched at School of Solar Energy, PDPU
//                         </p>
//                     </div>

//                 </div>
//             </div>
//             <div className="container_timeline right">
//                 {/* <div className="date">01 Jan</div> */}
//                 {/* <i className="icon fa fa-certificate"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading-right">
//                         <h2>October 2009</h2>
//                     </div>
//                     <div className="timeline-content-right">
//                         <p>
//                             Inauguration of GRIIC Laboratories
//                         </p>
//                     </div>
//                 </div>
//             </div>
//             <div className="container_timeline left">
//                 {/* <div className="date">15 Dec</div> */}
//                 {/* <i className="icon fa fa-home"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading">
//                         <h2>June, 2010</h2>
//                     </div>
//                     <div className="timeline-content">
//                         <p>
//                             Recognized by DSIR, GoI
//                         </p>
//                     </div>

//                 </div>
//             </div>
//             <div className="container_timeline right">
//                 {/* <div className="date">01 Jan</div> */}
//                 {/* <i className="icon fa fa-certificate"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading-right">
//                         <h2>December 2010 </h2>
//                     </div>
//                     <div className="timeline-content-right">
//                         <p>
//                             1st Solar Professional Workshop conducted
//                         </p>
//                     </div>
//                 </div>
//             </div>
//             <div className="container_timeline left">
//                 {/* <div className="date">15 Dec</div> */}
//                 {/* <i className="icon fa fa-home"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading">
//                         <h2>November 2012</h2>
//                     </div>
//                     <div className="timeline-content">
//                         <p>
//                             ISO 9001:2008 Certification
//                         </p>
//                     </div>

//                 </div>
//             </div>
//             <div className="container_timeline right">
//                 {/* <div className="date">01 Jan</div> */}
//                 {/* <i className="icon fa fa-certificate"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading-right">
//                         <h2>April 2013 </h2>
//                     </div>
//                     <div className="timeline-content-right">
//                         <p>
//                             5 MW Gandhinagar Rooftop Program, MNRE
//                         </p>
//                     </div>
//                 </div>
//             </div>
//             <div className="container_timeline left">
//                 {/* <div className="date">15 Dec</div> */}
//                 {/* <i className="icon fa fa-home"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading">
//                         <h2>June 2014</h2>
//                     </div>
//                     <div className="timeline-content">
//                         <p>
//                             Data Interpretation Centre for Oil & Gas Research
//                         </p>
//                     </div>

//                 </div>
//             </div>
//             <div className="container_timeline right">
//                 {/* <div className="date">01 Jan</div> */}
//                 {/* <i className="icon fa fa-certificate"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading-right">
//                         <h2>June 2015 </h2>
//                     </div>
//                     <div className="timeline-content-right">
//                         <p>
//                             National Certification Program was launched
//                         </p>
//                     </div>
//                 </div>
//             </div>
//             <div className="container_timeline left">
//                 {/* <div className="date">15 Dec</div> */}
//                 {/* <i className="icon fa fa-home"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading">
//                         <h2>December 2017</h2>
//                     </div>
//                     <div className="timeline-content">
//                         <p>
//                             Best Solar Training Institute Award from SCGJ
//                         </p>
//                     </div>

//                 </div>
//             </div>
//             <div className="container_timeline right">
//                 {/* <div className="date">01 Jan</div> */}
//                 {/* <i className="icon fa fa-certificate"></i> */}
//                 <div className="content-timeline">
//                     <div className="timeline-heading-right">
//                         <h2>September 2018 </h2>
//                     </div>
//                     <div className="timeline-content-right">
//                         <p>
//                             1000 MW of Project Management Consultancy
//                         </p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Timeline;

function Timeline() {
    return (
        <>
            <section className="tm-section">
                <div className="tm-pattern-layer"></div>
                <div className="tm-auto-container">
                    <div className="sec-title light tm-centred">
                        {/* <div className="title-top">
                            <h6>Our History</h6>
                        </div> */}
                        {/* <div className="heading-container">
                            <h4 id="title2" className="title-animation-about-us color-white">Significant Historical Milestones</h4>
                            <div className="div-for-hr-animation">
                                <hr id="hr1" className="animate-hr-tag-about-us" />
                                <hr id="hr2" className="animate-hr-tag-about-us" />
                            </div>
                        </div> */}
                        <OnScrollAnimation isWhite="true" title1="tc1" title2="tc2" hr1="tc3" hr2="tc4" heading="Significant Historical Milestones" subheading="" />
                        {/* <div className="title-text tm-centred">
                            <p className="tm-centred">These cases are perfectly simple easy to distinguish free hour <br />power off choice isuntrammelled.</p>
                        </div> */}
                    </div>
                </div>
                <div className="tm-outer-container">
                    <div>
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            spaceBetween={10}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                460: {
                                    slidesPerView: 2,
                                },
                                576: {
                                    slidesPerView: 3,
                                },
                                720: {
                                    slidesPerView: 4,
                                },
                                1024: {
                                    slidesPerView: 6,
                                },
                                1420: {
                                    slidesPerView: 8,
                                },

                            }}
                            // slidesPerView={8}
                            // navigation
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 3000, disableOnInteraction: false }}
                            loop={true}>
                            <SwiperSlide>
                                <TimelineData date="September 2003"
                                    desc="GERMI-MoUs signed in vibrant Gujarat Business Summit" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="April 2004"
                                    desc="GERMI registered as Society and Trust"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="April 2007"
                                    desc="PDPU Act notified by the Govt. of Gujarat"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="October 2007"
                                    desc="Inauguration of Pandit Deendayal Petroleum University"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="September 2009"
                                    desc="Ph.D. Program launched at School of Solar Energy, PDPU"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="October 2009"
                                    desc="Inauguration of GRIIC Laboratories"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="June 2010"
                                    desc="Recognized by DSIR, GoI"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="December 2010"
                                    desc="1st Solar Professional Workshop conducted"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="November 2012"
                                    desc="ISO 9001:2008 Certification"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="April 2013"
                                    desc="5 MW Gandhinagar Rooftop Program, MNRE"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="June 2014"
                                    desc="Data Interpretation Centre for Oil & Gas Research"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="June 2015"
                                    desc="Best Solar Training Institute Award from SCGJ"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TimelineData date="September 2018"
                                    desc="1000 MW of Project Management Consultancy"
                                />
                            </SwiperSlide>
                        </Swiper>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Timeline;