import React, { useEffect, useRef, useState } from 'react'
import './SuccessStoriesDetail.css'
import { Link, useLocation, useParams } from 'react-router-dom'
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import StoriesCover from '../../../Images_1/CommonSection/Braedcrumbs/StoriesCover.jpg'
import successStories from '../../../json/successStoriesData.json'
function DemoSS(props) {
    const location = useLocation();
    const { id,did } = useParams();
    const [activeid,setactiveid]=useState(parseInt(did));
    var index3 = successStories.findIndex(
        (x) => x.id == id
    );
    const divRefs = useRef([]);
    useEffect(() => {
        setactiveid(parseInt(did))
        if (index3 !== null && divRefs.current[index3]) {
            divRefs.current[index3].scrollIntoView({ behavior: 'smooth' });
            divRefs.current[index3].focus();
        }
    }, [index3,did]);
    return (
        <div>
            <BreadCrumbs parent="Home" child="Success Stories" parentPath="/" imgPath={StoriesCover} />
            <div className='buttons-container'>
                <button onClick={()=>setactiveid(1)} className={activeid==1?'top-button-active1':'top-button'}>New Technology Management</button>
                <button onClick={()=>setactiveid(2)}  className={activeid==2?'top-button-active1':'top-button'}>Renewables Management</button>
                <button onClick={()=>setactiveid(3)}  className={activeid==3?'top-button-active1':'top-button'}>Petroleum Management</button>
                <button onClick={()=>setactiveid(5)}  className={activeid==5?'top-button-active1':'top-button'}>Petroleum Research Management</button>
               
                <button onClick={()=>setactiveid(4)}  className={activeid==4?'top-button-active1':'top-button'}>Environment Management</button>
            </div>
           
           
            <section className='department-details-section'>





                <div className='department-details-div-container'>

                    {
                        activeid=="0"? successStories
                        .map((data, index) => {
                            if (index % 2 != 0) {
                                return (<div
                                    key={index}
                                    ref={(el) => (divRefs.current[index] = el)}
                                    className='department-details-div dep-div-1'>
                                    <div className='flex-center-div'>
                                        <div className='department-details-div-text-div'>
                                            <h4 className='department-details-heading thirty-eighteen-fonts'>{data.title}</h4>
                                            <p className='department-details-text normal-text'>
                                                {
                                                    // data.description.map(
                                                    //     (item) => (
                                                    //         <p className='normal-text'>{item}  </p>
                                                    //     )
                                                    // )
                                                    data.description
                                                }
                                            </p>
                                            {/* <Link to='/successstoriessingle' state={data.id}>
                                                <div className='seemore-text-department'>
                                                    <button className='see-more-button-department'>
                                                        Read More
                                                    </button>
                                                </div>
                                            </Link> */}
                                        </div>
                                        <div className='department-details-div-image-div-1-1'>

                                            <div className='department-details-div-image-div-2'>
                                                <img className='ss dep-image-3' src={data.imageSrc} />
                                            </div>
                                            {/* <div className='department-details-div-image-div-1'>
                                                <img className='dep-detail-image dep-image-1' src={data.imageSrc} />
                                                <img className='dep-detail-image dep-image-2' src={image2} />
                                            </div>
                                         
                                            <div className='department-details-div-image-div-2'>
                                                <img className='dep-detail-image dep-image-3' src={image1} />
                                            </div> */}

                                        </div>
                                    </div>
                                </div>);
                            }
                            else {
                                return (<div className='dep-div-2'>
                                    <div
                                        key={index}
                                        ref={(el) => (divRefs.current[index] = el)}
                                        className='department-details-div dep-div-2-bg'>
                                        <div className='flex-center-div dep-div-2'>
                                            <div className='department-details-div-text-div'>
                                                <h4 className='department-details-heading thirty-eighteen-fonts'>{data.title}</h4>
                                                <p className='department-details-text normal-text'>
                                                    {
                                                      data.description
                                                    }
                                                </p>
                                               
                                            </div>
                                            <div className='department-details-div-image-div-1-2'>
                                                <img className='ss dep-image-3' src={data.imageSrc} />
                                            </div>
                                        </div>
                                    </div>
                                </div>);
                            }
                        }):
                        successStories.filter(story => story.departmentId === activeid)
                        .map((data, index) => {
                            if (index % 2 != 0) {
                                return (<div
                                    key={index}
                                    ref={(el) => (divRefs.current[index] = el)}
                                    className='department-details-div dep-div-1'>
                                    <div className='flex-center-div'>
                                        <div className='department-details-div-text-div'>
                                            <h4 className='department-details-heading thirty-eighteen-fonts'>{data.title}</h4>
                                            <p className='department-details-text normal-text'>
                                                {
                                                    data.description
                                                }
                                            </p>
                                            
                                        </div>
                                        <div className='department-details-div-image-div-1-1'>

                                            <div className='department-details-div-image-div-2'>
                                                <img className='ss dep-image-3' src={data.imageSrc} />
                                            </div>
                                            {/* <div className='department-details-div-image-div-1'>
                                                <img className='dep-detail-image dep-image-1' src={data.imageSrc} />
                                                <img className='dep-detail-image dep-image-2' src={image2} />
                                            </div>
                                         
                                            <div className='department-details-div-image-div-2'>
                                                <img className='dep-detail-image dep-image-3' src={image1} />
                                            </div> */}

                                        </div>
                                    </div>
                                </div>);
                            }
                            else {
                                return (<div className='dep-div-2'>
                                    <div
                                        key={index}
                                        ref={(el) => (divRefs.current[index] = el)}
                                        className='department-details-div dep-div-2-bg'>
                                        <div className='flex-center-div dep-div-2'>
                                            <div className='department-details-div-text-div'>
                                                <h4 className='department-details-heading thirty-eighteen-fonts'>{data.title}</h4>
                                                <p className='department-details-text normal-text'>
                                                    {
                                                        // data.description.map(
                                                        //     (item) => (
                                                        //         <ul>
                                                        //             <li style={{ paddingBottom: '5px' }}>{item}</li>
                                                        //         </ul>
                                                        //     )
                                                        // )
                                                        data.description
                                                    }
                                                </p>
                                            </div>
                                            <div className='department-details-div-image-div-1-2'>
                                                <img className='ss dep-image-3' src={data.imageSrc} />
                                            </div>
                                        </div>
                                    </div>
                                </div>);
                            }
                        })
                    }

                </div>
            </section>
        </div>
    )
}

export default DemoSS