import "./SolarCalculator.css";
import React, { useState } from "react";
import handleCalculation from "./HandleCalculation";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { calculatePlantSizeArea, calculatePlantSizeBudget, getCategoryFromState, getBenchmark, getSubsidy, getIrradiationEle, getIrradiation } from './HandleCalculation';

import {
  Paper,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { blue } from "@mui/material/colors";
import EmiCalculator from "./EmiCalculator";

const customers = {
  "Residential": 2,
  "Institutional": 4,
  "Industrial": 3,
  "Commercial": 1,
  "Government": 5,
  "Social Sector": 7
}
const states = {
  "Andhra Pradesh": 28,
  "Arunachal Pradesh": 12,
  Assam: 18,
  Bihar: 10,
  Chhattisgarh: 22,
  Goa: 30,
  Gujarat: 24,
  Haryana: 6,
  "Himachal Pradesh": 1,
  Jharkhand: 20,
  Karnataka: 29,
  Kerala: 32,
  "Madhya Pradesh": 23,
  Maharashtra: 27,
  Manipur: 14,
  Meghalaya: 17,
  Mizoram: 15,
  Nagaland: 13,
  Odisha: 21,
  Punjab: 3,
  Rajasthan: 8,
  Sikkim: 11,
  "Tamil Nadu": 33,
  Telangana: 36,
  Tripura: 16,
  "Uttar Pradesh": 9,
  Uttarakhand: 5,
  "West Bengal": 19,
  // Union Territories
  "Andaman and Nicobar Islands": 35,
  Chandigarh: 1,
  "Dadra and Nagar Haveli": 26,
  "Daman and Diu": 25,
  Delhi: 7,
  "Jammu and Kashmir": 1,
  Ladakh: 1,
  Lakshadweep: 31,
  Puducherry: 34,
};

const getStateName = (state) => {
  const theState = Object.keys(states).filter((item) => {
    return parseInt(states[item]) === parseInt(state);
  }
  )
  return theState[0];
}

const SolarCalculator = () => {
  const [limitCrossed, setLimitCrossed] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [capacity, setCapacity] = useState(0);
  const [benchmark, setBenchmark] = useState(0);
  const [subsidy, setSubsidy] = useState(0);
  const [output, setOutput] = useState(0);
  const [withSubsidy, setWithSubsidy] = useState(0);
  const [electricityAnnual, setElectricityAnnual] = useState(0);
  const [electricityLifetime, setElectricityLifetime] = useState(0);
  const [savingMonthly, setSavingMonthly] = useState(0);
  const [savingAnnually, setSavingAnnually] = useState(0);
  const [savingLifetime, setSavingLifetime] = useState(0);
  const [co2, setCo2] = useState(0);
  const [trees, setTrees] = useState(0);
  const [generate, setGenerate] = useState(0);
  const [irradiate, setIrradiate] = useState(0);
  const [stateName, setStateName] = useState("");
  const handleCalculation = (data) => {
    // console.log(data);
    let calculateType = parseInt(data.caclulateType);
    let cap = 0, benchmark = 0, output = 0, additional_budget = 0, category = getCategoryFromState(data.state);
    let avgElecCost = parseInt(data.avgElecCost, 10);

    if (calculateType === 0) {
      // let shadowFreeAreaPer = data.shadowFreeAreaPer? parseInt(data.shadowFreeAreaPer, 10): 0;
      cap = calculatePlantSizeArea(data.areaType, data.area ? parseInt(data.area, 10) : 0, data.shadowFreeAreaPer ? parseInt(data.shadowFreeAreaPer) : 0);
      benchmark = getBenchmark(cap, category, data.customerCategory);
      output = benchmark * cap;
    }
    else if (calculateType === 1) {
      cap = data.capacityKW ? parseInt(data.capacityKW, 10) : 0;
      benchmark = getBenchmark(cap, category, data.customerCategory);
      output = benchmark * cap;
    }
    else {
      let budget = data.budget ? parseInt(data.budget, 10) : 0;
      cap = calculatePlantSizeBudget(budget, category, data.customerCategory);
      benchmark = getBenchmark(cap, category, data.customerCategory);
      if (budget < benchmark) budget = benchmark;
      output = budget;

    }
    if (cap > 500) {
      setLimitCrossed(true);
      setTimeout(function () {
        setLimitCrossed(false);
      }, 10000);
      return;
    }
    let subsidy = getSubsidy(cap, category, data.customerCategory);
    let subsidy2 = 1 - subsidy / 100;
    let outputWithSubsidy = Math.round(output * subsidy2);
    let generate = getIrradiationEle(data.state);
    let electricityAnnual = Math.round(generate * cap * 300);
    let electricityLifetime = electricityAnnual * 25;
    let valueA = 0.82 * electricityLifetime;
    let valueB = 625;
    let co2 = Math.round(valueA / 1e3);
    let trees = Math.round(valueA / valueB);
    let savingAnnually = avgElecCost * electricityAnnual;
    let savingMonthly = Math.round(savingAnnually / 12);
    let savingLifetime = savingAnnually * 25;

    setCapacity(cap);
    setBenchmark(benchmark);
    setOutput(output);
    setWithSubsidy(outputWithSubsidy);
    setElectricityAnnual(electricityAnnual);
    setElectricityLifetime(electricityLifetime);
    setSavingMonthly(savingMonthly);
    setSavingAnnually(savingAnnually);
    setSavingLifetime(savingLifetime);
    setCo2(co2);
    setTrees(trees);
    setSubsidy(subsidy2);
    setGenerate(generate);
    setIrradiate(getIrradiation(data.state));
    setStateName(getStateName(data.state));
    setCalculated(true);




    // console.log(electricityAnnual);
    // console.log(co2);
    // console.log(trees);
  }

  const buttonSx = {
    ...{
      bgcolor: blue[700],
      "&:hover": {
        bgcolor: blue[800],
      },
    },
  };

  const defaultValues = {
    shadowFreeAreaPer: 100,
    capacityKW: 0,
    budget: 100000,
    avgElecCost: 4,
    area: 0,
    areaType: "sqm",
    state: "",
    customerCategory: "",
    loanIntRate: 5,
    loanPeriod: 1,
    caclulateType: "0",
  };

  const validationSchema = Yup.object().shape({
    caclulateType: Yup.string().required("Type for Capacity Calculation is required"),
    state: Yup.string().required("State is Required"),
    customerCategory: Yup.string().required("Customer Category is Required"),
    avgElecCost: Yup.string().required("Avg Electricity Cost is required")
  });


  const [currentOpen, setCurrentOpen] = useState(0);
  const { handleSubmit, setValue, control, reset, watch, formState } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const renderRoofTopArae = () => (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Controller
          name="area"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.area}
              required
              type="number"
              helperText={errors?.area?.message}
              label="Area"
              // autoFocus
              id="area"
              variant="outlined"
              style={{ width: "75%" }}
              onInput={
                (event) => {
                  if (event.target.value > 1e10) event.target.value = 1e10;
                }
              }
            />
          )}
        />
        <Controller
          name="areaType"
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              id="areaType"
              row
              name="areaType"
              value={field.value}
              style={{ width: "23%" }}
            >
              <FormControlLabel
                value={"sqm"}
                control={<Radio />}
                label="Sq. m."
              />
              <FormControlLabel
                value={"sqfeet"}
                control={<Radio />}
                label="Sq. Feet."
              />
            </RadioGroup>
          )}
        />
      </div>
      <Controller
        name="shadowFreeAreaPer"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.shadowFreeAreaPer}
            required
            type="number"
            helperText={errors?.shadowFreeAreaPer?.message}
            label="% of Shadow Free Open Space Available"
            // autoFocus
            id="shadowFreeAreaPer"
            variant="outlined"
            style={{ width: "100%", marginBottom: "5px" }}
            onInput={
              (event) => {
                if (event.target.value > 100) event.target.value = 100;
              }
            }
          />
        )}
      />
      {/* <Controller
        name="shadowFreeAreaPer"
        control={control}
        render={({ field }) => (
          <Slider
            {...field}
            // size="small"
            // aria-label="Small"
            // valueLabelDisplay="auto"
            error={!!errors.shadowFreeAreaPer}
            required
            type="number"
            helperText={errors?.shadowFreeAreaPer?.message}
            // autoFocus
            id="shadowFreeAreaPer"
            variant="outlined"
            style={{ width: "100%" }}
          />
        )}
      /> */}
    </div>
  );

  const renderSolarPanelCapacity = () => (
    <>
      <Controller
        name="capacityKW"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.capacityKW}
            required
            type="number"
            helperText={errors?.capacityKW?.message}
            label="Solar Capacity (in KW)"
            // autoFocus
            id="capacityKW"
            variant="outlined"
            style={{ width: "100%", marginBottom: "5px" }}
          />
        )}
      />
      {/* <Controller
        name="capacityKW"
        control={control}
        render={({ field }) => (
          <Slider
            {...field}
            // size="small"
            // aria-label="Small"
            // valueLabelDisplay="auto"
            error={!!errors.capacityKW}
            required
            type="number"
            helperText={errors?.capacityKW?.message}
            // autoFocus
            id="capacityKW"
            variant="outlined"
            style={{ width: "100%" }}
          />
        )}
      /> */}
    </>
  );
  const renderYourBudget = () => (
    <>
      <Controller
        name="budget"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.budget}
            required
            type="number"
            helperText={errors?.budget?.message}
            label="Your Budget (in Rs.)"
            // autoFocus
            id="budget"
            variant="outlined"
            style={{ width: "100%", marginBottom: "5px" }}
          />
        )}
      />
      {/* <Controller
        name="budget"
        control={control}
        render={({ field }) => (
          <Slider
            {...field}
            // size="small"
            // aria-label="Small"
            // valueLabelDisplay="auto"
            error={!!errors.budget}
            required
            type="number"
            helperText={errors?.budget?.message}
            // autoFocus
            id="budget"
            variant="outlined"
            min={100000}
            max={10000000}
            style={{ width: "100%" }}
          />
        )}
      /> */}
    </>
  );




  return (
    <>
      <div
        className="calc-main-div"
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          margin: "50px 0px",
        }}
      >

        <Paper style={{ width: "85%" }}>
          <div className="form-container">
            {calculated ?
              (
                <>
                  <p style={{ padding: '20px 0px', textAlign: 'center' }}>Average Global Horizontal Irradiation (GHI) in {stateName} state is {irradiate} W / sq.m.<br></br>
                    1kWp solar rooftop plant will generate on an average over the year {generate} Unit of electricity per day (considering 5.5 sunshine hours).
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: 'center',
                      width: "100%",
                      gap: '40px'
                    }}
                    className="solar-result-div"
                  >
                    <div className="main-div-1 static-normal-text" style={{ width: '100%' }}>
                      <div className="result-header static-subheading-text">
                        Solar Rooftop Calculator Result
                      </div>

                      <div className="result-block">
                        <div className="result-title sc-bg-color" style={{ padding: '20px', }}>1. Size of Power Plant</div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: '0px 20px',
                          }}
                        >
                          <div className="result-sub-title sc-bg-color" >
                            {currentOpen == 0 ? "Feasible Plant size as per your Roof Top Area :" :
                              (currentOpen == 1 ? "Feasible Plant size as per your Capacity  :" :
                                "Feasible Plant size as per your Budget:")}
                          </div>
                          <div className="result-sub-result">{capacity} kW</div>
                        </div>
                      </div>

                      <div className="result-block">
                        <div className="result-title sc-bg-color" style={{ padding: '20px', }}>2. Cost of the Plant :</div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: '0px 20px',
                          }}
                        >
                          <div className="result-sub-title">
                            MNRE current Benchmark Cost:
                          </div>
                          <div className="result-sub-result">Rs. {benchmark} / kW</div>
                        </div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: '0px 20px',
                          }}
                        >
                          <div className="result-sub-title">
                            Without subsidy (Based on current MNRE benchmark):
                          </div>
                          <div className="result-sub-result">Rs. {output}</div>
                        </div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: '0px 20px'
                          }}
                        >
                          <div className="result-sub-title">
                            With subsidy {100 - (subsidy * 100)} % (Based on current MNRE benchmark) :
                          </div>
                          <div className="result-sub-result">Rs. {withSubsidy}</div>
                        </div>
                      </div>

                      <div className="result-block">
                        <div className="result-title sc-bg-color" style={{ padding: '20px', }}>
                          3. Electricity Generation from Solar :
                        </div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: '0px 20px',
                          }}
                        >
                          <div className="result-sub-title">
                            Annual (12 Months) :
                          </div>
                          <div className="result-sub-result">{electricityAnnual} Unit</div>
                        </div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: '0px 20px',
                          }}
                        >
                          <div className="result-sub-title">
                            Life-Time (25 years):
                          </div>
                          <div className="result-sub-result">{electricityLifetime} Unit</div>
                        </div>

                        <div className="result-block">
                          <div className="result-title sc-bg-color" style={{ padding: '20px', }}>
                            4. Financial Savings :
                          </div>
                        </div>
                      </div>

                      <div className="result-block">
                        <div className="result-title sc-bg-color" style={{ padding: '0px 20px 20px', }}>
                          a) Tariff @ Rs. 8 / Unit - No increase assumed over 25
                          years :
                        </div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: '0px 20px',
                          }}
                        >
                          <div className="result-sub-title">Monthly :</div>
                          <div className="result-sub-result">Rs. {savingMonthly}</div>
                        </div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: '0px 20px',
                          }}
                        >
                          <div className="result-sub-title">Annually :</div>
                          <div className="result-sub-result">Rs. {savingAnnually}</div>
                        </div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "15px",
                            padding: '0px 20px',
                          }}
                        >
                          <div className="result-sub-title">
                            Life-Time (25 years) :
                          </div>
                          <div className="result-sub-result">Rs. {savingLifetime}</div>
                        </div>
                      </div>

                      <div className="result-block sc-bg-color">
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: '10px 20px',
                          }}
                        >
                          <div className="result-title" style={{ fontWeight: 'normal' }}>
                            CO2 emission is
                          </div>
                          <div className="result-sub-result" style={{ marginBottom: '0px' }}>{co2} tonnes</div>
                        </div>
                        <div
                          className="sc-bg-color"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "15px",
                            padding: '10px 20px',
                          }}
                        >
                          <div className="result-title" style={{ fontWeight: 'normal' }}>
                            Installation equivalent to planting
                          </div>
                          <div className="result-sub-result" style={{ marginBottom: '0px' }}>{trees} Teak trees</div>
                        </div>
                      </div>
                    </div>
                    <EmiCalculator principle={output} subsidy={Math.round((1 - subsidy) * 100)} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: 'center',
                      marginTop: '20px',
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      style={{ backgroundColor: '#7cba27' }}
                      // disabled={loadingState || !clerification}
                      onClick={() => {
                        setCalculated(false);
                      }}
                    >
                      <div className="xss-small-text">Close</div>

                    </Button>
                  </div>
                </>
              )
              :
              (
                <>
                  <form style={{ alignSelf: "center" }} className="static-small-text">
                    <div className="text-salary">
                      {
                        limitCrossed && "Maximum Allowed Capacity is 500KW"
                      }
                    </div>
                    <div className="text-salary">
                      1. Choose any one of the following
                    </div>
                    <Controller
                      name="caclulateType"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          id="caclulateType"
                          row
                          // name="caclulateType"
                          className="mb-2 static-small-text"
                          onChange={(test) => {
                            setCurrentOpen(test.target.value);
                            setValue("caclulateType", test.target.value);

                          }}
                        >
                          <FormControlLabel
                            // name="caclulateType"
                            value={0}
                            control={<Radio />}
                            label="Total Roof Top Area&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OR&nbsp;&nbsp;&nbsp;&nbsp;"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: 'var(--global-font-size-sub-title-xs)', // Adjust the size as needed
                              },
                            }}
                          />
                          <FormControlLabel
                            // name="caclulateType"
                            value={1}
                            control={<Radio />}
                            label="Solar Panel Capacity you want to install&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OR&nbsp;&nbsp;&nbsp;&nbsp;"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: 'var(--global-font-size-sub-title-xs)', // Adjust the size as needed
                              },
                            }}
                          />
                          <FormControlLabel
                            // name="caclulateType"
                            value={2}
                            control={<Radio />}
                            label="Your budget"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: 'var(--global-font-size-sub-title-xs)', // Adjust the size as needed
                              },
                            }}
                          />
                        </RadioGroup>
                      )}
                    />
                    {currentOpen == 0
                      ? renderRoofTopArae()
                      : currentOpen == 1
                        ? renderSolarPanelCapacity()
                        : currentOpen == 2
                          ? renderYourBudget()
                          : ""}

                    <div className="text-salary">
                      2. Select State and Customer Category
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                      className='state-button-container'
                    >
                      <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            style={{ width: "47.5%" }}
                            variant="outlined"
                            error={!!errors.state}
                          >
                            <InputLabel id="state-lable" className="form-input-color">Select State</InputLabel>
                            <Select
                              labelId="state-lable"
                              {...field}
                              className="mb-2"
                              label="Select State"
                              // autoFocus
                              id="state"
                              required
                            >
                              {/* <MenuItem value="">Select Experience (in years)</MenuItem> */}
                              {Object.keys(states).map((i) => (
                                <MenuItem value={states[i]}>{i}</MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {errors?.state?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />

                      <Controller
                        name="customerCategory"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            style={{ width: "47.5%" }}
                            variant="outlined"
                            error={!!errors.customerCategory}
                          >
                            <InputLabel id="customerCategory-lable" className="form-input-color">
                              Select Customer Category
                            </InputLabel>
                            <Select
                              labelId="customerCategory-lable"
                              {...field}
                              className="mb-2"
                              label="Select Customer Category"
                              // autoFocus
                              id="customerCategory"
                              required
                            >
                              {/* <MenuItem value="">Select Experience (in years)</MenuItem> */}
                              {Object.keys(customers).map((i) => (
                                <MenuItem value={customers[i]}>{i}</MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {errors?.customerCategory?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </div>

                    <div className="text-salary">
                      3. What is your average Electricity Cost?
                    </div>
                    <Controller
                      name="avgElecCost"
                      control={control}
                      className="form-input-color"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          avgElecCost
                          error={!!errors.budget}
                          required
                          type="number"
                          helperText={errors?.avgElecCost?.message}
                          label="Average Electricity Cost (in Rs./Unit)"
                          id="avgElecCost"
                          variant="outlined"
                          style={{ width: "100%", marginBottom: "5px" }}
                          className="form-input-color"

                          onInput={
                            (event) => {
                              if (event.target.value > 20) event.target.value = 20;
                            }
                          }
                        />
                      )}
                    />
                    {/* <Controller
                      name="avgElecCost"
                      control={control}
                      render={({ field }) => (
                        <Slider
                          {...field}
                          // size="small"
                          // aria-label="Small"
                          // valueLabelDisplay="auto"
                          error={!!errors.avgElecCost}
                          required
                          type="number"
                          helperText={errors?.avgElecCost?.message}
                          // autoFocus
                          id="avgElecCost"
                          variant="outlined"
                          min={4}
                          max={20}
                          style={{ width: "100%" }}
                        />
                      )}
                    /> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",

                      }}
                    >
                      <Button
                        variant="contained"
                        sx={buttonSx}
                        style={{ width: "max-content", backgroundColor: '#7cba27', marginTop:"20px" }}
                        // disabled={loadingState || !clerification}
                        onClick={
                          handleSubmit(handleCalculation)


                          //   () => {
                          //   setCalculated(true);
                          // }
                        }
                      >
                        <div className="xxs-small-text">Calculate</div>
                      </Button>
                    </div>
                  </form>
                </>
              )}
          </div>
        </Paper>
      </div>
    </>
  );
};

export default SolarCalculator;
