import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../../../constants";

export const addDonation = createAsyncThunk(
  "addgivedata",
  async (data) => {
    const json=JSON.stringify(data)
    // console.log("here")
    const res = await publicRequest.post("/addgivedata", json);
    if (res) {
      // console.log(res);
      if (res.data.status) {
        document.getElementById('msg-div').innerText = res.data.message;
      //   setTimeout(function() {
      //     document.getElementById('msg-div').innerText = '';
      // }, 50000);
      
        // alert(res.data.message)
      }
      return res.data
    }

  }
);


const addDonationSlice = createSlice({
  name: "addgivedata",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addDonation.fulfilled, (state, action) => {
      return action.payload
    })
  }
});

export default addDonationSlice.reducer;  