import React from 'react'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './OurTeam.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import BreadCrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/team.jpg'
function OurTeams() {
    return (
        <div>
            <div>
                <BreadCrumbs parent="Home" child="Our Team" parentPath="/" imgPath={BreadCrumbImage} />
            </div>

            <section className="section-lgb tm-main">
                <div className="container-team pbmit-col-stretched-yes pbmit-col-right">
                    <div className="row main-div-team">
                        <div className='heading-container' style={{ color: "#18181B" }}>
                            <h4 className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }} >Our Team</h4>
                            <h2 id="title1" className='title-animation-about-us heading-container-white-fonts' style={{ color: "#18181B" }}>Explore Our Team</h2>
                            <div className=' div-for-hr-animation'>
                                <hr id='hr1' className='animate-hr-tag-about-us' />
                                <hr id='hr2' className='animate-hr-tag-about-us' />
                            </div>
                        </div>

                        <div className='team-grid-div'>
                            <div className="pbmit-service-style-4">
                                <div className="pbminfotech-post-item">
                                    <div className="pbmit-featured-wrapper img-border">
                                        {/* <img src={team1} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                    </div>
                                    <div className='team-content-div'>
                                        <div className='team-about-div'>
                                            <h3 className='team-name-class'>Cody Fisher</h3>
                                            <h4 className='team-desig-class small-text'>Developer</h4>
                                        </div>
                                        <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pbmit-service-style-4">
                                <div className="pbminfotech-post-item">
                                    <div className="pbmit-featured-wrapper img-border">
                                        {/* <img src={team2} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                    </div>
                                    <div className='team-content-div'>
                                        <div className='team-about-div'>
                                            <h3 className='team-name-class'>Cody Fisher</h3>
                                            <h4 className='team-desig-class small-text'>Developer</h4>
                                        </div>
                                        <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pbmit-service-style-4">
                                <div className="pbminfotech-post-item">
                                    <div className="pbmit-featured-wrapper img-border">
                                        {/* <img src={team3} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                    </div>
                                    <div className='team-content-div'>
                                        <div className='team-about-div'>
                                            <h3 className='team-name-class'>Cody Fisher</h3>
                                            <h4 className='team-desig-class small-text'>Developer</h4>
                                        </div>
                                        <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pbmit-service-style-4">
                                <div className="pbminfotech-post-item">
                                    <div className="pbmit-featured-wrapper img-border">
                                        {/* <img src={team4} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                    </div>
                                    <div className='team-content-div'>
                                        <div className='team-about-div'>
                                            <h3 className='team-name-class'>Cody Fisher</h3>
                                            <h4 className='team-desig-class small-text'>Developer</h4>
                                        </div>
                                        <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pbmit-service-style-4">
                                <div className="pbminfotech-post-item">
                                    <div className="pbmit-featured-wrapper img-border">
                                        {/* <img src={team5} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                    </div>
                                    <div className='team-content-div'>
                                        <div className='team-about-div'>
                                            <h3 className='team-name-class'>Cody Fisher</h3>
                                            <h4 className='team-desig-class small-text'>Developer</h4>
                                        </div>
                                        <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pbmit-service-style-4">
                                <div className="pbminfotech-post-item">
                                    <div className="pbmit-featured-wrapper img-border">
                                        {/* <img src={team6} className="img-fluid img-radius img-team-dept-style" alt="" /> */}
                                    </div>
                                    <div className='team-content-div'>
                                        <div className='team-about-div'>
                                            <h3 className='team-name-class'>Cody Fisher</h3>
                                            <h4 className='team-desig-class small-text'>Developer</h4>
                                        </div>
                                        <div className='team-socials-div'>
                                            <ul className="our-team-social-links socials-gap-class">
                                                <li className="pbmit-social-link pbmit-social-facebook">
                                                    <Link to="https://www.facebook.com/GERMI.GRIIC/" target="_blank"><FacebookIcon />
                                                        <span><i></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-twitter">
                                                    <Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
                                                        <span><i className="pbmit-base-icon-twitter-square"></i></span>
                                                    </Link>
                                                </li>
                                                <li className="pbmit-social-li pbmit-social-linkedin">
                                                    <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/" target="_blank">
                                                        <span><i><LinkedInIcon /></i></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OurTeams
