import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import TenderGridView from './TenderGridView';
// import Calendar from 'react-calendar';
// import Calender from './Calender';



function Tenders(props) {
 
    const [selecteddiv, setSelecteddiv] = useState('service-2');

    return (
        <div>
            {/* Page Wrapper */}
            <div className="page-wrapper">
                {/* Title Bar */}
                <div className="pbmit-title-bar-wrapper">
                    <div className="container">
                        <div className="pbmit-title-bar-content">
                            <div className="pbmit-title-bar-content-inner">
                                <div className="pbmit-tbar">
                                    <div className="pbmit-tbar-inner container">
                                        <h1 className="pbmit-tbar-title">Tenders</h1>
                                    </div>
                                </div>
                                <div className="pbmit-breadcrumb">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Title Bar End */}

                {/* Page Content */}
                <div className="page-content">

                    {/* Project Details */}
                    <section className="section-lgx">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 service-left-col order-2 order-lg-1">
                                    <aside className="service-sidebar">
                                        <aside className="widget post-list">
                                            <h2 className="widget-title" style={{ cursor: 'pointer' }}
                                                >Tenders</h2>
                                            <div className="all-post-list">
                                                <ul>
                                                    <li className="post-active"><Link to="#"
                                                        onClick={() => {
                                                            setSelecteddiv('service-1');
                                                        }}>Event Calender</Link></li>
                                                    <li><Link to="#"
                                                        onClick={() => {
                                                            setSelecteddiv('service-2');
                                                        }}>List of Tenders</Link></li>
                                                    
                    
                                                </ul>
                                            </div>
                                        </aside>
                                        
                                    </aside>
                                </div>

                                <div className="col-lg-8 service-right-col order-1">
                                    <div className="service-details maindiv" style={selecteddiv == 'maindiv' ? { display: 'block' } : { display: 'none' }}>
                                    
                                    </div>

                                    <div className='service-1' style={selecteddiv == 'service-1' ? { display: 'block' } : { display: 'none' }}>
                                       <br/>
                                       <br/>      
                                        {/* <Calendar/> */}
                                    </div>

                                    <div className='service-2' style={selecteddiv == 'service-2' ? { display: 'block' } : { display: 'none' }}>
                                       <TenderGridView/>
                                    </div>

                                    <div className='service-3' style={selecteddiv == 'service-3' ? { display: 'block' } : { display: 'none' }}>
                                        
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Project Details End */}

                </div>
                {/* Page Content End */}

            </div>
            {/* Page Wrapper End */}

            {/* Search Box Start Here */}
            <div className="pbmit-search-overlay">
                <div className="pbmit-icon-close"></div>
                <div className="pbmit-search-outer">
                    <form className="pbmit-site-searchform">
                        <input type="search" className="form-control field searchform-s" name="s" placeholder="Type Word Then Press Enter" />
                        <button type="submit">
                            <i className="pbmit-base-icon-search-1"></i>
                        </button>
                    </form>
                </div>
            </div>
            {/* Search Box End Here */}
        </div>
    );
}

export default Tenders;