import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
export default function WorkDetails() {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Sr. No</TableCell>
                        <TableCell align="center">Name of the Institute/Organization</TableCell>
                        <TableCell align="center">Designation</TableCell>
                        <TableCell align="center">Date of Joining</TableCell>
                        <TableCell align="center">Date of Relieving</TableCell>
                        <TableCell align="center">Salary Per Month</TableCell>
                        <TableCell align="center">Main Job Profile</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            1
                        </TableCell>

                        <TableCell align="right">
                            <TextField required id="outlined-basic" variant="outlined" />
                        </TableCell>
                        <TableCell align="right">
                            <TextField required id="outlined-basic" variant="outlined" />
                        </TableCell>
                        <TableCell align="right">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker views={['year', 'month']}
                                        label="DOJ" />
                                </DemoContainer>
                            </LocalizationProvider>

                        </TableCell>
                        <TableCell align="right">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker views={['year', 'month']}
                                        label="DOR" />
                                </DemoContainer>
                            </LocalizationProvider>
                        </TableCell>
                        <TableCell align="right">
                            <TextField
                                min="0"
                                id="outlined-number"
                                // label="Number"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <TextField required id="outlined-basic" variant="outlined" />
                        </TableCell>



                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            2
                        </TableCell>

                        <TableCell align="right">
                            <TextField required id="outlined-basic" variant="outlined" />
                        </TableCell>
                        <TableCell align="right">
                            <TextField required id="outlined-basic" variant="outlined" />
                        </TableCell>
                        <TableCell align="right">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker views={['year', 'month']}
                                        label="DOJ" />
                                </DemoContainer>
                            </LocalizationProvider>

                        </TableCell>
                        <TableCell align="right">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker views={['year', 'month']}
                                        label="DOR" />
                                </DemoContainer>
                            </LocalizationProvider>
                        </TableCell>
                        <TableCell align="right">
                            <TextField
                                min="0"
                                id="outlined-number"
                                // label="Number"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <TextField required id="outlined-basic" variant="outlined" />
                        </TableCell>



                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            3
                        </TableCell>

                        <TableCell align="right">
                            <TextField required id="outlined-basic" variant="outlined" />
                        </TableCell>
                        <TableCell align="right">
                            <TextField required id="outlined-basic" variant="outlined" />
                        </TableCell>
                        <TableCell align="right">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker views={['year', 'month']}
                                        label="DOJ" />
                                </DemoContainer>
                            </LocalizationProvider>

                        </TableCell>
                        <TableCell align="right">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker views={['year', 'month']}
                                        label="DOR" />
                                </DemoContainer>
                            </LocalizationProvider>
                        </TableCell>
                        <TableCell align="right">
                            <TextField
                                min="0"
                                id="outlined-number"
                                // label="Number"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <TextField required id="outlined-basic" variant="outlined" />
                        </TableCell>



                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}