import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../../../constants";

export const AddNTMTrainingForm = createAsyncThunk(
  "addtrainingdata",
  async (data) => {
    // const json=JSON.stringify(data)
    // console.log("here")
    const res = await publicRequest.post("/addtrainingdata", data);
    if (res) {
      // console.log(res);
      if (res.data.status) {
        alert(res.data.message)
      }
      return res.data
    }

  }
);


const NTMTrainingFormSlice = createSlice({
  name: "addtrainingdata",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AddNTMTrainingForm.fulfilled, (state, action) => {
      return action.payload
    })
  }
});

export default NTMTrainingFormSlice.reducer;  