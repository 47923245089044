import React, { createContext, useContext, useState, useEffect } from 'react';

const FontSizeContext = createContext();

export const useFontSize = () => {
  return useContext(FontSizeContext);
};

export const FontSizeProvider = ({ children }) => {
  const [fontSize, setFontSize] = useState(18);
  const [fontSizexs, setFontSizexs] = useState(16);
  const [fontSizetitle, setFontSizetitle] = useState(30);
  const [fontSizesubtitle, setFontSizesubtitle] = useState(22);
  const [fontSizexxs, setFontSizexxs] = useState(14);
  const [fontSizexxl , setFontSizexxl] = useState(36);
  const [fontSizexl, setFontSizexl] = useState(25);
  const [fontSizel, setFontSizel] = useState(20);
  const [fontSizeChart, setFontSizeChart] = useState(17);
  useEffect(() => {
    document.documentElement.style.setProperty('--global-font-size', `${fontSize}px`);
    document.documentElement.style.setProperty('--global-font-size-title', `${fontSizetitle}px`);
    document.documentElement.style.setProperty('--global-font-size-sub-title', `${fontSizesubtitle}px`);
    document.documentElement.style.setProperty('--global-font-size-sub-title-xs', `${fontSizexs}px`);
    document.documentElement.style.setProperty('--global-font-size-xxs', `${fontSizexxs}px`);
    document.documentElement.style.setProperty('--global-font-size-xl', `${fontSizexl}px`);
    document.documentElement.style.setProperty('--global-font-size-xxl', `${fontSizexxl}px`);
    document.documentElement.style.setProperty('--global-font-size-l', `${fontSizel}px`);
    document.documentElement.style.setProperty('--global-font-size-chart', `${fontSizeChart}px`);
  }, [fontSize]);

    const increaseFontSize = () => {
    setFontSize(prevSize => prevSize + 2);
    setFontSizetitle(prevSize => prevSize + 2);
    setFontSizesubtitle(prevSize => prevSize + 2);
    setFontSizexs(prevSize => prevSize + 2);
    setFontSizexxs(prevSize => prevSize + 2);
    setFontSizexl(prevSize => prevSize + 2);
    setFontSizexxl(prevSize => prevSize + 2);
    setFontSizel(prevSize => prevSize + 2);
    setFontSizeChart(prevSize => prevSize + 2);
  };

  const decreaseFontSize = () => {
    setFontSize(prevSize => (prevSize > 2 ? prevSize - 2 : prevSize));
    setFontSizetitle(prevSize => (prevSize > 2 ? prevSize - 2 : prevSize));
    setFontSizesubtitle(prevSize => (prevSize > 2 ? prevSize - 2 : prevSize));
    setFontSizexs(prevSize => (prevSize > 2 ? prevSize - 2 : prevSize));
    setFontSizexxs(prevSize => (prevSize > 2 ? prevSize - 2 : prevSize));
    setFontSizexl(prevSize => (prevSize > 2 ? prevSize - 2 : prevSize));
    setFontSizexxl(prevSize => (prevSize > 2 ? prevSize - 2 : prevSize));
    setFontSizel(prevSize => (prevSize > 2 ? prevSize -2 : prevSize));
    setFontSizeChart(prevSize => (prevSize > 2 ? prevSize -2 : prevSize));
  };
  const resetFontSize = () => {
    setFontSize(18);
    setFontSizetitle(30);
    setFontSizesubtitle(22);
    setFontSizexs(16);
    setFontSizexxs(14);
    setFontSizexl(26);
    setFontSizexxl(36);
    setFontSizel(20);
    setFontSizeChart(17);
  };

  return (
    <FontSizeContext.Provider value={{fontSizetitle, fontSize, increaseFontSize, decreaseFontSize,resetFontSize }}>
      {children}
    </FontSizeContext.Provider>
  );
};