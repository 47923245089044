import React from 'react'
// import AboutImage1 from '../../svgs/greentech-svgrepo-com (1).svg'
// import AboutImage2 from '../../svgs/renewable-energy-svgrepo-com.svg'
// import AboutImage3 from '../../svgs/ecologic-petroleum-svgrepo-com.svg'
// import AboutImage4 from '../../svgs/earth-day-ecology-svgrepo-com (1).svg'
// import AboutImage5 from '../../svgs/oil-platform-gas-svgrepo-com (1).svg'
// import AboutImage6 from '../../svgs/human-resources.png'
// import AboutImage6 from "../../svgs/human-resources1.png"
// import AboutImage1 from "../../Images_1/Departments/DepartmentAnimationImages/1.png"
// import AboutImage2 from "../../Images_1/Departments/DepartmentAnimationImages/2.png"
// import AboutImage3 from "../../Images_1/Departments/DepartmentAnimationImages/3.png"
// import AboutImage4 from "../../Images_1/Departments/DepartmentAnimationImages/4.png"
// import AboutImage5 from "../../Images_1/Departments/DepartmentAnimationImages/5.png"
// import AboutImage6 from "../../Images_1/Departments/DepartmentAnimationImages/6.png"
import './Services.css'
// import germi from "../../Images_1/Departments/DepartmentAnimationImages/7.png"
function Services() {

  return (
    <div>
      <section className='service-section'>
        <div className='main-service-div ourdepartment-bottom-spacing'>
          <div className=''>

            <div
              className='left-service-div'
            >
              <div className="image-container">
                {/* <img className='image-box' src={AboutImage1} alt='' /> */}
                <div className='ltline'>
                  <svg width="100%" height="193" viewBox="0 0 437 193" fill="none" xmlns="http://www.w3.org/2000/svg" className="overflow-visible svg">
                    <path id="path" d="M0 1H122.022C130.298 1 137.01 7.70248 137.022 15.9782L137.175 122.029C137.187 130.302 143.895 137.003 152.168 137.007L275.007 137.066C283.289 137.07 290 143.785 290 152.066V177C290 185.284 296.716 192 305 192H437" stroke="#DCE0D3" strokeWidth="2.5" stroke-dasharray="5 3"></path>
                    <defs>
                      <filter id="dropshadow" x="-40%" y="-40%" width="180%" height="180%" filterUnits="userSpaceOnUse">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="7" />
                        <feOffset dx="5" dy="5" result="offsetblur" />
                        <feOffset dx="-5" dy="-5" result="offsetblur" />
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                        <feDropShadow floodColor="chartreuse" dx='0' dy="0" />
                      </filter>
                    </defs>
                    <circle id="dot" r="7.5" fill="#bfef59" filter="url(#dropshadow)">
                      <animateMotion dur="3.5s" repeatCount="indefinite" keyPoints="1;0" keyTimes="0;1" calcMode="linear">
                        <mpath href="#path" />
                      </animateMotion>
                    </circle>
                  </svg>
                </div>
              </div>


              <div className='center-left-icon'>

                <div className="image-container-center-left">
                  {/* <img className='image-box' src={AboutImage2} alt='' /> */}
                  <div className='mtline'>

                    <svg width="100%" height="2" viewBox="0 0 373 2" fill="none" xmlns="http://www.w3.org/2000/svg" className="overflow-visible svg">
                      <path id="path-2" d="M0 1H373" stroke="#DCE0D3" strokeWidth="2.5" stroke-dasharray="5 3"></path>

                      <circle
                        id="dot"
                        r="7.5"
                        fill="#bfef59"
                        filter="url(#dropshadow)">
                        <animateMotion dur="3.5s" repeatCount="indefinite" keyPoints="1;0" keyTimes="0;1" calcMode="linear">
                          <mpath href="#path-2" />
                        </animateMotion>
                      </circle>

                    </svg>
                  </div>
                </div>
              </div>
              <div className='left-service-div'>
                <div className="image-container">
                  {/* <img className='image-box' src={AboutImage3} alt='' /> */}
                  <div className='btline'>
                    <svg width="100%" height="193" viewBox="0 0 437 193" fill="none" xmlns="http://www.w3.org/2000/svg" className="overflow-visible svg">
                      <path id="path-3" d="M0 192H122.022C130.298 192 137.01 185.298 137.022 177.022L137.175 70.971C137.187 62.6981 143.906 55.9968 152.179 55.9928C198.05 55.9707 229.13 55.9557 274.996 55.9336C283.278 55.9296 290 49.215 290 40.9336V16C290 7.7157 296.716 0.999985 305 0.999985H437" stroke="#DCE0D3" strokeWidth="2.5" stroke-dasharray="5 3"></path>
                      <circle id="dot" r="7.5" fill="#bfef59" filter="url(#dropshadow)">
                        <animateMotion dur="3.5s" repeatCount="indefinite" keyPoints="1;0" keyTimes="0;1" calcMode="linear">
                          <mpath href="#path-3" />
                        </animateMotion>
                      </circle>
                    </svg>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div className='image-box1'>
            {/* <span className=''>GERMI</span> */}
            {/* <img src={germi} className='center-service-div ' alt="" /> */}
          </div>
          <div className='right-service-div'>
            <div className="image-container2">
              {/* <img className='image-box' src={AboutImage4} alt='' /> */}
              <div className="trline">
                <svg width="100%" height="193" viewBox="0 0 437 193" fill="none" xmlns="http://www.w3.org/2000/svg" className="overflow-visible svg ">
                  <path id="path-4" d="M437 1H314.978C306.702 1 299.99 7.70248 299.978 15.9782L299.825 122.029C299.813 130.302 293.105 137.003 284.832 137.007L161.993 137.066C153.711 137.07 147 143.785 147 152.066V177C147 185.284 140.284 192 132 192H0" stroke="#DCE0D3" strokeWidth="2.5" stroke-dasharray="5 3"></path>
                  <circle
                    id="dot"
                    r="7.5"
                    fill="#bfef59"
                    filter="url(#dropshadow)">
                    <animateMotion dur="3.5s" repeatCount="indefinite" keyPoints="1;0" keyTimes="0;1" calcMode="linear">
                      <mpath href="#path-4" />
                    </animateMotion>
                  </circle>
                </svg>
              </div>
            </div>
            <div className='center-right-icon'>
              <div className="image-container1">
                {/* <img className='image-box' src={AboutImage5} alt='' /> */}

                <div className="mrline">
                  <svg width="100%" height="2" viewBox="0 0 373 2" fill="none" xmlns="http://www.w3.org/2000/svg" className="overflow-visible svg">
                    <path id="path-5" d="M373 1H0" stroke="#DCE0D3" strokeWidth="2.5" stroke-dasharray="5 3"></path>
                    <circle
                      id="dot"
                      r="7.5"
                      fill="#bfef59"
                      filter="url(#dropshadow)">
                      <animateMotion dur="3.5s" repeatCount="indefinite" keyPoints="1;0" keyTimes="0;1" calcMode="linear">
                        <mpath href="#path-5" />
                      </animateMotion>
                    </circle>

                  </svg>
                </div>
              </div>
            </div>
            <div className='right-service-div'>
              <div className="image-container2">
                {/* <img className='image-box' src={AboutImage6} alt='' /> */}

                <div className="brline">
                  <svg width="100%" height="193" viewBox="0 0 437 193" fill="none" xmlns="http://www.w3.org/2000/svg" className="overflow-visible svg">
                    <path id="path-6" d="M437 192H314.978C306.702 192 299.99 185.298 299.978 177.022L299.825 70.971C299.813 62.6981 293.105 55.9968 284.832 55.9928L161.993 55.9336C153.711 55.9296 147 49.215 147 40.9336V16C147 7.7157 140.284 0.999985 132 0.999985H0" stroke="#DCE0D3" strokeWidth="2.5" stroke-dasharray="5 3"></path>
                    <circle
                      id="dot"
                      r="7.5"
                      fill="#bfef59"
                      filter="url(#dropshadow)">
                      <animateMotion dur="3.5s" repeatCount="indefinite" keyPoints="1;0" keyTimes="0;1" calcMode="linear">
                        <mpath href="#path-6" />
                      </animateMotion>
                    </circle>
                  </svg>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}
export default Services;