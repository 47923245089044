import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const Tender = createAsyncThunk('TenderData', async() => {
  const response = await publicRequest.get('/tenderlist');
  return response.data.data;
});

const TenderDataSlice = createSlice({
  name: 'TenderData',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(Tender.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default TenderDataSlice.reducer;