import React from 'react'
import './NewSuccessStories.css'
import { Link, useNavigate } from 'react-router-dom'

function NewSuccessStoriesData(props) {
    const imageSource = props.imageSrc;
    const title = props.title;
    const desc = props.desc;

    const navigate = useNavigate()

    const handleNavigate = (data) => () => {
        navigate("/", { state: { id: data[0], name: data[1] } });
    };

    return (
        <div>
            <div className='nss-wrapper'>
                <div className='nss-items'>
                    <div className='nss-image-div'>
                        <img className='nss-image' src={imageSource} />
                    </div>
                    <div className='nss-content'>
                        <div className='nss-title'>
                            <h2>{title}</h2>
                        </div>
                        <p className='normal-text'>
                            {desc.length > 400?desc.substring(0,400)+"...":desc}
                        </p>
                        <div className='nss-see-more-text-card'>
                            <button className='see-more-button-card'><Link to={'/successstories/'+props.id+"/"+props.did} state={props.id}><h6 className=' text-color-card static-small-text'>Read More</h6></Link></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewSuccessStoriesData;
