import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const Updates = createAsyncThunk('updatesdata', async() => {
  const response = await publicRequest.get('/updateslist');
  // console.log(response + "helo");
  return response.data;
});

const UpdatessDataSlice = createSlice({
  name: 'updatesdata',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(Updates.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default UpdatessDataSlice.reducer;