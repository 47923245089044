import axios from 'axios';

//export const BASE_URL = "http://192.168.0.99:8080/germi/API/";
  export const BASE_URL = "https://germi.site/API/API/";
  export const BASE_URL_Image = "https://germi-public.s3.ap-south-1.amazonaws.com/";
  // export const BASE_URL_Link = "https://13.202.53.23/";
  export const BASE_URL_Link = "https://germi.org";
export const publicRequest = axios.create({
    baseURL:BASE_URL,
    // headers:{
    //     "Access-Control-Allow-Origin":"*"
    //     // "x-api-key":"e387ef6beb3e8ed4e96c0096"
    // }
});