import React, { useEffect, useRef } from 'react'
import './Home2ServicesNew.css'
import { Link, useParams } from 'react-router-dom'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import AboutImage1 from '../../../Images_1/Home/AboutImages/NTM.png'
import AboutImage2 from '../../../Images_1/Home/AboutImages/RM.png'
import AboutImage3 from '../../../Images_1/Home/AboutImages/PRM.png'
import AboutImage4 from '../../../Images_1/Home/AboutImages/EM.png'
import AboutImage5 from '../../../Images_1/Home/AboutImages/PM.png'
import OnScrollAnimation from '../../OnScrollAnimation';
import Clients from '../Clients/Clients';

export default function Home2ServicesNew() {
    const { id } = useParams();
    const topWrapperRef = useRef(null);
    const topWrapperRef1 = useRef(null);
    const topWrapperRef2 = useRef(null);
    const topWrapperRef3 = useRef(null);

    useEffect(() => {
        if (id == 0) {
            topWrapperRef.current.scrollIntoView({ behavior: 'smooth' });
  
              // Set focus on the div
              topWrapperRef.current.focus();
           
      }else if (id == 1) {
          topWrapperRef1.current.scrollIntoView({ behavior: 'smooth' });

            // Set focus on the div
            topWrapperRef1.current.focus();
         
    } else if (id == 2){
          topWrapperRef2.current.scrollIntoView({ behavior: 'smooth' });

            // Set focus on the div
            topWrapperRef2.current.focus();
          
    }else{
           topWrapperRef3.current.scrollIntoView({ behavior: 'smooth' });

            // Set focus on the div
            topWrapperRef3.current.focus();
          
    }
}, [id]);
    return (
        <>
            {/* <section className='home2-service-section'>
                <div className='home2-service-container div1'>
                    <div className='home2-service-div'>
                        <div className='sticky-div pq-sticky-top'>
                            <span className='sticky-class'>Consulting</span>
                        </div>
                        <div className='scroll-div'>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>New Technology Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>Renewable Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>Petroleum Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>Environment Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>Petroleum Research Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='home2-service-container div2'>

                    <div className='home2-service-div div2-2'>
                        <div className='sticky-div pq-sticky-top'>
                            <span className='sticky-class'>Research</span>
                        </div>
                        <div className='scroll-div'>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>New Technology Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>Renewable Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>Petroleum Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>Environment Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                            <div className='service-sub-div'>
                                <div className='heading-flex-ss-home2'>
                                    <div className='icon-div-ss-home2'><DvrIcon className='icon-class' fontSize='medium' /></div>
                                    <div>
                                        <h4 className='home2-service-heading'>Petroleum Research Management</h4>
                                        <span className='home2-service-text'>IT enables the collection, analysis</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section ref={topWrapperRef} className='bg'>
                {/* <div className='heading-container'>
                    <h4 className='title-animation-about-us'>Our Services</h4>
                    <div id="title1" className='title-animation-about-us'>GERMI offers comprehensive services including expert Advisory & Consulting, cutting-edge Research & Development, and tailored Training & Skill Development across our five specialized departments in Energy Sector.</div>
                    <div className=' div-for-hr-animation'>
                        <hr id='hr1' className='animate-hr-tag-about-us' />
                        <hr id='hr2' className='animate-hr-tag-about-us' />
                    </div>
                </div> */}
                <div className='heading-main'>
                <OnScrollAnimation title1="services1" title2="services2" hr1="services3" hr2="services4" heading="Our Services" subheading="GERMI offers comprehensive services including expert Advisory & Consulting, cutting-edge Research & Development, and tailored Training & Skill Development across our five specialized departments in Energy Sector" />
                </div>
                <section ref={topWrapperRef1} className='home2-services-section consulting-bg'>
                    <div className='bg-dark-div'>
                        <div className='home2-services-div-container'>
                            <div className='home2-services-div'>
                                <div className='home2-services-div-content home2-services-div-content-alt-1'>
                                    <div className="cornered-heading">
                                        <h2 className="home2-services-div-heading heading-fonts">Advisory & Consulting</h2>
                                    </div>

                                    <div className='home2-services-div-text-container'>
                                        <span className='home2-services-div-text normal-text'>
                                            GERMI extends specialized services within the expansive domain of the energy sector, encompassing strategic guidance, technical expertise, and tailored solutions across various facets such as renewable energy, energy efficiency, and policy formulation.
                                        </span>
                                    </div>
                                    {/* <div className='home2-services-depts-container'>
                                        <div className='home2-services-depts-grid'>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>New Technology Management</Link>
                                                </div>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Renewable Management</Link>
                                                </div>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Petroleum Management</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='home2-services-depts-grid-2'>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Environment Management</Link>
                                                </div>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Petroleum Research Management</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='home2-services-depts-container'>
                                        <div className='home2-services-depts-grid grid-3-3'>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/0/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">  Renewable <br />Policy </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/0/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">   Roadmap for Renewable Sector</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/renewable-management/0/3'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage2} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Solar Project Management Consultancy (PMC)</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/renewable-management/0/4'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage2} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Third Party Inspections</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/petroleum-management/0/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage3} className="service-icon" alt="dept-1" />
                                                            </div>

                                                            <div className="service-text-center">  Oil & Gas</div>
                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/petroleum-management/0/1'>
                                                    <div className='home2-services-depts'>
                                                        <div >
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage3} className="service-icon" alt="dept-1" />
                                                            </div>

                                                        </div>
                                                        <div className="service-text-center">    Energy <br />Transition</div>

                                                    </div>
                                                </Link>

                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/environment-management/0/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage4} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Environmental <br /> Audit</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/environment-management/0/4'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage4} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Design Verification and Adequacy of (EMS)</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/petroleum-research-management/0/6'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage5} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Compressed Biogas (CBG)</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            {/* <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font' to='departments/petroleum-research-management/0/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage5} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Crude Oil <br/>Analysis</div>
                                                    </div>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section ref={topWrapperRef2} className='home2-services-section research-bg'>
                    <div className='bg-dark-div'>
                        <div className='home2-services-div-container'>
                            <div className='home2-services-div-alt'>
                                <div className='home2-services-div-content home2-services-div-content-alt-1'>
                                    <div className="cornered-heading">
                                        <h2 className="home2-services-div-heading heading-fonts">Research & Development</h2>
                                    </div>
                                    <div className='home2-services-div-text-container'>
                                        <span className='home2-services-div-text normal-text'>
                                            GERMI leads groundbreaking advancements in Energy Research through its comprehensive Research & Development services, shaping the future of sustainable energy.
                                        </span>
                                    </div>
                                    {/* <div className='home2-services-depts-container'>
                                        <div className='home2-services-depts-grid'>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>New Technology Management</Link>
                                                </div>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Renewable Management</Link>
                                                </div>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Petroleum Management</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='home2-services-depts-grid-2'>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Environment Management</Link>
                                                </div>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Petroleum Research Management</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='home2-services-depts-container'>
                                        <div className='home2-services-depts-grid grid-3-3'>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/1/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Solar Cooking <br />System</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/1/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> 150 TR Solar <br />Thermal AC</div>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/renewable-management/1/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage2} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Graphene & 2D Material Based<br />Super Capacitor</div>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/petroleum-management/1/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage3} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">  Geophysical <br />Analysis</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/petroleum-management/1/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage3} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">    Scientific <br />Computation </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/environment-management/1/3'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage4} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Bioremediation of Petroleum Contaminated Sites</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/environment-management/1/4'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage4} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">R&D of Enriched <br />Biogas Plant</div>
                                                    </div>
                                                </Link>
                                            </div>


                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/petroleum-research-management/1/3'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage5} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Hydrocarbon Alternates</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/petroleum-research-management/1/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage5} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Waste to <br />Energy</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section ref={topWrapperRef3} className='home2-services-section skillDev-bg'>
                    <div className='bg-dark-div'>
                        <div className='home2-services-div-container'>
                            <div className='home2-services-div'>
                                <div className='home2-services-div-content home2-services-div-content-alt-1'>
                                    <div className="cornered-heading">
                                        <h2 className="home2-services-div-heading heading-fonts">Training & Skill Development</h2>
                                    </div>
                                    <div className='home2-services-div-text-container'>
                                        <span className='home2-services-div-text normal-text'>
                                            GERMI helps people learn and grow in the field of Energy Research through training programs in various department.
                                        </span>
                                    </div>
                                    {/* <div className='home2-services-depts-container'>
                                        <div className='home2-services-depts-grid'>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>New Technology Management</Link>
                                                </div>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Renewable Management</Link>
                                                </div>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Petroleum Management</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='home2-services-depts-grid-2'>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Environment Management</Link>
                                                </div>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <div className='home2-services-depts'>
                                                    <EmojiObjectsIcon fontSize='large' />
                                                    <Link className='home2-services-depts-font'>Petroleum Research Management</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='home2-services-depts-container'>
                                        <div className='home2-services-depts-grid grid-3-3'>

                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/2/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Solar PV Design and Installation</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/2/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Electric Vehicle Technology</div>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/2/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Green Hydrogen PtX - Electrolyser and Fuel Cell Demonstration </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/2/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Floating <br />Solar PV</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/2/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Entrepreneurship in Green Hydrogen </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/new-technology-management/2/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Energy Storage and Battery Management Systems</div>
                                                    </div>
                                                </Link>
                                            </div>

                                            {/* <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font' to='departments/new-technology-management/2/0'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Entrepreneurship development - Electric Vehicles </div>
                                                    </div>
                                                </Link>
                                            </div> */}

                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/renewable-management/2/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage2} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Training on <br />Solar Design Software</div>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/petroleum-management/2/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage3} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">   Exploration & Development</div>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className='home2-services-depts-div'>
                                                <Link className='home2-services-depts-font small-text' to='/departments/environment-management/2/1'>
                                                    <div className='home2-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div">
                                                                <img src={AboutImage4} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">  Environmental Training & Awareness</div>
                                                    </div>
                                                </Link>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='clients-bg-div'>
                            <div className="container">
                                <div className=" text-center">
                                    <div className='pt-5'>
                                    <OnScrollAnimation title1="oc1" title2="oc2" hr1="oc3" hr2="oc4" heading="Our Clients" subheading="" />
                                    </div>

                                </div>
                                <Clients />
                            </div>
                        </section>
            </section>
        </>
    )
}

{/* <div className='home2-services-depts-container'>
    <div className='home2-services-depts-grid'>
        <div className='home2-services-depts-div'>
            <div className='home2-services-depts'>
                <EmojiObjectsIcon fontSize='large' />
                <Link className='home2-services-depts-font'>New Technology Management</Link>
            </div>
        </div>
        <div className='home2-services-depts-div'>
            <div className='home2-services-depts'>
                <EmojiObjectsIcon fontSize='large' />
                <Link className='home2-services-depts-font'>Renewable Management</Link>
            </div>
        </div>
        <div className='home2-services-depts-div'>
            <div className='home2-services-depts'>
                <EmojiObjectsIcon fontSize='large' />
                <Link className='home2-services-depts-font'>Petroleum Management</Link>
            </div>
        </div>
        <div className='home2-services-depts-div'>
            <div className='home2-services-depts'>
                <EmojiObjectsIcon fontSize='large' />
                <Link className='home2-services-depts-font'>Environment Management</Link>
            </div>
        </div>
        <div className='home2-services-depts-div'>
            <div className='home2-services-depts'>
                <EmojiObjectsIcon fontSize='large' />
                <Link className='home2-services-depts-font'>Petroleum Research Management</Link>
            </div>
        </div>
    </div>
</div> */}