import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const getNews = createAsyncThunk('getnewsbydept', async(id) => {

   
  const response = await publicRequest.post('/getnewsbydept',JSON.stringify(id));

  return response.data.data;
});

const DptNewsDataSlice = createSlice({
  name: 'getnewsbydept',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(getNews.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default DptNewsDataSlice.reducer;